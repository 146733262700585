import React from 'react';

const Confirm = props => {
    const { onConfirm, onAbort, confirmLabel, abortLabel, title, message } = props;
    return (
        <div className="hc-modal-active" tabIndex="-1" role="dialog">
            <div className="modal-dialog slide-up-animation" role="document">
                <div className="hc-modal-content">
                    <div className="hc-modal-header">
                        <div className="hc-header-content">
                            <img className="hc-header-img" src="/images/settings/icon_remove_popup.png" alt="warrning" />
                            <span className="hc-modal-title">{title}</span>
                        </div>
                        <button type="button" className="hc-modal-close" aria-label="Close" onClick={onAbort}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="hc-modal-body">
                        <p>{message}</p>
                    </div>
                    <div className="hc-modal-footer">
                        <button type="button" className="hc-modal-btn" onClick={onAbort} id="abortButton">
                            {abortLabel}
                        </button>
                        <button type="button" className="hc-modal-btn" onClick={onConfirm} id="confirmButton">
                            {confirmLabel}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Confirm;
