import React, { Component } from "react";
import "./message.css";
import appEnums from "../appEnums";
import Moment from "react-moment";
import moment from "moment";
import commonApi from "../api/commonApi";
// import AppEvent from "../appEvent";

export default class MsgListItem extends Component {
    constructor(props) {
        super(props);
        this.state = { windowWidth: 0, isIOS: false };
        this.androidTagSrc = "/images/message/icon_android.png";
        this.appleTagSrc = "/images/message/icon_apple.png";
        this.myNode = null;
    }

    componentDidMount() {
        const { msgData, selectedDeviceId } = this.props;
        msgData.deviceId.includes("-");
        this.setState({ isIOS: msgData.deviceId.includes("-") });
        if (msgData.deviceId === selectedDeviceId && this.myNode) this.myNode.click();
    }

    updateWindowDimensions = () => {
        if (this.props.msgData != null) this.setState({ windowWidth: window.innerWidth });
    };

    scrollIntoView = () => {
        if (this.myNode != null && this.myNode.scrollIntoView) this.myNode.scrollIntoView();
    };

    onSelected = () => {
        const { onSelectCallback } = this.props;
        if (onSelectCallback != null) onSelectCallback();
    };

    componentDidUpdate(prevProps, prevState) {
        const { bundleId, selectedDeviceId, msgData } = this.props;
        const isSelected =
            bundleId === msgData.bundleId && selectedDeviceId === msgData.deviceId && prevProps.selectedDeviceId !== selectedDeviceId;

        if (isSelected && this.myNode) {
            this.myNode.click(); // always do click
            const position = this.myNode.getBoundingClientRect();
            if (!(position.top >= 40 && position.bottom <= window.innerHeight)) this.scrollIntoView();
        }
    }

    render() {
        const { bundleId, selectedDeviceId, msgData, appEvent, searchKeyword } = this.props;

        let messagePreview = msgData.lastMessage ? msgData.lastMessage : "";
            
        const isSelected = bundleId === msgData.bundleId && selectedDeviceId === msgData.deviceId;
        const classNameIfSelected = isSelected ? "message-list-item active" : "message-list-item";

        let bulletStatusSrc = "";
        let messageTitleClass = "new-message";
        let messagePreviewClass = "message-preview";

        switch (msgData.state) {
            case appEnums.MessageStates.PriorityNew:
                messageTitleClass = msgData.lastMessageSource === appEnums.MessageSource.Server ? "closed-message" : "new-message";
                messagePreviewClass =
                    msgData.lastMessageSource === appEnums.MessageSource.Server ? "message-preview" : "message-preview active";
                bulletStatusSrc = "/images/menu-bar/message_prio-new.png";
                break;
            case appEnums.MessageStates.New:
                messageTitleClass = msgData.lastMessageSource === appEnums.MessageSource.Server ? "closed-message" : "new-message";
                messagePreviewClass =
                    msgData.lastMessageSource === appEnums.MessageSource.Server ? "message-preview" : "message-preview active";
                bulletStatusSrc = "/images/menu-bar/message_new_issues.png";
                break;
            case appEnums.MessageStates.PriorityOpen:
                messageTitleClass = "closed-message";
                bulletStatusSrc = "/images/menu-bar/message_prio-open.png";
                break;
            case appEnums.MessageStates.Open:
                messageTitleClass = "closed-message";
                bulletStatusSrc = "/images/menu-bar/message_ongoing.png";
                break;
            case appEnums.MessageStates.Pending:
                messageTitleClass = "closed-message";
                bulletStatusSrc = "/images/menu-bar/message_pending.png";
                break;
            case appEnums.MessageStates.Closed:
                messageTitleClass = "closed-message";
                bulletStatusSrc = "/images/menu-bar/message_closed.png";
                break;
            default:
                break;
        }

        moment.updateLocale("en", {
            relativeTime: {
                future: "in %s",
                past: "%s ago",
                s: "< 1 m",
                ss: "%d s",
                m: "1m",
                mm: "%d m",
                h: "1 h",
                hh: "%d h",
                d: "1 d",
                dd: "%d d",
                M: "1 M",
                MM: "%d M",
                y: "1 Y",
                yy: "%d Y"
            }
        });
        const timeText = msgData.timestamp ? (
            <Moment unix fromNow ago interval={30000}>
                {msgData.timestamp / 1000}
            </Moment>
        ) : (
            "↖"
        );
        const messageTags = (
            <div className="message-tags-div">
                <img src={this.state.isIOS ? this.appleTagSrc : this.androidTagSrc} alt="Device Icon" title="Device" />
                {<ConversationTagList tagList={msgData ? msgData.tags : []} appEvent={appEvent} />}
            </div>
        );

        return (
            <div className={classNameIfSelected} onClick={this.onSelected} ref={n => (this.myNode = n)}>
                <div className="message-item-header">
                    <img src={bulletStatusSrc} alt="status" className="message-state-bullet" />
                    <span className={"message-item-title " + messageTitleClass}>{commonApi.parseHtmlStringHighlight(messagePreview, searchKeyword)}</span>
                    <span className="message-item-timestamp">{timeText}</span>
                </div>
                <div className={messagePreviewClass}>{commonApi.parseHtmlStringHighlight(messagePreview, searchKeyword)}</div>
                {messageTags}
            </div>
        );
    }
}

const ConversationTagList = props => {
    // const { tagList, appEvent } = props;
    const { tagList } = props;
    const onClickEvent = event => {
        event.preventDefault();
        const { tag } = event.target.dataset;
        console.info(tag);
    };
    const view =
        tagList.length > 0
            ? tagList.map((tag, index) => {
                  return (
                      <button key={index} className="conversation-tag-btn" data-tag={tag} onClick={onClickEvent}>
                          {tag}
                      </button>
                  );
              })
            : null;
    return view;
};
