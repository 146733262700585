import React, { Component } from "react";
import "./instantPushVerificationUi.css";
import { QRCodeSVG } from "qrcode.react";
import webApi from "../api/webApi";
import appEnums from "../appEnums";
import AppMenuUi from "../appMenu/appMenuUi";

const WAITING = 1;
const ONGOING = 2;
const FAILED = 4;
const NEXT = 8;
const FINISHED = 16;
const CLOSED = 32;

class InstantPushVerificationUi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stepStateArray: [ONGOING, WAITING, WAITING, WAITING, WAITING, WAITING],
            stepCheckboxArray1: [false, false, false],
            stepCheckboxArray3: [false, false, false],
            error: "",

            platform: appEnums.Platform.None,
            appName: "",
            appKey: "",
            googleFcmServerKey: "",
            apnFilenameDev: "",
            apnPasswordDev: "",
            apnFilenameRelease: "",
            apnPasswordRelease: "",
            androidState: false,
            iosDevelopment: false,
            iosProduction: false,

            deviceModel: "",
            isDebug: 0,
            appVersion: "",
            hv: "",
            deviceId: "",
            isPushEnabled: 0,
            firebaseId: "",
            deviceToken: "",
            matchAndroidDevice: false,
            isSendingPush: false
        };

        this.stopWaiting = this.stopWaiting.bind(this);
        this.dismissError = this.dismissError.bind(this);
        this.fetchGetAppSetting = this.fetchGetAppSetting.bind(this);
        this.onFetchAppSetting = this.onFetchAppSetting.bind(this);
        this.onChangeApp = this.onChangeApp.bind(this);
        this.initKeyboardShortcuts = this.initKeyboardShortcuts.bind(this);
        this.removeKeyboardShortcuts = this.removeKeyboardShortcuts.bind(this);
        this.testStep = this.testStep.bind(this);
        this.setStepState = this.setStepState.bind(this);
        this.isInStates = this.isInStates.bind(this);
        this.onNextStep = this.onNextStep.bind(this);
        this.initStep = this.initStep.bind(this);
        this.onClickStepTitle = this.onClickStepTitle.bind(this);
        this.getStepImage = this.getStepImage.bind(this);
        this.getCheckboxLi = this.getCheckboxLi.bind(this);
        this.getNextButtonUi = this.getNextButtonUi.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);
        this.checkStep1 = this.checkStep1.bind(this);
        this.checkCertificate = this.checkCertificate.bind(this);
        this.onIgnoreCertificate = this.onIgnoreCertificate.bind(this);
        this.containAndroidPlatform = this.containAndroidPlatform.bind(this);
        this.noContainAndroidPlatform = this.noContainAndroidPlatform.bind(this);
        this.containIosPlatform = this.containIosPlatform.bind(this);
        this.noContainIosPlatform = this.noContainIosPlatform.bind(this);
        this.waitingForLinkDevice = this.waitingForLinkDevice.bind(this);
        this.onLinkDevice = this.onLinkDevice.bind(this);
        this.checkStep3 = this.checkStep3.bind(this);
        this.onRelinkDevice = this.onRelinkDevice.bind(this);
        this.checkPushToken = this.checkPushToken.bind(this);
        this.waitPushToken = this.waitPushToken.bind(this);
        this.onSendPush = this.onSendPush.bind(this);
        this.onRetrySendPushStep = this.onRetrySendPushStep.bind(this);
        this.waitingForOpenInstantPush = this.waitingForOpenInstantPush.bind(this);
        this.onOpenInstantPush = this.onOpenInstantPush.bind(this);
    }

    componentDidMount() {
        this.fetchGetAppSetting();
        window.addEventListener("beforeunload", this.stopWaiting);
        this.initKeyboardShortcuts();
    }

    componentWillUnmount() {
        this.stopWaiting();
        window.removeEventListener("beforeunload", this.stopWaiting);
        this.removeKeyboardShortcuts();
    }

    stopWaiting() {
        if (this.isInStates(this.state.stepStateArray[2], ONGOING)) {
            this.waitingForLinkDevice(false);
        } else if (this.isInStates(this.state.stepStateArray[3], ONGOING)) {
            this.waitPushToken(false);
        } else if (this.isInStates(this.state.stepStateArray[5], ONGOING)) {
            this.waitingForOpenInstantPush(false);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { bundleId } = this.props;
        if (prevProps.bundleId !== bundleId) {
            this.fetchGetAppSetting();
        }
    }

    dismissError() {
        this.setState({ error: "" });
    }

    fetchGetAppSetting() {
        const { bundleId, token } = this.props;
        webApi
            .fetchGetAppSetting(bundleId, token)
            .then(this.onFetchAppSetting)
            .catch(errDict => this.setState({ error: errDict.error }));
    }

    onFetchAppSetting(resDict) {
        this.setState({
            platform: resDict.platform,
            appName: resDict.appName,
            appKey: resDict.appKey,
            googleFcmServerKey: resDict.googleFcmServerKey,
            apnFilenameDev: resDict.apnFilenameDev,
            apnPasswordDev: resDict.apnPasswordDev,
            apnFilenameRelease: resDict.apnFilenameRelease,
            apnPasswordRelease: resDict.apnPasswordRelease,
            androidState: resDict.googleFcmServerKey ? true : false,
            iosDevelopment: resDict.apnFilenameDev && resDict.apnPasswordDev ? true : false,
            iosProduction: resDict.apnFilenameRelease && resDict.apnPasswordRelease ? true : false
        });
    }

    onChangeApp(bundleId) {
        const { onChangeApp } = this.props;
        if (onChangeApp === null) {
            return;
        }
        onChangeApp(bundleId);
        this.stopWaiting();
        this.setState(
            {
                stepStateArray: [ONGOING, WAITING, WAITING, WAITING, WAITING, WAITING],
                stepCheckboxArray1: [false, false, false],
                stepCheckboxArray3: [false, false, false],
                error: "",

                platform: appEnums.Platform.None,
                appName: "",
                appKey: "",
                googleFcmServerKey: "",
                apnFilenameDev: "",
                apnPasswordDev: "",
                apnFilenameRelease: "",
                apnPasswordRelease: "",
                androidState: false,
                iosDevelopment: false,
                iosProduction: false,

                deviceModel: "",
                isDebug: 0,
                appVersion: "",
                hv: "",
                deviceId: "",
                isPushEnabled: 0,
                firebaseId: "",
                deviceToken: "",
                matchAndroidDevice: false,
                isSendingPush: false
            },
            () => onChangeApp(bundleId)
        );
    }

    initKeyboardShortcuts() {
        const { shortcut } = this.props;
        if (shortcut != null) {
            if (window.navigator.userAgent.indexOf("Mac") >= 0) {
                shortcut.add("alt+n", this.testStep);
            }
        }
    }

    removeKeyboardShortcuts() {
        const { shortcut } = this.props;
        if (shortcut != null) {
            if (window.navigator.userAgent.indexOf("Mac") >= 0) {
                shortcut.remove("alt+n");
            }
        }
    }

    testStep() {
        for (let i = 0; i < this.state.stepStateArray.length; i++) {
            const state = this.state.stepStateArray[i];
            if (state !== ONGOING) {
                continue;
            }
            switch (i) {
                case 2:
                    this.setState(
                        {
                            deviceModel: "TestCafe Model",
                            isDebug: 0,
                            appVersion: "none",
                            hv: "none",
                            deviceId: "TestCafe DeviceId",
                            isPushEnabled: 1
                        },
                        this.checkStep3
                    );
                    break;
                case 3:
                    this.setStepState(3, NEXT);
                    break;
                case 4:
                    this.setState({ isSendingPush: false });
                    this.setStepState(4, NEXT);
                    break;
                case 5:
                    this.setStepState(5, FINISHED);
                    break;
                default:
                    break;
            }
            break;
        }
    }

    setStepState(stepIndex, value, callback) {
        let array = this.state.stepStateArray;
        if (value === CLOSED) {
            array[stepIndex] |= CLOSED;
        } else {
            array[stepIndex] = value;
        }
        this.setState({ stepStateArray: array }, callback);
    }

    isInStates(state, ...values) {
        let result = false;
        values.forEach(function (value) {
            if ((state & value) === value) {
                result = true;
            }
        });
        return result;
    }

    onNextStep(currentStepIndex) {
        let array = this.state.stepStateArray;
        if (this.isInStates(array[currentStepIndex], NEXT)) {
            if (currentStepIndex >= 0) {
                array[currentStepIndex] = FINISHED | CLOSED;
                if (currentStepIndex < 6) {
                    array[currentStepIndex + 1] = ONGOING;
                }
            }
            this.setState(
                {
                    stepStateArray: array
                },
                () => this.initStep(currentStepIndex + 1)
            );
        }
    }

    initStep(stepIndex) {
        switch (stepIndex) {
            case 2:
                this.waitingForLinkDevice(true);
                break;
            case 3:
                this.checkPushToken();
                break;
            case 5:
                this.waitingForOpenInstantPush(true);
                break;
            default:
                break;
        }
    }

    onClickStepTitle(stepIndex) {
        let array = this.state.stepStateArray;
        if (this.isInStates(array[stepIndex], CLOSED)) {
            array[stepIndex] &= ~CLOSED;
            this.setState({ stepStateArray: array });
        } else if (this.isInStates(array[stepIndex], FINISHED)) {
            array[stepIndex] |= CLOSED;
            this.setState({ stepStateArray: array });
        }
    }

    getStepImage(state) {
        if (this.isInStates(state, FINISHED, CLOSED)) {
            return <img src="/images/devconsole/icon_done_step.png" alt="closed icon" className="instant-push-verification-step-image" />;
        }

        switch (state) {
            case ONGOING:
            case NEXT:
                return (
                    <img
                        src="/images/devconsole/icon_current_step.png"
                        alt="ongoing or finished icon"
                        className="instant-push-verification-step-image"
                    />
                );

            case FAILED:
                return (
                    <img src="/images/devconsole/icon_failed_step.png" alt="failed icon" className="instant-push-verification-step-image" />
                );

            case WAITING:
            default:
                return (
                    <img
                        src="/images/devconsole/icon_inactive_step.png"
                        alt="waiting icon"
                        className="instant-push-verification-step-image"
                    />
                );
        }
    }

    getCheckboxLi(stepIndex, itemIndex, text) {
        const id = "instantPushVerificationStepCheckbox" + stepIndex + "" + itemIndex;
        let checked = false;
        let disabled = false;
        if (stepIndex === 0) {
            checked = this.state.stepCheckboxArray1[itemIndex];
            disabled = this.isInStates(this.state.stepStateArray[0], NEXT, FINISHED);
        } else if (stepIndex === 2) {
            checked = this.state.stepCheckboxArray3[itemIndex];
            disabled = this.isInStates(this.state.stepStateArray[2], NEXT, FINISHED);
        }

        return (
            <li>
                <div>
                    <input
                        type="checkbox"
                        id={id}
                        checked={checked}
                        disabled={disabled}
                        onChange={() => this.onChangeCheckbox(stepIndex, itemIndex)}
                    />
                    <label htmlFor={id}>{text}</label>
                </div>
            </li>
        );
    }

    onChangeCheckbox(stepIndex, itemIndex) {
        if (stepIndex === 0) {
            let array = this.state.stepCheckboxArray1;
            array[itemIndex] = !array[itemIndex];
            this.setState(
                {
                    stepCheckboxArray1: array
                },
                this.checkStep1
            );
        } else if (stepIndex === 2) {
            let array = this.state.stepCheckboxArray3;
            array[itemIndex] = !array[itemIndex];
            this.setState(
                {
                    stepCheckboxArray3: array
                },
                this.checkStep3
            );
        }
    }

    getNextButtonUi(stepIndex) {
        return (
            <button
                id={"instantPushVerificationNextButton" + stepIndex}
                type="button"
                alt="next button"
                title="next button"
                onClick={() => this.onNextStep(stepIndex)}
                className={
                    "instant-push-verification-next-button-" +
                    (this.isInStates(this.state.stepStateArray[stepIndex], NEXT) ? "enabled" : "disabled")
                }>
                NEXT
            </button>
        );
    }

    checkStep1() {
        const state = this.state.stepCheckboxArray1[0] && this.state.stepCheckboxArray1[1] && this.state.stepCheckboxArray1[2];
        this.setStepState(0, state ? NEXT : ONGOING);
    }

    checkCertificate() {
        const platform = this.state.platform;
        const checkPlatform = platform !== appEnums.Platform.None;
        const checkAndroid =
            (this.state.androidState && this.containAndroidPlatform(platform)) ||
            (!this.state.androidState && this.noContainAndroidPlatform(platform));
        const checkIos =
            (this.state.iosDevelopment && this.state.iosProduction && this.containIosPlatform(platform)) ||
            (!this.state.iosDevelopment && !this.state.iosProduction && this.noContainIosPlatform(platform));
        const state = checkPlatform && checkAndroid && checkIos;
        this.setStepState(1, state ? NEXT : FAILED);
    }

    onIgnoreCertificate() {
        this.setStepState(1, NEXT);
    }

    containAndroidPlatform(platform) {
        return (
            platform === appEnums.Platform.AndroidGoogle ||
            platform === appEnums.Platform.AndroidAmazon ||
            platform === appEnums.Platform.Unity ||
            platform === appEnums.Platform.None
        );
    }

    noContainAndroidPlatform(platform) {
        return platform === appEnums.Platform.iOS;
    }

    containIosPlatform(platform) {
        return platform === appEnums.Platform.iOS || platform === appEnums.Platform.Unity || platform === appEnums.Platform.None;
    }

    noContainIosPlatform(platform) {
        return platform === appEnums.Platform.AndroidGoogle || platform === appEnums.Platform.AndroidAmazon;
    }

    waitingForLinkDevice(isStart) {
        const { bundleId, appWebSocket } = this.props;
        appWebSocket
            .waitingForLinkDevice(bundleId, isStart)
            .then(this.onLinkDevice)
            .catch(errDict => this.setState({ error: errDict.error }));
    }

    onLinkDevice(resDict) {
        this.setState(
            {
                deviceModel: resDict.deviceModel,
                isDebug: resDict.debug,
                appVersion: resDict.appVersion,
                hv: resDict.hv,
                deviceId: resDict.deviceId,
                isPushEnabled: resDict.isPushEnabled,
                firebaseId: resDict.firebaseId,
                deviceToken: resDict.deviceToken,
                matchAndroidDevice: !resDict.deviceId.includes("-")
            },
            this.checkStep3
        );
    }

    checkStep3() {
        const state =
            this.state.stepCheckboxArray3[0] &&
            this.state.stepCheckboxArray3[1] &&
            this.state.stepCheckboxArray3[2] &&
            this.state.isPushEnabled;
        if (state) {
            this.setStepState(2, NEXT);
        }
    }

    onRelinkDevice() {
        this.stopWaiting();
        this.waitingForLinkDevice(true);
        let array = this.state.stepStateArray;
        array[2] = ONGOING;
        array[3] = WAITING;
        array[4] = WAITING;
        array[5] = WAITING;
        this.setState({
            stepCheckboxArray3: [false, false, false],
            stepStateArray: array,
            deviceModel: "",
            isDebug: 0,
            appVersion: "",
            hv: "",
            deviceId: "",
            isPushEnabled: 0,
            firebaseId: "",
            deviceToken: "",
            matchAndroidDevice: false
        });
    }

    checkPushToken() {
        let state = false;
        if (this.state.matchAndroidDevice) {
            state = this.containAndroidPlatform(this.state.platform) && this.state.firebaseId;
        } else {
            state = this.containIosPlatform(this.state.platform) && this.state.deviceToken;
        }
        this.setStepState(3, state ? NEXT : ONGOING);
        if (!state) {
            this.waitPushToken(true);
        }
    }

    waitPushToken(isStart) {
        const { bundleId, appWebSocket } = this.props;
        appWebSocket
            .waitingPushToken(bundleId, this.state.deviceId, this.state.matchAndroidDevice, isStart)
            .then(() => this.setStepState(3, NEXT))
            .catch(errDict => this.setState({ error: errDict.error }));
    }

    onSendPush() {
        this.setState(
            {
                isSendingPush: true
            },
            () => {
                const { bundleId, token } = this.props;
                webApi
                    .fetchSendInstantPush(bundleId, this.state.deviceId, token)
                    .then(() => {
                        this.setState({ isSendingPush: false });
                        this.setStepState(4, NEXT);
                    })
                    .catch(errDict => {
                        let array = this.state.stepStateArray;
                        array[4] = FAILED;
                        this.setState({
                            stepStateArray: array,
                            error: errDict.error,
                            isSendingPush: false
                        });
                    });
            }
        );
    }

    onRetrySendPushStep() {
        this.stopWaiting();
        let array = this.state.stepStateArray;
        array[4] = ONGOING;
        array[5] = WAITING;
        this.setState(
            {
                stepStateArray: array,
                isSendingPush: false
            },
            this.onSendPush
        );
    }

    waitingForOpenInstantPush(isStart) {
        const { bundleId, appWebSocket } = this.props;
        appWebSocket
            .waitingForOpenInstantPush(bundleId, this.state.deviceId, isStart)
            .then(this.onOpenInstantPush)
            .catch(errDict => this.setState({ error: errDict.error }));
    }

    onOpenInstantPush() {
        this.setStepState(5, FINISHED);
    }

    render() {
        const { appInfoDict, onClickCreateAppButton, bundleId, role, token, hcCompanyId } = this.props;

        const appPopupComponent = (
            <AppMenuUi
                hcCompanyId={hcCompanyId}
                appInfoDict={appInfoDict}
                bundleId={bundleId}
                onClickCreateAppButton={onClickCreateAppButton}
                onChangeApp={this.onChangeApp}
                token={token}
                role={role}
            />
        );

        let stepImageArray = [];
        for (let i = 0; i < 6; i++) {
            stepImageArray.push(this.getStepImage(this.state.stepStateArray[i]));
        }

        let isOpenedArray = [];
        let isFinishedArray = [];
        for (let i = 0; i < 6; i++) {
            isOpenedArray.push(!this.isInStates(this.state.stepStateArray[i], WAITING, CLOSED));
            isFinishedArray.push(this.isInStates(this.state.stepStateArray[i], FINISHED));
        }

        // step 1
        const stepContainerClassName1 = "instant-push-verification-step-container-" + (isOpenedArray[0] ? "opened" : "closed");
        const cursorStyle1 = isFinishedArray[0] ? { cursor: "pointer" } : {};
        const stepUi1 = isOpenedArray[0] ? (
            <div className="instant-push-verification-step-content">
                <p className="instant-push-verification-text">Do the following steps first before you test your push integration:</p>
                <p />
                <ul>
                    {this.getCheckboxLi(
                        0,
                        0,
                        "Integrate SDK 1.0.6 (Android v1.0.6.2, iOS v1.0.6_200318.0, Unity v1.0.6.2) or higher into your app"
                    )}
                    {this.getCheckboxLi(0, 1, "Follow the push-specific integration docs")}
                    {this.getCheckboxLi(0, 2, "Enable the test mode url scheme")}
                </ul>
                <p className="instant-push-verification-text">If you have completed the steps above, you can get started.</p>
                {this.getNextButtonUi(0)}
            </div>
        ) : null;

        // step 2
        const stepContainerClassName2 = "instant-push-verification-step-container-" + (isOpenedArray[1] ? "opened" : "closed");
        const cursorStyle2 = isFinishedArray[1] ? { cursor: "pointer" } : {};
        let stepUi2 = null;
        if (this.isInStates(this.state.stepStateArray[1], ONGOING)) {
            stepUi2 = (
                <div className="instant-push-verification-step-content">
                    <p className="instant-push-verification-text">Click the button below to start checking your push certificate.</p>
                    <button
                        type="button"
                        alt="start check certificate"
                        title="start check certificate"
                        onClick={this.checkCertificate}
                        className="instant-push-verification-start-button">
                        START
                    </button>
                    {this.getNextButtonUi(1)}
                </div>
            );
        } else if (this.isInStates(this.state.stepStateArray[1], FAILED, NEXT, FINISHED)) {
            const platform = this.state.platform;
            const okText = <span className="instant-push-verification-ok-text">OK</span>;
            const failedText = <span className="instant-push-verification-failed-text">FAILED</span>;

            const ignoreButtonUi =
                this.state.stepStateArray[1] === FAILED ? (
                    <button
                        type="button"
                        alt="ignore failed"
                        title="ignore failed"
                        onClick={this.onIgnoreCertificate}
                        className="instant-push-verification-button">
                        IGNORE FAILED
                    </button>
                ) : null;

            const platformText = Object.keys(appEnums.Platform)[platform];
            const platformUi = (
                <tr>
                    <td>Platform</td>
                    <td>
                        {platform === appEnums.Platform.None ? (
                            <span className="instant-push-verification-failed-text">{platformText}</span>
                        ) : (
                            <span className="instant-push-verification-ok-text">{platformText}</span>
                        )}
                    </td>
                </tr>
            );

            const androidStateUi = this.containAndroidPlatform(platform) ? (
                <tr>
                    <td>Google FCM Server Key</td>
                    <td>{this.state.androidState ? okText : failedText}</td>
                </tr>
            ) : null;
            const iosDevelopmentStateUi = this.containIosPlatform(platform) ? (
                <tr>
                    <td>Apple Push Notification Development Certificate (.p12)</td>
                    <td>{this.state.iosDevelopment ? okText : failedText}</td>
                </tr>
            ) : null;
            const iosProductionStateUi = this.containIosPlatform(platform) ? (
                <tr>
                    <td>Apple Push Notification Production Certificate (.p12)</td>
                    <td>{this.state.iosProduction ? okText : failedText}</td>
                </tr>
            ) : null;

            let description2 = "";
            if (this.state.stepStateArray[1] === FAILED) {
                description2 = (
                    <p className="instant-push-verification-failed-description">
                        <b>Your push certificate has failed.</b>
                    </p>
                );
            } else if (this.isInStates(this.state.stepStateArray[1], NEXT, FINISHED)) {
                description2 = (
                    <p className="instant-push-verification-ok-description">
                        <b>Your push certificate is successful!</b>
                    </p>
                );
            }

            stepUi2 = isOpenedArray[1] ? (
                <div className="instant-push-verification-step-content">
                    <div className="details-table-container">
                        {description2}
                        <table className="instant-push-verification-table">
                            <tbody>
                                {platformUi}
                                {androidStateUi}
                                {iosDevelopmentStateUi}
                                {iosProductionStateUi}
                            </tbody>
                        </table>
                        {ignoreButtonUi}
                        {this.getNextButtonUi(1)}
                    </div>
                </div>
            ) : null;
        }

        // step 3
        let stepUi3 = null;
        const stepContainerClassName3 = "instant-push-verification-step-container-" + (isOpenedArray[2] ? "opened" : "closed");
        const cursorStyle3 = isFinishedArray[2] ? { cursor: "pointer" } : {};
        let retryImage3 = null;
        if (this.isInStates(this.state.stepStateArray[2], ONGOING)) {
            const qrCodeValue = this.state.appKey
                ? webApi.getFrontendHost() + "/instantPush/public/" + bundleId + "/" + this.state.appKey
                : "";
            const qrCodeComponent = qrCodeValue ? (
                <p>
                    <br />
                    <QRCodeSVG value={qrCodeValue} size={150} />
                </p>
            ) : null;
            stepUi3 = (
                <div className="instant-push-verification-step-content" onKeyDown={e => this.onKeyDown(e)}>
                    <p className="instant-push-verification-text">
                        In this step, we&apos;ll link your device to the Instant Push Verification tool.
                    </p>
                    <p className="instant-push-verification-text">
                        This will allow us to send your device a push message, as well as check for basic things that can go wrong with your
                        integration.
                    </p>
                    <p className="instant-push-verification-text">Before you can complete this step you must:</p>
                    <p />
                    <ul>
                        {this.getCheckboxLi(2, 0, "Have " + this.state.appName + " installed in your device")}
                        {this.getCheckboxLi(2, 1, "Have your device connected to the internet")}
                        {this.getCheckboxLi(2, 2, "Have a QR Code Reader installed")}
                    </ul>
                    <p className="instant-push-verification-text">Then, scan the QR Code below to continue:</p>
                    {qrCodeComponent}
                    {this.getNextButtonUi(2)}
                </div>
            );
        } else if (this.isInStates(this.state.stepStateArray[2], NEXT, FINISHED)) {
            retryImage3 = (
                <img
                    alt="relink device"
                    title="relink device"
                    src="/images/devconsole/btn_reintegrate_device.png"
                    onClick={this.onRelinkDevice}
                    className="instant-push-verification-relink-device-image"
                />
            );
            stepUi3 = isOpenedArray[2] ? (
                <div className="instant-push-verification-step-content">
                    <div className="details-table-container">
                        <p className="instant-push-verification-ok-description">Success! Your device has been linked.</p>
                        <p className="instant-push-verification-text">
                            Should you make any changes or update your app&apos;s push integration, please reintegrate your device.
                        </p>
                        <table className="instant-push-verification-table">
                            <tbody>
                                <tr>
                                    <td>Model</td>
                                    <td>{this.state.deviceModel}</td>
                                </tr>
                                <tr>
                                    <td>Build Environment</td>
                                    <td>{this.state.isDebug ? "Development" : "Production"}</td>
                                </tr>
                                <tr>
                                    <td>App Version</td>
                                    <td>{this.state.appVersion}</td>
                                </tr>
                                <tr>
                                    <td>Helpchatter SDK Version</td>
                                    <td>{this.state.hv}</td>
                                </tr>
                                <tr>
                                    <td>Device ID</td>
                                    <td>{this.state.deviceId}</td>
                                </tr>
                                <tr>
                                    <td>Notification Enabled</td>
                                    <td>
                                        {this.state.isPushEnabled ? (
                                            <span className="instant-push-verification-ok-text">Yes</span>
                                        ) : (
                                            <span className="instant-push-verification-failed-text">No</span>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {this.getNextButtonUi(2)}
                    </div>
                </div>
            ) : null;
        }

        // step 4
        const stepContainerClassName4 = "instant-push-verification-step-container-" + (isOpenedArray[3] ? "opened" : "closed");
        const cursorStyle4 = isFinishedArray[3] ? { cursor: "pointer" } : {};
        let stepUi4 = null;
        if (isOpenedArray[3]) {
            const resultUi4 = this.isInStates(this.state.stepStateArray[3], NEXT, FINISHED) ? (
                <p className="instant-push-verification-ok-description">
                    Success! Your {this.state.matchAndroidDevice ? "Android firebase" : "iOS device"} is already sent to the server.
                </p>
            ) : (
                <span className="instant-push-verification-tint-text">
                    <img
                        src="/images/devconsole/buffer_white.png"
                        className="instant-push-verification-loading-image"
                        alt="message loading circle"
                    />
                    &nbsp;&nbsp; Checking for your push tokens, please wait&nbsp;&nbsp;
                </span>
            );
            stepUi4 = (
                <div className="instant-push-verification-step-content">
                    <p className="instant-push-verification-text">
                        We are now waiting to receive your push token. This gets uploaded after you&apos;ve accepted notifications.
                        <br />
                        If this step does not get completed within 3 minutes of that, it might indicate an integration problem.
                    </p>
                    {resultUi4}
                    {this.getNextButtonUi(3)}
                </div>
            );
        }

        // step 5
        const stepContainerClassName5 = "instant-push-verification-step-container-" + (isOpenedArray[4] ? "opened" : "closed");
        const cursorStyle5 = isFinishedArray[4] ? { cursor: "pointer" } : {};
        let stepUi5 = null;
        if (isOpenedArray[4]) {
            const buttonUi5 = this.isInStates(this.state.stepStateArray[4], ONGOING) ? (
                <button
                    type="button"
                    alt="send push"
                    title="send push"
                    onClick={this.onSendPush}
                    style={{ minWidth: "260px" }}
                    className="instant-push-verification-button">
                    SEND PUSH NOTIFICATION
                </button>
            ) : (
                <button
                    type="button"
                    alt="retry send push"
                    title="retry send push"
                    onClick={this.onRetrySendPushStep}
                    style={{ minWidth: "260px" }}
                    className="instant-push-verification-button">
                    RESEND PUSH NOTIFICATION?
                </button>
            );
            let stateUi5 = null;
            if (this.isInStates(this.state.stepStateArray[4], NEXT, FINISHED)) {
                stateUi5 = (
                    <p className="instant-push-verification-ok-description">Success! Push notification has been sent to your device.</p>
                );
            } else if (this.state.isSendingPush) {
                stateUi5 = (
                    <div>
                        <img
                            src="/images/devconsole/buffer_orange.png"
                            className="instant-push-verification-loading-image"
                            alt="message loading circle"
                        />
                        &nbsp;
                        <span className="instant-push-verification-text">Sending...</span>
                    </div>
                );
            }

            stepUi5 = (
                <div className="instant-push-verification-step-content">
                    <p className="instant-push-verification-text">
                        If your integration is functioning properly, you should receive a push notification on your device after clicking
                        the button below.
                    </p>
                    <img src="/images/devconsole/icon_warning.png" className="instant-push-verification-warning-image" alt="icon warning" />
                    <span className="instant-push-verification-text">Please make sure that your app is closed during this step.</span>
                    <p />
                    {buttonUi5}
                    {stateUi5}
                    {this.getNextButtonUi(4)}
                </div>
            );
        }

        // step 6
        const stepContainerClassName6 = "instant-push-verification-step-container-" + (isOpenedArray[5] ? "opened" : "closed");
        const cursorStyle6 = isFinishedArray[5] ? { cursor: "pointer" } : {};
        let stepUi6 = null;
        if (isOpenedArray[5]) {
            let stateUi6 = null;
            if (this.isInStates(this.state.stepStateArray[5], NEXT, FINISHED)) {
                stateUi6 = <p className="instant-push-verification-ok-description">Success! The push integration is successful.</p>;
            } else {
                stateUi6 = (
                    <span className="instant-push-verification-tint-text">
                        <img
                            src="/images/devconsole/buffer_white.png"
                            className="instant-push-verification-loading-image"
                            alt="message loading circle"
                        />
                        &nbsp; Waiting for your push notification to be tracked&nbsp;
                    </span>
                );
            }
            stepUi6 = (
                <div className="instant-push-verification-step-content">
                    <p className="instant-push-verification-text">Make sure that your app is properly tracking the push event.</p>
                    {stateUi6}
                    <br />
                    <br />
                    <br />
                </div>
            );
        }

        return (
            <div className="ui1-container-div-lg background-color-v2 slide-up-animation">
                <div className="background-color-v1">
                    <div className="instant-push-verification-menu-div">{appPopupComponent}</div>
                </div>
                <div className="background-color-v2">
                    {bundleId && (
                        <div id="instantPushVerificationPage">
                            <span className="instant-push-verification-heading">INSTANT PUSH VERIFICATION</span>
                            <span className="instant-push-verification-heading-description">
                                Test your push notification integration here.
                            </span>

                            <div className={stepContainerClassName1}>
                                {stepImageArray[0]}
                                <span
                                    className="instant-push-verification-step-title"
                                    style={cursorStyle1}
                                    onClick={() => this.onClickStepTitle(0)}>
                                    STEP 1. INTEGRATE PUSH
                                </span>
                                {stepUi1}
                            </div>

                            <div className={stepContainerClassName2}>
                                {stepImageArray[1]}
                                <span
                                    className="instant-push-verification-step-title"
                                    style={cursorStyle2}
                                    onClick={() => this.onClickStepTitle(1)}>
                                    STEP 2. CHECK CERTIFICATE
                                </span>
                                {stepUi2}
                            </div>

                            <div className={stepContainerClassName3}>
                                {stepImageArray[2]}
                                <span
                                    className="instant-push-verification-step-title"
                                    style={cursorStyle3}
                                    onClick={() => this.onClickStepTitle(2)}>
                                    STEP 3. LINK DEVICE
                                </span>
                                {retryImage3}
                                {stepUi3}
                            </div>

                            <div className={stepContainerClassName4}>
                                {stepImageArray[3]}
                                <span
                                    className="instant-push-verification-step-title"
                                    style={cursorStyle4}
                                    onClick={() => this.onClickStepTitle(3)}>
                                    STEP 4. PUSH TOKENS
                                </span>
                                {stepUi4}
                            </div>

                            <div className={stepContainerClassName5}>
                                {stepImageArray[4]}
                                <span
                                    className="instant-push-verification-step-title"
                                    style={cursorStyle5}
                                    onClick={() => this.onClickStepTitle(4)}>
                                    STEP 5. SEND PUSH
                                </span>
                                {stepUi5}
                            </div>

                            <div className={stepContainerClassName6}>
                                {stepImageArray[5]}
                                <span
                                    className="instant-push-verification-step-title"
                                    style={cursorStyle6}
                                    onClick={() => this.onClickStepTitle(5)}>
                                    STEP 6. OPEN PUSH
                                </span>
                                {stepUi6}
                            </div>

                            {this.state.error && (
                                <div className="instant-push-verification-error alert-info" onClick={this.dismissError}>
                                    <span>
                                        <button
                                            id="instantPushVerificationErrorState"
                                            type="button"
                                            className="close"
                                            aria-label="Close"
                                            onClick={this.dismissError}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                        {this.state.error}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default InstantPushVerificationUi;
