import React, { Component } from 'react';
import SqlString from 'sqlstring';
import * as emoji from 'node-emoji';
import './automationSettingUi.css';
import webApi from '../api/webApi';
import appConstants from '../appConstants';
import AppEvent from '../appEvent';
import appEnums from "../appEnums";
import AppMenuUi from "../appMenu/appMenuUi";
import { toast } from 'react-toastify';

class AutomationSettingUi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sendAutomatedResponse: false,
            initialSendAutomatedResponse: false,
            automatedResponseText: '',
            sendAutomatedMessage: false,
            initialSendAutomatedMessage: false,
            automatedMessageText: '',
            automatedMessageDays: 30,
            sendAutomatedResolve: false,
            initialSendAutomatedResolve: false,
            automatedResolveText: '',
            automatedResolveDays: 60,
            automatedDisplayName: 'Helpchatter Bot',
            initialAutomatedDisplayName: 'Helpchatter Bot',
            priorityWords: "",
            initialPriorityWords: "",
            defaultTranslationLanguage: "en",
            initialDefaultTranslationLanguage: "en",
            error: '',
            errorType: toast.TYPE.ERROR, // error, warning, success, default, info
            bundleId: ''
        };

        this.mainNode = null;
        this.toastId = '__Automation_Settings__';

        this.onSendAutomatedMessage = () => this.setState({ sendAutomatedMessage: !this.state.sendAutomatedMessage });
        this.onSendAutoResolve = () => this.setState({ sendAutomatedResolve: !this.state.sendAutomatedResolve });
        this.onSendAutoResponse = () => this.setState({ sendAutomatedResponse: !this.state.sendAutomatedResponse });
        this.handleDisplayNameChange = event => this.setState({ automatedDisplayName: event.target.value });
        this.handlePriorityWordsChange = event => this.setState({ priorityWords: event.target.value });
        this.handleDefaultTranslationLanguageChange = event => this.setState({ defaultTranslationLanguage: event.target.value })
        this.dismissError = () => this.setState({ error: '', errorType: toast.TYPE.ERROR });
    }

    componentDidMount() {
        const { hcCompanyId, bundleId, token } = this.props;
        webApi
            .fetchGetAutomationSetting(hcCompanyId, bundleId, token)
            .then(this.onFetchAutomationSetting)
            .catch(errDict => this.setState({ error: errDict.error }));

        this.setState({ bundleId });
    }

    componentDidUpdate(prevProps, prevState) {
        const { appEvent } = this.props;
        const autoResponseChange = this.state.sendAutomatedResponse !== this.state.initialSendAutomatedResponse;
        const autoMessageChange = this.state.sendAutomatedMessage !== this.state.initialSendAutomatedMessage;
        const autoResolveChange = this.state.sendAutomatedResolve !== this.state.initialSendAutomatedResolve;
        const displayNameChange = this.state.automatedDisplayName !== this.state.initialAutomatedDisplayName;
        const priorityWordsChange = this.state.priorityWords !== this.state.initialPriorityWords;
        const defaultTranslationLanguageChange = this.state.defaultTranslationLanguage !== this.state.initialDefaultTranslationLanguage;

        const somethingChange = autoResponseChange || autoMessageChange || autoResolveChange || displayNameChange || priorityWordsChange || defaultTranslationLanguageChange;
        if (appEvent != null) {
            if (somethingChange) appEvent.fireEvent(AppEvent.EVENT_SET_UNSAVED_CHANGES);
            else appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
        }

        if (prevState && this.state.error !== prevState.error && this.state.error) {
            if (toast.isActive(this.toastId)) {
                toast.update(this.toastId, { type: this.state.errorType, render: this.state.error });
            } else {
                toast(this.state.error, {
                    position: toast.POSITION.TOP_RIGHT,
                    type: this.state.errorType,
                    toastId: this.toastId,
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    onClose: this.dismissError,
                    onClick: this.dismissError
                });
            }
        }

        if (prevState && prevState.bundleId !== '' && this.state.bundleId !== prevState.bundleId) {
            this.props.onChangeApp(this.state.bundleId);
            const { hcCompanyId, token } = this.props;
            webApi
                .fetchGetAutomationSetting(hcCompanyId, this.state.bundleId, token)
                .then(this.onFetchAutomationSetting)
                .catch(errDict => this.setState({ error: errDict.error }));
        }
    }

    componentWillUnmount() {
        const { appEvent } = this.props;
        toast.dismiss(this.toastId);
        if (appEvent != null) appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
    }

    onFetchAutomationSetting = resDict => {
        this.setState({
            sendAutomatedResponse: resDict.sendAutomatedResponse,
            initialSendAutomatedResponse: resDict.sendAutomatedResponse,
            automatedResponseText: decodeURIComponent(resDict.automatedResponseText),

            sendAutomatedMessage: resDict.sendAutomatedMessage,
            initialSendAutomatedMessage: resDict.sendAutomatedMessage,

            automatedMessageText: decodeURIComponent(resDict.automatedMessageText),
            automatedMessageDays: resDict.automatedMessageDays,

            sendAutomatedResolve: resDict.sendAutomatedResolve,
            initialSendAutomatedResolve: resDict.sendAutomatedResolve,
            automatedResolveText: decodeURIComponent(resDict.automatedResolveText),
            automatedResolveDays: resDict.automatedResolveDays,

            automatedDisplayName: decodeURIComponent(resDict.automatedDisplayName),
            initialAutomatedDisplayName: decodeURIComponent(resDict.automatedDisplayName),

            priorityWords: resDict.priorityWords ? resDict.priorityWords : "",
            initialPriorityWords: resDict.priorityWords ? resDict.priorityWords : "",

            defaultTranslationLanguage: resDict.defaultTranslationLanguage ? resDict.defaultTranslationLanguage : "en",
            initialDefaultTranslationLanguage: resDict.defaultTranslationLanguage ? resDict.defaultTranslationLanguage : "en"
        });
    };

    onChangeAutomationSetting = () => {
        this.setState(
            {
                error: 'Saved',
                errorType: toast.TYPE.SUCCESS,
                initialAutomatedDisplayName: this.state.automatedDisplayName,
                initialSendAutomatedMessage: this.state.sendAutomatedMessage,
                initialSendAutomatedResolve: this.state.sendAutomatedResolve,
                initialSendAutomatedResponse: this.state.sendAutomatedResponse,
                initialPriorityWords: this.state.priorityWords,
                initialDefaultTranslationLanguage: this.state.initialDefaultTranslationLanguage
            },
            () => {
                const { appEvent } = this.props;
                if (appEvent != null) appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
            }
        );
    };

    handleSubmit = event => {
        event.preventDefault();
        if (emoji.strip(this.state.automatedDisplayName).length === 0) {
            return this.setState({ error: 'Message Display Name is required' });
        }
        this.dismissError();
        const { hcCompanyId, token } = this.props;
        const metaData = {
            sendAutomatedResponse: this.state.sendAutomatedResponse,
            automatedResponseText: this.state.automatedResponseText,

            sendAutomatedMessage: this.state.sendAutomatedMessage,
            automatedMessageText: this.state.automatedMessageText,
            automatedMessageDays: this.state.automatedMessageDays,

            sendAutomatedResolve: this.state.sendAutomatedResolve,
            automatedResolveText: this.state.automatedResolveText,
            automatedResolveDays: this.state.automatedResolveDays,

            automatedDisplayName: emoji.strip(this.state.automatedDisplayName),

            priorityWords: emoji.strip(this.state.priorityWords),

            defaultTranslationLanguage: this.state.defaultTranslationLanguage
        };
        const escapeLength = SqlString.escape(JSON.stringify(metaData)).length;
        if (escapeLength <= appConstants.MAX_AUTOMATION_SETTING_ESCAPE_LENGTH)
            webApi
                .fetchChangeAutomationSetting(hcCompanyId, this.state.bundleId, JSON.stringify(metaData), token)
                .then(this.onChangeAutomationSetting)
                .catch(errDict => this.setState({ error: errDict.error }));
        else this.setState({ error: 'Submitted data exceeded the allowable size!' });
    };

    handleCancelButton = event => {
        event.preventDefault();
        const { hcCompanyId, token } = this.props;
        webApi
            .fetchGetAutomationSetting(hcCompanyId, this.state.bundleId, token)
            .then(this.onFetchAutomationSetting)
            .catch(errDict => this.setState({ error: errDict.error }));
        this.setState({ error: 'Changes Canceled', errorType: toast.TYPE.WARNING });
    };

    onClickEditAutoResponseMessage = event => {
        event.preventDefault();
        const { onEditAutoResponseCallBack } = this.props;
        onEditAutoResponseCallBack(this.state.automatedResponseText);
    };

    onClickEditAutomatedMessage = event => {
        event.preventDefault();
        const { onEditAutoMessageCallback } = this.props;
        onEditAutoMessageCallback(this.state.automatedMessageText, this.state.automatedMessageDays);
    };

    onClickEditAutoResolveMessage = event => {
        event.preventDefault();
        const { onEditAutoResolveCallback } = this.props;
        onEditAutoResolveCallback(this.state.automatedResolveText, this.state.automatedResolveDays);
    };

    automationSettingsIconGenerator = (onClickBehavior, domId = null) => {
        const automatedSettingsUi = (
            <input
                alt="automation settings button"
                type="button"
                className="automation-settings-button"
                onClick={onClickBehavior}
                id={domId}
            />
        );
        return automatedSettingsUi;
    };

    automationToggleIconGenerator = (onClickBehavior, toggleSrc, domId = null) => {
        const toggleIcon = (
            <input
                src={toggleSrc}
                className="auto-toggle-button"
                alt="toggle button on off"
                type="image"
                onClick={onClickBehavior}
                id={domId}
            />
        );
        return toggleIcon;
    };

    onChangeApp = bundleId => {
        const { appEvent } = this.props;
        if (appEvent != null) appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
        this.setState({ bundleId });
    }

    render() {
        const autoResolveSrc = this.state.sendAutomatedResolve ? '/images/settings/on.png' : '/images/settings/off.png';
        const autoMessageSrc = this.state.sendAutomatedMessage ? '/images/settings/on.png' : '/images/settings/off.png';
        const autoResponseSrc = this.state.sendAutomatedResponse
            ? '/images/settings/on.png'
            : '/images/settings/off.png';
        return (
            <div
                className="ui2-container-div automation-container-div slide-up-animation"
                ref={n => (this.mainNode = n)}
                id="automationSetting"
            >
                <span className="automation-main-heading">AUTOMATION SETTING</span>
                <span className="automation-sub-heading mt-3">App Specific Setting</span>

                <span className="d-inline-block official-text-color font-weight-bold">Game Category:</span>
                <div className="d-inline-block w-50">
                    <AppMenuUi
                        hcCompanyId={this.props.hcCompanyId}
                        appInfoDict={this.props.appInfoDict}
                        bundleId={this.state.bundleId}
                        onChangeApp={this.onChangeApp}
                        role={this.props.role}
                        token={this.props.token}
                    />
                </div>

                <div className="automation-content-block">
                    <p className="automation-div-header">Auto-Response Setting</p>
                    <p className="automation-content-label">
                        Sends an automated response to the user as soon as they reach out to us.
                    </p>
                    <div className="automation-option-container">
                        <span
                            className="automation-message-preview"
                            id="autoResponsePreview"
                            onClick={this.onClickEditAutoResponseMessage}>
                            {this.state.automatedResponseText || 'Not Set'}
                        </span>
                        {this.automationSettingsIconGenerator(
                            this.onClickEditAutoResponseMessage,
                            'autoResponseSettingsButton'
                        )}
                        {this.automationToggleIconGenerator(
                            this.onSendAutoResponse,
                            autoResponseSrc,
                            'autoResponseToggle'
                        )}
                    </div>
                </div>

                <div className="automation-content-block">
                    <p className="automation-div-header">Automatically Follow Up with the user after X days</p>
                    <p className="automation-content-label">
                        Sends an automated follow up to the user if the user does not reply after X days.
                    </p>
                    <div className="automation-option-container">
                        <span
                            className="automation-message-preview"
                            id="autoMessagePreview"
                            onClick={this.onClickEditAutomatedMessage}>
                            {this.state.automatedMessageText || 'Not Set'}
                        </span>
                        {this.automationSettingsIconGenerator(
                            this.onClickEditAutomatedMessage,
                            'autoMessageSettingsButton'
                        )}
                        {this.automationToggleIconGenerator(
                            this.onSendAutomatedMessage,
                            autoMessageSrc,
                            'autoMessageToggle'
                        )}
                    </div>
                </div>

                <div className="automation-content-block">
                    <p className="automation-div-header">
                        Automatically Resolve conversation with the user after X days
                    </p>
                    <p className="automation-content-label">
                        Automatically resolves the conversation when the user does not reply after X days.
                    </p>
                    <div className="automation-option-container">
                        <span
                            className="automation-message-preview"
                            id="autoResolvePreview"
                            onClick={this.onClickEditAutoResolveMessage}>
                            {this.state.automatedResolveText || 'Not Set'}
                        </span>
                        {this.automationSettingsIconGenerator(
                            this.onClickEditAutoResolveMessage,
                            'autoResolveSettingsButton'
                        )}
                        {this.automationToggleIconGenerator(
                            this.onSendAutoResolve,
                            autoResolveSrc,
                            'autoResolveToggle'
                        )}
                    </div>
                </div>

                <hr className='mr-4' />
                <span className="automation-sub-heading mt-3">Global Setting</span>

                <div className="automation-content-block">
                    <p className="automation-div-header">Message Display Name</p>
                    <input
                        id="editAutomationDisplayNameInput"
                        value={this.state.automatedDisplayName}
                        className="edit-automation-text-input"
                        onChange={this.handleDisplayNameChange}
                        autoComplete="off"
                    />
                </div>

                <div className="automation-content-block">
                    <p className="automation-div-header">Priority Word - Separated by Comma</p>
                    <input
                        id="accountPriotityWordsInput"
                        value={this.state.priorityWords}
                        className="account-setting-text-input"
                        placeholder="Separated by Comma"
                        onChange={this.handlePriorityWordsChange}
                        type="text"
                    />
                </div>

                <div className="automation-content-block">
                    <p className="automation-div-header">Default Translation Language</p>
                    <select
                        className="account-setting-text-input"
                        value={this.state.defaultTranslationLanguage}
                        id="accountDefaultTranslationLanguageInput"
                        onChange={this.handleDefaultTranslationLanguageChange}
                    >
                        {Object.keys(appEnums.Languages).map(key => {
                            return (<option key={key} value={key}>{appEnums.Languages[key]}</option>)
                        })}
                    </select>
                </div>

                <form onSubmit={this.handleSubmit} className="mt-5">
                    <input id="automationPageSubmitButton" type="submit" value="SAVE" alt="Save Changes" />
                    <input
                        id="automationPageCancelButton"
                        type="button"
                        value="CANCEL"
                        alt="Cancel Changes"
                        onClick={this.handleCancelButton}
                    />
                </form>
            </div>
        );
    }
}

export default AutomationSettingUi;
