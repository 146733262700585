import React from "react";
import { Editor, RichUtils, getDefaultKeyBinding, KeyBindingUtil, EditorState, AtomicBlockUtils, convertToRaw } from "draft-js";
import { toast } from "react-toastify";
import "./faqEditor.css";
import fileApi from "../api/fileApi";
import { customBlockRendererFn, customStyleMap, editorDecorators, getPlatformTag, getEntities } from "./faqUtils";
import { VideoInputModal, ImageInputModal, LinkInputModal, FaqBlockerUI, LanguageInputModal } from "./faqEditorModal";
import appConstants from "../appConstants";
import AppEvent from "../appEvent";
import appEnums from "../appEnums";
import * as emoji from "node-emoji";
import deepEqual from "deep-equal";
import commonApi from "../api/commonApi";

const { FontSizes, FaqStates } = appEnums;

export default class FaqEditor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFontMenuOpen: false,
            currentFontSize: "FS12PT",
            link: "",
            hasInitialLink: false,
            error: "",
            readOnly: false,
            isUploading: false,
            editorState: props.editorState ? props.editorState : EditorState.createEmpty(editorDecorators),
            initialEditorState: props.editorState ? props.editorState : EditorState.createEmpty(editorDecorators),
            articleTitle: props.faqArticleObj ? props.faqArticleObj.title : "",
            initialArticleTitle: props.faqArticleObj ? props.faqArticleObj.title : "",
            languageCodes: props.faqArticleObj ? props.faqArticleObj.languageCodes : [],
            initialLanguageCodes: props.faqArticleObj ? props.faqArticleObj.languageCodes : []
        };

        this.s3KeyList = [];
        this.styleDict = {};
        this.toastId = "__FAQ_EDITOR__";
        this.addImageInputNode = null;
        this.addVideoInputNode = null;
    }

    updateLanguageCodes = lgc => this.setState({ languageCodes: lgc });
    focus = () => setTimeout(() => this.refEditor.focus(), 0);
    closeFontMenu = () => this.setState({ isFontMenuOpen: false });
    clearEditor = () => this.setState({ articleTitle: "", editorState: EditorState.createEmpty(editorDecorators) }, this.focus);

    deleteUnusedFiles = rawContentState => {
        const entityKeys = Object.keys(rawContentState.entityMap);
        const includedKeys = entityKeys
            .map(key => rawContentState.entityMap[key])
            .filter(obj => obj.type === "IMAGE" && obj.data.imageKey)
            .map(obj => obj.data.imageKey);

        includedKeys.forEach(key => {
            const index = this.s3KeyList.indexOf(key);
            if (index !== -1) {
                this.s3KeyList.splice(index, 1);
            }
        });
        // delete unused files uploaded to s3 while creating this article
        if (this.s3KeyList.length > 0) {
            const { appWebSocket, hcAccountId, appEvent } = this.props;
            appWebSocket
                .deleteFaq({ contentKeys: this.s3KeyList, hcAccountId: hcAccountId })
                .then(() => (this.s3KeyList = []))
                .catch(errDict => appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
        }
    };

    onSaveChanges = () => {
        const { editorState, articleTitle, languageCodes } = this.state;
        if (articleTitle.length === 0) {
            return commonApi.displayToast("Title is required!", this.toastId, toast.TYPE.WARNING);
        }
        if (editorState.getCurrentContent().getPlainText().length === 0) {
            return commonApi.displayToast("Content is required!", this.toastId, toast.TYPE.WARNING);
        }
        if (languageCodes.length === 0) {
            return commonApi.displayToast("Language Code is required!", this.toastId, toast.TYPE.WARNING);
        }
        const { appWebSocket, faqArticleObj, appEvent } = this.props;
        if (appWebSocket && faqArticleObj) {
            const { hcAccountId, bundleId, onFaqEditSaveChanges } = this.props;
            faqArticleObj.title = emoji.strip(articleTitle);
            faqArticleObj.lastHcAccountId = hcAccountId;
            faqArticleObj.lastUpdatedTime = Date.now();
            faqArticleObj.languageCodes = languageCodes;
            const raw = convertToRaw(editorState.getCurrentContent());
            this.deleteUnusedFiles(raw);
            const rawContent = JSON.stringify(raw);
            const json = {
                metaData: faqArticleObj,
                rawContent: rawContent,
                hcAccountId: hcAccountId,
                bundleId: bundleId,
                appFaqId: faqArticleObj.appFaqId
            };

            commonApi.displayToast("Saving", this.toastId, toast.TYPE.INFO);

            appWebSocket
                .saveChangesFaq(json)
                .then(res => {
                    faqArticleObj.lastUpdatedTime = res.lastUpdatedTime;
                    if (appEvent) {
                        appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
                    }
                    if (onFaqEditSaveChanges) {
                        onFaqEditSaveChanges(faqArticleObj);
                    }
                })
                .catch(errDict => {
                    commonApi.displayToast(errDict.error, this.toastId, toast.TYPE.ERROR);
                });
        }
    };

    onSavePublish = () => {
        const { editorState, articleTitle, languageCodes } = this.state;
        if (articleTitle.length === 0) {
            return commonApi.displayToast("Title is required!", this.toastId, toast.TYPE.WARNING);
        }
        if (editorState.getCurrentContent().getPlainText().length === 0) {
            return commonApi.displayToast("Content is required!", this.toastId, toast.TYPE.WARNING);
        }
        if (languageCodes.length === 0) {
            return commonApi.displayToast("Language Code is required!", this.toastId, toast.TYPE.WARNING);
        }
        const { faqArticleObj } = this.props;
        faqArticleObj.publish = true;
        let tags = faqArticleObj.tags;
        const index = tags.indexOf("unpublished");
        if (index !== -1) {
            tags.splice(index, 1);
            faqArticleObj.tags = tags;
        }
        this.onSaveChanges();
    };

    onSaveUnpublish = () => {
        const { editorState, articleTitle, languageCodes } = this.state;
        if (articleTitle.length === 0) {
            return commonApi.displayToast("Title is required!", this.toastId, toast.TYPE.WARNING);
        }
        if (editorState.getCurrentContent().getPlainText().length === 0) {
            return commonApi.displayToast("Content is required!", this.toastId, toast.TYPE.WARNING);
        }
        if (languageCodes.length === 0) {
            return commonApi.displayToast("Language Code is required!", this.toastId, toast.TYPE.WARNING);
        }
        const { faqArticleObj } = this.props;
        faqArticleObj.publish = false;
        let tags = faqArticleObj.tags;
        tags.push("unpublished");
        faqArticleObj.tags = tags;
        this.onSaveChanges();
    };

    onMousedownListener = event => {
        if (this.state.isFontMenuOpen && event.target.id !== "fontSizeBtn") {
            this.closeFontMenu();
        }
    };

    initializeImageTypes = () => {
        const { editorState } = this.state;
        const imageEntities = getEntities(editorState, "IMAGE");
        let newEditorState = editorState;
        imageEntities.forEach(entity => {
            const contentState = newEditorState.getCurrentContent();
            const newContentState = contentState.mergeEntityData(entity.entityKey, { resizeImage: this.resizeImage });
            newEditorState = EditorState.push(newEditorState, newContentState, "apply-entity");
        });
        this.setState({ editorState: EditorState.moveFocusToEnd(newEditorState) });
    };

    onSaveAsDraft = () => this.onCreate(FaqStates.Draft);
    onPublish = () => this.onCreate(FaqStates.Published);

    onCreate = publish => {
        const { editorState, articleTitle, languageCodes } = this.state;
        if (articleTitle.length === 0) {
            return commonApi.displayToast("Title is required!", this.toastId, toast.TYPE.WARNING);
        }
        if (editorState.getCurrentContent().getPlainText().length === 0) {
            return commonApi.displayToast("Content is required!", this.toastId, toast.TYPE.WARNING);
        }
        if (languageCodes.length === 0) {
            return commonApi.displayToast("Language code is required!", this.toastId, toast.TYPE.WARNING);
        }
        commonApi.displayToast("Saving", this.toastId, toast.TYPE.INFO);
        const { appWebSocket, appEvent, hcAccountId, hcCompanyId, bundleId, appInfo } = this.props;
        const rawContentState = convertToRaw(editorState.getCurrentContent());
        const rawContent = JSON.stringify(rawContentState);
        let tags = publish ? [] : ["unpublished"];
        if (appInfo) tags.push(getPlatformTag(appInfo.platform));

        const json = { hcAccountId, hcCompanyId, bundleId, title: articleTitle, rawContent, publish, tags, languageCodes };

        this.deleteUnusedFiles(rawContentState);

        appWebSocket
            .addFaq(json)
            .then(resultJson => {
                if (appEvent) {
                    appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
                    appEvent.fireEvent(AppEvent.EVENT_FAQ_CREATE, resultJson);
                }
            })
            .catch(errDict => {
                commonApi.displayToast(errDict.error, this.toastId, toast.TYPE.ERROR);
            });
    };

    onChangeEditor = editorState => this.setState({ editorState: editorState });

    onChangeLinkInput = event => this.setState({ link: event.target.value });

    onBoldButtonClick = event => {
        event.preventDefault();
        this.toggleInlineStyle("BOLD");
    };

    onItalicButtonClick = event => {
        event.preventDefault();
        this.toggleInlineStyle("ITALIC");
    };

    onUnderlineButtonClick = event => {
        event.preventDefault();
        this.toggleInlineStyle("UNDERLINE");
    };

    onStrikeButtonClick = event => {
        event.preventDefault();
        this.toggleInlineStyle("STRIKETHROUGH");
    };

    onBulletButtonClick = event => {
        event.preventDefault();
        this.toggleBlockType("unordered-list-item");
    };

    onNumberButtonClick = event => {
        event.preventDefault();
        this.toggleBlockType("ordered-list-item");
    };

    onLinkButtonClick = event => {
        event.preventDefault();
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        if (selection.isCollapsed()) return true;

        const contentState = editorState.getCurrentContent();
        const startKey = editorState.getSelection().getStartKey();
        const startOffset = editorState.getSelection().getStartOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

        let url = "";
        if (linkKey) {
            const linkInstance = contentState.getEntity(linkKey);
            url = linkInstance.getData().href;
        }
        this.setState({ link: url, hasInitialLink: url.length > 0 });
    };

    onImageButtonClick = event => event.preventDefault();

    onVideoButtonClick = event => event.preventDefault();

    onConfirmLink = event => {
        event.preventDefault();
        const { editorState, link } = this.state;
        if (link.length > 0) {
            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", { href: link });
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
            const newState = RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey);
            this.setState({ editorState: newState, link: "" }, this.focus);
        }
    };

    onRemoveLink = event => {
        event.preventDefault();
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const newState = RichUtils.toggleLink(editorState, selection, null);
            this.setState({ editorState: newState, link: "" }, this.focus);
        }
    };

    toggleInlineStyle = inlineStyle => {
        const { editorState } = this.state;
        const newState = RichUtils.toggleInlineStyle(editorState, inlineStyle);
        this.setState({ editorState: newState });
    };

    toggleBlockType = blockType => {
        const { editorState } = this.state;
        const newState = RichUtils.toggleBlockType(editorState, blockType);
        this.setState({ editorState: newState });
    };

    handleKeyCommand = (command, editorState) => {
        if (command === "editor-onTab") {
            return "handled";
        }
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChangeEditor(newState);
            return "handled";
        }
        return "not-handled";
    };

    customKeyBindingFn = event => {
        const { editorState } = this.state;
        if (event.keyCode === 9) {
            event.preventDefault();
            const maxDepth = 4;
            const newState = RichUtils.onTab(event, editorState, maxDepth);
            this.setState({ editorState: newState });
            return "editor-onTab";
        }
        const { hasCommandModifier } = KeyBindingUtil;
        if (event.keyCode === 68 && hasCommandModifier(event)) {
            this.onStrikeButtonClick(event);
            return "strikethrough"; // suppress default behavior (CMD, CTRL + D)
        }
        return getDefaultKeyBinding(event);
    };

    onChangeFontSize = fontSizeStlye => {
        const { editorState } = this.state;
        let newState = editorState;
        for (let size of FontSizes) {
            const key = `FS${size}PT`;
            if (newState.getCurrentInlineStyle().has(key)) {
                newState = RichUtils.toggleInlineStyle(newState, key);
            }
        }
        newState = RichUtils.toggleInlineStyle(newState, fontSizeStlye);
        this.setState({ editorState: newState, currentFontSize: fontSizeStlye }, this.focus);
    };

    onFontMenuClick = event => {
        event.preventDefault();
        this.setState({ isFontMenuOpen: !this.state.isFontMenuOpen });
    };

    confirmAddImage = (src, imageKey = "") => {
        const { editorState } = this.state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("IMAGE", "IMMUTABLE", {
            src: src,
            imageKey: imageKey,
            domClassName: "faq-image-auto",
            resizeImage: this.resizeImage
        });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        const newState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
        this.setState({ editorState: newState, isUploading: false, readOnly: false }, this.focus);
    };

    resizeImage = (entityKey, domClassName) => {
        const { editorState } = this.state;
        const contentState = editorState.getCurrentContent();
        const newContentState = contentState.mergeEntityData(entityKey, { domClassName: domClassName });
        const newEditorState = EditorState.push(editorState, newContentState, "apply-entity");
        this.setState({ editorState: EditorState.moveFocusToEnd(newEditorState) });
    };

    confirmUploadImage = event => {
        event.preventDefault();
        if (event.target.files[0].size >= appConstants.faqFileMaxSizeMB) {
            return this.uploadFileError.click();
        }
        const { appWebSocket, hcAccountId, hcCompanyId, bundleId } = this.props;
        fileApi
            .convertFileToDataURL(event.target.files[0])
            .then(stringBase64 => {
                this.setState({ isUploading: true, readOnly: true }, () => {
                    appWebSocket
                        .uploadFaqImage(hcAccountId, hcCompanyId, bundleId, stringBase64)
                        .then(res => {
                            this.s3KeyList.push(res.imageKey);
                            this.confirmAddImage(res.image, res.imageKey);
                        })
                        .catch(errDict => {
                            commonApi.displayToast(errDict.error, this.toastId, toast.TYPE.ERROR);
                        });
                });
            })
            .catch(error => {
                commonApi.displayToast(error, this.toastId, toast.TYPE.ERROR);
            });
    };

    confirmAddImageByLink = src => this.confirmAddImage(src);

    confirmAddVideo = src => {
        const { editorState } = this.state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("VIDEO", "IMMUTABLE", { src: src });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        const newState = AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
        this.setState({ editorState: newState }, this.focus);
    };

    getCurrentSelectedText = () => {
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        const anchorKey = selection.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentBlock = currentContent.getBlockForKey(anchorKey);

        const start = selection.getStartOffset();
        const end = selection.getEndOffset();
        return currentBlock.getText().slice(start, end);
    };

    onChangeArticleTitle = event => this.setState({ articleTitle: event.target.value });

    componentDidMount() {
        const { appEvent, editorState } = this.props;
        window.addEventListener("mousedown", this.onMousedownListener);
        if (appEvent) {
            appEvent.addCallback(AppEvent.EVENT_FAQ_PUBLISH, this.onPublish);
            appEvent.addCallback(AppEvent.EVENT_FAQ_SAVE_DRAFT, this.onSaveAsDraft);
            appEvent.addCallback(AppEvent.EVENT_FAQ_SAVE_CHANGES, this.onSaveChanges);
            appEvent.addCallback(AppEvent.EVENT_FAQ_SAVE_PUBLISH, this.onSavePublish);
            appEvent.addCallback(AppEvent.EVENT_FAQ_SAVE_UNPUBLISH, this.onSaveUnpublish);
            appEvent.addCallback(AppEvent.EVENT_FAQ_CLEAR_EDITOR, this.clearEditor);
        }
        if (editorState) {
            this.initializeImageTypes();
            const rawDataObj = convertToRaw(editorState.getCurrentContent());
            const entityKeys = Object.keys(rawDataObj.entityMap);
            this.s3KeyList = entityKeys
                .map(key => rawDataObj.entityMap[key])
                .filter(obj => obj.type === "IMAGE" && obj.data.imageKey)
                .map(obj => obj.data.imageKey); // list of all uploaded images
        } else {
            this.focus();
        }
    }

    componentWillUnmount() {
        const { appEvent, hcAccountId, appWebSocket, faqArticleObj } = this.props;
        window.removeEventListener("mousedown", this.onMousedownListener);
        toast.dismiss(this.toastId);
        if (appEvent && appWebSocket) {
            appEvent.removeCallback(AppEvent.EVENT_FAQ_PUBLISH, this.onPublish);
            appEvent.removeCallback(AppEvent.EVENT_FAQ_SAVE_DRAFT, this.onSaveAsDraft);
            appEvent.removeCallback(AppEvent.EVENT_FAQ_SAVE_CHANGES, this.onSaveChanges);
            appEvent.removeCallback(AppEvent.EVENT_FAQ_SAVE_PUBLISH, this.onSavePublish);
            appEvent.removeCallback(AppEvent.EVENT_FAQ_SAVE_UNPUBLISH, this.onSaveUnpublish);
            appEvent.removeCallback(AppEvent.EVENT_FAQ_CLEAR_EDITOR, this.clearEditor);
            appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
            if (this.s3KeyList.length > 0) {
                if (!faqArticleObj) {
                    appWebSocket
                        .deleteFaq({ contentKeys: this.s3KeyList, hcAccountId: hcAccountId })
                        .catch(errDict => appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { editorState, articleTitle, initialArticleTitle, initialEditorState, languageCodes, initialLanguageCodes } = this.state;
        const { appEvent } = this.props;
        if (appEvent) {
            setTimeout(() => {
                const currContentState = convertToRaw(editorState.getCurrentContent());
                const initContentState = convertToRaw(initialEditorState.getCurrentContent());
                const titleChange = initialArticleTitle !== articleTitle;
                const codesChange = !deepEqual(initialLanguageCodes, languageCodes);
                const editorStateChange =
                    (!initialEditorState.getCurrentContent().hasText() && editorState.getCurrentContent().hasText()) ||
                    (initialEditorState.getCurrentContent().hasText() && !deepEqual(currContentState, initContentState));

                if (titleChange || editorStateChange || codesChange) appEvent.fireEvent(AppEvent.EVENT_SET_UNSAVED_CHANGES);
                else appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
            }, 0);
        }
    }

    render() {
        const { editorState, isFontMenuOpen } = this.state;
        const inlineStyles = editorState.getCurrentInlineStyle();
        const blockType = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType();
        const selection = editorState.getSelection();
        this.styleDict["FONT"] = isFontMenuOpen ? "faq-format-btn active" : "faq-format-btn";
        this.styleDict["BOLD"] = inlineStyles.has("BOLD") ? "faq-format-btn active" : "faq-format-btn";
        this.styleDict["UNDERLINE"] = inlineStyles.has("UNDERLINE") ? "faq-format-btn active" : "faq-format-btn";
        this.styleDict["ITALIC"] = inlineStyles.has("ITALIC") ? "faq-format-btn active" : "faq-format-btn";
        this.styleDict["STRIKE"] = inlineStyles.has("STRIKETHROUGH") ? "faq-format-btn active" : "faq-format-btn";
        this.styleDict["ordered-list-item"] = blockType === "ordered-list-item" ? "faq-format-btn active" : "faq-format-btn";
        this.styleDict["unordered-list-item"] = blockType === "unordered-list-item" ? "faq-format-btn active" : "faq-format-btn";
        this.styleDict["LINK"] = "faq-format-btn";

        return (
            <div className="faq-editor-div">
                <input
                    type="text"
                    placeholder="Title Here"
                    className="faq-title-input"
                    value={this.state.articleTitle}
                    onChange={this.onChangeArticleTitle}
                />
                <div className="faq-options-div">
                    <div className={this.styleDict["FONT"]} title="FONT SIZES">
                        <input type="button" id="fontSizeBtn" alt="font size" onMouseDown={this.onFontMenuClick} />
                    </div>
                    {isFontMenuOpen && (
                        <div className="font-size-list">
                            {FontSizes.map(size => {
                                return (
                                    <button
                                        key={`FS${size}PT`}
                                        id={`FS${size}PT`}
                                        className={inlineStyles.has(`FS${size}PT`) ? "active" : ""}
                                        onMouseDown={event => {
                                            event.preventDefault();
                                            this.closeFontMenu();
                                            this.onChangeFontSize(`FS${size}PT`);
                                        }}>
                                        {size}
                                    </button>
                                );
                            })}
                        </div>
                    )}
                    <div className={this.styleDict["BOLD"]} title="BOLD">
                        <input type="button" alt="text bold" id="boldButton" onMouseDown={this.onBoldButtonClick} />
                    </div>
                    <div className={this.styleDict["ITALIC"]} title="ITALIC">
                        <input type="button" alt="text italic" id="italicButton" onMouseDown={this.onItalicButtonClick} />
                    </div>
                    <div className={this.styleDict["UNDERLINE"]} title="UNDERLINE">
                        <input type="button" alt="text underline" id="underlineButton" onMouseDown={this.onUnderlineButtonClick} />
                    </div>
                    <div className={this.styleDict["STRIKE"]} title="STRIKETHROUGH">
                        <input type="button" alt="strikethrough" id="strikethroughButton" onMouseDown={this.onStrikeButtonClick} />
                    </div>
                    <div className={this.styleDict["unordered-list-item"]} title="UNORDERED LIST">
                        <input type="button" alt="text bullet" id="bulletButton" onMouseDown={this.onBulletButtonClick} />
                    </div>
                    <div className={this.styleDict["ordered-list-item"]} title="ORDERED LIST">
                        <input type="button" alt="text numbering" id="numberButton" onMouseDown={this.onNumberButtonClick} />
                    </div>
                    <div
                        className={this.styleDict["LINK"]}
                        data-toggle="modal"
                        title="LINK"
                        data-target={selection.isCollapsed() ? "" : "#linkInputModal"}>
                        <input type="button" alt="text link" id="linkButton" onMouseDown={this.onLinkButtonClick} />
                    </div>
                    <div className="faq-format-btn" data-toggle="modal" title="IMAGE" data-target="#imageInputModal">
                        <input type="button" alt="text video" id="imageButton" onMouseDown={this.onImageButtonClick} />
                    </div>
                    <div className="faq-format-btn" data-toggle="modal" title="VIDEO" data-target="#videoInputModal">
                        <input type="button" alt="text video" id="videoButton" onMouseDown={this.onVideoButtonClick} />
                    </div>
                    <div className="faq-format-btn" data-toggle="modal" title="LANGUAGE" data-target="#languageInputModal">
                        <input type="button" alt="text video" id="languageButton" onMouseDown={null} />
                    </div>
                </div>
                <div>
                    <Editor
                        customStyleMap={customStyleMap}
                        keyBindingFn={this.customKeyBindingFn}
                        ref={node => (this.refEditor = node)}
                        editorState={editorState}
                        onChange={this.onChangeEditor}
                        handleKeyCommand={this.handleKeyCommand}
                        blockRendererFn={customBlockRendererFn}
                        spellCheck={true}
                        readOnly={this.state.readOnly}
                    />
                </div>
                <input
                    ref={n => (this.addImageInputNode = n)}
                    className="faq-add-media"
                    type="file"
                    name="attachImage"
                    accept="image/*"
                    onClick={event => (event.target.value = null)}
                    onChange={this.confirmUploadImage}
                />
                <input
                    ref={n => (this.addVideoInputNode = n)}
                    className="faq-add-media"
                    type="file"
                    name="attachVideo"
                    accept="video/*"
                    onClick={event => (event.target.value = null)}
                    onChange={null}
                />
                <VideoInputModal onConfirm={this.confirmAddVideo} />
                <LanguageInputModal languageCodes={this.state.languageCodes} onConfirm={this.updateLanguageCodes} />
                <ImageInputModal
                    onConfirmAddImageByLink={this.confirmAddImageByLink}
                    onClickUpload={() => this.addImageInputNode.click()}
                />
                <LinkInputModal
                    onChangeLinkInput={this.onChangeLinkInput}
                    onConfirm={this.onConfirmLink}
                    onCancel={() => {}}
                    onRemoveLink={this.onRemoveLink}
                    link={this.state.link}
                    hasInitialLink={this.state.hasInitialLink}
                    selectedText={this.getCurrentSelectedText()}
                />
                {this.state.isUploading && <FaqBlockerUI />}

                <input
                    id="uploadFaqFileErrorTrigger"
                    data-toggle="modal"
                    data-target="#uploadError"
                    ref={n => (this.uploadFileError = n)}
                    className="chat-input-send-file"
                    type="button"
                />
                <UploadSizeError />
            </div>
        );
    }
}

const UploadSizeError = props => {
    return (
        <div className="modal fade" id="uploadError" tabIndex="-1" role="dialog" aria-labelledby="uploadErrorModal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="uploadErrorModal">
                            Upload error
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <span>File size exceed the file size limit 10MB.</span>
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="modalConfirmBtn" title="Confirm" className="btn btn-primary" data-dismiss="modal">
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
