import React, { Component } from "react";
import "./faqArticleList.css";
import moment from "moment";
import Moment from "react-moment";
import FaqArticleTag from "./faqArticleTag";
import { Draggable } from "react-beautiful-dnd";

export default class FaqArticleItem extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onFaqArticleView = this.onFaqArticleView.bind(this);
    }

    onFaqArticleView() {
        const { onFaqArticleView, faqArticleObj, selectedFaqId } = this.props;
        if (faqArticleObj.appFaqId === selectedFaqId) {
            return;
        }
        onFaqArticleView(faqArticleObj);
    }

    componentDidUpdate(prevProps, prevState) {
        const { faqArticleObj, selectedFaqId } = this.props;
        if (faqArticleObj.appFaqId === selectedFaqId && prevProps.selectedFaqId !== selectedFaqId) {
            this.onFaqArticleView();
        }
    }

    render() {
        const { faqArticleObj, selectedFaqId } = this.props;
        const itemTags = faqArticleObj.tags.map((tag, index) => {
            return <FaqArticleTag tag={tag} key={tag + index} />;
        });

        moment.updateLocale("en", {
            relativeTime: {
                future: "in %s",
                past: "%s ago",
                s: "< 1 m",
                ss: "%d s",
                m: "1m",
                mm: "%d m",
                h: "1 h",
                hh: "%d h",
                d: "1 d",
                dd: "%d d",
                M: "1 M",
                MM: "%d M",
                y: "1 Y",
                yy: "%d Y"
            }
        });

        const timeText = faqArticleObj.lastUpdatedTime ? (
            <Moment unix fromNow ago interval={30000}>
                {faqArticleObj.dateCreated / 1000}
            </Moment>
        ) : (
            "↖"
        );

        const authorUi = faqArticleObj.authorName ? (
            <span className="faq-display-author">
                Created&nbsp;{timeText}&nbsp;ago by {faqArticleObj.authorName}
            </span>
        ) : null;

        const className = selectedFaqId === faqArticleObj.appFaqId ? "faq-article-item faq-article-item-active" : "faq-article-item";

        return (
            <Draggable draggableId={`${faqArticleObj.appFaqId}`} index={this.props.index}>
                {provided => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={className}
                        onClick={this.onFaqArticleView}>
                        <div className="faq-item">
                            <span className="faq-display-title">{faqArticleObj.title}</span>
                            <div className="faq-tags-container">
                                {itemTags}
                                {authorUi}
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>
        );
    }
}
