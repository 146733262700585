const appEnums = {
    RootPage: {
        Home: 0,
        Login: 1,
        Message: 2,
        Faq: 3,
        HomeDashboardMetrics: 4,
        // Developer console
        DeveloperConsole: 5,
        CreateApp: 6,
        AppSetting: 7,
        InstantPushVerification: 8,
        // End Developer Console Page
        Help: 9,
        GeneralSettings: 10,
        ResetPassword: 11,
        Loading: 12
    },

    GeneralSettings: {
        None: -1,
        Profile: 0,
        UsersAndPermissions: 1,
        Billing: 2,
        Automation: 3,
        Template: 4
    },

    Platform: {
        None: 0,
        iOS: 1,
        AndroidGoogle: 2,
        AndroidAmazon: 3,
        Unity: 4
    },

    MessageStates: {
        None: -1,
        PriorityNew: 0.1,
        New: 0,
        PriorityOpen: 1.1,
        Open: 1,
        Closed: 2,
        Pending: 3
    },

    MessageSource: {
        Client: 0,
        Server: 1,
        Robot: 2
    },

    AskRatingStates: {
        None: 0,
        Show: 1,
        Hide: 2
    },

    ChatSurveyStates: {
        None: 0,
        Star1: 1,
        Star2: 2,
        Star3: 3,
        Star4: 4,
        Star5: 5,
        Star0: 8,
        Finish: 16
    },

    KeyboardShortcut: {
        //for windows/linux platform
        WinReplyMessage: "Ctrl+Enter",
        WinResolveMessage: "Ctrl+R",

        WinNextMessage0: "Ctrl+Right",
        WinNextMessage1: "Ctrl+Down",
        WinPrevMessage0: "Ctrl+Left",
        WinPrevMessage1: "Ctrl+Up",

        WinNewMessagesTab: "Ctrl+1",
        WinOpenMessagesTab: "Ctrl+2",
        WinClosedMessagesTab: "Ctrl+3",

        WinTranslateLastMessage: "Ctrl+,",
        WinTranslateReplyMessage: "Ctrl+.",
        WinTranslateBracketReplyMessage: "Ctrl+Shift+.",

        WinTemplateMessage1: "Alt+1",
        WinTemplateMessage2: "Alt+2",
        WinTemplateMessage3: "Alt+3",
        WinTemplateMessage4: "Alt+4",
        WinTemplateMessage5: "Alt+5",
        WinTemplateMessage6: "Alt+6",
        WinTemplateMessage7: "Alt+7",
        WinTemplateMessage8: "Alt+8",
        WinTemplateMessage9: "Alt+9",
        WinTemplateMessage0: "Alt+0",

        //for mac platform
        MacReplyMessage: "Meta+Enter",
        MacResolveMessage: "Meta+R",

        MacNextMessage0: "Meta+Right",
        MacNextMessage1: "Meta+Down",
        MacPrevMessage0: "Meta+Left",
        MacPrevMessage1: "Meta+Up",

        MacNewMessageTab: "Meta+1",
        MacOpenMessagesTab: "Meta+2",
        MacClosedMessagesTab: "Meta+3",

        MacTranslateLastMessage: "Meta+,",
        MacTranslateReplyMessage: "Meta+.",
        MacTranslateBracketReplyMessage: "Meta+Shift+.",

        MacTemplateMessage1: "Alt+1",
        MacTemplateMessage2: "Alt+2",
        MacTemplateMessage3: "Alt+3",
        MacTemplateMessage4: "Alt+4",
        MacTemplateMessage5: "Alt+5",
        MacTemplateMessage6: "Alt+6",
        MacTemplateMessage7: "Alt+7",
        MacTemplateMessage8: "Alt+8",
        MacTemplateMessage9: "Alt+9",
        MacTemplateMessage0: "Alt+0",
    },

    Role: {
        Guest: -1,
        Agent: 0,
        Developer: 1,
        Admin: 2,
        Owner: 3
    },

    GuestPermissionLevel: {
        Read: 1,
        ReadWrite: 2,
    },

    AccountState: {
        Deactivated: 0,
        Active: 1
    },

    Features: {
        Tickets: 0,
        Analytics: 1,
        Faq: 2,
        Automation: 3,
        Apps: 4,
        UsersAndPermissions: 5,
        Billing: 6
    },

    FontSizes: [6, 8, 9, 10, 11, 12, 14, 18, 24, 28, 30, 36, 48, 60, 72],

    FaqStates: {
        Draft: false,
        Published: true
    },

    Automation: {
        Response: 0,
        Reply: 1,
        Resolve: 2
    },

    Languages: {
        en : "English",
        ar : "Arabic",
        az : "Azerbaijani",
        zh : "Chinese",
        cs : "Czech",
        da : "Danish",
        nl : "Dutch",
        eo : "Esperanto",
        fi : "Finnish",
        fr : "French",
        de : "German",
        el : "Greek",
        he : "Hebrew",
        hi : "Hindi",
        hu : "Hungarian",
        id : "Indonesia",
        ga : "Irish",
        it : "Italian",
        ja : "Japanese",
        ko : "Korean",
        fa : "Persian",
        pl : "Polish",
        pt : "Portuguese",
        ru : "Russian",
        sk : "Slovak",
        es : "Spanish",
        sv : "Swedish",
        tr : "Turkish",
        uk : "Ukranian"
    }
};

export default appEnums;
