import React from "react";
import platform from "platform";

export default class AppMenuListUi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.toastId = "__TEMPLATE_BUILDER_LIST_UI__";
    }
    
    onChangeTitle(key, title) {
        const { templateList, maxTitleLength } = this.props;

        let subStr = `${title}`;

        if (subStr.length > maxTitleLength) {
            subStr = title.slice(0, maxTitleLength);
        }

        templateList[key].title = subStr;

        this.props.onChangeTemplateList(templateList);
    }

    onChangeMessage(key, message) {
        const { templateList, maxMessageLength } = this.props;

        let subStr = `${message}`;

        if (subStr.length > maxMessageLength) {
            subStr = message.slice(0, maxMessageLength);
        }

        templateList[key].message = subStr;

        this.props.onChangeTemplateList(templateList);
    }

    onRemove(key) {
        const { templateList } = this.props;

        templateList.splice(key, 1);

        this.props.onChangeTemplateList(templateList);
    }

    onToggle(key) {
        const { templateList } = this.props;

        templateList[key].publish = !templateList[key].publish;

        this.props.onChangeTemplateList(templateList);
    }

    displayShortcut(key) {
        const { appEnums } = this.props;

        if (platform.os.family === "OS X") {
            return key === 10 ? appEnums.KeyboardShortcut.MacTemplateMessage0 : appEnums.KeyboardShortcut[`MacTemplateMessage${key}`];
        } else {
            return key === 10 ? appEnums.KeyboardShortcut.WinTemplateMessage0 : appEnums.KeyboardShortcut[`WinTemplateMessage${key}`];
        }
    }

    render() {
        const { templateList } = this.props;
        let count = 0;
        const itemListUi = Object.keys(templateList).map(id => {
            count++;

            const src = templateList[id].publish ? "/images/settings/on.png" : "/images/settings/off.png";

            return (
                <div key={id} className="template-div">
                    <div>
                        <p className="template-content-label2">
                            Template {count} of {this.props.maxTemplateCount} - Shortcut( {this.displayShortcut(count)} )
                        </p>
                    </div>
                    <div>
                        <input
                            value={templateList[id].title}
                            className="account-setting-text-input short-input mb-3"
                            placeholder="Title"
                            onChange={event => this.onChangeTitle(id, event.target.value)}
                            type="text"
                        />
                    </div>
                    <input
                        value={templateList[id].message}
                        className="account-setting-text-input"
                        placeholder="Message"
                        onChange={event => this.onChangeMessage(id, event.target.value)}
                        type="text"
                    />
                    <p className="template-content-label3 pr-3">
                        {templateList[id].message.length} / {this.props.maxMessageLength}
                    </p>
                    <input
                        alt="template delete button"
                        type="button"
                        className="template-delete-button"
                        onClick={() => this.onRemove(id)}
                    />
                    <input
                        src={src}
                        className="auto-toggle-button"
                        alt="toggle button on off"
                        type="image"
                        onClick={() => this.onToggle(id)}
                    />
                </div>
            );
        });

        return itemListUi;
    }
}
