import React from "react";
import webApi from "../api/webApi";
import appConstants from "../appConstants";
import AppEvent from "../appEvent";
import { toast } from "react-toastify";
import commonApi from "../api/commonApi";

export default class ChangePasswordUi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPw: "",
            newPw: "",
            confirmPw: "",
            toastMessage: "",
            toastType: toast.TYPE.ERROR,
            email: "",
            isSuccessful: false
        };
        this.toastId = "__Change_Password__";
        this.handleChangeCurrentPassword = event => this.setState({ currentPw: event.target.value });
        this.handleChangeNewPassword = event => this.setState({ newPw: event.target.value });
        this.handleChangeConfirmPassword = event => this.setState({ confirmPw: event.target.value });
        this.clearInputs = () => this.setState({ currentPw: "", newPw: "", confirmPw: "" });
        this.currentPasswordNode = null;
        this.newPasswordNode = null;
        this.confirmPasswordNode = null;
        this.submitButtonNode = null;
    }

    componentWillUnmount() {
        commonApi.dismissToast(this.toastId);
    }

    componentDidUpdate(prevProps, prevState) {
        const somethingChange = this.state.confirmPw || this.state.currentPw || this.state.newPw;
        const { appEvent } = this.props;

        if (appEvent != null) {
            if (somethingChange) appEvent.fireEvent(AppEvent.EVENT_SET_UNSAVED_CHANGES);
            else appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
        }

        if (this.state.toastMessage !== prevState.toastMessage && this.state.toastMessage) {
            commonApi.displayToast(this.state.toastMessage, this.toastId, this.state.toastType, {
                type: this.state.toastType,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                onClose: this.dismissError,
                onClick: this.dismissError
            });
        }
    }

    onSuccess = response => {
        this.setState(
            {
                toastMessage: "Your password has been changed successfully!",
                toastType: toast.TYPE.SUCCESS,
                isSuccessful: true
            },
            () => {
                this.props.getNewAccessTokens(response);
            }
        );
    };

    dismissError = () => {
        this.setState({ toastMessage: "", toastType: toast.TYPE.ERROR }, this.state.isSuccessful ? this.onCancel : null);
    };

    onSave = event => {
        event.preventDefault();
        if (this.state.currentPw.length === 0) {
            return this.setState({ toastMessage: "Current password is required" }, this.clearInputs);
        }
        if (this.state.newPw.length === 0) {
            return this.setState({ toastMessage: "New password is required" }, this.clearInputs);
        }
        if (this.state.confirmPw.length === 0) {
            return this.setState({ toastMessage: "Confirm new password is required" }, this.clearInputs);
        }
        if (this.state.newPw !== this.state.confirmPw) {
            return this.setState({ toastMessage: "New password and confirm new password do not match" }, this.clearInputs);
        }
        if (this.state.newPw.length < appConstants.MINIMUM_PASSWORD_LENGTH) {
            const toastMessage = `New password must be atleast ${appConstants.MINIMUM_PASSWORD_LENGTH} characters long`;
            return this.setState({ toastMessage }, this.clearInputs);
        }

        const { email, token } = this.props;

        webApi
            .fetchChangePassword(email, this.state.currentPw, this.state.confirmPw, token)
            .then(this.onSuccess)
            .catch(errDict => this.setState({ toastMessage: errDict.toastMessage }))
            .finally(this.clearInputs);
    };

    onCancel = event => {
        this.props.onChangePasswordCancel();
    };

    onKeyUp = event => {
        const activeElement = document.activeElement;
        if (event.keyCode === 13) {
            switch (activeElement.id) {
                case this.currentPasswordNode.id:
                    this.newPasswordNode.focus();
                    break;
                case this.newPasswordNode.id:
                    this.confirmPasswordNode.focus();
                    break;
                case this.confirmPasswordNode.id:
                    this.submitButtonNode.focus();
                    break;
                default:
                    break;
            }
        }
    };

    render() {
        return (
            <div className="ui2-container-div background-color-v2 slide-up-animation">
                <div className="change-password-main-div">
                    <span className="change-password-main-heading">CHANGE PASSWORD</span>
                    <p />
                    <span className="settings-input-label">Current Password</span>
                    <input
                        type="password"
                        id="currentPassword"
                        ref={n => (this.currentPasswordNode = n)}
                        value={this.state.currentPw}
                        onChange={this.handleChangeCurrentPassword}
                        onKeyUp={this.onKeyUp}
                        className="account-setting-text-input"
                        readOnly={this.state.isSuccessful}
                    />
                    <p />
                    <span className="settings-input-label">New Password</span>
                    <input
                        type="password"
                        id="newPassword"
                        ref={n => (this.newPasswordNode = n)}
                        value={this.state.newPw}
                        onChange={this.handleChangeNewPassword}
                        onKeyUp={this.onKeyUp}
                        className="account-setting-text-input"
                        readOnly={this.state.isSuccessful}
                    />
                    <p />
                    <span className="settings-input-label">Confirm New Password</span>
                    <input
                        type="password"
                        id="confirmNewPassword"
                        ref={n => (this.confirmPasswordNode = n)}
                        value={this.state.confirmPw}
                        onChange={this.handleChangeConfirmPassword}
                        onKeyUp={this.onKeyUp}
                        className="account-setting-text-input"
                        readOnly={this.state.isSuccessful}
                    />
                    <form className="change-password-form" onSubmit={this.onSave}>
                        <input
                            id="changePasswordSubmit"
                            ref={n => (this.submitButtonNode = n)}
                            value="SUBMIT"
                            className="account-setting-main-button"
                            type="submit"
                            alt="change password submit"
                            disabled={this.state.isSuccessful}
                        />
                        <input
                            onClick={this.onCancel}
                            id="changePasswordCancel"
                            value="CANCEL"
                            className="account-setting-main-button"
                            type="button"
                            alt="change password cancel"
                            disabled={this.state.isSuccessful}
                        />
                    </form>
                </div>
            </div>
        );
    }
}
