import appEnums from "./appEnums";

const appConstants = {
    defaultRootPage: appEnums.RootPage.Login,
    defaultProfilePic: "/images/zqMOBVjGtoWel60h.png",
    androidPlatformIcon: "/images/icon_android_blk.png",
    unityPlatformIcon: "/images/icon_unity_blk.png",
    iosPlatformIcon: "/images/icon_ios_blk.png",
    defaultPageNumber: 1,
    defaultPageSize: 20,
    imageMaxSizeMB: 5 * 1024 * 1024,
    videoMaxSizeMB: 50 * 1024 * 1024,
    faqFileMaxSizeMB: 10 * 1024 * 1024,

    MAX_MESSAGE_ESCAPE_LENGTH: 350,
    MAX_INTERNAL_NOTES_ESCAPE_LENGTH: 350,
    MAX_AUTOMATION_SETTING_ESCAPE_LENGTH: 4000,

    EVENT_SOCKET_INFO: "socketInfo",
    EVENT_MESSAGE: "message",
    EVENT_INTERNAL_NOTES: "internalNotes",
    EVENT_SELECT_CONVERSATION: "selectConversation",
    EVENT_TYPING: "typing",
    EVENT_CHANGE_STATE: "changeState",
    EVENT_UPDATE_APN_FILES: "updateAPNFiles",
    EVENT_UPLOAD_PROFILE_IMAGE: "uploadProfileImage",

    EVENT_UPLOAD_FAQ_IMAGE: "uploadFaqImage",
    EVENT_DELETE_FAQ_IMAGE: "deleteFaqImage",

    EVENT_ADD_FAQ: "addFaq",
    EVENT_DELETE_FAQ: "deleteFaq",
    EVENT_RETRIEVE_FAQ: "retrieveFaq",
    EVENT_SAVE_CHANGES_FAQ: "saveChangesFaq",
    EVENT_UPDATE_FAQS_ORDER: "updateFaqsOrder",
    EVENT_UPDATE_CONVERSATION_METADATA_TAGS: "updateConversationMetadataTags",
    EVENT_LINK_DEVICE: "linkDevice",
    EVENT_INSTANT_PUSH_TOKEN: "instantPushToken",
    EVENT_OPEN_INSTANT_PUSH: "openInstantPush",

    EVENT_UPLOAD_APP_ICON: "uploadAppIcon",

    TYPING_UI_ANIMATION_TIME: 10000,

    ADMIN_LINK_KEY_BUNDLE_ID: /{appName}/g,
    ADMIN_LINK_KEY_DEVICE_ID: /{userId}/g,
    ADMIN_LINK_KEY_PASSWORD_HASH: /{password}/g,
    ADMIN_LINK_KEY_NETFLIX_PROFILE_ID: /{netflixProfileId}/g,
    BUNDLE_ID_KEY: /{bundleId}/g,
    CONVERSATION_STATE_KEY: /{messageState}/g,
    TIMESTAMP_KEY: /{timestamp}/g,
    MESSAGE_BTN_FILTER_KEY: "{bundleId}:message_filter",
    MESSAGE_BTN_FILTER_FRMT: "{messageState}:{timestamp}",
    MESSAGE_BTN_FILTER_EXPR: 43200000, // 12 hours, in milliseconds
    LAST_SELECTED_APP_KEY: "last_selected_application",

    TIME_SORT_DOWN: 0,
    TIME_SORT_UP: 1,

    SET_NEW_PASSWORD_SUCCESS_RELOAD_TIME: 10000,
    MINIMUM_PASSWORD_LENGTH: 6
};

export default appConstants;
