import React, { useEffect, useState } from "react";
import "./index.css";
import _ from "lodash";
import appConstants from "../appConstants";
import { getCountryDataList } from "countries-list";
import { DateTime } from "luxon";
import Select from "react-select";

const countryList = [...getCountryDataList().map(({ name }) => name), 'Latin America'].sort();
const countryOptions = countryList.map(country => ({ value: country, label: country }))

const initialFilterState = Object.freeze({
    timestampStart: null,
    timestampEnd: null,
    dateAscending: false,
    deviceType: "All",
    sdk: "",
    appVersion: "",
    country: "",
})

const generateTimestampStart = (date) => {
    if (_.isEmpty(date)) return null;
    return DateTime.fromISO(date).startOf("day").toMillis();
}
const generateTimestampEnd = (date) => {
    if (_.isEmpty(date)) return null;
    return DateTime.fromISO(date).endOf("day").toMillis();
}

export const ConversationFilterFormTrigger = function ({ filters, ...props }) {
    const [isOpen, setIsOpen] = useState(false);

    const isFilterActive = !_.isEqual(filters, initialFilterState);

    const onClick = event => {
        var expanded = event.target.getAttribute("aria-expanded") === "true";
        setIsOpen(expanded);
    };

    const title = isFilterActive
        ? 'Filters are currently active'
        : undefined;

    return (
        <div className="position-relative" title={title}>
            <input
                onClick={onClick}
                type="image"
                src="/images/conversation/filter/btn_filter.png"
                alt="Conversation filter form trigger"
                {...props}
            />
            {isFilterActive && <div className="conversation-filter-active-bullet" />}
        </div>
    );
};

export const ConversationFilterForm = function ({ filters, onApplyFilters }) {
    const [newFilters, setNewFilters] = useState(initialFilterState);
    const setFilterParameter = (key, value) => setNewFilters((newFilters) => ({ ...newFilters, [key]: value }));

    const onChangeDateStart = (event) => {
        const timestampStart = generateTimestampStart(event.target.value);
        const timestampEnd = newFilters.timestampEnd;
        setFilterParameter("timestampStart", timestampStart);

        if (_.isNil(timestampEnd)) {
            // Autofill date end
            setFilterParameter("timestampEnd", DateTime.now().endOf('day').toMillis());
        } else if (_.isNil(timestampStart) && !_.isNil(timestampEnd)) {
            // Clear other date field as well
            setFilterParameter("timestampEnd", null);
        } else if (!_.isNil(timestampEnd) && +DateTime.fromMillis(timestampEnd) < +DateTime.fromMillis(timestampStart)) {
            // Validate date range
            setFilterParameter("timestampEnd", generateTimestampEnd(event.target.value));
        }
    }
    const onChangeDateEnd = (event) => {
        const timestampEnd = generateTimestampEnd(event.target.value);
        const timestampStart = newFilters.timestampStart;
        setFilterParameter("timestampEnd", timestampEnd);

        if (_.isNil(timestampStart)) {
            // Autofill date start (-1 month)
            setFilterParameter("timestampStart", DateTime.fromISO(event.target.value).minus({ month: 1 }).toMillis());
        } else if (_.isNil(timestampEnd) && !_.isNil(timestampStart)) {
            // Clear other date field as well
            setFilterParameter("timestampStart", null);
        } else if (!_.isNil(timestampStart) && +DateTime.fromMillis(timestampEnd) < +DateTime.fromMillis(timestampStart)) {
            // Validate date range
            setFilterParameter("timestampStart", generateTimestampStart(event.target.value));
        }
    }
    const generateOnChangeSortDate = (isAscending) => () => setFilterParameter("dateAscending", isAscending)
    const generateOnChangeDeviceType = (deviceType) => () => setFilterParameter("deviceType", deviceType)
    const onChangeSdk = (event) => setFilterParameter("sdk", event.target.value)
    const onChangeAppVersion = (event) => setFilterParameter("appVersion", event.target.value)
    const onChangeCountry = (newCountry) => setFilterParameter("country", newCountry?.value ?? "")

    const onClickApply = () => onApplyFilters(newFilters);
    const onClickClear = () => {
        setNewFilters(initialFilterState);
        onApplyFilters(initialFilterState);
    }

    const isModified = !_.isEqual(filters, newFilters);
    const isFilterActive = !_.isEqual(filters, initialFilterState);

    const newestToOldest = (
        <>
            <i className="bi bi-sort-alpha-down" />
            &nbsp;Newest to Oldest
        </>
    );

    const oldestToNewest = (
        <>
            <i className="bi bi-sort-alpha-up-alt" />
            &nbsp;Oldest to Newest
        </>
    );

    const isoDateStart = newFilters.timestampStart ? DateTime.fromMillis(newFilters.timestampStart).toISODate() : "";
    const isoDateEnd = newFilters.timestampEnd ? DateTime.fromMillis(newFilters.timestampEnd).toISODate() : "";

    return (
        <div id="conversationFilterFields">
            <div className="collapse" id="collapseFilters">
                <div className="d-flex" id="conversationFilterHeader">
                    <span>FILTERS</span>
                    <input
                        type="image"
                        src="/images/conversation/filter/btn_close.png"
                        id="conversationFilterCloseBtn"
                        alt="Close button"
                        data-toggle="collapse"
                        data-target="#collapseFilters"
                        aria-expanded="false"
                        aria-controls="collapseFilters"
                    />
                </div>
                <div className="p-3 border border-top-0 border-left-0 border-right-0 border-bottom-0">
                    <div className="form-group">
                        <span className="conversation-filter-label">Date</span>
                        <div id="conversationDateFilter" className="d-flex justify-content-center align-items-center">
                            <input
                                type="date"
                                title="Start date"
                                placeholder="MM/DD/YYYY"
                                onChange={onChangeDateStart}
                                value={isoDateStart}
                                className="conversation-date-range"
                            />
                            <span style={{ margin: "0 0.5rem" }}>-</span>
                            <input
                                type="date"
                                title="End date"
                                placeholder="MM/DD/YYYY"
                                onChange={onChangeDateEnd}
                                value={isoDateEnd}
                                className="conversation-date-range"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="btn-group w-100">
                            <button id="conversationSortOrderBtn" type="button" onClick={generateOnChangeSortDate(!newFilters.dateAscending)}>
                                {newFilters.dateAscending ? oldestToNewest : newestToOldest}
                            </button>
                        </div>
                    </div>

                    <div className="form-group">
                        <span className="conversation-filter-label">Device</span>
                        <div id="conversationsDeviceFilter" className="d-flex justify-content-start align-items-center">
                            <div className="conversation-device-group">
                                <input
                                    type="radio"
                                    name="deviceType"
                                    id="deviceTypeAll"
                                    checked={newFilters.deviceType === "All"}
                                    onChange={generateOnChangeDeviceType("All")}
                                />
                                &nbsp;&nbsp;
                                <label htmlFor="deviceTypeAll">
                                    <span>All</span>
                                </label>
                            </div>
                            <div className="conversation-device-group">
                                <input
                                    type="radio"
                                    name="deviceType"
                                    id="deviceTypeIOS"
                                    checked={newFilters.deviceType === "iOS"}
                                    onChange={generateOnChangeDeviceType("iOS")}
                                />
                                <label htmlFor="deviceTypeIOS">
                                    <img src={appConstants.iosPlatformIcon} alt="Apple" />
                                    <span>Apple</span>
                                </label>
                            </div>
                            <div className="conversation-device-group">
                                <input
                                    type="radio"
                                    name="deviceType"
                                    id="deviceTypeAndroid"
                                    checked={newFilters.deviceType === "Android"}
                                    onChange={generateOnChangeDeviceType("Android")}
                                />
                                <label htmlFor="deviceTypeAndroid">
                                    <img src={appConstants.androidPlatformIcon} alt="Android" />
                                    <span>Android</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <span className="conversation-filter-label">SDK</span>
                        <input
                            type="text"
                            id="conversationSDKFilter"
                            className="conversation-filter-text-field"
                            value={newFilters.sdk}
                            maxLength={15}
                            onChange={onChangeSdk}
                        />
                    </div>

                    <div className="form-group">
                        <span className="conversation-filter-label">App Version</span>
                        <input
                            type="text"
                            id="conversationAppVersionFilter"
                            className="conversation-filter-text-field"
                            value={newFilters.appVersion}
                            maxLength={15}
                            onChange={onChangeAppVersion}
                        />
                    </div>

                    <div className="form-group">
                        <span className="conversation-filter-label">Country</span>
                        <Select
                            value={!newFilters.country ? null : { value: newFilters.country, label: newFilters.country }}
                            options={countryOptions}
                            onChange={onChangeCountry}
                            placeholder="All Countries"
                            isClearable
                            classNames={{
                                control: (state) => "conversation-select-control"
                            }}
                        />
                    </div>
                    <br />
                    <div className="form-group">
                        <button
                            id="conversationFilterClearBtn"
                            className="conversation-filter-btn"
                            onClick={onClickClear}
                            disabled={!isFilterActive}
                            data-toggle="collapse"
                            data-target="#collapseFilters"
                            aria-expanded="false"
                            aria-controls="collapseFilters"
                        >
                            CLEAR
                        </button>
                    </div>
                    <button
                        id="conversationFilterApplyBtn"
                        className="conversation-filter-btn"
                        onClick={onClickApply}
                        disabled={!isModified}
                        data-toggle="collapse"
                        data-target="#collapseFilters"
                        aria-expanded="false"
                        aria-controls="collapseFilters"
                    >
                        APPLY
                    </button>
                </div>
            </div>
        </div>
    )
}
