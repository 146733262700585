import React, { Component } from "react";
import SqlString from "sqlstring";
import appConstants from "../appConstants";
import appEnums from "../appEnums";
import AppEvent from "../appEvent";
import webApi from "../api/webApi";
import commonApi from "../api/commonApi";
import AutomationTranslation from "./automationTranslationUi";
import { toast } from "react-toastify";

export default class EditAutoResolveUi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageText: "",
            initialMessageText: "",
            days: 60,
            initialDays: 60,
            errorType: toast.TYPE.ERROR,
            error: ""
        };
        this.toastId = "__Edit_Auto_Resolve__";
        this.dismissError = () => this.setState({ error: "" });
    }

    componentWillUnmount() {
        const { appEvent } = this.props;
        toast.dismiss(this.toastId);
        appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
    }

    componentDidMount() {
        this.setState({
            messageText: this.props.srcMessageText,
            initialMessageText: this.props.srcMessageText,
            days: commonApi.isInteger(this.props.srcDays) ? parseInt(this.props.srcDays) : this.props.srcDays,
            initialDays: commonApi.isInteger(this.props.srcDays) ? parseInt(this.props.srcDays) : this.props.srcDays
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { appEvent } = this.props;
        const daysChange = this.state.days !== this.state.initialDays;
        const messageChange = this.state.messageText !== this.state.initialMessageText;
        if (appEvent != null) {
            if (daysChange || messageChange) appEvent.fireEvent(AppEvent.EVENT_SET_UNSAVED_CHANGES);
            else appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
        }
        if (prevState && this.state.error !== prevState.error && this.state.error) {
            if (toast.isActive(this.toastId)) {
                toast.update(this.toastId, { type: this.state.errorType, render: this.state.error });
            } else {
                toast(this.state.error, {
                    position: toast.POSITION.TOP_RIGHT,
                    type: this.state.errorType,
                    toastId: this.toastId,
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    onClose: this.dismissError,
                    onClick: this.dismissError
                });
            }
        }
    }

    handleMessageTextChange = event => {
        let newText = event.target.value;
        let escapeLength = SqlString.escape(newText).length;

        if (newText.length - this.state.messageText.length === 1) {
            if (escapeLength <= appConstants.MAX_MESSAGE_ESCAPE_LENGTH) this.setState({ messageText: newText });
        } else {
            while (escapeLength > appConstants.MAX_MESSAGE_ESCAPE_LENGTH) {
                newText = newText.slice(0, -1);
                escapeLength = SqlString.escape(newText).length;
            }
            this.setState({ messageText: newText });
        }
    };

    handleDaysChange = event => {
        let days = event.target.value;
        if (commonApi.isInteger(days)) days = parseInt(days);
        this.setState({ days: days });
    };

    onSuccess = () => {
        const { appEvent, onClickSettingsTab } = this.props;
        if (appEvent != null) appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
        onClickSettingsTab(appEnums.GeneralSettings.Automation);
    };

    onSave = () => {
        if (this.state.messageText.length === 0) return this.setState({ error: "Message Text is required" });
        if (!commonApi.isInteger(this.state.days)) return this.setState({ error: 'Integer value is required for the "days" field' });

        this.dismissError();
        const { hcCompanyId, token, appEvent } = this.props;
        webApi
            .fetchEditAutoResolveData(hcCompanyId, this.props.bundleId, this.state.messageText, this.state.days, token)
            .then(this.onSuccess)
            .catch(errDict => {
                this.setState({ error: errDict.error });
                appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict);
            });
    };

    onCancel = () => {
        const { onClickSettingsTab } = this.props;
        onClickSettingsTab(appEnums.GeneralSettings.Automation);
    };

    render() {
        return (
            <div className="background-color-v2 ui2-container-div">
                <div className="edit-automation-content">
                    <p className="edit-automation-heading">EDIT AUTO-RESOLVE</p>
                    <p className="text-orange">Message</p>
                    <textarea
                        id="editAutomationTextArea"
                        value={this.state.messageText}
                        onChange={this.handleMessageTextChange}
                        autoFocus></textarea>
                    <p />
                    <p className="text-orange">Days</p>
                    <input
                        id="editAutomationDaysInput"
                        value={this.state.days}
                        className="edit-automation-text-input"
                        onChange={this.handleDaysChange}
                    />
                    <div className="automation-btn-div">
                        <input onClick={this.onSave} id="editAutomationSave" className="automation-btn" value="SAVE" type="button" />
                        <input onClick={this.onCancel} id="editAutomationCancel" className="automation-btn" value="CANCEL" type="button" />
                    </div>

                    <br />
                    <span className="official-text-color d-block my-2 font-weight-bold">Localized messages</span>
                    <AutomationTranslation
                        messageType={appEnums.Automation.Resolve}
                        token={this.props.token}
                        hcCompanyId={this.props.hcCompanyId}
                        bundleId={this.props.bundleId}
                        onClickSettingsTab={this.props.onClickSettingsTab}
                        role={appEnums.Role.Agent}
                        appInfoDict={this.props.appInfoDict}
                        appEvent={this.props.appEvent}
                        onChangeApp={this.props.onChangeApp}
                    />
                </div>
            </div>
        );
    }
}
