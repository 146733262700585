import React from 'react';

const Alert = props => {
    const { onConfirm, confirmLabel, title, message, topIconSrc } = props;
    return (
        <div className="hc-modal-active" tabIndex="-1" role="dialog">
            <div className="modal-dialog slide-up-animation" role="document">
                <div className="hc-modal-content">
                    <div className="hc-modal-header">
                        <div className="hc-header-content">
                            <img
                                className="hc-header-img"
                                src={topIconSrc ? topIconSrc : '/images/settings/icon_remove_popup.png'}
                                alt="warrning"
                            />
                            <span className="hc-modal-title">{title}</span>
                        </div>
                        <button type="button" className="hc-modal-close" aria-label="Close" onClick={onConfirm}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="hc-modal-body">
                        <p>{message}</p>
                    </div>
                    <div className="hc-modal-footer">
                        <button type="button" className="hc-modal-btn" onClick={onConfirm}>
                            {confirmLabel}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Alert;
