import React, { Component } from 'react';
import './helpUi.css';

class HelpUi extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="ui1-container-div-lg slide-up-animation" id="helpPage">
                <span className="help-title-text">Helpchatter Tutorial</span>
                <p />

                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label className="help-subtitle-text">
                                    <b>1. Analytics</b>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                In the home dashboard metrics page:
                                <ul>
                                    <li>New messages: new message count</li>
                                    <li>
                                        mau total: the total monthly active user. The client which opens the app will affect the
                                        value. Server updates the value per hour.
                                    </li>
                                    <li>
                                        mau on chat: the total mau on chat. When someone sends a message will affect the value.
                                        Server updates the value per hour.
                                        <p />
                                        <img src="/images/help/analytics1.png" alt="analytics1" title="analytics1" />
                                        <span> </span>
                                        <img src="/images/help/analytics2.png" alt="analytics2" title="analytics2" />
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p />

                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label className="help-subtitle-text">
                                    <b>2. Messaging</b>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>Search: search for last message from the message list.</li>
                                    <li>
                                        Wait time: sort the message list by time.
                                        <br />
                                        <img src="/images/help/messaging1.png" alt="messaging1" title="messaging1" />
                                        <p />
                                    </li>
                                    <li>
                                        UDID: display the user device id. Clicking on that will open the admin link web site. The
                                        link icon will not show if there is no admin link set in settings.
                                    </li>
                                    <li>User: user name</li>
                                    <li>The left message: client message</li>
                                    <li>The right message: agent message</li>
                                    <li>Resolve button：set the conversation state to closed.</li>
                                    <li>
                                        Reply button：send message to the client user.
                                        <br />
                                        <img src="/images/help/messaging2.png" alt="messaging2" title="messaging2" width="50%" />
                                        <p />
                                    </li>
                                    <li>Camera icon: send image file.</li>
                                    <li>
                                        Star icon: click the icon can send rating message to the client user. If the user clicks
                                        the rating button, the device will open the app store link web.
                                        <br />
                                        <img
                                            src="/images/help/messaging_rating1.png"
                                            alt="messaging_rating1"
                                            title="messaging_rating1"
                                            width="50%"
                                        />
                                        <br />
                                        <img
                                            src="/images/help/messaging_rating2.png"
                                            alt="messaging_rating2"
                                            title="messaging_rating2"
                                            width="25%"
                                        />
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p />

                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label className="help-subtitle-text">
                                    <b>3. App Setting</b>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>App Name: the application name.</li>
                                    <li>App Icon (optional): use the image URL link to display the icon.</li>
                                    <li>
                                        Admin Link (optional): in the message page, we can click the right corner #number button
                                        to open the admin URL. The {"'{appName}'"} in the URL will be replaced with bundle id, and
                                        the {"'{userId}'"} will be replaced with device unique id.
                                    </li>
                                    <li>
                                        App Store Link (optional): we can send a rating message to the client user. The user who
                                        clicks the message will be directed to the url.
                                    </li>

                                    <li>
                                        App Key: the developer must copy the app key to the client app.
                                        <br />
                                        <img src="/images/help/appSetting1.png" alt="appSetting1" title="appSetting1" />
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <blockquote>
                                    <label className="help-subtitle-text2">
                                        <b>
                                            <u>Android</u>
                                        </b>
                                    </label>
                                </blockquote>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>
                                        Google FCM Server Key: This is the firebase server key.
                                        <br />
                                        <img src="/images/help/appSetting2_1.png" alt="appSetting2_1" title="appSetting2_1" />
                                    </li>
                                    <br />
                                    <li>
                                        App Key: Add the app key to the AndroidManifest.xml.
                                        <br />
                                        <img
                                            src="/images/help/appSetting2_2.png"
                                            alt="appSetting2_2"
                                            title="appSetting2_2"
                                            width="50%"
                                        />
                                    </li>
                                </ul>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <blockquote>
                                    <label className="help-subtitle-text2">
                                        <b>
                                            <u>iOS</u>
                                        </b>
                                    </label>
                                </blockquote>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>
                                        Upload Apple Push Notification service (APNs) files (only *.p12)
                                        <br />
                                        <img src="/images/help/appSetting3_1.png" alt="appSetting3_1" title="appSetting3_1" />
                                        <p />
                                    </li>
                                    <li>
                                        Add key and value in the info.plist
                                        <br />
                                        <img
                                            src="/images/help/appSetting3_2.png"
                                            alt="appSetting3_2"
                                            title="appSetting3_2"
                                            width="50%"
                                        />
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <p />

                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label className="help-subtitle-text">
                                    <b>4. Company Setting</b>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>Company Name: change your company name</li>
                                    <li>
                                        Name: change your account name (client can see who sent the message)
                                        <p />
                                        <img
                                            src="/images/help/accountSetting1.png"
                                            alt="accountSetting1"
                                            title="accountSetting1"
                                        />
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label className="help-subtitle-text">
                                    <b>6. Automation Setting</b>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>Auto-Response Setting: change automated response as soon users reach out.</li>
                                    <li>Automatically Follow Up with the user after X days : change automated follow up to the user if the user dows not reply after X days.</li>
                                    <li>Automatically resolve conversation with the user after X days : change setup for automatically resolve the conversation when the user dows not reply after X days.</li>
                                    <li>Message Display Name : change display name on automated response.</li>
                                    <li>Prority Word - change list of word to be check to include message to Priority New inbox.</li>
                                    <li>
                                        Default Translation Language : change default translation language.
                                        <p />
                                        <img
                                            src="/images/help/automationSetting1.png"
                                            alt="automationSetting1"
                                            title="automationSetting1"
                                            width="50%"
                                        />
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table>
                    <tbody>
                        <tr>
                            <td>
                                <label className="help-subtitle-text">
                                    <b>7. Template Builder</b>
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <ul>
                                    <li>Name of App/Game : Select App/Game to modify template.</li>
                                    <li>
                                        Templates : List of template. Maximum of 10 templates. Shortcut key ALT + 1 to 0.
                                        <p />
                                        <img
                                            src="/images/help/templateBuilder1.png"
                                            alt="templateBuilder1"
                                            title="templateBuilder1"
                                            width="50%"
                                        />
                                    </li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default HelpUi;
