import React, { Component } from 'react';
import webApi from '../api/webApi';
import Loading from '../loading';
import './faqArticleView.css';

import { Editor, EditorState, convertFromRaw } from 'draft-js';
import { customBlockRendererFn, customStyleMap, editorDecorators } from './faqUtils';

class FaqPublicView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            dateCreated: '',
            lastUpdatedTime: '',
            error: '',
            isLoading: true,
            editorState: EditorState.createEmpty(editorDecorators)
        };
        this.onFetchFaqArticle = this.onFetchFaqArticle.bind(this);
    }

    onFetchFaqArticle(res) {
        const body = res.body;
        const rawDataObj = JSON.parse(body);
        const fromRaw = convertFromRaw(rawDataObj);
        const contentState = EditorState.createWithContent(fromRaw);
        const contentWithDecorator = EditorState.set(contentState, { decorator: editorDecorators });
        this.setState({
            editorState: contentWithDecorator,
            isLoading: false,
            title: res.title,
            dateCreated: res.dateCreated,
            lastUpdatedTime: res.lastUpdatedTime
        });
    }

    componentDidMount() {
        const { bundleId, appFaqId } = this.props;
        webApi
            .fetchGetFaqArticle(bundleId, appFaqId)
            .then(this.onFetchFaqArticle)
            .catch(errDict => this.setState({ error: errDict.error, isLoading: false }));
    }

    render() {
        const mainDiv = this.state.isLoading ? (
            <Loading />
        ) : this.state.error ? (
            this.state.error
        ) : (
            <div className="w-100 mb-5 mt-3">
                <h1 className="text-center">{this.state.title}</h1>
                <Editor
                    customStyleMap={customStyleMap}
                    blockRendererFn={customBlockRendererFn}
                    editorState={this.state.editorState}
                    spellCheck={true}
                    readOnly
                />
            </div>
        );
        return (
            <div className="container">
                {mainDiv}
                <nav className="navbar bg-transparent" id="faqPublicFooter">
                    Helpchatter LLC &copy; 2008 - {new Date().getFullYear()}
                </nav>
            </div>
        );
    }
}

export default FaqPublicView;
