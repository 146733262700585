import React, { Component } from "react";
import UserItem from "./userItem";
import "./userList.css";
import appConstants from "../appConstants";
import webApi from "../api/webApi";
import DeleteConfirm from "../customAlerts/deleteConfirm";
import { toast } from "react-toastify";

export default class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // fields to be set for user deletion
            userIndex: -1,
            userName: "",
            userEmail: "",
            userHcAccountId: "",

            userListSliced: [],
            pageSize: appConstants.defaultPageSize,
            pageNumber: appConstants.defaultPageNumber,
            error: "",
            errorType: toast.TYPE.ERROR
        };
        this.toastId = Date.now();
        this.onDeleteUser = this.onDeleteUser.bind(this);
        this.setConfirmDeleteUserModal = this.setConfirmDeleteUserModal.bind(this);
        this.onClickPageNumber = this.onClickPageNumber.bind(this);
        this.onClickPreviousPage = this.onClickPreviousPage.bind(this);
        this.onClickNextPage = this.onClickNextPage.bind(this);
        this.onFetchCompanyAccounts = this.onFetchCompanyAccounts.bind(this);
        this.dismissError = this.dismissError.bind(this);
    }

    componentDidMount() {
        const { hcAccountId, hcCompanyId, token } = this.props;
        webApi
            .fetchGetCompanyAccounts(hcAccountId, hcCompanyId, token)
            .then(this.onFetchCompanyAccounts)
            .catch(errDict => this.setState({ error: errDict.error, errorType: toast.TYPE.ERROR }));
    }

    componentWillUnmount() {
        toast.dismiss(this.toastId);
    }

    componentDidUpdate(prevProps, prevState) {
        // detects changes for both adding and removing a user
        if (prevProps.userList.length !== this.props.userList.length) {
            const { userList } = this.props;
            this.setState({ userListSliced: userList.slice(0, this.state.pageSize), pageNumber: 1 });
        }
        if (this.state.error !== prevState.error && this.state.error) {
            if (toast.isActive(this.toastId)) {
                toast.update(this.toastId, {
                    type: this.state.errorType,
                    render: this.state.error
                });
            } else {
                toast(this.state.error, {
                    position: toast.POSITION.TOP_RIGHT,
                    type: this.state.errorType,
                    toastId: this.toastId,
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    onClose: this.dismissError,
                    onClick: this.dismissError
                });
            }
        }
    }

    onFetchCompanyAccounts(resDict) {
        const { setUserList, setUserCount } = this.props;
        this.setState({ userListSliced: resDict.userList.slice(0, this.state.pageSize) }, () => {
            setUserList(resDict.userList);
            setUserCount(resDict.userList.length);
        });
    }

    onClickPageNumber(event) {
        event.preventDefault();
        const { userCount, userList } = this.props;
        let page = event.target.value;
        page = parseInt(page);
        const start = (page - 1) * this.state.pageSize;
        let end = start + this.state.pageSize;
        end = end > userCount ? userCount : end;
        this.setState({ pageNumber: page, userListSliced: userList.slice(start, end) });
    }

    onClickPreviousPage(event) {
        event.preventDefault();
        if (this.state.pageNumber > 1) {
            const { userCount, userList } = this.props;
            let previousPage = this.state.pageNumber - 1;
            const start = (previousPage - 1) * this.state.pageSize;
            let end = start + this.state.pageSize;
            end = end > userCount ? userCount : end;
            this.setState({ pageNumber: previousPage, userListSliced: userList.slice(start, end) });
        }
    }

    onClickNextPage(event) {
        event.preventDefault();
        const { userCount, userList } = this.props;
        const totalPageNumber = Math.ceil(userCount / this.state.pageSize);
        if (this.state.pageNumber < totalPageNumber) {
            let nextPage = this.state.pageNumber + 1;
            const start = this.state.pageNumber * this.state.pageSize;
            let end = start + this.state.pageSize;
            end = end > userCount ? userCount : end;
            this.setState({ pageNumber: nextPage, userListSliced: userList.slice(start, end) });
        }
    }

    onDeleteUser() {
        const { setUserCount, setUserList, userList, token } = this.props;
        const { userEmail, userHcAccountId } = this.state;
        webApi
            .fetchDeactivateCompanyAccount(userEmail, userHcAccountId, token)
            .then(() => {
                this.setState(
                    {
                        error: `Successfully removed '${this.state.userName}' from ${this.props.companyName}`,
                        errorType: toast.TYPE.SUCCESS
                    },
                    () => {
                        const newList = Array.from(userList);
                        newList.splice(this.state.userIndex, 1);
                        setUserList(newList);
                        setUserCount(newList.length);
                    }
                );
            })
            .catch(errDict => this.setState({ error: errDict.error, errorType: toast.TYPE.ERROR }));
    }

    setConfirmDeleteUserModal(index, name, userHcAccountId, userEmail) {
        this.setState({
            userIndex: index,
            userName: name,
            userHcAccountId: userHcAccountId,
            userEmail: userEmail
        });
    }

    dismissError() {
        this.setState({ error: "", errorType: toast.TYPE.ERROR });
    }

    render() {
        const { token, userCount, userList, setUserList, setRole, hcAccountId } = this.props;
        const { pageNumber, pageSize, userListSliced } = this.state;
        const userItems = userListSliced.map((user, index) => {
            const trueIndex = index + (pageNumber - 1) * pageSize;
            return (
                <UserItem
                    key={index}
                    index={trueIndex}
                    userName={user.name}
                    userEmail={user.email}
                    userProfileImage={user.profileImage}
                    userHcAccountId={user.hcAccountId}
                    userHcCompanyId={user.hcCompanyId}
                    userRole={user.role}
                    token={token}
                    userList={userList}
                    setUserList={setUserList}
                    setConfirmDeleteUserModal={this.setConfirmDeleteUserModal}
                    setRole={setRole}
                    hcAccountId={hcAccountId}
                    guestAppNamesOptions={this.props.guestAppNamesOptions}
                />
            );
        });
        const arr = Array(Math.ceil(userCount / pageSize)).fill(0);
        const pageNumberList = arr.map((item, index) => {
            const page = index + 1;
            const active = pageNumber === page ? "active" : "";
            return (
                <li className={"page-item " + active} key={index}>
                    <button className="page-link" value={page} onClick={this.onClickPageNumber}>
                        {page}
                    </button>
                </li>
            );
        });
        const prevDisabled = pageNumber === 1 ? " disabled" : "";
        const nextDisabled = pageNumber === arr.length ? " disabled" : "";
        const paginatorComponent =
            this.props.userList.length > this.state.pageSize ? (
                <nav aria-label="user list navigation">
                    <ul className="pagination pagination-sm">
                        <li className={"page-item" + prevDisabled}>
                            <button className="page-link" aria-label="Previous" onClick={this.onClickPreviousPage}>
                                <span aria-hidden="true">&laquo;</span>
                            </button>
                        </li>
                        {pageNumberList}
                        <li className={"page-item" + nextDisabled}>
                            <button className="page-link" aria-label="Next" onClick={this.onClickNextPage}>
                                <span aria-hidden="true">&raquo;</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            ) : null;

        const modalMessage = (
            <span>
                Are you sure you want to remove&nbsp;
                <span className="text-orange">{this.state.userName}</span>
                &#63;
            </span>
        );
        const modalTitle = (
            <span>
                Remove&nbsp;
                <span className="text-orange">{this.state.userName}</span>
            </span>
        );

        const deleteConfirmModal = (
            <DeleteConfirm
                title={modalTitle}
                message={modalMessage}
                onAbort={null}
                onConfirm={this.onDeleteUser}
                confirmLabel="REMOVE USER"
                abortLabel="CANCEL"
                domId="confirmModal"
            />
        );

        return (
            <div className="user-list-div">
                <table className="user-table">
                    <tbody>{userItems}</tbody>
                </table>
                {paginatorComponent}
                {deleteConfirmModal}
            </div>
        );
    }
}
