import ImageZoom from "react-medium-image-zoom";
import React from "react";
import appEnums from "../appEnums";
import commonApi from "../api/commonApi";
import { CompositeDecorator } from "draft-js";
import ReactPlayer from "react-player";

export const getPlatformTag = platform => {
    const platformInt = parseInt(platform);
    if (!commonApi.isInteger(platform)) {
        return platform;
    }
    let tag = null;
    switch (platformInt) {
        case appEnums.Platform.AndroidGoogle:
            tag = Object.keys(appEnums.Platform)[platformInt];
            break;
        case appEnums.Platform.iOS:
            tag = Object.keys(appEnums.Platform)[platformInt];
            break;
        default:
            tag = null;
            break;
    }
    return tag;
};

export const customBlockRendererFn = block => {
    if (block.getType() === "atomic") {
        return {
            component: Media,
            editable: false
        };
    }
    return null;
};

export const customStyleMap = (() => {
    const styleMap = {};
    appEnums.FontSizes.forEach(size => {
        styleMap[`FS${size}PT`] = { fontSize: `${size / 12}rem` };
    });
    return styleMap;
})();

const FaqImageSizes = {
    S: "faq-image-sm",
    M: "faq-image-md",
    L: "faq-image-lg",
    FIT: "faq-image-fit",
    AUTO: "faq-image-auto"
};

const Image = props => {
    const { src, domClassName, resizeImage, entityKey } = props;
    let view = null;
    if (src) {
        view = (
            <div className="faq-image-div">
                <ImageZoom
                    zoomImg={{
                        src: { src },
                        alt: "Attached FAQ Photo"
                    }}
                    zoomMargin={75}>
                    <img src={src} alt="Attached FAQ Photo" className={domClassName ? domClassName : "faq-image"} />
                </ImageZoom>
                {resizeImage && (
                    <div className="faq-image-size-opts">
                        {Object.keys(FaqImageSizes).map(key => (
                            <button
                                onClick={() => resizeImage(entityKey, FaqImageSizes[key])}
                                key={key}
                                className={FaqImageSizes[key] === domClassName ? "active" : null}>
                                {key}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        );
    }
    return view;
};

const Video = props => {
    const src = props.src;
    if (src) {
        return (
            <div className="embed-responsive embed-responsive-16by9">
                <ReactPlayer url={src} controls={true} className="faq-video-player" />
            </div>
        );
    }
    return (
        <video width="480" height="320" src={src} controls>
            Your browser does not support the video tag.
        </video>
    );
};

const Media = props => {
    const key = props.block.getEntityAt(0);
    if (!key) {
        return null;
    }
    const entity = props.contentState.getEntity(key);
    const { src } = entity.getData();
    const type = entity.getType();
    let media = null;
    switch (type) {
        case "VIDEO":
            media = <Video src={src} />;
            break;
        case "IMAGE": {
            const { imageKey, domClassName, resizeImage } = entity.getData();
            media = <Image src={src} entityKey={key} imageKey={imageKey} domClassName={domClassName} resizeImage={resizeImage} />;
            break;
        }
        default:
            break;
    }
    return media;
};

const findLinkEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK";
    }, callback);
};

const Link = props => {
    let { href } = props.contentState.getEntity(props.entityKey).getData();
    if (!href.includes("http") || !href.includes("https")) {
        href = `https://${href}`;
    }
    return (
        <a href={href} title={href} rel="noopener noreferrer" target="_blank" style={{ color: "#2986ff", textDecoration: "underline" }}>
            {props.children}
        </a>
    );
};

export const linkDecorator = {
    strategy: findLinkEntities,
    component: Link
};

export const getEntities = (editorState, entityType = null) => {
    const content = editorState.getCurrentContent();
    const entities = [];
    content.getBlocksAsArray().forEach(block => {
        let selectedEntity = null;
        block.findEntityRanges(
            character => {
                if (character.getEntity() !== null) {
                    const entity = content.getEntity(character.getEntity());
                    if (!entityType || (entityType && entity.getType() === entityType)) {
                        selectedEntity = {
                            entityKey: character.getEntity(),
                            blockKey: block.getKey(),
                            entity: content.getEntity(character.getEntity())
                        };
                        return true;
                    }
                }
                return false;
            },
            (start, end) => {
                entities.push({ ...selectedEntity, start, end });
            }
        );
    });
    return entities;
};

export const editorDecorators = new CompositeDecorator([linkDecorator]);
