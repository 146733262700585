import React, { Component } from "react";
import SqlString from "sqlstring";
import appConstants from "../appConstants";
import appEnums from "../appEnums";
import AppEvent from "../appEvent";
import webApi from "../api/webApi";
import commonApi from "../api/commonApi";
import AutomationTranslation from "./automationTranslationUi";
import { toast } from "react-toastify";

export default class EditAutoResponseUi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responseText: "",
            initialResponseText: "",
            message: "",

            toastType: toast.TYPE.ERROR
        };
    }

    toastId = "__Edit_Auto_Response__";
    dismissMessage = () => this.setState({ message: "" });

    componentDidMount() {
        this.setState({
            responseText: this.props.responseText,
            initialResponseText: this.props.responseText
        });
    }

    componentWillUnmount() {
        const { appEvent } = this.props;
        toast.dismiss(this.toastId);
        appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
    }

    handleResponseTextChange = event => {
        let newText = event.target.value;
        let escapeLength = SqlString.escape(newText).length;
        if (newText.length - this.state.responseText.length === 1) {
            if (escapeLength <= appConstants.MAX_MESSAGE_ESCAPE_LENGTH) this.setState({ responseText: newText });
        } else {
            while (escapeLength > appConstants.MAX_MESSAGE_ESCAPE_LENGTH) {
                newText = newText.slice(0, -1);
                escapeLength = SqlString.escape(newText).length;
            }
            this.setState({ responseText: newText });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const { appEvent } = this.props;
        if (appEvent != null) {
            if (this.state.initialResponseText !== this.state.responseText) appEvent.fireEvent(AppEvent.EVENT_SET_UNSAVED_CHANGES);
            else appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
        }

        if (prevState && this.state.message !== prevState.message && this.state.message) {
            commonApi.displayToast(this.state.message, this.toastId, {
                autoClose: 4000,
                onClose: this.dismissMessage,
                onClick: this.dismissMessage,
                closeOnClick: true,
                pauseOnHover: true
            });
        }
    }

    onSave = () => {
        const { hcCompanyId, token, onClickSettingsTab, appEvent } = this.props;
        if (this.state.responseText.length === 0) return this.setState({ message: "Message Text is required." });
        webApi
            .fetchEditAutoResponseText(hcCompanyId, this.props.bundleId, this.state.responseText, token)
            .then(() => {
                if (appEvent !== null) appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
                onClickSettingsTab(appEnums.GeneralSettings.Automation);
            })
            .catch(error => {
                appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, error);
            });
    };

    onCancel = () => {
        this.props.onClickSettingsTab(appEnums.GeneralSettings.Automation);
    };

    render() {
        return (
            <div className="background-color-v2 ui2-container-div">
                <div className="edit-automation-content">
                    <p className="edit-automation-heading">EDIT AUTO-RESPONSE</p>
                    <p className="text-orange">Message</p>
                    <textarea
                        id="editAutomationTextArea"
                        value={this.state.responseText}
                        onChange={this.handleResponseTextChange}
                        autoFocus></textarea>
                    <div className="automation-btn-div">
                        <input onClick={this.onSave} id="editAutomationSave" className="automation-btn" value="SAVE" type="button" />
                        <input onClick={this.onCancel} id="editAutomationCancel" className="automation-btn" value="CANCEL" type="button" />
                    </div>
                    <br />
                    <span className="official-text-color d-block my-2 font-weight-bold">Localized messages</span>

                    <AutomationTranslation
                        messageType={appEnums.Automation.Response}
                        token={this.props.token}
                        hcCompanyId={this.props.hcCompanyId}
                        bundleId={this.props.bundleId}
                        onClickSettingsTab={this.props.onClickSettingsTab}
                        role={appEnums.Role.Agent}
                        appInfoDict={this.props.appInfoDict}
                        appEvent={this.props.appEvent}
                        onChangeApp={this.props.onChangeApp}
                    />
                </div>
            </div>
        );
    }
}
