import React, { Component } from "react";
import Moment from "react-moment";

class ChatMsgListDateTitleItem extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { timestamp } = this.props;

        const calendarStrings = {
            sameDay: "[Today]",
            nextDay: "[Tomorrow]",
            nextWeek: "dddd",
            lastDay: "[Yesterday]",
            lastWeek: "dddd",
            sameElse: "dddd, MMMM D, YYYY"
        };
        const momentUi = (
            <Moment className="chat-msg-date-title" unix calendar={calendarStrings} interval={3600000}>
                {timestamp / 1000}
            </Moment>
        );

        return <div className="chat-msg-date-title-div">{momentUi}</div>;
    }
}

export default ChatMsgListDateTitleItem;
