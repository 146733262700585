import React, { Component } from "react";
import moment from "moment";
import "./homeDashboardMetricsUi.css";
import webApi from "../api/webApi";
import AppEvent from "../appEvent";
import AppMenuUi from "../appMenu/appMenuUi";
import appEnums from "../appEnums";
import { toast } from "react-toastify";

class HomeDashboardMetricsUi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            metricsDataDict: {},
            expired: false
        };
        this.toastId = "__HomeDashboardMetricsUi__";
    }

    componentDidMount() {
        this.fetchMetrics(this.props.bundleId);
    }

    componentWillUnmount() {
        this.dismissToast();
    }

    dismissToast = () => {
        if (toast.isActive(this.toastId)) {
            toast.dismiss();
        }
    };

    fetchMetrics = bundleId => {
        const { token, appInfoDict } = this.props;
        webApi
            .fetchMetrics(bundleId, token)
            .then(metricsDataDict => {
                this.setState({ metricsDataDict });
            })
            .then(() => {
                const appInfo = appInfoDict[bundleId];
                if (appEnums.Platform.iOS === parseInt(appInfo.platform) || appEnums.Platform.Unity === parseInt(appInfo.platform)) {
                    return webApi.fetchCheckIosPushCertificate(bundleId, token).then(response => {
                        this.setState({ expired: response.expired });
                    });
                }
            })
            .catch(errDict => {
                if (this.props.appEvent != null) {
                    this.props.appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict);
                }
            });
    };

    onChangeApp = bundleId => {
        const { onChangeApp } = this.props;
        if (onChangeApp != null) {
            onChangeApp(bundleId);
        }
        this.setState({ expired: false }, () => {
            this.fetchMetrics(bundleId);
        });
    };

    render() {
        const { bundleId, appInfoDict, onClickCreateAppButton, token, role, hcCompanyId } = this.props;
        const appInfo = appInfoDict[bundleId];
        if (appInfo == null) {
            return <div className="ui1-container-div slide-up-animation"></div>;
        }

        const firstResponse = moment.duration(this.state.metricsDataDict.timeToFirstResponse, "milliseconds");
        const firstComplete = moment.duration(this.state.metricsDataDict.timeToComplete, "milliseconds");

        const responseHr = firstResponse.hours();
        const responseMin = firstResponse.minutes();
        const completeHr = firstComplete.hours();
        const completeMin = firstComplete.minutes();

        const timeToFirstResponse = responseHr === 0 && responseMin === 0 ? "< 1 min" : responseHr + " hr " + responseMin + " min";
        const timeToComplete = completeHr === 0 && completeMin === 0 ? "< 1 min" : completeHr + " hr " + completeMin + " min";

        const appMetricsUi = (
            <div className="app-metrics-div">
                <p>
                    <span className="app-metrics-data-label">New messages</span>
                    <span className="app-metrics-data-text">{this.state.metricsDataDict.newMessageCount}</span>
                </p>
                <p>
                    <span className="app-metrics-data-label">Monthly Active Users</span>
                    <span className="app-metrics-data-text">{this.state.metricsDataDict.mauTotal}</span>
                </p>
                <p>
                    <span className="app-metrics-data-label">Monthly Active Users on Chat</span>
                    <span className="app-metrics-data-text">{this.state.metricsDataDict.mauOnChat}</span>
                </p>
                <p>
                    <span className="app-metrics-data-label">Monthly Avg. Time to First Response</span>
                    <span className="app-metrics-data-text">{timeToFirstResponse}</span>
                </p>
                <p>
                    <span className="app-metrics-data-label">Monthly Avg. Time to Complete</span>
                    <span className="app-metrics-data-text">{timeToComplete}</span>
                </p>
                <p>
                    <span className="app-metrics-data-label">Total Rating Users</span>
                    <span className="app-metrics-data-text">{this.state.metricsDataDict.ratingTotalCount}</span>
                </p>
                {this.state.expired && (
                    <div className="alert alert-warning" role="alert">
                        <strong>Your Push certificate for {appInfo.appName} is expired!</strong>
                        <p />
                        You will not be able to send push messages until you update your certificate.
                    </div>
                )}
            </div>
        );

        return (
            <div className="ui1-container-div slide-up-animation">
                <AppMenuUi
                    hcCompanyId={hcCompanyId}
                    appInfoDict={appInfoDict}
                    bundleId={bundleId}
                    onClickCreateAppButton={onClickCreateAppButton}
                    onChangeApp={this.onChangeApp}
                    token={token}
                    role={role}
                />
                <div className="dashboard-content-div">{appMetricsUi}</div>
            </div>
        );
    }
}

export default HomeDashboardMetricsUi;
