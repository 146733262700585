import React, { Component } from "react";
import AutoComplete from "../autocomplete/autocomplete";
import "./tagComponent.css";
import { matchSorter, rankings } from "match-sorter";

export default class TagComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { inputValue: "", currFocus: -1, isSuggestionOpen: false, addTagOpen: false };
        this.setCurrFocus = i => this.setState({ currFocus: i });
        this.openSuggestion = () => this.setState({ isSuggestionOpen: true });
        this.closeSuggestion = () => this.setState({ isSuggestionOpen: false });
        this.onClickAddTagIcon = () => this.setState({ addTagOpen: true });
        this.inputNode = null;
    }

    addSuggestion = tag => {
        const index = this.props.suggestions.map(i => i.tag.toLowerCase()).indexOf(tag.toLowerCase());
        if (index === -1) this.props.setSuggestions([...this.props.suggestions, { tag: tag.trim(), metaData: "{}" }]);
    };

    onChangeListener = event => {
        this.setState({ inputValue: event.target.value, currFocus: -1, isSuggestionOpen: true });
    };

    deleteSuggestion = tag => {
        const index = this.props.suggestions.map(i => i.tag.toLowerCase()).indexOf(tag.toLowerCase());
        const newSuggestions = this.props.suggestions.filter((val, i) => i !== index);
        const suggestion = tag.toLowerCase();
        this.props.setSuggestions(newSuggestions);
        if (suggestion) this.props.onDeleteSuggestion(suggestion);
    };

    onKeydownListener = event => {
        if (event.target.value.trim() === "") {
            return false;
        }
        switch (event.keyCode) {
            case 40:
                this.setCurrFocus(this.state.currFocus + 1);
                break;
            case 38:
                event.preventDefault();
                this.setCurrFocus(this.state.currFocus - 1);
                break;
            case 13: {
                let dom = document.getElementById("autocompleteList");
                dom = dom.getElementsByTagName("div");
                dom = dom[this.state.currFocus];
                let value = "";
                if (dom) {
                    dom = dom.getElementsByTagName("span")[0];
                    value = dom.innerText;
                } else {
                    value = event.target.value;
                    this.addSuggestion(value);
                }
                this.selectSuggestion(value);
                break;
            }
            default:
                break;
        }
    };

    renderSuggestion = (suggestion, index, suggestions) => {
        const upperIndex = suggestions.length - 1;
        let validFocus = this.state.currFocus;
        const tag = suggestion.tag;
        if (this.state.currFocus > upperIndex + 1) {
            this.setCurrFocus(0);
        } else if (this.state.currFocus < -1) {
            this.setCurrFocus(upperIndex);
            validFocus = upperIndex;
        }
        return (
            <div key={index + tag} className={index === validFocus ? "autocomplete-active" : null}>
                <span className="autocomplete-span" data-tag={tag} onMouseDown={this.onSelectSuggestion}>
                    {tag}
                </span>
                <button type="button" data-tag={tag} className="close" aria-label="Close" onMouseDown={() => this.deleteSuggestion(tag)}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        );
    };

    getSuggestions = value => {
        if (value.trim() === "") {
            return [];
        }
        const inputLower = value.trim().toLowerCase();
        return matchSorter(this.props.suggestions, inputLower, {
            keys: [{ threshold: rankings.CONTAINS, key: "tag" }]
        });
    };

    onSelectSuggestion = event => {
        event.preventDefault();
        const { tag } = event.target.dataset;

        this.selectSuggestion(tag);
    };

    selectSuggestion = tag => {
        if (this.props.tagValidator(tag)) {
            this.inputNode.value = "";
            this.setState({ isSuggestionOpen: false, addTagOpen: false, inputValue: "" });
            this.props.setTags([...this.props.tags, tag.trim()]);
            this.props.onAddTag(tag);
        }
    };

    removeTag = event => {
        const { index } = event.target.dataset;
        const newTags = this.props.tags.filter((value, i) => i !== parseInt(index));
        this.props.setTags(newTags);
    };

    render() {
        const addTag = this.props.tags.length > 0 && !this.state.addTagOpen ? (
            <input
                type="image"
                id="addTagIcon"
                alt="add tag icon"
                title="Add tag"
                onClick={this.onClickAddTagIcon}
                src="/images/chat/addTagPlus.png"
            />
        ) : (
            <input
                type="text"
                id="tagInput"
                ref={n => (this.inputNode = n)}
                placeholder="Add tag"
                onChange={this.onChangeListener}
                onKeyDown={this.onKeydownListener}
                autoComplete="off"
                maxLength={25}
                onBlur={() => this.setState({ isSuggestionOpen: false, addTagOpen: false })}
            />
        )
        return (
            <div className="float-right clearfix w-50 p-0 m-0">
                <div className="tags-component">
                    {this.props.tags.length > 0 && (
                        <ul className={`tag-list-inline ${this.state.addTagOpen && "active"}`}>
                            {this.props.tags.map((tag, index) => (
                                <li key={index}>
                                    <span className="tag-text-span">{tag}</span>
                                    {!this.props.readonly && (
                                        <>
                                            &nbsp;
                                            <i className="bi bi-x-circle" role="button" data-index={index} onMouseDown={this.removeTag}></i>
                                        </>
                                    )}
                                </li>
                            ))}
                        </ul>
                    )}
                    {!this.props.readonly && addTag}
                    <AutoComplete
                        suggestions={this.props.suggestions} // required
                        renderSuggestion={this.renderSuggestion} // required
                        getSuggestions={this.getSuggestions} // required... algorithm
                        inputValue={this.state.inputValue} // required
                        domId="autocompleteList" // required
                        isSuggestionOpen={this.state.isSuggestionOpen}
                    />
                </div>
            </div>
        );
    }
}
