import React, { useState, useEffect } from "react";
import "./faqEditorModal.css";
import LANGUAGE_CODES from "../language-codesISO639-1.json";
import { matchSorter, rankings } from "match-sorter";

export const LanguageInputModal = props => {
    let [langSearch, setLangSearch] = useState("");
    let [langCodes, setLangCodes] = useState(LANGUAGE_CODES);
    let [selectedLangs, setSelectedLangs] = useState(new Set());

    const onChangeLangSearch = event => setLangSearch(event.target.value);

    const onClickSelectLang = event => {
        let selected = new Set(selectedLangs);
        const { value } = event.target;
        if (selected.has(value)) selected.delete(value);
        else selected.add(event.target.value);
        setSelectedLangs(selected);
    };

    useEffect(() => {
        setLangCodes(
            matchSorter(LANGUAGE_CODES, langSearch, {
                keys: [{ threshold: rankings.STARTS_WITH, key: "language" }]
            })
        );
    }, [langSearch]);

    useEffect(() => {
        setSelectedLangs(new Set(props.languageCodes));
    }, [props.languageCodes]);

    const onConfirm = () => {
        if (props.onConfirm) props.onConfirm(Array.from(selectedLangs));
    };

    return (
        <div
            className="modal fade"
            id="languageInputModal"
            tabIndex="-1"
            role="dialog"
            data-backdrop="static"
            aria-labelledby="modalForLanguageInput"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalForLanguageInput">
                            Assign Languages
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onMouseDown={e => e.preventDefault()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <input
                                value={langSearch}
                                onChange={onChangeLangSearch}
                                type="text"
                                className="form-control"
                                placeholder="Search language"
                            />
                        </div>
                        <div className="form-group">
                            <div className="flex flex-wrap" id="languageCodesMultipleSelect">
                                {langCodes.map(data => {
                                    return (
                                        <button
                                            onClick={onClickSelectLang}
                                            type="button"
                                            key={data.code}
                                            className={`${selectedLangs.has(data.code) && "active"}`}
                                            value={data.code}>
                                            {data.language}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            id="modalCloseBtn"
                            title="Close"
                            alt="Close"
                            className="btn btn-secondary"
                            onClick={() => setSelectedLangs(new Set())}>
                            Reset
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={onConfirm}
                            data-dismiss="modal"
                            id="languageInputConfirmBtn"
                            alt="Close">
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const VideoInputModal = props => {
    const [url, onChangeLink] = useState("");
    const onConfirm = event => {
        event.preventDefault();
        props.onConfirm(url);
    };
    return (
        <div
            className="modal fade"
            id="videoInputModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="modalForVideoInput"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalForVideoInput">
                            Embed a Video
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onMouseDown={e => e.preventDefault()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input
                            value={url}
                            onChange={e => onChangeLink(e.target.value)}
                            type="text"
                            title="Video link"
                            id="videoLinkTextInput"
                            placeholder="URL"
                            className="form-control"
                            aria-label="Text input for video url"
                        />
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            id="modalCloseBtn"
                            title="Close"
                            alt="Close"
                            className="btn btn-secondary"
                            data-dismiss="modal">
                            Close
                        </button>
                        <button
                            type="button"
                            disabled={url.length === 0}
                            className="btn btn-primary"
                            data-dismiss="modal"
                            id="videoModalConfirmBtn"
                            alt="Close"
                            onMouseDown={onConfirm}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ImageInputModal = props => {
    const [url, onChangeLink] = useState("");
    const { onClickUpload, onConfirmAddImageByLink } = props;
    const onConfirm = event => {
        event.preventDefault();
        onConfirmAddImageByLink(url);
    };
    return (
        <div className="modal fade" id="imageInputModal" tabIndex="-1" role="dialog" aria-labelledby="modalForImage" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalForImage">
                            Embed an Image
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onMouseDown={e => e.preventDefault()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input
                            value={url}
                            onChange={e => onChangeLink(e.target.value)}
                            type="text"
                            title="image link"
                            placeholder="URL"
                            className="form-control"
                            id="imageLinkTextInput"
                            aria-label="Text input for image url"
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" id="modalCancelBtn" title="Cancel" className="btn btn-secondary" data-dismiss="modal">
                            Cancel
                        </button>
                        <button
                            type="button"
                            disabled={url.length === 0}
                            className="btn btn-primary"
                            id="imageModalConfirmBtn"
                            data-dismiss="modal"
                            onMouseDown={onConfirm}>
                            Confirm
                        </button>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            id="imageModalUploadBtn"
                            className="btn btn-success"
                            data-dismiss="modal"
                            onClick={onClickUpload}>
                            Upload image
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const LinkInputModal = props => {
    const { onChangeLinkInput, onConfirm, onCancel, link, onRemoveLink, selectedText, hasInitialLink } = props;
    return (
        <div className="modal fade" id="linkInputModal" tabIndex="-1" role="dialog" aria-labelledby="modalForLink" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title faq-selected-text" id="modalForUrl" title={selectedText}>
                            Link for <span style={{ color: "#77b3d4", fontWeight: "bolder" }}>{selectedText}</span>
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onMouseDown={onCancel}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input
                            value={link}
                            onChange={onChangeLinkInput}
                            id="linkTextInput"
                            type="text"
                            title="Video link"
                            placeholder="URL"
                            className="form-control"
                            aria-label="Text input for text link"
                        />
                    </div>
                    <div className="modal-footer">
                        {hasInitialLink && (
                            <button
                                type="button"
                                id="modalRemoveBtn"
                                alt="Remove"
                                title="Remove"
                                className="btn btn-danger"
                                data-dismiss="modal"
                                onClick={onRemoveLink}>
                                Remove
                            </button>
                        )}
                        <button
                            type="button"
                            disabled={link.length === 0}
                            className="btn btn-primary"
                            data-dismiss="modal"
                            id="modalConfirmBtn"
                            onClick={onConfirm}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const FaqBlockerUI = () => {
    return (
        <div id="faqBlockerOverlay" tabIndex="-1" role="dialog" aria-labelledby="faqBlocker" aria-hidden="true">
            <img src="/images/chat/loading_image.png" id="faqBlockerSpinner" alt="message loading circle" />
        </div>
    );
};

export const FaqShareModal = props => {
    const [copyToClipboardLabel, onChangeLabel] = useState("Copy link to clipboard");
    const copyToClipboard = () => {
        const shareableLinkInput = document.getElementById("shareableLink");
        shareableLinkInput.select();
        document.execCommand("copy");
        onChangeLabel("Copied");
    };

    const openLink = () => {
        let openLink = props.shareableLink;
        window.open(openLink);
    };

    return (
        <div className="modal fade" id="faqShareModal" tabIndex="-1" role="dialog" aria-labelledby="faqShareModal" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="shareModalTitle">
                            Share this article
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input
                            type="text"
                            value={props.publish ? props.shareableLink : "Publish this article first before sharing it."}
                            id="shareableLink"
                            readOnly={true}
                        />
                    </div>
                    <div className="modal-footer">
                        <button type="button" disabled={!props.publish} className="btn btn-success" data-dismiss="modal" onClick={openLink}>
                            Open
                        </button>
                        <button type="button" disabled={!props.publish} className="btn btn-primary" onClick={copyToClipboard}>
                            {copyToClipboardLabel}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
