import React, { Component } from "react";
import "./faqUi.css";
import FaqArticleList from "./faqArticleList";
import AppEvent from "../appEvent";
import webApi from "../api/webApi";
import AppMenuUi from "../appMenu/appMenuUi";
import _ from "lodash";
import commonApi from "../api/commonApi";
import { toast } from "react-toastify";

export default class FaqUi extends Component {
    state = {
        faqArticleList: []
    };

    toastId = "__FaqUi_Toast__";

    _saveFaqArticlesOrderDelay = _.debounce(() => this.updateFaqArticlesOrder(), 1000);

    componentDidMount() {
        const { resetUi2, bundleId, appEvent, selectedFaqId } = this.props;
        if (appEvent) {
            appEvent.addCallback(AppEvent.EVENT_FAQ_CREATE, this.onFaqCreate);
            appEvent.addCallback(AppEvent.EVENT_FAQ_DELETE, this.onFaqDelete);
        }
        if (selectedFaqId === -1) {
            resetUi2();
        }
        this.fetchFaqInfo(bundleId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.bundleId !== this.props.bundleId) {
            this.props.resetUi2();
            this.fetchFaqInfo(this.props.bundleId);
        }
    }

    componentWillUnmount() {
        const { appEvent, resetUi2 } = this.props;
        if (appEvent) {
            appEvent.removeCallback(AppEvent.EVENT_FAQ_CREATE, this.onFaqCreate);
            appEvent.removeCallback(AppEvent.EVENT_FAQ_DELETE, this.onFaqDelete);
        }
        resetUi2();
        this._saveFaqArticlesOrderDelay.cancel();
    }

    updateFaqArticlesOrder = () => {
        const { appWebSocket, bundleId } = this.props;

        const orders = this.state.faqArticleList.map(data => {
            const { appFaqId, order } = data;
            return { appFaqId, order };
        });

        appWebSocket
            .updateFaqArticlesOrder(bundleId, orders)
            .then(response => {
                console.log(response);
                commonApi.displayToast("FAQ orders were updated successfully", this.toastId, toast.TYPE.SUCCESS, { autoClose: 2000 });
            })
            .catch(error => {
                commonApi.displayToast("Something went wrong!", this.toastId, toast.TYPE.ERROR, { autoClose: 2000 });
                console.log(error);
            });
    };

    fetchFaqInfo = bundleId => {
        const { token, appEvent } = this.props;
        webApi
            .fetchGetFaqInfo(bundleId, token)
            .then(this.onFetchFaqInfo)
            .catch(errDict => {
                if (appEvent) {
                    appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict);
                }
            });
    };

    onFaqCreate = json => {
        const { onFaqArticleView } = this.props;
        const newList = this.state.faqArticleList;
        newList.unshift(json); // update list
        this.setState({ faqArticleList: newList }, () => onFaqArticleView(json));
    };

    onFaqDelete = faqObj => {
        const { resetUi2 } = this.props;
        const newList = this.state.faqArticleList;
        const index = newList.indexOf(faqObj);
        newList.splice(index, 1);
        this.setState({ faqArticleList: newList }, resetUi2);
    };

    onFetchFaqInfo = faqList => {
        this.setState({ faqArticleList: faqList });
    };

    onFaqArticlesReorder = faqArticleList => {
        this.setState({ faqArticleList }, this._saveFaqArticlesOrderDelay);
    };

    onChangeApp = bundleId => {
        const { onChangeApp } = this.props;
        if (onChangeApp === null) {
            return;
        }
        onChangeApp(bundleId);
    };

    render() {
        const { hcCompanyId, bundleId, appInfoDict, onClickCreateAppButton, onClickNewFaq, onFaqArticleView, selectedFaqId, token, role } =
            this.props;

        if (appInfoDict[bundleId] == null) {
            return <div className="ui1-container-div slide-up-animation faq-main-div"></div>;
        }

        const newFaqButtonUi = (
            <input onClick={onClickNewFaq} type="button" alt="New Faq" id="newFaqButton" value="&nbsp;&nbsp;&nbsp;&nbsp;NEW FAQ" />
        );

        return (
            <div className="ui1-container-div slide-up-animation faq-main-div">
                <AppMenuUi
                    hcCompanyId={hcCompanyId}
                    appInfoDict={appInfoDict}
                    bundleId={bundleId}
                    onClickCreateAppButton={onClickCreateAppButton}
                    onChangeApp={this.onChangeApp}
                    token={token}
                    role={role}
                />
                <div id="newFaqButtonDiv">{newFaqButtonUi}</div>
                <div className="mx-3 mb-2" style={{ fontSize: "0.85rem", color: "gray" }}>
                    <span>*Reorder FAQ articles by dragging and dropping them</span>
                </div>
                <FaqArticleList
                    faqArticleList={this.state.faqArticleList}
                    onFaqArticleView={onFaqArticleView}
                    selectedFaqId={selectedFaqId}
                    onFaqArticlesReorder={this.onFaqArticlesReorder}
                />
            </div>
        );
    }
}
