import React from "react";
import "./faqArticleList.css";
import FaqArticleItem from "./faqArticleItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

export default function FaqArticleList({ onFaqArticleView, selectedFaqId, faqArticleList, onFaqArticlesReorder }) {
    const onDragEnd = result => {
        if (!result.destination) return;

        let copy = Array.from(faqArticleList);

        const [reorderedItem] = copy.splice(result.source.index, 1);
        // insert the reordered item to the array with a new index
        copy.splice(result.destination.index, 0, reorderedItem);

        copy = copy.map((item, idx) => {
            return { ...item, order: idx };
        });

        onFaqArticlesReorder(copy);
    };
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="faq-articles">
                {provided => (
                    <div {...provided.droppableProps} ref={provided.innerRef} className="faq-article-list">
                        {faqArticleList.map((article, idx) => {
                            return (
                                <FaqArticleItem
                                    key={article.appFaqId}
                                    index={idx}
                                    faqArticleObj={article}
                                    onFaqArticleView={onFaqArticleView}
                                    selectedFaqId={selectedFaqId}
                                />
                            );
                        })}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
