import React from "react";
import "./chatUi.css";
import ChatMsgListUi from "./chatMsgListUi";
import ChatInputUi from "./chatInputUi";
import webApi from "../api/webApi";
import commonApi from "../api/commonApi";
import appConstants from "../appConstants";
import moment from "moment";
import Moment from "react-moment";
import AppEvent from "../appEvent";
import TagComponent from "../tag/tagComponent";
import { ChangeDeviceVipStatusModal } from "./chatModals";
import _ from "lodash";
import appEnums from "../appEnums";

export default class ChatUi extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adminLink: "",
            adminLinkOrigin: "",
            passwordHash: "",
            accountName: "",
            clientProfilePic: "",
            faqArticleList: [],
            templateList: [],
            tags: [],
            tagSuggestions: [],
            translatedMsgData: [],
            defaultTranslationLanguage: "en",
            msgInfo: null
        };

        this.onFetchCompanySetting = resDict => this.setState({ accountName: resDict.accountName });
        this.onFetchAppSetting = resDict => this.setState({ adminLink: resDict.adminLink, adminLinkOrigin: resDict.adminLinkOrigin, passwordHash: resDict.passwordHash });
        this.onFetchFaqInfo = faqList => this.setState({ faqArticleList: faqList.filter(object => object.publish) });
        this.onFetchTemplateBuilder = templateList => this.setState({ templateList: templateList.sort((a, b) => a.sequence - b.sequence) });
        this.onFetchTagSuggestions = suggestions => this.setState({ tagSuggestions: suggestions });
        this.onFetchAutomationSetting = resDict => {
            this.setState({
                defaultTranslationLanguage: resDict.defaultTranslationLanguage ? resDict.defaultTranslationLanguage : "en"
            });
        };

        this.onAddTranslatedMsgData = this.addTranslatedMsgData.bind(this);
    }

    tagValidator = tag => {
        const loweredTag = tag.trim().toLowerCase();
        const loweredTagsList = this.state.tags.map(t => t.trim().toLowerCase());
        return loweredTagsList.indexOf(loweredTag) === -1;
    };

    onAddTag = tValue => {
        // add tag to the conversationTag table
        webApi.fetchAddConversationTag(this.props.hcCompanyId, tValue.trim(), this.props.token);
    };

    onDeleteSuggestion = tValue => {
        // delete tag from the conversationTag table
        webApi.fetchDeleteConversationTag(this.props.hcCompanyId, tValue.trim(), this.props.token);
    };

    setTags = newTags => {
        this.setState({ tags: newTags }, () => {
            const { appEvent, deviceId } = this.props;
            const decodedDeviceId = decodeURIComponent(deviceId);
            const jsonData = { deviceId: decodedDeviceId, tags: newTags };
            appEvent.fireEvent(AppEvent.EVENT_ADD_CONVERSATION_TAG, jsonData);
        });
    };

    onFetchFaqInfo(faqList) {
        this.setState({ faqArticleList: faqList.filter(object => object.publish) });
    }

    fetchFaqInfo = bundleId => {
        const { token, appEvent } = this.props;
        webApi
            .fetchGetFaqInfo(bundleId, token)
            .then(this.onFetchFaqInfo)
            .catch(errDict => appEvent?.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
    };

    onFetchTemplateBuilder(templateList) {
        this.setState({ templateList: templateList.filter(object => object.publish) });
    }

    fetchTemplateBuilder = (hcCompanyId, bundleId) => {
        const { token, appEvent } = this.props;
        webApi
            .fetchGetTemplateBuilder(hcCompanyId, bundleId, token)
            .then(this.onFetchTemplateBuilder)
            .catch(errDict => appEvent?.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
    };

    fetchTagSuggestions = () => {
        const { hcCompanyId, token } = this.props;
        webApi
            .fetchGetAllConversationTags(hcCompanyId, token)
            .then(this.onFetchTagSuggestions)
            .catch(err => console.warn(err));
    };

    componentDidMount() {
        const { hcCompanyId, bundleId, token, deviceId } = this.props;
        const decodedBundleId = decodeURIComponent(bundleId);
        this.fetchCompanySetting();
        this.fetchAppSetting();
        this.fetchFaqInfo(bundleId);
        this.fetchTemplateBuilder(hcCompanyId, bundleId);
        this.fetchTagSuggestions();
        this.fetchAutomationSetting();
        if (deviceId && bundleId) {
            const decodedDeviceId = decodeURIComponent(deviceId);
            const { onFetchConversation, onEventFetchError } = this.props;
            webApi
                .fetchConversation(decodedBundleId, decodedDeviceId, token)
                .then(msgInfo => {
                    this.setState({ msgInfo }, () => onFetchConversation(msgInfo));
                    return msgInfo;
                })
                .then(data => this.setState({ tags: data.tags }))
                .catch(errDict => {
                    if ("error" in errDict && errDict.error.includes("040002")) {
                        return window.location.assign(window.location.origin);
                    }
                    onEventFetchError(errDict);
                });
        } else {
            this.setState({ tags: this.props.msgData.tags });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { hcCompanyId, bundleId, deviceId, token } = this.props;
        const decodedBundleId = decodeURIComponent(bundleId);

        if (prevProps.bundleId !== bundleId && bundleId != null) {
            this.fetchFaqInfo(decodedBundleId);
            this.fetchTemplateBuilder(hcCompanyId, decodedBundleId);
        }
        if (deviceId && prevProps.deviceId !== deviceId) {
            const decodedDeviceId = decodeURIComponent(deviceId);
            const { onEventFetchError } = this.props;
            webApi
                .fetchConversation(decodedBundleId, decodedDeviceId, token)
                .then(msgInfo => {
                    this.setState({ msgInfo, tags: msgInfo.tags });
                })
                .catch(errDict => {
                    if ("error" in errDict && errDict.error.includes("040002")) {
                        return window.location.assign(window.location.origin);
                    }
                    onEventFetchError(errDict);
                });
        }
    }

    fetchCompanySetting = () => {
        const { hcAccountId, hcCompanyId, token, appEvent } = this.props;
        webApi
            .fetchGetCompanySetting(hcAccountId, hcCompanyId, token)
            .then(this.onFetchCompanySetting)
            .catch(errDict => appEvent?.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
    };

    fetchAutomationSetting = () => {
        const { hcCompanyId, bundleId, token, appEvent } = this.props;
        webApi
            .fetchGetAutomationSetting(hcCompanyId, bundleId, token)
            .then(this.onFetchAutomationSetting)
            .catch(errDict => appEvent?.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
    };

    fetchAppSetting = () => {
        const { bundleId, token, appEvent } = this.props;
        const decodedBundleId = decodeURIComponent(bundleId);
        webApi
            .fetchGetAppSetting(decodedBundleId, token)
            .then(this.onFetchAppSetting)
            .catch(errDict => appEvent?.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
    };

    onClickTag = tag => {
        const { appEvent } = this.props;
        if (appEvent && tag.trim().length > 0) {
            appEvent.fireEvent(AppEvent.EVENT_MESSAGE_TAG_FILTER, tag);
        }
    };

    onClickedAdminLinkButton = () => {
        if (this.state.adminLink) {
            const { bundleId, msgData } = this.props;
            const deviceId = msgData ? msgData.deviceId : "0";
            const netflixProfileId = bundleId.includes("netflix")
                ? (msgData?.netflixProfileId ?? "")
                : "";

            const adminLink = this.state.adminLink
                .replace(appConstants.ADMIN_LINK_KEY_ORIGIN, this.state.adminLinkOrigin)
                .replace(appConstants.ADMIN_LINK_KEY_BUNDLE_ID, decodeURIComponent(bundleId))
                .replace(appConstants.ADMIN_LINK_KEY_DEVICE_ID, decodeURIComponent(deviceId))
                .replace(appConstants.ADMIN_LINK_KEY_PASSWORD_HASH, this.state.passwordHash)
                .replace(appConstants.ADMIN_LINK_KEY_NETFLIX_PROFILE_ID, netflixProfileId);

            window.open(adminLink, "_blank");
        }
    };

    onTranslate = async (contextText, translationLanguage) => {
        const data = await webApi
            .fetchGetTranslation(contextText, translationLanguage)
            .then(response => {
                return { status: true, ...response };
            })
            .catch(errorDict => {
                console.log(errorDict);
                return { status: false, ...errorDict };
            });

        return data;
    };

    addTranslatedMsgData(data) {
        const { sid, translateData } = data;
        const { translatedMsgData } = this.state;
        translatedMsgData[sid] = translateData;

        this.setState({ translatedMsgData });
    }

    onClickChangeVip = () => {
        const { bundleId, deviceId, token, appEvent } = this.props;
        const { msgInfo } = this.state;

        const decodedBundleId = decodeURIComponent(bundleId);
        const decodedDeviceId = decodeURIComponent(deviceId);
        const vip = msgInfo && msgInfo.vip ? msgInfo.vip : "Non-VIP";

        //Reverse current vip status
        const vipStatus = vip.toLowerCase() === "vip" ? "Non-VIP" : "VIP";

        webApi
            .fetchChangeUserDeviceVipStatus(decodedBundleId, decodedDeviceId, vipStatus, token)
            .then( async () => {
                this.onChangeVip(decodedBundleId, decodedDeviceId, token)
            })
            .catch(errDict => appEvent?.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
    };

    onChangeVip(decodedBundleId, decodedDeviceId, token) {
        const { onEventFetchError } = this.props;
        webApi
            .fetchConversation(decodedBundleId, decodedDeviceId, token)
            .then(msgInfo => {
                this.setState({ msgInfo, tags: msgInfo.tags });
            })
            .catch(errDict => {
                if ("error" in errDict && errDict.error.includes("040002")) {
                    return window.location.assign(window.location.origin);
                }
                onEventFetchError(errDict);
            });
    }

    render() {
        const { setMessageStateFilter, appWebSocket, appEvent, setDialogBox } = this.props;
        const { deviceId, bundleId, msgData, profileImage, shortcut, token } = this.props;
        const { msgInfo, translatedMsgData, defaultTranslationLanguage } = this.state;
        const tagUi = this.state.tags.length > 0 ? this.state.tags.join() : null;
        const paidUi = msgInfo && msgInfo.paid ? "Paid" : "Not Paid";
        const vipUi = msgInfo && msgInfo.vip ? msgInfo.vip : "Non-VIP";
        const decodedDeviceId = decodeURIComponent(deviceId);
        const decodedBundleId = decodeURIComponent(bundleId);
        const isIOS = decodedDeviceId.includes("-");
        const osIcon = isIOS ? "/images/chat/icon_details_apple.png" : "/images/chat/icon_details_android.png";
        const osUi = isIOS ? "iOS" : "Android";
        const osVersion = msgInfo ? msgInfo.os : null;
        const deviceModel = msgInfo && msgInfo.deviceModel ? commonApi.mapToDevice(msgInfo.deviceModel) : null;
        const appVersionUi = msgInfo ? `App:${msgInfo.appVersion}` : null;
        const countryUi = msgInfo ? msgInfo.country : null;
        const languageCode = msgInfo ? msgInfo.languageCode : null;
        const freeSpace = msgInfo && msgInfo.freeSpace ? msgInfo.freeSpace.replace(" ", "") : null;
        const totalSpace = msgInfo && msgInfo.totalSpace ? msgInfo.totalSpace.replace(" ", "") : null;
        const helpchatterVersionUi = msgInfo ? `SDK:${msgInfo.hv}` : null;
        const advertisingId = !_.isEmpty(msgInfo?.advertisingId) ? msgInfo.advertisingId : null

        const isUserAllowedToReply = this.props.role != null &&
            !(
                this.props.role == appEnums.Role.Guest &&
                this.props.guestPermissionLevel ==
                appEnums.GuestPermissionLevel.Read
            );

        const detailsTable = (
            <div className="details-table-container">
                <table className="table table-borderless details-table">
                    <tbody>
                        <tr>
                            <td>
                                <img src="/images/chat/icon_UDID.png" alt="UDID" title="UDID" />
                            </td>
                            <td>
                                {isUserAllowedToReply
                                    ? (
                                        <label className="text-break" id="deviceIdLabel" onClick={this.onClickedAdminLinkButton}>
                                            <span title={decodedDeviceId}>
                                                <u>{decodedDeviceId || "N/A"}</u>
                                            </span>
                                        </label>
                                    )
                                    : (
                                        <label className="text-break mb-0" style={{fontSize: '0.75rem'}} >
                                            <span title={decodedDeviceId}>
                                                {decodedDeviceId || "N/A"}
                                            </span>
                                        </label>
                                    )
                                }
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="/images/chat/icon_ADID.png" alt="Advertising ID" title="Advertising ID" />
                            </td>
                            <td>
                                {advertisingId || "N/A"}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="/images/chat/icon_tag.png" alt="issue tag" title="Title" />
                            </td>
                            <td>{tagUi || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>
                                <img src="/images/chat/icon_details_paid_or_free.png" alt="user type" title="User Type" />
                            </td>
                            <td id="chatPaidLabel">{paidUi || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>
                                <img src="/images/chat/icon_vip.png" alt="vip status" title="VIP Status" />
                            </td>
                            <td id="chatVipLabel">
                                {vipUi || "non-vip"}
                                <br />
                                {isUserAllowedToReply && (
                                    <button
                                        type="button"
                                        className="btn btn-info btn-sm"
                                        data-toggle="modal"
                                        data-target="#changeDeviceVipStatusWindow"
                                    >
                                        Set as&nbsp;{vipUi.toLowerCase() === "vip" ? "Non-VIP" : "VIP"}
                                    </button>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src="/images/details/icon_SDK.png" alt="App Version" title="App Version" />
                            </td>
                            <td id="chatVersionLabel">
                                {appVersionUi || "App:N/A"}
                                <br />
                                {helpchatterVersionUi || "SDK:N/A"}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <img src={osIcon} alt="OS" title="OS" />
                            </td>
                            <td>{osUi || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>
                                <img src="/images/chat/icon_location.png" alt="Country Icon" title="Country" />
                            </td>
                            <td>{countryUi || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>
                                <img src="/images/chat/icon_version.png" alt="Language Icon" title="Language" />
                            </td>
                            <td>{languageCode || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>
                                <img src="/images/chat/icon_device_model.png" alt="Device" title="Device" />
                            </td>
                            <td>{deviceModel || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>
                                <img src="/images/chat/icon_device_OS.png" alt="OS Version" title="OS Version" />
                            </td>
                            <td>{osVersion || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>
                                <img src="/images/chat/icon_total_space.png" alt="Storage" title="Storage" />
                            </td>
                            <td>{totalSpace || "N/A"}</td>
                        </tr>
                        <tr>
                            <td>
                                <img src="/images/chat/icon_free_space.png" alt="Memory" title="Memory" />
                            </td>
                            <td>{freeSpace || "N/A"}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );

        let lastMessage = msgInfo ? msgInfo.lastMessage : "";
        let userName = msgInfo ? msgInfo.userName : "";

        moment.updateLocale("en", {
            relativeTime: {
                future: "in %s",
                past: "%s ago",
                s: "< 1 m",
                ss: "%d s",
                m: "1m",
                mm: "%d m",
                h: "1 h",
                hh: "%d h",
                d: "1 d",
                dd: "%d d",
                M: "1 M",
                MM: "%d M",
                y: "1 Y",
                yy: "%d Y"
            }
        });

        const timeText =
            msgInfo && msgInfo.timestamp ? (
                <Moment unix fromNow ago interval={30000}>
                    {msgInfo.timestamp / 1000}
                </Moment>
            ) : (
                "↖"
            );
        return (
            <div className="chat-container-div ui2-container-div slide-up-animation">
                <div className="chat-content-div">
                    <div className="chat-content-header">
                        <div className="chat-header-upper">
                            <div className="chat-detail-title">{lastMessage}</div>
                            <div className="chat-detail-timestamp">
                                <span>{timeText}</span>
                            </div>
                        </div>
                        <div className="chat-header-lower">
                            <div className="chat-header-user">
                                <img
                                    src={this.state.clientProfilePic || appConstants.defaultProfilePic}
                                    className="client-profile"
                                    alt="Client profile pic"
                                    title="Client profile picture"
                                />
                                <span className="client-username" title={userName} alt={userName}>
                                    {userName}
                                </span>
                            </div>
                            &nbsp;
                            <TagComponent
                                tags={this.state.tags}
                                setTags={this.setTags}
                                setSuggestions={this.onFetchTagSuggestions}
                                tagValidator={this.tagValidator}
                                onAddTag={this.onAddTag}
                                onDeleteSuggestion={this.onDeleteSuggestion}
                                suggestions={this.state.tagSuggestions}
                                onClickEvent={this.onClickTag}
                                readonly={!isUserAllowedToReply}
                            />
                        </div>
                    </div>
                    <ChatMsgListUi
                        bundleId={decodedBundleId}
                        deviceId={decodedDeviceId}
                        token={token}
                        appWebSocket={appWebSocket}
                        appEvent={appEvent}
                        msgData={msgData}
                        translatedMsgData={translatedMsgData}
                        defaultTranslationLanguage={defaultTranslationLanguage}
                        profileImage={profileImage}
                        setDialogBox={setDialogBox}
                        shortcut={shortcut}
                        addTranslatedMsgData={this.onAddTranslatedMsgData}
                        onTranslate={this.onTranslate}
                        readonly={!isUserAllowedToReply}
                    />
                    {isUserAllowedToReply && (
                        <ChatInputUi
                            bundleId={decodedBundleId}
                            deviceId={decodedDeviceId}
                            accountName={this.state.accountName}
                            token={token}
                            msgData={msgData}
                            appWebSocket={appWebSocket}
                            appEvent={appEvent}
                            profileImage={profileImage}
                            setMessageStateFilter={setMessageStateFilter}
                            shortcut={shortcut}
                            faqArticleList={this.state.faqArticleList}
                            templateList={this.state.templateList}
                            onTranslate={this.onTranslate}
                            languageCode={languageCode}
                            defaultTranslationLanguage={defaultTranslationLanguage}
                        />
                    )}
                </div>
                <div className="chat-user-detail">
                    <span className="chat-user-detail-header">Details</span>
                    {detailsTable}
                </div>

                <ChangeDeviceVipStatusModal onConfirm={this.onClickChangeVip} onCancel={null} />
            </div>
        );
    }
}
