const fileApi = {
    convertFileToDataURL: function (file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = () => {
                reject(reader.error);
            };
            if (file != null) {
                reader.readAsDataURL(file);
            }
        });
    },

    convertFileToArrayBuffer: function (file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = () => {
                reject(reader.error);
            };
            if (file != null) {
                reader.readAsArrayBuffer(file);
            }
        });
    }
};

export default fileApi;
