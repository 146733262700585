import React from 'react';

const Loading = props => {
    return (
        <div className="loading-container">
            <img src="/images/login/logo_helpchatter.png" className="loading-logo" alt="message loading logo" />
            <img src="/images/chat/loading_image.png" className="loading-circle" alt="message loading circle" />
        </div>
    );
};

export default Loading;
