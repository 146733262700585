import React, { Component } from "react";
import appEnums from "../appEnums";
import * as ReactDOM from "react-dom";
import moment from "moment";
import ChatMsgListItem from "./chatMsgListItem";
import ChatMsgListDateTitleItem from "./chatMsgListDateTitleItem";
import ChatLoadingUi from "./chatLoadingUi";
import commonApi from "../api/commonApi";
import webApi from "../api/webApi";
import AppEvent from "../appEvent";
import { DeleteMessageModal } from "./chatModals";

class ChatMsgListUi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            allChatDataList: [],
            isLoading: false,
            timestampId: "" // timestamp of the message - (to be used for deleting the message)
        };

        this.enableAutoScroll = true;
        this.typingUiTimer = null;

        this.controller = new AbortController();
        this.setTimestampId = t => this.setState({ timestampId: t });

        this.inputRefs = [];
    }

    componentDidMount() {
        this.refMsgListDiv.addEventListener("scroll", this.handleScroll);
        this.getAllMessages();
        const { appWebSocket, appEvent, shortcut } = this.props;
        if (appWebSocket != null) {
            appWebSocket.addMessageCallback(this.onReceiveMessage);
        }
        if (appEvent != null) {
            appEvent.addCallback(AppEvent.EVENT_SEND_MESSAGE, this.onSendMessage);
            appEvent.addCallback(AppEvent.EVENT_INTERNAL_NOTES, this.onSendInternalNotes);
            // appEvent.addCallback(AppEvent.EVENT_TRANSLATE, this.onTranslate);
        }
        if (shortcut != null) {
            if (window.navigator.userAgent.indexOf("Mac") >= 0) {
                shortcut.add(appEnums.KeyboardShortcut.MacTranslateLastMessage, this.onTranslateLastMessage);
            } else {
                shortcut.add(appEnums.KeyboardShortcut.WinTranslateLastMessage, this.onTranslateLastMessage);
            }
        }
    }

    onSendInternalNotes = info => {
        if ("notesJson" in info) {
            const { notesJson } = info;
            if (commonApi.checkUser(notesJson, this.props)) {
                let allChatDataList = this.state.allChatDataList;
                allChatDataList.push(notesJson);
                this.setState({ allChatDataList: allChatDataList });
            }
        } else if ("result" in info) {
            const { result } = info;
            if (commonApi.checkUser(result, this.props)) {
                const allChatDataList = this.state.allChatDataList;
                for (let i = allChatDataList.length - 1; i >= 0; i--) {
                    let data = allChatDataList[i];
                    if ("sid" in data && data.sid === result.sid) {
                        data.timestamp = result.timestamp;
                        this.setState({ allChatDataList: allChatDataList });
                        break;
                    }
                }
            }
        }
        setTimeout(this.scrollToBottom, 10);
    };

    onSendMessage = info => {
        if ("chatJson" in info) {
            const chatJson = info.chatJson;
            if (commonApi.checkUser(chatJson, this.props)) {
                let allChatDataList = this.state.allChatDataList;
                allChatDataList.push(chatJson);
                this.setState({ allChatDataList: allChatDataList });
            }
        } else if ("result" in info) {
            const result = info.result;
            if (commonApi.checkUser(result, this.props)) {
                const allChatDataList = this.state.allChatDataList;
                for (let i = allChatDataList.length - 1; i >= 0; i--) {
                    let data = allChatDataList[i];
                    if ("sid" in data && data.sid === result.sid) {
                        data.timestamp = result.timestamp;
                        data.video = result.video; // after successfully uploading video
                        this.setState({ allChatDataList: allChatDataList });
                        break;
                    }
                }
            }
        }
        setTimeout(this.scrollToBottom, 10);
    };

    componentWillUnmount() {
        this.controller.abort();
        if (this.refMsgListDiv != null) {
            this.refMsgListDiv.removeEventListener("scroll", this.handleScroll);
        }
        const { appWebSocket, appEvent } = this.props;
        if (appWebSocket != null) {
            appWebSocket.removeMessageCallback(this.onReceiveMessage);
        }
        if (appEvent != null) {
            appEvent.removeCallback(AppEvent.EVENT_SEND_MESSAGE, this.onSendMessage);
            appEvent.removeCallback(AppEvent.EVENT_INTERNAL_NOTES, this.onSendInternalNotes);
        }

        this.removeKeyboardShortcuts();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isLoading && commonApi.checkUser(prevProps, this.props)) {
            return;
        }
        if (!commonApi.checkUser(prevProps, this.props)) {
            this.controller.abort(); // abort previous api call
            this.controller = new AbortController();
            this.getAllMessages();
        }
    }

    removeKeyboardShortcuts = () => {
        const { shortcut } = this.props;

        if (!shortcut) return;

        if (window.navigator.userAgent.indexOf("Mac") >= 0) {
            shortcut.remove(appEnums.KeyboardShortcut.MacTranslateLastMessage);
        } else {
            shortcut.remove(appEnums.KeyboardShortcut.WinTranslateLastMessage);
        }
    };

    getAllMessages = () => {
        const { bundleId, deviceId, token, appEvent } = this.props;
        if (bundleId && deviceId) {
            this.setState({ isLoading: true }, () => {
                webApi
                    .fetchAllMessages(this.controller.signal, bundleId, deviceId, token)
                    .then(this.onFetchAllMessages)
                    .catch(errDict => {
                        if (appEvent) {
                            appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict);
                        }
                    });
            });
        }
    };

    onFetchAllMessages = fetchData => {
        this.resetRef();

        this.enableAutoScroll = true;
        this.setState({ allChatDataList: fetchData, isLoading: false }, () => {
            // force scrollToBottom again
            setTimeout(this.scrollToBottom, 50);
        });
    };

    scrollToBottom = () => {
        const msgListDiv = this.refMsgListDiv;
        if (msgListDiv != null) {
            const scrollHeight = msgListDiv.scrollHeight;
            const height = msgListDiv.clientHeight;
            const maxScrollTop = scrollHeight - height;
            this.refMsgListDiv.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
        }
    };

    handleScroll = event => {
        const target = event.target;
        const isBottom = target.scrollHeight - target.scrollTop === target.clientHeight;
        this.enableAutoScroll = isBottom ? false : true;
    };

    onReceiveMessage = chatJson => {
        if (commonApi.checkUser(chatJson, this.props) && (chatJson.message.trim().length > 0 || chatJson.image)) {
            let allChatDataList = this.state.allChatDataList;
            allChatDataList.push(chatJson);
            this.setState({ allChatDataList: allChatDataList });
            setTimeout(this.scrollToBottom, 10);
        }
    };

    onDeleteMessage = () => {
        const { bundleId, deviceId, token, appEvent } = this.props;
        this.setState({ isLoading: true }, () => {
            webApi
                .fetchDeleteMessage(bundleId, deviceId, this.state.timestampId, token)
                .then(this.getAllMessages)
                .catch(errDict => {
                    if (appEvent) {
                        appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict);
                    }
                });
        });
    };

    onTranslateLastMessage = (e) => {
        this.translateLastMessage();
    }

    translateLastMessage = (offset = 1) => {
        const { translatedMsgData } = this.props;

        if (this.inputRefs.length > 0) {
            if (!translatedMsgData[this.inputRefs[this.inputRefs.length - offset].state.sid] && this.inputRefs[this.inputRefs.length - offset].state.contentText.length > 0 && this.inputRefs[this.inputRefs.length - offset].state.contentTextTranslation === null) {
                this.inputRefs[this.inputRefs.length - offset].onTranslate();
            }
            else {
                offset = offset + 1;
                this.translateLastMessage(offset);
            }
        }
    }

    setRef = (ref) => {
        if (ref) {
            this.inputRefs.push(ref);
        }
    };

    resetRef = () => {
        this.inputRefs = [];
    }

    render() {
        const { setDialogBox, translatedMsgData } = this.props;
        const allChatDataList = this.state.isLoading ? [] : this.state.allChatDataList;

        let chatDataList = [];
        let lastMoment = null;
        for (let i = 0; i < allChatDataList.length; i++) {
            const data = allChatDataList[i];
            let isSameDay = true;

            if (data.timestamp) {
                const dataMoment = moment(data.timestamp);
                if (lastMoment == null) {
                    lastMoment = dataMoment;
                    chatDataList.push(data.timestamp);
                } else {
                    isSameDay = dataMoment.isSame(lastMoment, "day");
                    lastMoment = dataMoment;
                }

                if (isSameDay) {
                    chatDataList.push(data);
                } else {
                    chatDataList.push(data.timestamp);
                    chatDataList.push(data);
                }
            } else {
                chatDataList.push(data);
            }
        }

        const itemUiList = this.state.isLoading ? (
            <ChatLoadingUi />
        ) : (
            chatDataList.map((data, idx) => {
                if (commonApi.isNumber(data)) {
                    return <ChatMsgListDateTitleItem key={data - 1} timestamp={data} />;
                } else {
                    const componentKey = data.timestamp ? data.timestamp : Date.now();
                    const translationData = translatedMsgData[data.sid] ? translatedMsgData[data.sid] : null;
                    return (
                        <ChatMsgListItem
                            key={componentKey}
                            ref={this.setRef}
                            appEvent={this.props.appEvent}
                            chatData={data}
                            translationData={translationData}
                            setDialogBox={setDialogBox}
                            scrollToBottom={this.scrollToBottom}
                            setTimestampId={this.setTimestampId}
                            msgData={this.props.msgData}
                            shortcut={this.props.shortcut}
                            onTranslate={this.props.onTranslate}
                            defaultTranslationLanguage={this.props.defaultTranslationLanguage}
                            addTranslatedMsgData={this.props.addTranslatedMsgData}
                            idx_testing={idx}
                            readonly={this.props.readonly}
                        />
                    );
                }
            })
        );

        return (
            <div className="chat-msg-list-container" ref={node => this.refMsgListDiv = node}>
                <div className="w-100">{itemUiList}</div>
                <DeleteMessageModal onConfirm={this.onDeleteMessage} onCancel={null} />
            </div>
        );
    }
}

export default ChatMsgListUi;
