import React, { Component } from "react";
import "../appEnums";
import appEnums from "../appEnums";
import appConstants from "../appConstants";
import webApi from "../api/webApi";
import commonApi from "../api/commonApi";
import { toast } from "react-toastify";
import { invert, isEmpty, isEqual } from "lodash";
import GuestRoleForm, { getPermissionLevelOption, guestPermissionLevelOptions } from "./GuestRoleForm";

const roleLookup = invert(appEnums.Role);
const initialGuestRoleParamState = {
    guestCanAccessAllAppsInputValue: false,
    guestAppNamesInputValue: [],
    guestPermissionLevelInputValue: guestPermissionLevelOptions[0],
};

export default class UserItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountEmail: "",
            selectedRole: appEnums.Role.Agent,
            isSettingsOpen: false,
            modified: initialGuestRoleParamState,
            original: initialGuestRoleParamState,
            isSavingSettings: false,
        };
    }

    toastId = "__USER_ITEM_COMPONENT__";
    message = "You updated your permission. You need to re-login to apply the new permission";

    componentDidMount() {
        this.setState({ selectedRole: this.props.userRole });
    }

    componentWillUnmount() {
        commonApi.dismissToast(this.toastId);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userHcAccountId !== this.props.userHcAccountId) {
            this.setState({ selectedRole: this.props.userRole });
        }

        if (!prevState.isSettingsOpen && this.state.isSettingsOpen) {
            this.setState({ selectedRole: this.props.userRole });

            if (this.props.userRole === appEnums.Role.Guest) {
                webApi.fetchGetAccountRoleParameters(this.props.userHcAccountId, this.props.token)
                    .then(params => {
                        if (typeof params !== 'object') { return; }

                        const { guestAllowedApps, guestPermissionLevel } = params;
                        const options = this.props.guestAppNamesOptions;
                        const optionsLookup = Object.fromEntries(
                            options.map(option => [option.value, option])
                        );
                        const selectedOptions = (guestAllowedApps ?? []).map(bundleId => optionsLookup[bundleId] ?? { label: bundleId, value: bundleId });

                        this.updateOriginal('guestPermissionLevelInputValue', getPermissionLevelOption(guestPermissionLevel));
                        this.updateModified('guestPermissionLevelInputValue', getPermissionLevelOption(guestPermissionLevel));
                        this.updateOriginal('guestAppNamesInputValue', selectedOptions);
                        this.updateModified('guestAppNamesInputValue', selectedOptions);
                        this.updateOriginal('guestCanAccessAllAppsInputValue', isEmpty(guestAllowedApps));
                        this.updateModified('guestCanAccessAllAppsInputValue', isEmpty(guestAllowedApps));
                    })
                    .catch(err => console.error(err));
            }
        } else if (prevState.isSettingsOpen && !this.state.isSettingsOpen) {
            this.setState({
                original: initialGuestRoleParamState,
                modified: initialGuestRoleParamState,
            });
        }
    }

    onDelete = () => {
        const { setConfirmDeleteUserModal, index, userName, userHcAccountId, userEmail } = this.props;
        setConfirmDeleteUserModal(index, userName, userHcAccountId, userEmail);
    };

    onFetchAccountEmail = response => this.setState({ accountEmail: response.accountEmail });

    onChangeAccountRole = event => {
        this.setState({ selectedRole: parseInt(event.target.value) });
    };

    onSaveButtonClick = async () => {
        try {
            const { userList, setUserList, index, userHcAccountId, hcAccountId, userRole, token } = this.props;

            if (this.state.selectedRole === appEnums.Role.Guest) {
                if (!this.state.modified.guestCanAccessAllAppsInputValue && isEmpty(this.state.modified.guestAppNamesInputValue)) {
                    return commonApi.displayToast("App(s) is required", this.toastId, toast.TYPE.ERROR, { autoClose: 5000 });
                }
            }

            this.setState({ isSavingSettings: true });

            const didRoleChange = this.state.selectedRole !== userRole;
            if (didRoleChange) {
                await webApi.fetchChangeAccountPermissions(userHcAccountId, this.state.selectedRole, token);

                let newList = Array.from(userList);
                newList[index].role = this.state.selectedRole;
                setUserList(newList);
                // notify the user to re-login
                if (userHcAccountId === hcAccountId) {
                    commonApi.displayToast(this.message, this.toastId, toast.TYPE.INFO, {});
                }
            }

            if (this.state.selectedRole === appEnums.Role.Guest) {
                const guestAppNames = !this.state.modified.guestCanAccessAllAppsInputValue
                    ? this.state.modified.guestAppNamesInputValue.map(({ value }) => value)
                    : [];

                await webApi.fetchChangeAccountRoleParameters(userHcAccountId, guestAppNames, this.state.modified.guestPermissionLevelInputValue.value, token);
                this.setState({
                    original: { ...this.state.modified },
                });
            }

            this.setState({ isSettingsOpen: false });
        } catch (errDict) {
            commonApi.displayToast(errDict.message ?? errDict.error, this.toastId, toast.TYPE.INFO, {});
        } finally {
            this.setState({ isSavingSettings: false });
        }
    };

    onSettingsClick = () => this.setState({ isSettingsOpen: !this.state.isSettingsOpen });

    updateModified = (key, value) => {
        this.setState({
            modified: {
                ...this.state.modified,
                [key]: value,
            }
        });
    };

    updateOriginal = (key, value) => {
        this.setState({
            original: {
                ...this.state.original,
                [key]: value,
            }
        });
    };

    render() {
        const { userName, userProfileImage, userEmail } = this.props;
        const profilePhoto = (
            <img
                className="user-profile-photo"
                src={userProfileImage || appConstants.defaultProfilePic}
                alt="profile"
                ref={node => (this.imgNode = node)}
                onError={() => {
                    this.imgNode.src = appConstants.defaultProfilePic;
                }}
            />
        );
        const editUserButton = (
            <input
                type="image"
                src="/images/settings/btn_user_settings.png"
                alt="setting button"
                className={`user-edit-button ${this.state.isSettingsOpen ? 'open' : ''}`}
                onClick={this.onSettingsClick}
            />
        );
        const removeUserButton = (
            <input
                type="image"
                src="/images/settings/btn_remove.png"
                alt="setting button"
                className="user-remove-button"
                data-toggle="modal"
                data-target="#confirmModal"
                onClick={this.onDelete}
            />
        );
        const roleSelectComponent = (
            this.state.isSettingsOpen
                ? (
                    <select className="role-select" value={this.state.selectedRole} onChange={this.onChangeAccountRole}>
                        {Object.keys(appEnums.Role).map(key => {
                            return (
                                <option value={appEnums.Role[key]} key={key}>
                                    {key}
                                </option>
                            );
                        })}
                    </select>
                )
                : roleLookup[this.props.userRole] ?? '(unknown)'
        );

        const didUserSettingChange = this.state.selectedRole !== this.props.userRole ||
            !isEqual(this.state.original, this.state.modified);

        return (
            <>
                <tr>
                    <td>{profilePhoto}</td>
                    <td>{userName}</td>
                    <td>{userEmail}</td>
                    <td>{roleSelectComponent}</td>
                    <td>{editUserButton}</td>
                    <td>{removeUserButton}</td>
                </tr>
                {this.state.isSettingsOpen && this.state.selectedRole === appEnums.Role.Guest && (
                    <tr className="user-setting">
                        <td colSpan={6}>
                            <GuestRoleForm
                                guestAppNamesOptions={this.props.guestAppNamesOptions}
                                guestAppNames={this.state.modified.guestAppNamesInputValue}
                                setGuestAppNames={(newVal) => this.updateModified('guestAppNamesInputValue', newVal)}
                                guestCanAccessAllApps={this.state.modified.guestCanAccessAllAppsInputValue}
                                setGuestCanAccessAllApps={(newVal) => this.updateModified('guestCanAccessAllAppsInputValue', newVal)}
                                guestPermissionLevel={this.state.modified.guestPermissionLevelInputValue}
                                setGuestPermissionLevel={(newVal) => this.updateModified('guestPermissionLevelInputValue', newVal)}
                            />
                        </td>
                    </tr>
                )}
                {this.state.isSettingsOpen && (
                    <tr className="user-setting">
                        <td colSpan="6">
                            <div className="d-flex justify-content-end" style={{ gap: "0.5rem" }}>
                                <button
                                    className="user-setting-button"
                                    onClick={this.onSaveButtonClick}
                                    disabled={!didUserSettingChange || this.state.isSavingSettings}
                                >
                                    {this.state.isSavingSettings ? "SAVING" : "SAVE"}
                                </button>
                                <button
                                    className="user-setting-button"
                                    onClick={() => this.setState({ isSettingsOpen: false })}
                                >
                                    CANCEL
                                </button>
                            </div>
                        </td>
                    </tr>
                )}
            </>
        );
    }
}
