import React from "react";
import appEnums from "../appEnums";
import appConstants from "../appConstants";
import commonApi from "../api/commonApi";
import webApi from "../api/webApi";
import AppEvent from "../appEvent";
import fileApi from "../api/fileApi";
import { Editor, EditorState, RichUtils, getDefaultKeyBinding, Modifier, KeyBindingUtil, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { editorDecorators } from "../faqs/faqUtils";
import { VideoAttachModal, UploadSizeError } from "./chatModals";

const { MAX_MESSAGE_ESCAPE_LENGTH, MAX_INTERNAL_NOTES_ESCAPE_LENGTH, TYPING_UI_ANIMATION_TIME, videoMaxSizeMB, imageMaxSizeMB } =
    appConstants;

export default class ChatInputUi extends React.Component {
    constructor(props) {
        super(props);
        this.initialEditorState = EditorState.createEmpty(editorDecorators);

        this.state = {
            inputText: "",
            inputTextLength: 0,
            isRemoteTyping: false,
            remoteUserName: "",
            isFormatIconClicked: false,
            editorState: this.initialEditorState,
            isFaqMenuOpen: false,
            isTemplateMenuOpen: false,
            showChatInput: true,
            notes: "",
            currTicketState: appEnums.MessageStates.Open,
            defaultLanguage: props.languageCode,
            isTranslating: false
        };

        this.lastInputTextDict = {};
        this.typingUiTimer = null;

        this.onChangeEditor = newState => this.setState({ editorState: newState });
        this.focus = () => this.inputRef.current.focus();
        this.faqMenuClose = () => this.setState({ isFaqMenuOpen: false });
        this.faqMenuOpen = () => this.setState({ isFaqMenuOpen: true });
        this.templateMenuClose = () => this.setState({ isTemplateMenuOpen: false });
        this.templateMenuOpen = () => this.setState({ isTemplateMenuOpen: true });
        this.onChangeNotes = event => this.setState({ notes: event.target.value });
        this.onChangeDefaultLanguage = (language) => this.setState({ defaultLanguage: language });
    }

    onClickMsgState = event => {
        const { ticketState } = event.target.dataset;
        this.setState({ currTicketState: parseInt(ticketState) });
    };

    ticketStateDisplay = ticketState => {
        switch (ticketState) {
            case appEnums.MessageStates.New:
                return "New";
            case appEnums.MessageStates.Open:
                return "Open";
            case appEnums.MessageStates.Pending:
                return "Pending";
            case appEnums.MessageStates.Closed:
                return "Resolved";
            default:
                return "";
        }
    };

    toastId = "__CHAT_INPUT__";

    inputRef = React.createRef();

    componentDidMount() {
        this.focus();
        const { appWebSocket, shortcut, languageCode, defaultTranslationLanguage } = this.props;

        const translationLanguage = Object.prototype.hasOwnProperty.call(appEnums.Languages, languageCode) ? languageCode : defaultTranslationLanguage;
        this.onChangeDefaultLanguage(translationLanguage);

        if (appWebSocket != null) {
            appWebSocket.addMessageCallback(this.onReceiveMessage);
            appWebSocket.addTypingCallback(this.onRemoteChatTyping);
        }
        if (shortcut != null) {
            if (window.navigator.userAgent.indexOf("Mac") >= 0) {
                shortcut.add(appEnums.KeyboardShortcut.MacReplyMessage, this.onReply);
                shortcut.add(appEnums.KeyboardShortcut.MacTranslateReplyMessage, this.onTranslateReply);
                shortcut.add(appEnums.KeyboardShortcut.MacTranslateBracketReplyMessage, this.onTranslateBracketReply);

                //template shortcut
                shortcut.add(appEnums.KeyboardShortcut.MacTemplateMessage1, () => this.onSelectTemplateShortcut(1));
                shortcut.add(appEnums.KeyboardShortcut.MacTemplateMessage2, () => this.onSelectTemplateShortcut(2));
                shortcut.add(appEnums.KeyboardShortcut.MacTemplateMessage3, () => this.onSelectTemplateShortcut(3));
                shortcut.add(appEnums.KeyboardShortcut.MacTemplateMessage4, () => this.onSelectTemplateShortcut(4));
                shortcut.add(appEnums.KeyboardShortcut.MacTemplateMessage5, () => this.onSelectTemplateShortcut(5));
                shortcut.add(appEnums.KeyboardShortcut.MacTemplateMessage6, () => this.onSelectTemplateShortcut(6));
                shortcut.add(appEnums.KeyboardShortcut.MacTemplateMessage7, () => this.onSelectTemplateShortcut(7));
                shortcut.add(appEnums.KeyboardShortcut.MacTemplateMessage8, () => this.onSelectTemplateShortcut(8));
                shortcut.add(appEnums.KeyboardShortcut.MacTemplateMessage9, () => this.onSelectTemplateShortcut(9));
                shortcut.add(appEnums.KeyboardShortcut.MacTemplateMessage0, () => this.onSelectTemplateShortcut(10));
            } else {
                shortcut.add(appEnums.KeyboardShortcut.WinReplyMessage, this.onReply);
                shortcut.add(appEnums.KeyboardShortcut.WinTranslateReplyMessage, this.onTranslateReply);
                shortcut.add(appEnums.KeyboardShortcut.WinTranslateBracketReplyMessage, this.onTranslateBracketReply);

                //template shortcut
                shortcut.add(appEnums.KeyboardShortcut.WinTemplateMessage1, () => this.onSelectTemplateShortcut(1));
                shortcut.add(appEnums.KeyboardShortcut.WinTemplateMessage2, () => this.onSelectTemplateShortcut(2));
                shortcut.add(appEnums.KeyboardShortcut.WinTemplateMessage3, () => this.onSelectTemplateShortcut(3));
                shortcut.add(appEnums.KeyboardShortcut.WinTemplateMessage4, () => this.onSelectTemplateShortcut(4));
                shortcut.add(appEnums.KeyboardShortcut.WinTemplateMessage5, () => this.onSelectTemplateShortcut(5));
                shortcut.add(appEnums.KeyboardShortcut.WinTemplateMessage6, () => this.onSelectTemplateShortcut(6));
                shortcut.add(appEnums.KeyboardShortcut.WinTemplateMessage7, () => this.onSelectTemplateShortcut(7));
                shortcut.add(appEnums.KeyboardShortcut.WinTemplateMessage8, () => this.onSelectTemplateShortcut(8));
                shortcut.add(appEnums.KeyboardShortcut.WinTemplateMessage9, () => this.onSelectTemplateShortcut(9));
                shortcut.add(appEnums.KeyboardShortcut.WinTemplateMessage0, () => this.onSelectTemplateShortcut(10));
            }
        }
    }

    customKeyBindingFn = event => {
        if (event.keyCode === 9) {
            const maxDepth = 4;
            const newState = RichUtils.onTab(event, this.state.editorState, maxDepth);
            this.setState({ editorState: newState });
            return "editor-onTab";
        }
        const { hasCommandModifier } = KeyBindingUtil;
        if (event.keyCode === 13 && hasCommandModifier(event)) {
            return "meta-enter"; // suppress default behavior
        }
        if (event.keyCode === 68 && hasCommandModifier(event)) {
            this.onStrikeButtonClick(event);
            return "strikethrough"; // suppress default behavior (CMD + D, CTRL + D)
        }
        return getDefaultKeyBinding(event);
    };

    handleBeforeInput = (chars, editorState, eventTimeStamp) => {
        const currentContent = editorState.getCurrentContent();
        if (currentContent.getPlainText().length === MAX_MESSAGE_ESCAPE_LENGTH) {
            return "handled";
        }
        this.onSelfChatTyping();
    };

    handlePastedText = pastedText => {
        const { editorState } = this.state;
        const currentContent = editorState.getCurrentContent();
        const currentLength = currentContent.getPlainText().length;
        if (currentLength === MAX_MESSAGE_ESCAPE_LENGTH) {
            return true;
        }

        const imposedLength = currentLength + pastedText.length;
        if (imposedLength > MAX_MESSAGE_ESCAPE_LENGTH) {
            const subStr = pastedText.slice(0, MAX_MESSAGE_ESCAPE_LENGTH - currentLength);
            const selection = editorState.getSelection();
            const currentInlineStyle = editorState.getCurrentInlineStyle();
            const newContent = Modifier.insertText(currentContent, selection, subStr, currentInlineStyle);
            const newContentState = EditorState.push(editorState, newContent, "insert-characters");
            this.onChangeEditor(newContentState);
            return true;
        }
        this.onSelfChatTyping();
        return false;
    };

    toggleInlineStyle = inlineStyle => this.onChangeEditor(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
    toggleBlockType = blockStlye => this.onChangeEditor(RichUtils.toggleBlockType(this.state.editorState, blockStlye));

    handleKeyCommand = command => {
        const { editorState } = this.state;
        if (command === "editor-onTab") {
            return "handled";
        }
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            this.onChangeEditor(newState);
            return "handled";
        }
        return "not-handled";
    };

    onBoldButtonClick = event => {
        event.preventDefault();
        this.toggleInlineStyle("BOLD");
    };

    onItalicButtonClick = event => {
        event.preventDefault();
        this.toggleInlineStyle("ITALIC");
    };

    onUnderlineButtonClick = event => {
        event.preventDefault();
        this.toggleInlineStyle("UNDERLINE");
    };

    onStrikeButtonClick = event => {
        event.preventDefault();
        this.toggleInlineStyle("STRIKETHROUGH");
    };

    onNumberBulletClick = event => {
        event.preventDefault();
        this.toggleBlockType("ordered-list-item");
    };

    onBulletButtonClick = event => {
        event.preventDefault();
        this.toggleBlockType("unordered-list-item");
    };

    componentWillUnmount() {
        this.lastInputTextDict = {};
        if (this.typingUiTimer != null) {
            clearTimeout(this.typingUiTimer);
            this.typingUiTimer = null;
        }
        const { appWebSocket, shortcut } = this.props;
        if (appWebSocket != null) {
            appWebSocket.removeMessageCallback(this.onReceiveMessage);
            appWebSocket.removeTypingCallback(this.onRemoteChatTyping);
        }
        if (shortcut != null) {
            if (window.navigator.userAgent.indexOf("Mac") >= 0) shortcut.remove(appEnums.KeyboardShortcut.MacReplyMessage);
            else shortcut.remove(appEnums.KeyboardShortcut.WinReplyMessage);
        }
        commonApi.dismissToast(this.toastId);
    }

    componentDidUpdate(prevProps, prevState) {
        const { deviceId, languageCode, defaultTranslationLanguage } = this.props;
        if (deviceId !== prevProps.deviceId) {
            this.lastInputTextDict[prevProps.deviceId] = this.state.editorState;
            this.onChangeDeviceId();
        }

        if (languageCode !== prevProps.languageCode) {
            const translationLanguage = Object.prototype.hasOwnProperty.call(appEnums.Languages, languageCode) ? languageCode : defaultTranslationLanguage;
            this.onChangeDefaultLanguage(translationLanguage);
        }
    }

    onChangeDeviceId = () => {
        const { deviceId } = this.props;
        let newState = this.initialEditorState;
        if (deviceId in this.lastInputTextDict) {
            newState = this.lastInputTextDict[deviceId];
        }
        this.setState({ editorState: newState }, this.focus);
        this.hideTypingUi();
    };

    onClickSubmitAs = event => {
        const { msgData } = this.props;
        const { currTicketState } = this.state;

        if (!msgData || msgData.state === currTicketState) {
            return;
        }

        const { bundleId, deviceId, token, appEvent, appWebSocket } = this.props;
        webApi
            .fetchChangeState(bundleId, deviceId, currTicketState, token)
            .then(() => {
                appEvent.fireEvent(AppEvent.EVENT_CHANGE_MESSAGE_STATE, {
                    bundleId: bundleId,
                    deviceId: deviceId,
                    state: currTicketState
                });
                appWebSocket.sendChangeState(bundleId, deviceId, currTicketState);
                appEvent.fireEvent(AppEvent.EVENT_UPDATE_MESSAGES_COUNT);
            })
            .catch(errDict => appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
    };

    onAskRating = event => {
        event.preventDefault();
        const { bundleId, deviceId, accountName, appWebSocket, appEvent, profileImage } = this.props;
        if (appWebSocket && appEvent) {
            appWebSocket
                .sendAskRating(bundleId, deviceId, sid => {
                    appEvent.fireEvent(AppEvent.EVENT_SEND_MESSAGE, {
                        chatJson: {
                            sid: sid,
                            message: "(askRating)",
                            askRating: appEnums.AskRatingStates.Show,
                            sender: accountName,
                            source: appEnums.MessageSource.Server,
                            bundleId: bundleId,
                            deviceId: deviceId,
                            senderImage: profileImage
                        }
                    });
                })
                .then(resultJson => this.onReplyResponse(resultJson))
                .catch(errDict => appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
        }
    };

    onChatSurvey = event => {
        event.preventDefault();
        const { bundleId, deviceId, accountName, appWebSocket, appEvent, profileImage } = this.props;
        if (appWebSocket && appEvent) {
            appWebSocket
                .sendChatSurvey(bundleId, deviceId, sid =>
                    appEvent.fireEvent(AppEvent.EVENT_SEND_MESSAGE, {
                        chatJson: {
                            sid: sid,
                            message: "(chatSurvey)",
                            chatSurvey: appEnums.ChatSurveyStates.Star0,
                            sender: accountName,
                            source: appEnums.MessageSource.Server,
                            bundleId: bundleId,
                            deviceId: deviceId,
                            senderImage: profileImage
                        }
                    })
                )
                .then(resultJson => this.onReplyResponse(resultJson))
                .catch(errDict => appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
        }
    };

    onReceiveMessage = chatJson => {
        if (commonApi.checkUser(chatJson, this.props) && this.state.isRemoteTyping) {
            this.hideTypingUi();
            const { appEvent, msgData, setMessageStateFilter } = this.props;
            const { Closed, New } = appEnums.MessageStates;
            msgData.lastMessageSource = appEnums.MessageSource.Client;
            msgData.lastMessage = chatJson.message;
            if (appEvent != null && (msgData.state === Closed || msgData.state === New)) {
                appEvent.fireEvent(AppEvent.EVENT_CHANGE_MESSAGE_STATE, {
                    bundleId: this.props.bundleId,
                    deviceId: this.props.deviceId,
                    state: New
                });
                appEvent.fireEvent(AppEvent.EVENT_UPDATE_MESSAGES_COUNT);
                setMessageStateFilter(New);
            }
        }
    };

    onSelfChatTyping = () => {
        const { bundleId, deviceId, appWebSocket } = this.props;
        if (appWebSocket != null) {
            appWebSocket.sendTyping(bundleId, deviceId);
        }
    };

    onRemoteChatTyping = typingJson => {
        if (commonApi.checkUser(typingJson, this.props)) {
            if (this.typingUiTimer != null) {
                clearTimeout(this.typingUiTimer);
            }
            this.setState({ isRemoteTyping: true, remoteUserName: typingJson.userName });
            this.typingUiTimer = setTimeout(() => {
                this.typingUiTimer = null;
                this.setState({ isRemoteTyping: false });
            }, TYPING_UI_ANIMATION_TIME);
        }
    };

    hideTypingUi = () => {
        if (this.typingUiTimer != null) {
            clearTimeout(this.typingUiTimer);
            this.typingUiTimer = null;
        }
        this.setState({ isRemoteTyping: false });
    };

    onFormatIconClick = event => {
        event.preventDefault();
        this.setState({ isFormatIconClicked: !this.state.isFormatIconClicked });
    };

    onSelectFaqArticle = object => {
        const { editorState } = this.state;
        const currentLength = editorState.getCurrentContent().getPlainText().length;
        const link = `${object.title} - ${window.location.protocol}//${window.location.host}/faq/public/${this.props.bundleId}/${object.appFaqId}`;
        let subStr = `${link} `;
        const imposedLength = currentLength + subStr.length;
        if (imposedLength > MAX_MESSAGE_ESCAPE_LENGTH) {
            subStr = link.slice(0, MAX_MESSAGE_ESCAPE_LENGTH - currentLength);
        }
        const currentContent = editorState.getCurrentContent();
        const currentInlineStyle = editorState.getCurrentInlineStyle();
        const newContent = Modifier.insertText(currentContent, editorState.getSelection(), subStr, currentInlineStyle);
        this.setState({ editorState: EditorState.push(editorState, newContent, "insert-characters"), isFaqMenuOpen: false }, () =>
            setTimeout(this.focus, 10)
        );
    };

    onSelectTemplateShortcut = (key) => {
        try {
            const { templateList } = this.props;
            const template = templateList[parseInt(key) - 1];

            if (template.publish) {
                this.onSelectTemplate(template);
            }
        }
        catch { /* empty */ }
    };

    onSelectTemplate = object => {
        const { editorState } = this.state;
        const currentLength = editorState.getCurrentContent().getPlainText().length;
        let subStr = `${object.message} `;
        const imposedLength = currentLength + subStr.length;
        if (imposedLength > MAX_MESSAGE_ESCAPE_LENGTH) {
            subStr = object.message.slice(0, MAX_MESSAGE_ESCAPE_LENGTH - currentLength);
        }
        const currentContent = editorState.getCurrentContent();
        const currentInlineStyle = editorState.getCurrentInlineStyle();
        const newContent = Modifier.insertText(currentContent, editorState.getSelection(), subStr, currentInlineStyle);
        this.setState({ editorState: EditorState.push(editorState, newContent, "insert-characters"), isTemplateMenuOpen: false }, () =>
            setTimeout(this.focus, 10)
        );
    };

    onClickUploadVideo = e => {
        e.preventDefault();
        this.attachVideoNode.click();
    };

    onConfirmVideoLink = url => {
        const { appWebSocket, appEvent, bundleId, deviceId, accountName, profileImage } = this.props;
        if (appWebSocket && appEvent) {
            appWebSocket
                .sendVideoLink(bundleId, deviceId, url, sid => {
                    appEvent.fireEvent(AppEvent.EVENT_SEND_MESSAGE, {
                        chatJson: {
                            sid: sid,
                            message: "(video)",
                            html: "<p>(video)</p>",
                            video: url,
                            sender: accountName,
                            source: appEnums.MessageSource.Server,
                            bundleId: bundleId,
                            deviceId: deviceId,
                            senderImage: profileImage
                        }
                    });
                })
                .then(resultJson => this.onReplyResponse(resultJson))
                .catch(errDict => appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
        }
    };

    onAttachVideo = event => {
        const { appWebSocket, appEvent } = this.props;
        const input = event.target;
        if (input.files[0].size >= videoMaxSizeMB) {
            return this.uploadFileError.click();
        }
        fileApi
            .convertFileToArrayBuffer(input.files[0])
            .then(videoArrayBuffer => {
                const { bundleId, deviceId, accountName, profileImage } = this.props;
                const videoType = input.files[0].type.split("/")[1];
                if (appWebSocket && appEvent) {
                    appWebSocket
                        .sendVideoFile(bundleId, deviceId, videoArrayBuffer, videoType, json => {
                            appEvent.fireEvent(AppEvent.EVENT_SEND_MESSAGE, {
                                chatJson: {
                                    sid: json.sid,
                                    message: "(video)",
                                    html: "<p>uploading...</p>",
                                    video: json.video,
                                    sender: accountName,
                                    source: appEnums.MessageSource.Server,
                                    bundleId: bundleId,
                                    deviceId: deviceId,
                                    senderImage: profileImage
                                }
                            });
                        })
                        .then(resultJson => this.onReplyResponse(resultJson))
                        .catch(errDict => appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
                }
            })
            .catch(errDict => appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
    };

    onAttachPhoto = async event => {
        const input = event.target;
        const imageFile = input.files[0]
        if (imageFile.size >= imageMaxSizeMB) {
            return this.uploadFileError.click();
        }

        try {
            const stringBase64 = await fileApi.convertFileToDataURL(imageFile)
            const { bundleId, deviceId, accountName, appWebSocket, appEvent, profileImage } = this.props;
            if (appWebSocket && appEvent) {
                const imageType = imageFile.type.split("/")[1];
                try {
                    const resultJson = await appWebSocket.sendImage(bundleId, deviceId, imageFile, imageType, sid => {
                        appEvent.fireEvent(AppEvent.EVENT_SEND_MESSAGE, {
                            chatJson: {
                                sid,
                                message: "(photo)",
                                html: "<p>(photo)</p>",
                                image: stringBase64,
                                sender: accountName,
                                source: appEnums.MessageSource.Server,
                                bundleId,
                                deviceId,
                                senderImage: profileImage
                            }
                        });
                    })
                    this.onReplyResponse(resultJson)
                } catch (errDict) {
                    appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict);
                }
            }
        } catch (err) {
            console.error(err)
        }
    };

    onSubmitNotes = event => {
        event.preventDefault();
        const { bundleId, deviceId, appWebSocket, appEvent, accountName, profileImage } = this.props;

        if (this.state.notes.trim().length === 0) return;
        if (!appWebSocket || !appEvent) return;

        const { notes } = this.state;

        appWebSocket
            .sendInternalNotes(bundleId, deviceId, notes, accountName, sid => {
                this.setState({ notes: "" });
                appEvent.fireEvent(AppEvent.EVENT_INTERNAL_NOTES, {
                    notesJson: {
                        bundleId,
                        deviceId,
                        sid: sid,
                        message: notes,
                        sender: accountName,
                        senderImage: profileImage,
                        source: appEnums.MessageSource.Server,
                        isNote: true
                    }
                });
            })
            .then(resultJson => {
                if (appEvent) {
                    appEvent.fireEvent(AppEvent.EVENT_INTERNAL_NOTES, {
                        result: {
                            sid: resultJson.sid,
                            bundleId: bundleId,
                            deviceId: deviceId,
                            timestamp: resultJson.timestamp,
                            isNote: true
                        }
                    });
                }
            })
            .catch(errDict => appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
    };

    onReply = () => {
        const { editorState } = this.state;
        const contentState = editorState.getCurrentContent();
        const plainMessage = contentState.getPlainText();
        if (plainMessage.trim().length === 0) {
            return true;
        }
        const { bundleId, deviceId, accountName, appWebSocket, appEvent, profileImage } = this.props;
        const htmlMessage = stateToHTML(editorState.getCurrentContent(), options)
            .trim()
            .replace(/\n/g, "")
            .replace(/^(<p><br><\/p>)+/gm, "")
            .replace(/(<p><br><\/p>)+$/gm, "");
        this.setState({ editorState: this.initialEditorState }, () => (this.lastInputTextDict[deviceId] = editorState));

        if (appWebSocket && appEvent) {
            appWebSocket
                .sendMessage(bundleId, deviceId, plainMessage, htmlMessage, sid =>
                    appEvent.fireEvent(AppEvent.EVENT_SEND_MESSAGE, {
                        chatJson: {
                            sid: sid,
                            message: plainMessage,
                            html: htmlMessage,
                            sender: accountName,
                            senderImage: profileImage,
                            source: appEnums.MessageSource.Server,
                            bundleId: bundleId,
                            deviceId: deviceId
                        }
                    })
                )
                .then(resultJson => this.onReplyResponse(resultJson))
                .catch(errDict => appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
        }
        this.inputRef.current.blur();
    };

    onReplyResponse = resultJson => {
        const { appEvent } = this.props;
        const isSuccess = "timestamp" in resultJson;
        if (!isSuccess || !appEvent) {
            return;
        }
        const { bundleId, deviceId, token, msgData, appWebSocket } = this.props;
        const { Open, Closed } = appEnums.MessageStates;

        appEvent.fireEvent(AppEvent.EVENT_SEND_MESSAGE, {
            result: {
                sid: resultJson.sid,
                bundleId: bundleId,
                deviceId: deviceId,
                timestamp: resultJson.timestamp,
                video: resultJson.video ? resultJson.video : null
            }
        });

        if (msgData.state === Closed) {
            webApi
                .fetchChangeState(bundleId, deviceId, Open, token)
                .then(() => {
                    appWebSocket.sendChangeState(bundleId, deviceId, Open);
                    appEvent.fireEvent(AppEvent.EVENT_CHANGE_MESSAGE_STATE, {
                        bundleId: bundleId,
                        deviceId: deviceId,
                        state: appEnums.MessageStates.Open
                    });
                    appEvent.fireEvent(AppEvent.EVENT_UPDATE_MESSAGES_COUNT);
                    appEvent.fireEvent(AppEvent.EVENT_SORT_MESSAGE_LIST_TIMESTAMP);
                })
                .catch(errDict => appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
        } else if (msgData.state === Open) appEvent.fireEvent(AppEvent.EVENT_SORT_MESSAGE_LIST_TIMESTAMP);
    };

    onClickTranslateReply = event => {
        const { language } = event.target.dataset;
        this.onTranslateReply({ language });
    };

    onTranslateReply = data => {
        if (this.state.isTranslating) return;

        const { editorState, defaultLanguage } = this.state;
        const currentContent = editorState.getCurrentContent();
        const plainText = currentContent.getPlainText();
        const currentLength = plainText.length;

        if (currentLength === 0) return;

        this.setState({ isTranslating: true }, async () => {

            const { language } = data;

            const languageCode = language ? language : defaultLanguage;

            if (currentLength > 0) {
                const translateData = await this.props.onTranslate(plainText, languageCode);
                this.setState({ isTranslating: false }, () => {
                    if (translateData.status) {
                        this.handleTranslateReply(translateData.translatedText);
                    }
                });
            }
        });
    };

    onTranslateBracketReply = async () => {
        if (this.state.isTranslating) return;

        const { editorState, defaultLanguage } = this.state;
        const currentContent = editorState.getCurrentContent();
        const plainText = currentContent.getPlainText();
        const currentLength = plainText.length;

        if (currentLength === 0) return;

        const pattern = /(?<=\[)(.*?)(?=\])/gm;
        const textToBeTranslated = plainText.match(pattern);

        if (textToBeTranslated.length > 0) {
            this.setState({ isTranslating: true }, async () => {
                const translatedDatas = await Promise.all(
                    textToBeTranslated.map(async t => {
                        const translatedData = await this.props.onTranslate(t, defaultLanguage);
                        return translatedData && translatedData.translatedText;
                    })
                );

                const pairs = {};
                textToBeTranslated.forEach((key, i) => (pairs[key] = translatedDatas[i]));

                const replaceText = this.replaceAll(plainText, pairs);

                this.setState({ isTranslating: false }, () => {
                    this.handleTranslateReply(replaceText);
                });
            });
        }
    };

    replaceAll = (str, mapObj) => {
        let string = str;
        for (const [key, value] of Object.entries(mapObj)) {
            string = string.replace(new RegExp("\\[" + key + "\\]", "gm"), value);
        }
        return string;
    };

    handleTranslateReply = translatedText => {
        const { editorState } = this.state;

        const newContentState = EditorState.push(editorState, ContentState.createFromText(translatedText), "translate-message");
        this.onChangeEditor(newContentState);
    };

    render() {
        const { msgData, faqArticleList, templateList, languageCode } = this.props;
        const { editorState, isRemoteTyping, isFaqMenuOpen, isTemplateMenuOpen, isFormatIconClicked, currTicketState, defaultLanguage, isTranslating } = this.state;
        const plainText = editorState.getCurrentContent().getPlainText();

        const typingUi = isRemoteTyping && (
            <div id="chatInputTypingDiv" className="clearfix">
                <span className="chat-input-typing-dot-text">
                    <b>{this.state.remoteUserName}</b>&nbsp;is typing
                </span>
                <img className="chat-input-typing-dot-image" src="/images/chat/dotAnimation.gif" alt="TypingDot"></img>
            </div>
        );

        const chatSurveyUi = (
            <label className="chat-icon-label">
                <input
                    type="image"
                    className="chat-box-icon"
                    alt="chat survey icon"
                    title="Send an chat survey message to the user."
                    src="/images/conversation/msgbx_btn_chat_survey.png"
                    onClick={this.onChatSurvey}
                />
            </label>
        );

        const attachPhotoIcon = (
            <label className="chat-icon-label">
                <input
                    type="image"
                    className="chat-box-icon chat-photo-send"
                    alt="attach image icon"
                    title="Select a photo and send to the user."
                    src="/images/conversation/btn_image.png"
                    onClick={() => this.attachPhotoNode.click()}
                />
                <input
                    id="sendPhoto"
                    ref={n => (this.attachPhotoNode = n)}
                    type="file"
                    className="chat-input-send-file"
                    accept="image/*"
                    onClick={e => (e.target.value = null)}
                    onChange={this.onAttachPhoto}
                />
            </label>
        );

        const attachVideoIcon = (
            <label className="chat-icon-label">
                <input
                    type="image"
                    data-toggle="modal"
                    data-target="#attachVideoInput"
                    className="chat-box-icon"
                    alt="attach video icon"
                    title="Select a video and send to the user."
                    src="/images/conversation/btn_video.png"
                />
                <input
                    id="sendVideo"
                    ref={n => (this.attachVideoNode = n)}
                    className="chat-input-send-file"
                    type="file"
                    accept="video/*"
                    onClick={e => (e.target.value = null)}
                    onChange={this.onAttachVideo}
                />
            </label>
        );

        const faqIcon = (
            <label className="chat-icon-label" onClick={this.faqMenuOpen}>
                <input
                    type="image"
                    src={isFaqMenuOpen ? "/images/conversation/msgbx_btn_faq_p.png" : "/images/conversation/msgbx_btn_faq.png"}
                    alt="FAQ Button"
                    id="faqButton"
                    title="FAQ"
                    className="chat-box-icon"
                />
            </label>
        );

        const faqItemListUi = (
            <div id="faqItemListDiv" className="slide-right-animation">
                {faqArticleList.length > 0 ? (
                    faqArticleList
                        .filter(object => object.publish)
                        .map((object, index) => {
                            return (
                                <div
                                    className="chat-faq-item"
                                    key={object.appFaqId}
                                    onMouseDown={event => this.onSelectFaqArticle(object)}
                                    style={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "rgba(0,0,0,.05)" }}>
                                    <span className="chat-faq-title">
                                        {object.title}
                                    </span>
                                </div>
                            );
                        })
                ) : (
                    <div className="chat-faq-item">
                        <span>No FAQ available</span>
                    </div>
                )}
            </div>
        );

        const faqPopup = (
            <Popup
                onOpen={this.faqMenuOpen}
                onClose={this.faqMenuClose}
                closeOnDocumentClick={true}
                arrow={true}
                position="right bottom"
                contentStyle={{
                    padding: "0",
                    border: "none",
                    borderRadius: "5px",
                    width: "30%",
                    minWidth: "320px"
                }}
                trigger={faqIcon}
            >
                {faqItemListUi}
            </Popup>
        );

        const quickResponseIcon = (
            <label className="chat-icon-label">
                <input
                    type="image"
                    src="/images/conversation/msgbx_btn_rate_me.png"
                    title="Send an ask for rating message to the user."
                    className="chat-box-icon"
                    alt="quick response button"
                    onClick={this.onAskRating}
                />
            </label>
        );

        const formatIcon = (
            <label className="chat-icon-label">
                <input
                    type="image"
                    src={
                        isFormatIconClicked
                            ? "/images/conversation/msgbx_btn_font_format_p.png"
                            : "/images/conversation/msgbx_btn_font_format.png"
                    }
                    alt="Text format button"
                    className="chat-box-icon"
                    title="Format your message"
                    onMouseDown={this.onFormatIconClick}
                />
            </label>
        );

        const templateIcon = (
            <label className="chat-icon-label" onClick={this.templateMenuOpen}>
                <input
                    type="image"
                    src={isTemplateMenuOpen ? "/images/conversation/btn_icon_template_p.png" : "/images/conversation/btn_icon_template.png"}
                    alt="Template Button"
                    id="templateButton"
                    title="Template"
                    className="chat-box-icon"
                />
            </label>
        );

        const templateItemListUi = (
            <div id="templateItemListDiv" className="slide-right-animation">
                {templateList &&
                    (templateList.length > 0 ? (
                        templateList
                            .filter(object => object.publish)
                            .map((object, index) => {
                                return (
                                    <div
                                        className="chat-template-item"
                                        key={index}
                                        onMouseDown={event => this.onSelectTemplate(object)}
                                        style={{ backgroundColor: index % 2 === 0 ? "#ffffff" : "rgba(0,0,0,.05)" }}
                                    >
                                        <span className="chat-template-title">
                                            {object.sequence + 1} - {object.title}
                                        </span>
                                    </div>
                                );
                            })
                    ) : (
                        <div className="chat-template-item">
                            <span>No Template available</span>
                        </div>
                    ))
                }
            </div>
        );

        const templatePopup = (
            <Popup
                onOpen={this.templateMenuOpen}
                onClose={this.templateMenuClose}
                closeOnDocumentClick={true}
                arrow={true}
                position="right bottom"
                contentStyle={{
                    padding: "0",
                    border: "none",
                    borderRadius: "5px",
                    width: "25%",
                    minWidth: "280px"
                }}
                trigger={templateIcon}
            >
                {templateItemListUi}
            </Popup>
        );

        const currInlineStyle = editorState.getCurrentInlineStyle();
        const boldClass = currInlineStyle.has("BOLD") ? "format-icon-active" : "format-icon";
        const italicClass = currInlineStyle.has("ITALIC") ? "format-icon-active" : "format-icon";
        const underlineClass = currInlineStyle.has("UNDERLINE") ? "format-icon-active" : "format-icon";
        const strikeClass = currInlineStyle.has("STRIKETHROUGH") ? "format-icon-active" : "format-icon";
        const boldTextIcon = (
            <div className={boldClass} onMouseDown={this.onBoldButtonClick}>
                <input title="bold" alt="Bold Text" id="boldIconButton" type="button" />
            </div>
        );
        const italicTextIcon = (
            <div className={italicClass} onMouseDown={this.onItalicButtonClick}>
                <input title="italic" type="button" alt="Italic Text" id="italicIconButton" />
            </div>
        );
        const underlineTextIcon = (
            <div className={underlineClass} onMouseDown={this.onUnderlineButtonClick}>
                <input title="underline" type="button" alt="underline Text" id="underlineIconButton" />
            </div>
        );
        const strikethroughTextIcon = (
            <div className={strikeClass} onMouseDown={this.onStrikeButtonClick}>
                <input type="button" alt="strikethrough Text" id="strikeIconButton" />
            </div>
        );
        const selection = editorState.getSelection();
        const currBlockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();
        const olClass = currBlockType === "ordered-list-item" ? "format-icon-active" : "format-icon";
        const ulClass = currBlockType === "unordered-list-item" ? "format-icon-active" : "format-icon";

        const numberBulletTextIcon = (
            <div className={olClass} onMouseDown={this.onNumberBulletClick}>
                <input type="button" alt="Number Bullet" id="numberBulletIconButton" />
            </div>
        );

        const bulletTextIcon = (
            <div className={ulClass} onMouseDown={this.onBulletButtonClick}>
                <input type="button" alt="Unordered Bullet" id="bulletIconButton" />
            </div>
        );

        const formatOptionsUi = this.state.isFormatIconClicked ? (
            <div className="format-options-div slide-right-animation">
                {boldTextIcon}
                {italicTextIcon}
                {underlineTextIcon}
                {strikethroughTextIcon}
                {numberBulletTextIcon}
                {bulletTextIcon}
            </div>
        ) : null;
        const msgState = msgData && msgData.state != null ? parseInt(msgData.state) : appEnums.MessageStates.Closed;
        const replayButtonDisabled = editorState.getCurrentContent().getPlainText().trim().length === 0 && isTranslating === false;
        const replyButtonValue = msgState === appEnums.MessageStates.Closed ? "Reopen" : "Reply";

        const charLeft = this.state.showChatInput
            ? MAX_MESSAGE_ESCAPE_LENGTH - plainText.length
            : MAX_INTERNAL_NOTES_ESCAPE_LENGTH - this.state.notes.length;

        const charLeftUi = (
            <span className={`${charLeft > 0 ? "chat-text-length" : "chat-text-length over"}`}>Characters Remaining:&nbsp;{charLeft}</span>
        );

        return (
            <div id="chatInput">
                <div>
                    <button
                        className={`chat-input-navBtn${this.state.showChatInput ? " active" : ""}`}
                        onClick={event => this.setState({ showChatInput: true })}>
                        PUBLIC REPLY
                    </button>
                    <button
                        className={`chat-input-navBtn${!this.state.showChatInput ? " active" : ""}`}
                        onClick={event => this.setState({ showChatInput: false })}>
                        INTERNAL NOTE
                    </button>
                </div>
                {typingUi}
                {this.state.showChatInput ? (
                    <>
                        <div id="chatInputOptions" style={{ paddingTop: "5px" }}>
                            {faqPopup}
                            {attachPhotoIcon}
                            {attachVideoIcon}
                            {quickResponseIcon}
                            {chatSurveyUi}
                            {formatIcon}
                            {formatOptionsUi}
                            {templatePopup}
                        </div>
                        <Editor
                            ref={this.inputRef}
                            editorState={this.state.editorState}
                            onChange={this.onChangeEditor}
                            spellCheck={true}
                            keyBindingFn={this.customKeyBindingFn}
                            handleKeyCommand={this.handleKeyCommand}
                            handleBeforeInput={this.handleBeforeInput}
                            handlePastedText={this.handlePastedText}
                        />

                        <div id="chatButtonContainer">
                            <div className="btn-group dropup">
                                <button
                                    type="button"
                                    className="btn btn-info"
                                    onClick={this.onClickSubmitAs}
                                    disabled={currTicketState === -1}>
                                    Set as&nbsp;{this.ticketStateDisplay(currTicketState)}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-info dropdown-toggle dropdown-toggle-split"
                                    data-toggle="dropdown"
                                    aria-expanded="false">
                                    <span className="sr-only">Toggle Dropdown</span>
                                </button>
                                <div className="dropdown-menu">
                                    <button
                                        className={`msg-state-item-btn ${currTicketState === appEnums.MessageStates.New && "active"}`}
                                        onClick={this.onClickMsgState}
                                        data-ticket-state={appEnums.MessageStates.New}>
                                        New
                                    </button>
                                    <button
                                        className={`msg-state-item-btn ${currTicketState === appEnums.MessageStates.Open && "active"}`}
                                        onClick={this.onClickMsgState}
                                        data-ticket-state={appEnums.MessageStates.Open}>
                                        Open
                                    </button>
                                    <button
                                        className={`msg-state-item-btn ${currTicketState === appEnums.MessageStates.Pending && "active"}`}
                                        onClick={this.onClickMsgState}
                                        data-ticket-state={appEnums.MessageStates.Pending}>
                                        Pending
                                    </button>
                                    <button
                                        className={`msg-state-item-btn ${currTicketState === appEnums.MessageStates.Closed && "active"}`}
                                        onClick={this.onClickMsgState}
                                        data-ticket-state={appEnums.MessageStates.Closed}>
                                        Resolve
                                    </button>
                                </div>
                            </div>

                            <div
                                className="btn-group dropup"
                                id="chatReplyLanguageButton"
                                hidden={languageCode === "en" ? true : false}>
                                <button
                                    type="button"
                                    className="btn btn-warning"
                                    onClick={this.onTranslateReply}
                                    disabled={replayButtonDisabled || isTranslating}>
                                    {!isTranslating ? "Translate in " + appEnums.Languages[defaultLanguage] : "Translating..."}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-warning dropdown-toggle dropdown-toggle-split"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                    disabled={replayButtonDisabled || isTranslating}>
                                    <span className="sr-only">Toggle Dropdown</span>
                                </button>
                                <div className="dropdown-menu" style={{ height: "500px", overflowY: "scroll" }}>
                                    {Object.keys(appEnums.Languages).map(key => {
                                        return (
                                            <button
                                                key={key}
                                                className={`msg-state-item-btn ${defaultLanguage === key && "active"}`}
                                                onClick={this.onClickTranslateReply}
                                                data-language={key}>
                                                {appEnums.Languages[key]}
                                            </button>
                                        );
                                    })}
                                </div>
                            </div>

                            <input
                                type="button"
                                className="chat-main-button"
                                id="chatReplyButton"
                                value={replyButtonValue}
                                onClick={this.onReply}
                                disabled={replayButtonDisabled}
                            />
                        </div>
                    </>
                ) : (
                    <form onSubmit={this.onSubmitNotes}>
                        <div className="w-100">
                            <textarea
                                className="form-control"
                                id="chatInternalNotes"
                                value={this.state.notes}
                                onChange={this.onChangeNotes}
                                ref={this.inputRef}
                                rows={5}
                                required={true}
                                maxLength={MAX_INTERNAL_NOTES_ESCAPE_LENGTH}
                                aria-label="With textarea"></textarea>
                        </div>
                        <div id="chatButtonContainer">
                            <button type="submit" id="chatInternalNotesSubmit" disabled={this.state.notes.trim().length === 0}>
                                Submit
                            </button>
                        </div>
                    </form>
                )}
                {charLeftUi}
                <VideoAttachModal onClickUpload={this.onClickUploadVideo} onConfirmVideoLink={this.onConfirmVideoLink} />
                <input
                    id="uploadFileErrorTrigger"
                    data-toggle="modal"
                    data-target="#uploadError"
                    ref={n => (this.uploadFileError = n)}
                    className="chat-input-send-file"
                    type="button"
                />
                <UploadSizeError />
            </div>
        );
    }
}

const options = {
    entityStyleFn: entity => {
        const entityType = entity.get("type").toLowerCase();
        if (entityType === "link") {
            // const data = entity.getData();
            return {
                element: "span"
                // attributes: {
                //     href: data.url,
                //     target: '_blank'
                // }
            };
        }
    },
    inlineStyles: {
        BOLD: { element: "b" }
    }
};
