import React, { Component } from "react";
import "./usersAndPermissionsUi.css";
import webApi from "../api/webApi";
import UserList from "./userList";
import appConstants from "../appConstants";
import appEnums from "../appEnums";
import UserInfo from "./userInfo";
import AppEvent from "../appEvent";
import commonApi from "../api/commonApi";
import { toast } from "react-toastify";
import GuestRoleForm, { guestPermissionLevelOptions } from "./GuestRoleForm";

export default class UsersAndPermissionsUi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: "",
            accountName: "",
            accountEmail: "",
            isAddUserButtonClick: false,
            userNameInputValue: "",
            emailInputValue: "",
            roleInputValue: "",
            guestCanAccessAllAppsInputValue: false,
            guestAppNamesOptions: null,
            guestAppNamesInputValue: [],
            guestPermissionLevelInputValue: guestPermissionLevelOptions[0],
            error: "",
            errorType: toast.TYPE.ERROR,
            userCount: 0,
            userList: []
        };
        this.toastId = "__USERS_AND_PERMISSIONS__";

        this.onClickAddUserButton = () => this.setState({ isAddUserButtonClick: true });
        this.onChangeUserName = event => this.setState({ userNameInputValue: event.target.value });
        this.onChangeEmail = event => this.setState({ emailInputValue: event.target.value });

        this.setUserCount = count => this.setState({ userCount: count });
        this.setUserList = list => this.setState({ userList: list });
        this.dismissError = () => this.setState({ error: "", errorType: toast.TYPE.ERROR });
    }

    componentDidMount() {
        const { hcAccountId, hcCompanyId, token } = this.props;
        webApi
            .fetchGetCompanySetting(hcAccountId, hcCompanyId, token)
            .then(this.onFetchCompanySetting)
            .catch(errDict => this.setState({ error: errDict.error ?? errDict.message, errorType: toast.TYPE.ERROR }));
        window.addEventListener("keyup", this.onKeyUpListener);

        webApi
            .fetchAppInfoData(hcCompanyId, token)
            .then((response) => {
                const options = Object.entries(response).map(([bundleId, data]) => ({
                    label: data.appName ?? bundleId,
                    value: bundleId,
                }))
                this.setState({
                    guestAppNamesOptions: options,
                })
            })
            .catch(errDict => this.setState({ error: errDict.error ?? errDict.message, errorType: toast.TYPE.ERROR }));
    }

    onKeyUpListener = event => {
        if (event.keyCode === 27) {
            // escape key
            this.setState({
                isAddUserButtonClick: false,
                userNameInputValue: "",
                roleInputValue: "",
                emailInputValue: ""
            });
        }
    };

    componentDidUpdate(prevProps, prevState) {
        const { appEvent } = this.props;
        const somethingChange = this.state.emailInputValue || this.state.userNameInputValue || this.state.roleInputValue !== "";
        if (appEvent != null) {
            if (somethingChange) {
                appEvent.fireEvent(AppEvent.EVENT_SET_UNSAVED_CHANGES);
            } else {
                appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
            }
        }
        if (this.state.error !== prevState.error && this.state.error) {
            if (toast.isActive(this.toastId)) {
                toast.update(this.toastId, {
                    type: this.state.errorType,
                    render: this.state.error
                });
            } else {
                toast(this.state.error, {
                    position: toast.POSITION.TOP_RIGHT,
                    type: this.state.errorType,
                    toastId: this.toastId,
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    onClose: this.dismissError,
                    onClick: this.dismissError
                });
            }
        }
    }

    componentWillUnmount() {
        const { appEvent } = this.props;
        if (appEvent != null) {
            appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
        }
        window.removeEventListener("keyup", this.onKeyUpListener);
    }

    onFetchCompanySetting = resDict => {
        this.setState({
            companyName: resDict.companyName,
            accountName: resDict.accountName,
            accountEmail: resDict.accountEmail
        });
    };

    handleSubmit = event => {
        event.preventDefault();
        if (this.state.userNameInputValue === "") {
            return this.setState({ error: "User name is required", errorType: toast.TYPE.ERROR });
        }
        if (this.state.emailInputValue === "") {
            return this.setState({ error: "Email address is required", errorType: toast.TYPE.ERROR });
        }
        if (this.state.roleInputValue === "") {
            return this.setState({ error: "User role is required", errorType: toast.TYPE.ERROR });
        }

        if (this.state.roleInputValue === appEnums.Role.Guest) {
            if (!this.state.guestCanAccessAllAppsInputValue && this.state.guestAppNamesInputValue.length === 0) {
                return this.setState({ error: "Guest role - App(s) is required", errorType: toast.TYPE.ERROR });
            }
        }

        const guestAppNames = this.state.roleInputValue === appEnums.Role.Guest && !this.state.guestCanAccessAllAppsInputValue
            ? this.state.guestAppNamesInputValue.map(({ value }) => value)
            : [];

        const guestInfo = this.state.roleInputValue === appEnums.Role.Guest
            ? {
                guestAllowedApps: guestAppNames,
                guestPermissionLevel: this.state.guestPermissionLevelInputValue.value,
            }
            : {
                guestAllowedApps: [],
                guestPermissionLevel: appEnums.GuestPermissionLevel.Read,
            };

        const { hcCompanyId, token } = this.props;
        const newAccount = {
            name: this.state.userNameInputValue,
            email: this.state.emailInputValue,
            role: parseInt(this.state.roleInputValue),
            profileImage: appConstants.defaultProfilePic,
            profileKey: ""
        };
        webApi
            .fetchAddCompanyAccount(hcCompanyId, newAccount.email, newAccount.name, newAccount.role, guestInfo.guestAllowedApps, guestInfo.guestPermissionLevel, token)
            .then(resDict => {
                newAccount.hcAccountId = resDict.userHcAccountId;
                newAccount.hcCompanyId = resDict.userHcCompanyId;
                const newList = Array.from(this.state.userList);
                newList.unshift(newAccount);
                this.setState({
                    userList: newList,
                    userCount: newList.length,
                    userNameInputValue: "",
                    emailInputValue: "",
                    roleInputValue: "",
                    isAddUserButtonClick: false
                });
            })
            .catch(errDict => this.setState({ error: errDict.error ?? errDict.message, errorType: toast.TYPE.ERROR }))
    };

    onChangeRoleInput = event => {
        let role = event.target.value;
        if (commonApi.isInteger(role)) {
            role = parseInt(role);
        }
        this.setState({ roleInputValue: role });
    };

    render() {
        const roleSelect = (
            <select className="form-control" required value={this.state.roleInputValue} onChange={this.onChangeRoleInput}>
                <option value="">Assign Role</option>
                {Object.keys(appEnums.Role).map(key => {
                    return (
                        <option value={appEnums.Role[key]} key={key}>
                            {key}
                        </option>
                    );
                })}
            </select>
        );
        const addNewUserButton = (
            <button id="addNewUserButton" onClick={this.onClickAddUserButton}>
                ADD NEW USER
            </button>
        );
        const addUserForm = (
            <form className="add-user-form slide-up-animation" onSubmit={this.handleSubmit} autoComplete="off">
                <div className="form-row">
                    <div className="col-4">
                        <input
                            type="text"
                            className="form-control"
                            id="addUserNameInput"
                            placeholder="username"
                            value={this.state.userNameInputValue}
                            onChange={this.onChangeUserName}
                            required
                        />
                    </div>
                    <div className="col-4">
                        <input
                            type="email"
                            className="form-control"
                            id="addUserEmailInput"
                            placeholder="email"
                            value={this.state.emailInputValue}
                            onChange={this.onChangeEmail}
                            required
                        />
                    </div>
                    <div className="col-2">{roleSelect}</div>
                    <div className="col-2">
                        <button className="add-user-submit" type="submit">
                            &nbsp;&nbsp;ADD
                        </button>
                    </div>
                </div>

                {/* Only visible when Guest role is selected */}
                {this.state.roleInputValue === appEnums.Role.Guest && (
                    <GuestRoleForm
                        guestAppNamesOptions={this.state.guestAppNamesOptions}
                        guestAppNames={this.state.guestAppNamesInputValue}
                        setGuestAppNames={(newVal) => this.setState({ guestAppNamesInputValue: newVal })}
                        guestCanAccessAllApps={this.state.guestCanAccessAllAppsInputValue}
                        setGuestCanAccessAllApps={(newVal) => this.setState({ guestCanAccessAllAppsInputValue: newVal })}
                        guestPermissionLevel={this.state.guestPermissionLevelInputValue}
                        setGuestPermissionLevel={(newVal) => this.setState({ guestPermissionLevelInputValue: newVal })}
                    />
                )}
            </form>
        );
        const addUserComponent = this.state.isAddUserButtonClick ? addUserForm : addNewUserButton;
        const userListComponent = (
            <UserList
                setUserCount={this.setUserCount}
                setUserList={this.setUserList}
                userList={this.state.userList}
                userCount={this.state.userCount}
                hcAccountId={this.props.hcAccountId}
                hcCompanyId={this.props.hcCompanyId}
                token={this.props.token}
                setRole={this.props.setRole}
                resetUi2={this.props.resetUi2}
                companyName={this.state.companyName}
                guestAppNamesOptions={this.state.guestAppNamesOptions}
            />
        );

        const userInfoPopup = (
            <div
                className="modal fade user-info-trigger"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="userRolesPermissionsModal"
                aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        {<UserInfo />}
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" id="userInfoClose">
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );

        return (
            <div className="ui2-container-div background-color-v2 slide-up-animation">
                <div className="user-permissions-container">
                    <p className="text-uppercase h5 font-weight-bold">{this.state.companyName}</p>
                    <div className="user-table-description">
                        Your organizations has&nbsp;
                        <small className="text-orange">({this.state.userCount})</small>
                        &nbsp;members.&nbsp;Click&nbsp;
                        <input className="user-info-link" value="here" type="button" data-toggle="modal" data-target=".user-info-trigger" />
                        &nbsp;to view user roles & permissions.&nbsp;
                    </div>
                    {addUserComponent}
                    {userListComponent}
                    {userInfoPopup}
                </div>
            </div>
        );
    }
}
