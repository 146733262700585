import React from "react";
import Popup from "reactjs-popup";
import AppMenuListUi from "./appMenuListUi";
import commonApi from "../api/commonApi";
import "reactjs-popup/dist/index.css";
import "./appMenuUi.css";
import AppEvent from "../appEvent";

export default class AppMenuUi extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isMenuOpen: false };
        this.appIconImg = null;
    }

    openMenu = () =>
        this.setState({ isMenuOpen: true }, () => {
            const { appEvent } = this.props;
            if (appEvent) appEvent.fireEvent(AppEvent.EVENT_UPDATE_MESSAGES_COUNT);
        });
    closeMenu = () => this.setState({ isMenuOpen: false });

    onChangeApp = bundleId => {
        const { onChangeApp } = this.props;
        this.closeMenu();
        onChangeApp(bundleId);
    };

    // onMouseDown = event => {
    //     if (!this.mainRef.contains(event.target)) this.closeMenu();
    //     return true;
    // };

    // componentDidMount() {
    //     document.addEventListener("mousedown", this.onMouseDown);
    // }

    // componentWillUnmount() {
    //     document.removeEventListener("mousedown", this.onMouseDown);
    // }

    render() {
        const { appInfoDict, bundleId, onClickCreateAppButton, token, role, newMessageCount, hcCompanyId } = this.props;
        const appInfo = appInfoDict[bundleId];

        if (!appInfo) return null;

        const menuIcon = (
            <img
                className="app-menu-top-icon"
                alt={bundleId}
                src={appInfo.icon}
                ref={img => (this.appIconImg = img)}
                onError={() => (this.appIconImg.src = "/images/app/defaultIcon.png")}
            />
        );

        const menuArrow = (
            <img
                className={this.state.isMenuOpen ? "app-menu-arrow-down" : "app-menu-arrow-up"}
                alt="menu arrow"
                src="/images/message/btn_drop_down.png"
            />
        );

        const platformSrc = commonApi.getPlatformIconSrc(parseInt(appInfo.platform));

        const platformIcon = platformSrc ? <img src={platformSrc} alt="os icon" className="app-menu-icon" /> : null;

        const popupTrigger = (
            <div
                className="d-flex align-items-center"
                style={{ width: this.props.children ? "80%" : "100%" }}
                id="appMenuTrigger"
                onClick={this.openMenu}>
                <div className="align-self-start">{menuIcon}</div>
                <div className="app-menu-text-div">
                    <span className="app-menu-top-text">{appInfo.appName}</span>
                    {!this.props.children && <div className="app-menu-platform-div">{platformIcon}</div>}
                </div>
                <div className="align-self-center">{menuArrow}</div>
            </div>
        );

        const popupContent = (
            <div className="app-popup-content">
                <AppMenuListUi
                    hcCompanyId={hcCompanyId}
                    bundleId={bundleId}
                    appInfoDict={appInfoDict}
                    onChangeApp={this.onChangeApp}
                    onClickCreateAppButton={onClickCreateAppButton}
                    role={role}
                    token={token}
                    newMessageCount={newMessageCount}
                />
            </div>
        );

        return (
            <div className="app-menu-div" ref={n => (this.mainRef = n)}>
                <div className="app-popup">
                    <Popup
                        open={this.state.isMenuOpen}
                        onOpen={this.openMenu}
                        onClose={this.closeMenu}
                        closeOnDocumentClick
                        arrow={false}
                        contentStyle={{
                            padding: "0",
                            border: "none",
                            width: "25%",
                            minWidth: "319px",
                            marginTop: "10px",
                            borderRadius: "5px"
                        }}
                        position={"bottom left"}
                        trigger={popupTrigger}>
                        {popupContent}
                    </Popup>
                    {this.props.children}
                </div>
            </div>
        );
    }
}
