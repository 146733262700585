import React, { Component } from 'react';
import FaqEditor from './faqEditor';
import AppEvent from '../appEvent';

class FaqEditUi extends Component {
    constructor(props) {
        super(props);
        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onPublish = this.onPublish.bind(this);
        this.onUnpublish = this.onUnpublish.bind(this);
    }

    onSave() {
        const { appEvent } = this.props;
        if (appEvent) {
            appEvent.fireEvent(AppEvent.EVENT_FAQ_SAVE_CHANGES);
        }
    }

    onCancel() {
        const { onFaqEditCancel, faqArticleObj } = this.props;
        onFaqEditCancel(faqArticleObj);
    }

    onPublish() {
        const { appEvent } = this.props;
        if (appEvent) {
            appEvent.fireEvent(AppEvent.EVENT_FAQ_SAVE_PUBLISH);
        }
    }

    onUnpublish() {
        const { appEvent } = this.props;
        if (appEvent) {
            appEvent.fireEvent(AppEvent.EVENT_FAQ_SAVE_UNPUBLISH);
        }
    }

    render() {
        const {
            faqArticleObj,
            editorState,
            appEvent,
            appWebSocket,
            hcAccountId,
            hcCompanyId,
            bundleId,
            onFaqEditSaveChanges
        } = this.props;

        return (
            <div className="background-color-v2 ui2-container-div">
                <div className="faq-editor-main-div">
                    <span className="faq-editor-div-header">EDIT ARTICLE</span>
                    <FaqEditor
                        appEvent={appEvent}
                        appWebSocket={appWebSocket}
                        hcAccountId={hcAccountId}
                        hcCompanyId={hcCompanyId}
                        bundleId={bundleId}
                        faqArticleObj={faqArticleObj}
                        editorState={editorState}
                        onFaqEditSaveChanges={onFaqEditSaveChanges}
                    />
                    <div className="faq-buttons-container">
                        <input
                            type="button"
                            value="SAVE CHANGES"
                            id="faqSaveChanges"
                            alt="Save Changes"
                            title="Save Changes"
                            onClick={this.onSave}
                        />
                        {!faqArticleObj.publish ? (
                            <input
                                type="button"
                                value="PUBLISH"
                                id="faqEditPublishButton"
                                alt="Publish"
                                title="Publish"
                                onClick={this.onPublish}
                            />
                        ) : (
                            <input
                                type="button"
                                value="UNPUBLISH"
                                id="faqEditPublishButton"
                                alt="Unpublish"
                                title="Unpublish"
                                onClick={this.onUnpublish}
                            />
                        )}
                        <input
                            type="button"
                            title="Cancel Changes"
                            value="CANCEL"
                            id="faqCancelChanges"
                            alt="Cancel"
                            onClick={this.onCancel}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default FaqEditUi;
