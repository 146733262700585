import React, { Component } from "react";
import "./loginUi.css";
import webApi from "../api/webApi";
import commonApi from "../api/commonApi";
import { toast } from "react-toastify";

export default class LoginUi extends Component {
    constructor(props) {
        super(props);
        this.state = { email: "", password: "" };
        this.handleEmailChange = event => this.setState({ email: event.target.value });
        this.handlePasswordChange = event => this.setState({ password: event.target.value });
        this.toastId = "__LOGIN_PAGE__";
    }

    handlePasswordKeyUp = event => {
        if (event.keyCode === 13) this.handleSubmit(event);
        else return false;
    };

    handleSubmit = event => {
        event.preventDefault();
        if (!this.state.email) return commonApi.displayToast("Email is required", this.toastId, toast.TYPE.ERROR, {});
        if (!this.state.password) return commonApi.displayToast("Password is required", this.toastId, toast.TYPE.ERROR, {});

        webApi
            .fetchLogin(this.state.email, this.state.password)
            .then(this.onFetchLoginData, this.state.email)
            .catch(error =>
                this.setState({ password: "" }, () => {
                    commonApi.displayToast(error.message ?? error.error ?? "Network Error", this.toastId, toast.TYPE.ERROR, {});
                })
            );
    };

    onFetchLoginData = async response => {
        const isSuccess = "dataToken" in response;
        if (isSuccess) {
            const hash = commonApi.generateHash(
                {
                    ip: webApi.userIP
                },
                webApi.userIP
            );

            const authData = await commonApi.verifyDataToken(response.dataToken, hash);
            window.localStorage.setItem("dataToken", response.dataToken);
            window.localStorage.setItem("token", authData.token);
            const { onLoginCallback } = this.props;
            onLoginCallback?.(authData);
        } else {
            this.setState({ password: "" }, () => {
                commonApi.displayToast(response.error, this.toastId, toast.TYPE.ERROR, {});
            });
        }
    };

    onForgotPassword = () => {
        const { onForgotPasswordCallback } = this.props;
        if (onForgotPasswordCallback != null) onForgotPasswordCallback();
        else return false;
    };

    componentWillUnmount() {
        commonApi.dismissToast(this.toastId);
    }

    render() {
        const isLoginActive = this.state.email.length > 0 && this.state.password.length > 0;
        return (
            <div className="login-container-div">
                <img src="/images/login/logo_helpchatter.png" id="helpChatterBannerLogo" alt="help chatter Logo" />
                <div className="mb-3 login-card bg-transparent">
                    <div className="login-header-card">
                        <p className="login-header-text">LOGIN</p>
                        <div className="triangle-right"></div>
                    </div>
                    <div className="login-body-card">
                        <div className="login-body-content">
                            <div className="form-group d-inline-flex container-fluid">
                                <span className="login-text-label">Email</span>
                                <input
                                    autoFocus
                                    id="loginEmailInput"
                                    value={this.state.email}
                                    onChange={this.handleEmailChange}
                                    type="email"
                                    autoComplete="on"
                                />
                            </div>
                            <div className="form-group d-inline-flex container-fluid">
                                <span className="login-text-label">Password</span>
                                <input
                                    type="password"
                                    id="loginPasswordInput"
                                    value={this.state.password}
                                    onChange={this.handlePasswordChange}
                                    onKeyUp={this.handlePasswordKeyUp}
                                    autoComplete="on"
                                />
                            </div>
                            <button
                                id="loginButton"
                                className="login-button"
                                type="submit"
                                disabled={!isLoginActive}
                                onClick={this.handleSubmit}>
                                LOGIN
                            </button>
                            <label className="login-forgot-password-label" onClick={this.onForgotPassword}>
                                <u>Forgot Password?</u>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
