import React, { Component } from "react";
import "./generalSettingsUi.css";
import appEnums from "../appEnums";

export default class GeneralSettingsUi extends Component {
    tabDefaultClass = "settings-tab";
    tabSelectedClass = "settings-tab active";

    componentDidMount() {
        this.props.resetUi2();
    }

    componentWillUnmount() {
        this.props.resetUi2();
    }

    rightArrowGenerator = selected => {
        let src = selected ? "/images/settings/btn_arrow_right.png" : "/images/settings/btn_arrow_right_p.png";
        const rightArrowUi = <img src={src} alt="right arrow" className="settings-right-arrow" title="app menu arrow" id="appMenuArrow" />;
        return rightArrowUi;
    };

    onChangeTab = tabPage => {
        const { generalSettingsTab, onClickSettingsTab } = this.props;
        if (tabPage === generalSettingsTab) {
            return true;
        }
        onClickSettingsTab(tabPage);
    };

    onClickProfileSettings = () => this.onChangeTab(appEnums.GeneralSettings.Profile);

    onClickUsersPermissions = () => this.onChangeTab(appEnums.GeneralSettings.UsersAndPermissions);

    onClickBillingSettings = () => this.onChangeTab(appEnums.GeneralSettings.Billing);

    onClickAutomation = () => this.onChangeTab(appEnums.GeneralSettings.Automation);

    onClickTemplate = () => this.onChangeTab(appEnums.GeneralSettings.Template);

    render() {
        const { generalSettingsTab, role } = this.props;
        const isProfileSettingsClicked = generalSettingsTab === appEnums.GeneralSettings.Profile;
        const isAutomationSettingsClicked = generalSettingsTab === appEnums.GeneralSettings.Automation;
        const isBillingClicked = generalSettingsTab === appEnums.GeneralSettings.Billing;
        const isUserPermissionsClicked = generalSettingsTab === appEnums.GeneralSettings.UsersAndPermissions;
        const isTemplateSettingsClicked = generalSettingsTab === appEnums.GeneralSettings.Template;

        const automationSettingTabStyle = isAutomationSettingsClicked ? this.tabSelectedClass : this.tabDefaultClass;
        const profileSettingsTabStyle = isProfileSettingsClicked ? this.tabSelectedClass : this.tabDefaultClass;
        const billingSettingTabStyle = isBillingClicked ? this.tabSelectedClass : this.tabDefaultClass;
        const userPermissionsTabStyle = isUserPermissionsClicked ? this.tabSelectedClass : this.tabDefaultClass;
        const templateSettingTabStyle = isTemplateSettingsClicked ? this.tabSelectedClass : this.tabDefaultClass;

        const usersAndPermissionsTab =
            role === appEnums.Role.Admin || role === appEnums.Role.Owner ? (
                <div className={userPermissionsTabStyle} id="userPermissionsTab" onClick={this.onClickUsersPermissions}>
                    Users&nbsp;&amp;&nbsp;Permissions
                    <p className="settings-tab-description">Manage who has access to your account and adjust permissions.</p>
                    {this.rightArrowGenerator(isUserPermissionsClicked)}
                </div>
            ) : null;

        const accountSettingsTab = (
            <div className={profileSettingsTabStyle} id="accountSettingTab" onClick={this.onClickProfileSettings}>
                Profile
                <p className="settings-tab-description">Change your account&apos;s display name, picture, etc.</p>
                {this.rightArrowGenerator(isProfileSettingsClicked)}
            </div>
        );

        const billingTab =
            role === appEnums.Role.Owner ? (
                <div className={billingSettingTabStyle} id="billingSettingTab" onClick={this.onClickBillingSettings}>
                    Billing
                    <p className="settings-tab-description">Change or update your plan or payment information.</p>
                    {this.rightArrowGenerator(isBillingClicked)}
                </div>
            ) : null;

        const automationTab = (
            role !== appEnums.Role.Guest && <div className={automationSettingTabStyle} id="automationSettingTab" onClick={this.onClickAutomation}>
                Quick Replies and Automation
                <p className="settings-tab-description">Add, edit or change Quick Replies and Automation</p>
                {this.rightArrowGenerator(isAutomationSettingsClicked)}
            </div>
        );

        const templateTab = (
            role !== appEnums.Role.Guest && <div className={templateSettingTabStyle} id="templateSettingTab" onClick={this.onClickTemplate}>
                Template Builder
                <p className="settings-tab-description">Make common response templates.</p>
                {this.rightArrowGenerator(isTemplateSettingsClicked)}
            </div>
        );

        return (
            <div className="ui1-container-div slide-up-animation" id="generalSettingsDiv">
                <div className="setting-text-block">
                    <p className="settings-tab-header">SETTINGS</p>
                    {accountSettingsTab}
                    {usersAndPermissionsTab}
                    {billingTab}
                    {automationTab}
                    {templateTab}
                </div>
            </div>
        );
    }
}
