import React, { Component } from 'react';
import appEnums from '../appEnums';
import './userInfo.css';

class UserInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.checkboxGenerator = this.checkboxGenerator.bind(this);
        this.checkBoxChecked = (
            <label className="checkbox-container">
                <input type="checkbox" checked readOnly />
                <span className="checkmark"></span>
            </label>
        );
        this.checkBoxUnchecked = (
            <label className="checkbox-container">
                <input type="checkbox" readOnly disabled={true} />
                <span className="checkmark"></span>
            </label>
        );
    }

    checkboxGenerator(feature, role) {
        let checkboxUi = null;
        switch (feature) {
            case appEnums.Features.Tickets:
                checkboxUi = this.checkBoxChecked;
                break;
            case appEnums.Features.Analytics:
            case appEnums.Features.Faq:
            case appEnums.Features.Automation:
                checkboxUi = role === appEnums.Role.Guest ? this.checkBoxUnchecked : this.checkBoxChecked;
                break;
            case appEnums.Features.Apps:
                checkboxUi = role === appEnums.Role.Guest || role === appEnums.Role.Agent ? this.checkBoxUnchecked : this.checkBoxChecked;
                break;
            case appEnums.Features.UsersAndPermissions:
                checkboxUi =
                    role === appEnums.Role.Guest || role === appEnums.Role.Agent || role === appEnums.Role.Developer
                        ? this.checkBoxUnchecked
                        : this.checkBoxChecked;
                break;
            case appEnums.Features.Billing:
                checkboxUi = role !== appEnums.Role.Owner ? this.checkBoxUnchecked : this.checkBoxChecked;
                break;
            default:
                break;
        }
        return checkboxUi;
    }

    render() {
        const permissionArr = Array(5).fill(0); // -1-guest 0-agent 1-dev 2-admin 3-owner
        const ticketsCheckboxes = permissionArr.map((val, index) => {
            index = index - 1;
            return <td key={appEnums.Features.Tickets + index}>{this.checkboxGenerator(appEnums.Features.Tickets, index)}</td>;
        });

        const analyticsCheckboxes = permissionArr.map((val, index) => {
            index = index - 1;
            return (
                <td key={appEnums.Features.Analytics + index}>{this.checkboxGenerator(appEnums.Features.Analytics, index)}</td>
            );
        });

        const faqCheckBoxes = permissionArr.map((val, index) => {
            index = index - 1;
            return <td key={appEnums.Features.Faq + index}>{this.checkboxGenerator(appEnums.Features.Faq, index)}</td>;
        });

        const automationCheckboxes = permissionArr.map((val, index) => {
            index = index - 1;
            return (
                <td key={appEnums.Features.Automation + index}>{this.checkboxGenerator(appEnums.Features.Automation, index)}</td>
            );
        });

        const appsCheckboxes = permissionArr.map((val, index) => {
            index = index - 1;
            return <td key={appEnums.Features.Apps + index}>{this.checkboxGenerator(appEnums.Features.Apps, index)}</td>;
        });

        const permissionsCheckboxes = permissionArr.map((val, index) => {
            index = index - 1;
            return (
                <td key={appEnums.Features.UsersAndPermissions + index}>
                    {this.checkboxGenerator(appEnums.Features.UsersAndPermissions, index)}
                </td>
            );
        });

        const billingCheckboxes = permissionArr.map((val, index) => {
            index = index - 1;
            return <td key={appEnums.Features.Billing + index}>{this.checkboxGenerator(appEnums.Features.Billing, index)}</td>;
        });

        return (
            <div className="user-info-div">
                <table className="user-info-table">
                    <thead className="user-info-thead">
                        <tr>
                            <td>Features</td>
                            <td>Guest</td>
                            <td>Agent</td>
                            <td>Developer</td>
                            <td>Admin</td>
                            <td>Owner</td>
                        </tr>
                    </thead>
                    <tbody className="user-info-tbody">
                        <tr>
                            <td>
                                Tickets
                                <br />
                                <small>Reply and resolve tickets</small>
                            </td>
                            {ticketsCheckboxes}
                        </tr>
                        <tr>
                            <td>
                                Analytics <br />
                                <small>View and monitor Analytics</small>
                            </td>
                            {analyticsCheckboxes}
                        </tr>
                        <tr>
                            <td>
                                FAQ <br />
                                <small>Create, edit and manage FAQs</small>
                            </td>
                            {faqCheckBoxes}
                        </tr>
                        <tr>
                            <td>
                                Quick Replies and Automation <br />
                                <small>Create, edit and manage Quick replies and Automation</small>
                            </td>
                            {automationCheckboxes}
                        </tr>
                        <tr>
                            <td>
                                Apps
                                <br />
                                <small>Install, configure and delete apps, manage app settings</small>
                            </td>
                            {appsCheckboxes}
                        </tr>
                        <tr>
                            <td>
                                Users &amp; Permissions <br />
                                <small>Add, delete and change user roles in the organization</small>
                            </td>
                            {permissionsCheckboxes}
                        </tr>
                        <tr>
                            <td>
                                Billing <br />
                                <small>Change or update your organization&apos;s plan or payment method</small>
                            </td>
                            {billingCheckboxes}
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default UserInfo;
