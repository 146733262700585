import React, { Component } from 'react';
import './homeUi.css';

class HomeUi extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="HomeContainerDiv">
        <span className="HomeTilteText">Kooapps Helpchatter</span><p />
        <span className="HomeVersionText">Version: 1.0.2</span>
      </div>
    )
  }
}

export default HomeUi;
