import { publicIpv4 } from "public-ip";
import axios from "axios"
import stringApi from "./stringApi";

const getHost = function () {
    const hostname = window.location.hostname;
    if (hostname.includes("localhost") || hostname.includes("127.0.0.1")) {
        return "http://" + hostname + ":3001";
    }
    if (hostname.includes("192.168")) {
        return stringApi.format("http://{0}:3001", hostname);
    }
    return "https://www.helpchatter.com/api";
};

const generateRequestCustomHeaders = (token) => {
    const customHeaders = {};
    if (token) {
        customHeaders['Authorization'] = token;
    }

    return customHeaders;
};

const extractDataFromAxiosResponse = (axiosResponse) => {
    const { data } = axiosResponse;

    if (data && "error" in data) {
        throw new Error(data.error);
    }

    return data;
};

axios.defaults.baseURL = getHost();

export default {
    userIP: "",

    login: "/login",
    verifyAccessToken: "/login/verifyAccessToken",
    getAccountMetaDataSafe: "/login/getAccountMetaDataSafe",
    checkAccountActive: "/login/checkAccountActive",

    sendPasswordResetCode: "/login/sendPasswordResetCode",
    setNewPassword: "/login/setNewPassword",
    changePassword: "/login/changePassword",
    deleteProfileImage: "/login/deleteProfileImage",
    getProfileImage: "/login/getProfileImage",
    updateLastLogin: "/login/updateLastLogin",
    getAccountEmail: "/login/getAccountEmail",

    createApp: "/app/createApp",
    deleteApp: "/app/deleteApp",
    getAllAppInfo: "/app/getAllAppInfo",
    getMetrics: "/app/getMetrics",
    getBundleIdListByCompanyId: "/app/getBundleIdListByCompanyId",

    getAllConversations: "/chat/getAllConversations",
    getConversations: "/chat/getConversations",
    getConversation: "/chat/getConversation",
    updateConversationNotes: "/chat/updateConversationNotes",

    getAllMessages: "/chat/getAllMessages",
    getNewMessageCount: "/chat/getNewMessageCount",
    getCompanyNewMessageCounts: "/chat/getCompanyNewMessageCounts",
    getConversationsCount: "/chat/getMessagesCount",
    getPriorityConversationsCount: "/chat/getPriorityMessagesCount",
    hasNewMessage: "/chat/hasNewMessage",
    addConversationTag: "/chat/addConversationTag",
    deleteConversationTag: "/chat/deleteConversationTag",
    getAllConversationTags: "/chat/getAllConversationTags",
    deleteMessage: "/chat/deleteMessage",

    changeConversationState: "/chat/changeConversationState",

    getCompanySetting: "/setting/getCompanySetting",
    changeCompanySetting: "/setting/changeCompanySetting",

    getAutomationSetting: "/setting/getAutomationSetting",
    changeAutomationSetting: "/setting/changeAutomationSetting",
    addAutoResponseText: "/setting/addAutoResponseText",
    editAutoResponseText: "/setting/editAutoResponseText",
    editAutoMessageData: "/setting/editAutoMessageData",
    editAutoResolveData: "/setting/editAutoResolveData",

    getTemplateBuilder: "/setting/getTemplateBuilder",
    changeTemplateBuilder: "/setting/changeTemplateBuilder",

    getAppSetting: "/setting/getAppSetting",
    changeAppSetting: "/setting/changeAppSetting",
    updateAdminLinkOriginForAllApps: "/setting/updateAdminLinkOriginForAllApps",
    updatePasswordHashForAllApps: "/setting/updatePasswordHashForAllApps",

    getFaqInfo: "/faq/getFaqInfo",
    getFaqArticle: "/faq/getFaqArticle",

    getCompanyAccounts: "/permissions/getCompanyAccounts",
    getAccountRoleParameters: "/permissions/getAccountRoleParameters",
    changeAccountRoleParameters: "/permissions/changeAccountRoleParameters",
    changeAccountPermissions: "/permissions/changeAccountPermissions",
    addCompanyAccount: "/permissions/addCompanyAccount",
    deleteCompanyAccount: "/permissions/deleteCompanyAccount",
    deactivateCompanyAccount: "/permissions/deactivateCompanyAccount",
    activateCompanyAccount: "/permissions/activateCompanyAccount",

    sendInstantPush: "/push/sendInstantPush",
    checkIosPushCertificate: "/push/checkIosPushCertificate",

    addAutomationMessage: "/automation/message/add",
    deleteAutomationMessage: "/automation/message/delete",
    updateAutomationMessage: "/automation/message/update",
    getAutomationMessages: "/automation/messages",
    getAutomationMessage: "/automation/message",

    getTranslation: "/translate",

    changeUserDeviceVipStatus: "/userDevice/changeUserDeviceVipStatus",

    getChatSocket: function () {
        const hostname = window.location.hostname;
        if (hostname.includes("localhost") || hostname.includes("127.0.0.1")) {
            return "http://" + hostname + ":1337";
        }
        if (hostname.includes("192.168")) {
            return stringApi.format("http://{0}:1337", hostname);
        }
        return "https://www.helpchatter.com";
    },

    getHost,

    getFrontendHost: function () {
        const hostname = window.location.hostname;
        if (hostname.includes("localhost") || hostname.includes("127.0.0.1")) {
            return "http://" + hostname + ":3000";
        }
        if (hostname.includes("192.168")) {
            return stringApi.format("http://{0}:3000", hostname);
        }
        return "https://www.helpchatter.com";
    },

    getFullUrl: function (url, ...args) {
        const encodedParams = args.map(arg => encodeURIComponent(arg))
        return stringApi.format(this.getHost() + url, ...encodedParams);
    },

    axiosGet: async function (url, headers, queryParams, signal, axiosReqConfig = {}) {
        const response = await axios.get(url, {
            ...axiosReqConfig,
            params: queryParams,
            headers,
            signal,
        })

        return extractDataFromAxiosResponse(response);
    },

    axiosDelete: async function (url, headers, queryParams, signal, axiosReqConfig = {}) {
        const response = await axios.delete(url, {
            ...axiosReqConfig,
            params: queryParams,
            headers,
            signal,
        })

        return extractDataFromAxiosResponse(response);
    },

    axiosPost: async function (url, headers, data, signal, axiosReqConfig = {}) {
        const response = await axios.post(url, data, {
            ...axiosReqConfig,
            headers,
            signal,
        });

        return extractDataFromAxiosResponse(response);
    },

    axiosPut: async function (url, headers, data, signal, axiosReqConfig = {}) {
        const response = await axios.put(url, data, {
            ...axiosReqConfig,
            headers,
            signal,
        });

        return extractDataFromAxiosResponse(response);
    },

    updateUserIP: async function () {
        this.userIP = await publicIpv4();
    },

    fetchLogin: function (email, password) {
        const queryParams = { email, password, ipAddress: this.userIP };
        return this.axiosGet(this.login, undefined, queryParams);
    },

    fetchGetAccountMetaDataSafe: function (hcAccountId, token) {
        const queryParams = { hcAccountId };
        const headers = generateRequestCustomHeaders(token);
        return this.axiosGet(this.getAccountMetaDataSafe, headers, queryParams);
    },

    fetchUpdateLastLogin: function (hcAccountId, hcCompanyId, token) {
        const queryParams = { hcAccountId, hcCompanyId };
        const headers = generateRequestCustomHeaders(token);
        return this.axiosGet(this.updateLastLogin, headers, queryParams);
    },

    fetchCheckAccountActive: function (hcAccountId) {
        const queryParams = { hcAccountId };
        return this.axiosGet(this.checkAccountActive, undefined, queryParams);
    },

    fetchSendPasswordResetCode: function (email) {
        const queryParams = { email };
        return this.axiosGet(this.sendPasswordResetCode, undefined, queryParams);
    },

    fetchSetNewPassword: function (resetCode, newPassword) {
        const queryParams = { resetCode, newPassword, ipAddress: this.userIP };
        return this.axiosGet(this.setNewPassword, undefined, queryParams);
    },

    fetchChangePassword: function (email, password, newPassword, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { email, password, newPassword, ipAddress: this.userIP };
        return this.axiosGet(this.changePassword, headers, queryParams);
    },

    fetchDeleteProfileImage: function (hcAccountId, hcCompanyId, profileKey, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcAccountId, hcCompanyId, profileKey };
        return this.axiosGet(this.deleteProfileImage, headers, queryParams);
    },

    fetchGetProfileImage: function (hcAccountId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcAccountId };
        return this.axiosGet(this.getProfileImage, headers, queryParams);
    },

    fetchVerifyAccessToken: function (token) {
        const headers = generateRequestCustomHeaders(token);
        return this.axiosGet(this.verifyAccessToken, headers);
    },

    fetchAllConversations: function (signal, bundleId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId };
        return this.axiosGet(this.getAllConversations, headers, queryParams, signal);
    },

    fetchConversations: function (signal, hcCompanyId, bundleId, timestampPagination, timestampStart, timestampEnd, state, search, order, sdk, appVersion, country, token) {
        const data = {
            hcCompanyId,
            bundleId,
            timestampPagination,
            timestampStart,
            timestampEnd,
            state,
            search,
            order,
            sdk,
            appVersion,
            country,
        };
        const headers = generateRequestCustomHeaders(token);

        return this.axiosPost(this.getConversations, headers, data, signal);
    },

    fetchConversation: function (bundleId, deviceId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId, deviceId };
        return this.axiosGet(this.getConversation, headers, queryParams);
    },

    fetchUpdateConversationNotes: function (bundleId, deviceId, notes, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId, deviceId, notes };
        return this.axiosGet(this.updateConversationNotes, headers, queryParams);
    },

    fetchAllMessages: function (signal, bundleId, deviceId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId, deviceId };
        return this.axiosGet(this.getAllMessages, headers, queryParams, signal);
    },

    fetchGetNewMessagesCount: function (bundleId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId };
        return this.axiosGet(this.getNewMessageCount, headers, queryParams);
    },

    fetchHasNewMessage: function (bundleId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId };
        return this.axiosGet(this.hasNewMessage, headers, queryParams);
    },

    fetchGetMessagesCount: function (bundleId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId };
        return this.axiosGet(this.getConversationsCount, headers, queryParams);
    },

    fetchGetPriorityMessagesCount: function (hcCompanyId, bundleId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId };
        return this.axiosGet(this.getPriorityConversationsCount, headers, queryParams);
    },

    fetchGetCompanyNewMessageCounts: function (hcCompanyId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId };
        return this.axiosGet(this.getCompanyNewMessageCounts, headers, queryParams);
    },

    fetchAddConversationTag: function (hcCompanyId, tag, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, tag };
        return this.axiosGet(this.addConversationTag, headers, queryParams);
    },

    fetchDeleteConversationTag: function (hcCompanyId, tag, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, tag };
        return this.axiosGet(this.deleteConversationTag, headers, queryParams);
    },

    fetchGetAllConversationTags: function (hcCompanyId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId };
        return this.axiosGet(this.getAllConversationTags, headers, queryParams);
    },

    fetchDeleteMessage: function (bundleId, deviceId, timestamp, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId, deviceId, timestamp };
        return this.axiosGet(this.deleteMessage, headers, queryParams);
    },

    fetchCreateApp: function (hcCompanyId, bundleId, appName, metaData, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId, appName, metaData };
        return this.axiosGet(this.createApp, headers, queryParams);
    },

    fetchDeleteApp: function (hcCompanyId, bundleId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId };
        return this.axiosGet(this.deleteApp, headers, queryParams);
    },

    fetchAppInfoData: function (hcCompanyId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId };
        return this.axiosGet(this.getAllAppInfo, headers, queryParams);
    },

    fetchMetrics: function (bundleId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId };
        return this.axiosGet(this.getMetrics, headers, queryParams);
    },

    fetchChangeState: function (bundleId, deviceId, state, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId, deviceId, state };
        return this.axiosGet(this.changeConversationState, headers, queryParams);
    },

    fetchGetCompanySetting: function (hcAccountId, hcCompanyId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcAccountId, hcCompanyId };
        return this.axiosGet(this.getCompanySetting, headers, queryParams);
    },

    fetchChangeCompanySetting: function (hcAccountId, hcCompanyId, companyName, accountName, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcAccountId, hcCompanyId, companyName, accountName };
        return this.axiosGet(this.changeCompanySetting, headers, queryParams);
    },

    fetchGetAutomationSetting: function (hcCompanyId, bundleId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId };
        return this.axiosGet(this.getAutomationSetting, headers, queryParams);
    },

    fetchChangeAutomationSetting: function (hcCompanyId, bundleId, metaData, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId, metaData };
        return this.axiosGet(this.changeAutomationSetting, headers, queryParams);
    },

    fetchGetTemplateBuilder: function (hcCompanyId, bundleId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId };
        return this.axiosGet(this.getTemplateBuilder, headers, queryParams);
    },

    fetchChangeTemplateBuilder: function (hcCompanyId, bundleId, metaData, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId, metaData };
        return this.axiosGet(this.changeTemplateBuilder, headers, queryParams);
    },

    fetchAddAutoResponseText: function (hcCompanyId, responseText, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, responseText };
        return this.axiosGet(this.addAutoResponseText, headers, queryParams);
    },

    fetchEditAutoResponseText: function (hcCompanyId, bundleId, responseText, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId, responseText };
        return this.axiosGet(this.editAutoResponseText, headers, queryParams);
    },

    fetchEditAutoMessageData: function (hcCompanyId, bundleId, messageText, days, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId, messageText, days };
        return this.axiosGet(this.editAutoMessageData, headers, queryParams);
    },

    fetchEditAutoResolveData: function (hcCompanyId, bundleId, messageText, days, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId, messageText, days };
        return this.axiosGet(this.editAutoResolveData, headers, queryParams);
    },

    fetchGetAppSetting: function (bundleId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId };
        return this.axiosGet(this.getAppSetting, headers, queryParams);
    },

    fetchChangeAppSetting: function (bundleId, appName, metaData, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId, appName, metaData };
        return this.axiosGet(this.changeAppSetting, headers, queryParams);
    },

    fetchUpdatePasswordHashForAllApps: function (hcCompanyId, passwordHash, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, passwordHash };
        return this.axiosGet(this.updatePasswordHashForAllApps, headers, queryParams);
    },

    fetchUpdateAdminLinkOriginForAllApps: function (hcCompanyId, adminLinkOrigin, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, adminLinkOrigin };
        return this.axiosGet(this.updateAdminLinkOriginForAllApps, headers, queryParams);
    },

    fetchGetBundleIdListByCompanyId: function (hcCompanyId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId };
        return this.axiosGet(this.getBundleIdListByCompanyId, headers, queryParams);
    },

    fetchGetFaqInfo: function (bundleId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId };
        return this.axiosGet(this.getFaqInfo, headers, queryParams);
    },

    fetchGetFaqArticle: function (bundleId, appFaqId) {
        const queryParams = { bundleId, appFaqId };
        return this.axiosGet(this.getFaqArticle, undefined, queryParams);
    },

    fetchGetCompanyAccounts: function (hcAccountId, hcCompanyId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcAccountId, hcCompanyId };
        return this.axiosGet(this.getCompanyAccounts, headers, queryParams);
    },

    fetchGetAccountRoleParameters: function (hcAccountId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcAccountId };
        return this.axiosGet(this.getAccountRoleParameters, headers, queryParams);
    },

    fetchGetAccountEmail: function (hcAccountId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcAccountId };
        return this.axiosGet(this.getAccountEmail, headers, queryParams);
    },

    fetchChangeAccountRoleParameters: function (hcAccountId, guestAllowedApps, guestPermissionLevel, token) {
        const headers = generateRequestCustomHeaders(token);
        const guestAllowedAppsEncoded = JSON.stringify(guestAllowedApps);
        const queryParams = { hcAccountId, guestAllowedApps: guestAllowedAppsEncoded, guestPermissionLevel }
        return this.axiosGet(this.changeAccountRoleParameters, headers, queryParams);
    },

    fetchChangeAccountPermissions: function (hcAccountId, role, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcAccountId, role };
        return this.axiosGet(this.changeAccountPermissions, headers, queryParams);
    },

    fetchAddCompanyAccount: function (hcCompanyId, email, userName, role, guestAllowedApps, guestPermissionLevel, token) {
        const headers = generateRequestCustomHeaders(token);
        const guestAllowedAppsEncoded = JSON.stringify(guestAllowedApps);
        const queryParams = { hcCompanyId, email, userName, role, guestAllowedApps: guestAllowedAppsEncoded, guestPermissionLevel };
        return this.axiosGet(this.addCompanyAccount, headers, queryParams);
    },

    fetchDeleteCompanyAccount: function (email, hcAccountId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { email, hcAccountId };
        return this.axiosGet(this.deleteCompanyAccount, headers, queryParams);
    },

    fetchDeactivateCompanyAccount: function (email, hcAccountId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { email, hcAccountId };
        return this.axiosGet(this.deactivateCompanyAccount, headers, queryParams);
    },

    fetchActivateCompanyAccount: function (email, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { email };
        return this.axiosGet(this.activateCompanyAccount, headers, queryParams);
    },

    fetchSendInstantPush: function (bundleId, deviceId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId, deviceId };
        return this.axiosGet(this.sendInstantPush, headers, queryParams);
    },

    fetchCheckIosPushCertificate: function (bundleId, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId };
        return this.axiosGet(this.checkIosPushCertificate, headers, queryParams);
    },

    fetchAddAutomationMessage: function (hcCompanyId, bundleId, type, languageCode, message, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId, type, languageCode, message };
        return this.axiosGet(this.addAutomationMessage, headers, queryParams);
    },

    fetchDeleteAutomationMessage: function (hcCompanyId, bundleId, type, languageCode, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId, type, languageCode };
        return this.axiosGet(this.deleteAutomationMessage, headers, queryParams);
    },

    fetchUpdateAutomationMessage: function (hcCompanyId, bundleId, type, languageCode, message, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId, type, languageCode, message };
        return this.axiosGet(this.updateAutomationMessage, headers, queryParams);
    },

    fetchGetAutomationMessages: function (hcCompanyId, bundleId, type, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId, type };
        return this.axiosGet(this.getAutomationMessages, headers, queryParams);
    },

    fetchGetAutomationMessage: function (hcCompanyId, bundleId, type, languageCode, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { hcCompanyId, bundleId, type, languageCode };
        return this.axiosGet(this.getAutomationMessage, headers, queryParams);
    },

    fetchGetTranslation: async function (contentText, translationLanguage) {
        const queryParams = { contentText, translationLanguage };
        return this.axiosGet(this.getTranslation, undefined, queryParams);
    },

    fetchChangeUserDeviceVipStatus: function (bundleId, deviceId, vipStatus, token) {
        const headers = generateRequestCustomHeaders(token);
        const queryParams = { bundleId, deviceId, vipStatus };
        return this.axiosGet(this.changeUserDeviceVipStatus, headers, queryParams);
    },
};
