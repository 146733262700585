import React, { Component } from 'react';
import './deepLinking.css';

class DeepLinking extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.onLaunchAppClick = this.onLaunchAppClick.bind(this);
    }

    componentDidMount() {
        setTimeout(this.onLaunchAppClick, 1000);
    }

    onLaunchAppClick() {
        const { bundleId, keyData } = this.props;
        if (bundleId && keyData) {
            const url = "helpchatter-" + keyData + "://" + bundleId;
            window.open(url, '_self');
        }
    }

    render() {
        const launchAppButtonUi = (
            <button
                id="deepLinkingLaunchAppButton"
                type="submit"
                alt="Launch App"
                title="Launch App"
                className="deep-linking-button"
                onClick={this.onLaunchAppClick}>
                LAUNCH APP
            </button>
        );

        const mainDiv = (
            <div className="w-100 mb-5">
                <div className="deep-linking-logo">
                    <img src="/images/login/logo_helpchatter.png"
                        alt="help chatter Logo"/>
                </div>
                <span className="deep-linking-heading">Instant Push Verification</span>
                <span className="deep-linking-description">If this page does not automatically launch the app, please click the button below.</span>
                {launchAppButtonUi}
            </div>
        );

        return (
            <div>
                {mainDiv}
            </div>
        );
    }
}

export default DeepLinking;
