import React, { Component, useState } from "react";
import Moment from "react-moment";
import appEnums from "../appEnums";
import commonApi from "../api/commonApi";
import ImageZoom from "react-medium-image-zoom";
import appConstants from "../appConstants";
import Linkify from "linkify-react";
import ReactPlayer from "react-player";
import "react-medium-image-zoom/dist/styles.css";
import "./chatMsgListItemImage.css";

class ChatMsgListItem extends Component {
    constructor(props) {
        super(props);
        this.state = { sid: null, showIcons: false, contentText: "", contentTextTranslation: null, copied: false };

        this.onMouseLeave = () => this.setState({ showIcons: false });
        this.onMouseOver = () => this.setState({ showIcons: true });
    }

    componentDidMount() {
        let contentText = "";

        const { chatData, translationData } = this.props;

        if (!chatData.image && !chatData.video && !chatData.askRating && !chatData.chatSurvey) {
            const contentItem = chatData.html ? chatData.html : chatData.message;
            contentText = contentItem.trim().length > 0 ? commonApi.removeElemHtmlTag(chatData.message) : null;
        }

        this.setState({ sid: chatData.sid, contentText, contentTextTranslation: translationData });
    }

    onDeleteMsg = e => {
        e.preventDefault();
        const { setTimestampId, chatData } = this.props;
        setTimestampId(chatData.timestamp);
    };

    copyToClipBoard = url => {
        return <CopyToClipboardUi url={url} />;
    };

    onImageLoad = () => {
        const { scrollToBottom } = this.props;
        setTimeout(scrollToBottom, 10);
    };

    onClickCopyToClipBoardText = (e, text) => {
        e.preventDefault();
        navigator.clipboard.writeText(text);
        this.setCopied(true);

        setTimeout(() => {
            this.setCopied(false);
        }, 2000);
    };

    setCopied = status => {
        this.setState({ copied: status });
    };

    onClickTranslate = async e => {
        e.preventDefault();

        this.onTranslate();
    };

    onTranslate = async () => {
        const { sid, contentText, contentTextTranslation } = this.state;

        if (contentText.length > 0 && contentTextTranslation === null) {
            this.setState({ contentTextTranslation: { loading: true } });

            const translateData = await this.props.onTranslate(contentText, this.props.defaultTranslationLanguage);
            if (translateData.status) {
                this.setState({ contentTextTranslation: translateData });
                this.props.addTranslatedMsgData({ sid, translateData });
            } else {
                this.setState({ contentTextTranslation: { loading: false, translatedText: "" } });
            }
        }
    };

    render() {
        const { chatData, setDialogBox } = this.props;
        let contentUi = null;
        let contentText = null;

        if (chatData.image) {
            contentUi = (
                <ImageZoom
                    zoomImg={{
                        src: chatData.image,
                        alt: "chat image message"
                    }}
                    zoomMargin={75}
                    onZoom={() => setDialogBox(this.copyToClipBoard(chatData.image))}
                    onUnzoom={() => setDialogBox(null)}
                >
                    <img
                        src={chatData.image}
                        alt="chat image message"
                        className="chat-msg-image"
                        onLoad={this.onImageLoad}
                    />
                </ImageZoom>
            );
        } else if (chatData.video) {
            contentUi = <ReactPlayer url={chatData.video} controls={true} className="react-player" />;
        } else if (chatData.askRating) {
            const askRatingItem = "(Rate us on the store!)";
            contentUi = askRatingItem;
        } else if (chatData.chatSurvey) {
            const isFinished = commonApi.isChatSurveyFinished(chatData.chatSurvey);
            const starValue = commonApi.getChatSurveyStarValue(chatData.chatSurvey);
            let chatSurveyItem = "(Chat survey NPS";
            if (isFinished) {
                chatSurveyItem += ": star=" + starValue;
            }
            chatSurveyItem += ")";
            contentUi = chatSurveyItem;
        } else {
            const hasHtmlProp = !!chatData.html
            const contentItem = hasHtmlProp ? chatData.html : chatData.message;
            contentUi = contentItem.trim().length > 0 ? <Linkify>{commonApi.parseHtmlString(contentItem)}</Linkify> : null;
            contentUi = <span style={hasHtmlProp ? null : { whiteSpace: 'pre-line' }}>{contentUi}</span>
            contentText = contentItem.trim().length > 0 ? commonApi.removeElemHtmlTag(chatData.message) : null;
        }
        const momentUi = chatData.timestamp ? (
            <div className="chat-msg-timestamp">
                <Moment unix format="hh:mm&nbsp;a" interval={3600000}>
                    {chatData.timestamp / 1000}
                </Moment>
            </div>
        ) : (
            "↖"
        );
        const senderUi = chatData.sender ? <span className="chat-msg-senderName">{chatData.sender}</span> : null;

        const clientProfile = (
            <img
                src={appConstants.defaultProfilePic}
                className="chat-profile-client"
                alt="Client profile"
                ref={img => (this.clientImage = img)}
                onError={() => (this.clientImage.src = appConstants.defaultProfilePic)}
            />
        );

        const agentProfile = (
            <img
                src={chatData.senderImage || appConstants.defaultProfilePic}
                className="chat-profile-agent"
                alt="Server profile"
                ref={img => (this.agentImage = img)}
                onError={() => (this.agentImage.src = appConstants.defaultProfilePic)}
            />
        );

        if (!contentUi) return null;
        const isClient = chatData.source === appEnums.MessageSource.Client ? true : false;

        let messageClass = isClient ? "chat-msg-client" : `chat-msg-agent${chatData.isNote ? " notes" : ""}`;
        let detailClass = isClient ? "msg-detail-client" : "msg-detail-agent";
        let senderProfile = isClient ? clientProfile : agentProfile;

        return (
            <div className="chat-msg-div" onMouseLeave={this.onMouseLeave} onMouseOver={this.onMouseOver}>
                <div className={messageClass}>
                    <div className="msg-content">
                        {!isClient && (
                            <span className={`${chatData.isNote ? "tag internal" : "tag"}`}>{`${chatData.isNote ? "Internal" : "Public"
                                }`}</span>
                        )}
                        <div className="content">
                            {contentUi}
                            <div className="translation-container">
                                {contentText && !this.state.contentTextTranslation && (
                                    <button className="btn btn-link btn-sm p-0" onClick={e => this.onClickTranslate(e, contentText)}>
                                        <small>Translate</small>
                                    </button>
                                )}
                                <p className="ml-1 pl-2 text-muted" style={{ borderLeft: "2px solid #df6800" }}>
                                    {this.state.contentTextTranslation && this.state.contentTextTranslation.loading && "Loading..."}
                                    {this.state.contentTextTranslation && this.state.contentTextTranslation.translatedText}
                                </p>
                            </div>
                        </div>
                    </div>
                    {contentText && (
                        <div className="msg-translate-div px-2">
                            <small className="px-1 position-absolute" style={{ top: "-15%" }}>
                                {this.state.copied ? "Copied!" : ""}
                            </small>
                            <input
                                type="image"
                                src="/images/conversation/icon_copy.png"
                                alt="Copy to Clipboard button"
                                title="Copy to Clipboard"
                                onClick={e => this.onClickCopyToClipBoardText(e, contentText)}
                            />
                        </div>
                    )}
                    {!this.props.readonly && this.state.showIcons && (
                        <>
                            <div className="msg-delete-div">
                                <input
                                    type="image"
                                    src="/images/chat/icon_delete_message.png"
                                    title="Delete message."
                                    alt="delete button"
                                    data-toggle="modal"
                                    data-target="#deleteMessageWindow"
                                    onClick={this.onDeleteMsg}
                                />
                            </div>
                        </>
                    )}
                </div>
                <div className={detailClass}>
                    {senderUi}&nbsp;&nbsp;{momentUi}
                </div>
                {senderProfile}
            </div>
        );
    }
}

const CopyToClipboardUi = props => {
    const [clipboardText, setClipboardText] = useState("Copy to clipboard");

    const onClickCopy = () => {
        let copyText = document.getElementById("imageUrl");
        navigator.clipboard.writeText(copyText.value);
        copyText.select();
        setClipboardText("Copied");
    };

    return (
        <div className="copy-to-clipboard-div">
            <input type="text" value={props.url} id="imageUrl" readOnly />
            <input type="image" src="/images/copyToClipboard.png" alt="copy to clipboard" title="copy to clipboard" onClick={onClickCopy} />
            <div className="copy-to-clipboard-text">{clipboardText}</div>
        </div>
    );
};

export default ChatMsgListItem;
