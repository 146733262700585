import React, { useState } from 'react';

export const DeleteMessageModal = props => {
    const onConfirm = () => {
        props.onConfirm();
    };
    return (
        <div
            className="modal fade"
            id="deleteMessageWindow"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="deleteMessageModal"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="deleteMessageModal">
                            Delete Message
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">Are you sure you want to delete this message?</div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            id="modalCancelBtn"
                            title="Cancel"
                            className="btn btn-secondary"
                            data-dismiss="modal">
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            id="deleteMsgBtn"
                            data-dismiss="modal"
                            onClick={onConfirm}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const VideoAttachModal = props => {
    const [url, onChangeLink] = useState('');
    const onConfirm = e => {
        props.onConfirmVideoLink(url);
        onChangeLink('');
    };
    const onClickUpload = e => {
        onChangeLink('');
        props.onClickUpload(e);
    };

    return (
        <div
            className="modal fade"
            id="attachVideoInput"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="attachVideoModal"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="attachVideoModal">
                            Attach a video
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input
                            value={url}
                            onChange={e => onChangeLink(e.target.value)}
                            type="text"
                            title="image link"
                            placeholder="URL"
                            className="form-control"
                            id="attachVideoLinkInput"
                            autoComplete="off"
                            aria-label="Input for video url"
                        />
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            id="modalCancelBtn"
                            title="Cancel"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => onChangeLink('')}>
                            Cancel
                        </button>
                        <button
                            type="button"
                            disabled={url.length === 0}
                            className="btn btn-primary"
                            id="videoModalConfirmBtn"
                            data-dismiss="modal"
                            onClick={onConfirm}>
                            Confirm
                        </button>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            id="videoAttachUploadBtn"
                            className="btn btn-success"
                            data-dismiss="modal"
                            onClick={onClickUpload}>
                            Upload (max. size: 50MB)
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const UploadSizeError = props => {
    return (
        <div
            className="modal fade"
            id="uploadError"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="uploadErrorModal"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="uploadErrorModal">
                            Upload error
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <span>Video/Photo size exceed the file size limit. Video max 50MB, Photo max 5MB.</span>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            id="modalConfirmBtn"
                            title="Confirm"
                            className="btn btn-primary"
                            data-dismiss="modal">
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const ChangeDeviceVipStatusModal = props => {
    const onConfirm = () => {
        props.onConfirm();
    };
    return (
        <div
            className="modal fade"
            id="changeDeviceVipStatusWindow"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="changeDeviceVipStatusModal"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="changeDeviceVipStatusModal">
                            Change Device VIP Status
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">Are you sure you want to change the device VIP status?</div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            id="modalCancelBtn"
                            title="Cancel"
                            className="btn btn-secondary"
                            data-dismiss="modal">
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            id="deleteMsgBtn"
                            data-dismiss="modal"
                            onClick={onConfirm}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};