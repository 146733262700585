import React, { Component } from "react";
import * as emoji from "node-emoji";
import "./accountSettingUi.css";
import webApi from "../api/webApi";
import fileApi from "../api/fileApi";
import appConstants from "../appConstants";
import AppEvent from "../appEvent";
import appEnums from "../appEnums";
import { toast } from "react-toastify";
import commonApi from "../api/commonApi";

export default class AccountSettingUi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyName: "",
            initialCompanyName: "",
            accountName: "",
            initialAccountName: "",
            accountEmail: "",
            initialAccountEmail: "",
            accountProfilePic: "",
            initialAccountProfilePic: "",
            toastMessage: "",
            toastType: toast.TYPE.ERROR,
            profileKey: ""
        };

        this.toastId = "__ACCOUNT_SETTINGS_PAGE__";
        this.saveMessage = "Saved";
        this.savingMessage = "Saving...";
        this.changesCanceledMessage = "Changes Canceled";

        this.handleCompanyNameChange = event => this.setState({ companyName: event.target.value });
        this.handleAccountNameChange = event => this.setState({ accountName: event.target.value });
        this.handleAccountEmailChange = event => this.setState({ accountEmail: emoji.strip(event.target.value) });
        this.dismissError = () => this.setState({ toastMessage: "", toastType: toast.TYPE.ERROR });
    }

    componentDidMount() {
        const { hcAccountId, hcCompanyId, token } = this.props;
        webApi
            .fetchGetCompanySetting(hcAccountId, hcCompanyId, token)
            .then(this.onFetchCompanySetting)
            .catch(errDict => this.setState({ toastMessage: errDict.error }));
    }

    componentDidUpdate(prevProps, prevState) {
        const companyNameChange = this.state.companyName !== this.state.initialCompanyName;
        const accountNameChange = this.state.accountName !== this.state.initialAccountName;
        const accountEmailChange = this.state.accountEmail !== this.state.initialAccountEmail;
        const accountProfileChange = this.state.accountProfilePic !== this.state.initialAccountProfilePic;
        const isSaving = this.state.toastMessage === this.savingMessage;
        const somethingChange = companyNameChange || accountNameChange || accountEmailChange || isSaving || accountProfileChange;
        const { appEvent } = this.props;
        if (appEvent) {
            if (somethingChange) {
                appEvent.fireEvent(AppEvent.EVENT_SET_UNSAVED_CHANGES);
            } else {
                appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
            }
        }
        if (this.state.toastMessage !== prevState.toastMessage && this.state.toastMessage) {
            commonApi.displayToast(this.state.toastMessage, this.toastId, this.state.toastType, {
                type: this.state.toastType,
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                pauseOnFocusLoss: false,
                onClose: this.dismissError,
                onClick: this.dismissError
            });
        }
    }

    componentWillUnmount() {
        const { appEvent } = this.props;
        toast.dismiss(this.toastId);
        if (appEvent != null) {
            appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
        }
    }

    onFetchCompanySetting = resDict => {
        const { setProfileImage } = this.props;
        this.setState(
            {
                companyName: resDict.companyName,
                initialCompanyName: resDict.companyName,
                accountName: resDict.accountName,
                initialAccountName: resDict.accountName,
                accountEmail: resDict.accountEmail,
                initialAccountEmail: resDict.accountEmail,
                accountProfilePic: resDict.profileImage ? resDict.profileImage : "",
                initialAccountProfilePic: resDict.profileImage ? resDict.profileImage : "",
                profileKey: resDict.profileKey ? resDict.profileKey : ""
            },
            () => setProfileImage(this.state.accountProfilePic)
        );
    };

    handleSubmit = event => {
        event.preventDefault();
        if (!this.state.companyName) {
            return this.setState({ toastMessage: "Company name is required", toastType: toast.TYPE.ERROR });
        }
        if (!this.state.accountName) {
            return this.setState({ toastMessage: "Account name is required", toastType: toast.TYPE.ERROR });
        }
        if (!this.state.accountEmail) {
            return this.setState({ toastMessage: "Email is required", toastType: toast.TYPE.ERROR });
        }
        const { hcAccountId, hcCompanyId, token } = this.props;
        webApi
            .fetchChangeCompanySetting(
                hcAccountId,
                hcCompanyId,
                emoji.strip(this.state.companyName),
                emoji.strip(this.state.accountName),
                token
            )
            .then(this.onChangeCompanySetting)
            .then(() => {
                const profileImageChange =
                    (this.state.accountProfilePic &&
                        this.state.initialAccountProfilePic &&
                        this.state.accountProfilePic !== this.state.initialAccountProfilePic) ||
                    (!this.state.initialAccountProfilePic && this.state.accountProfilePic);

                const profileImageDeleted = !this.state.accountProfilePic && this.state.initialAccountProfilePic;

                if (profileImageChange) {
                    this.uploadProfileImage();
                } else if (profileImageDeleted) {
                    this.deleteProfileImage();
                } else {
                    this.setState({ toastMessage: this.saveMessage, toastType: toast.TYPE.SUCCESS });
                }
            })
            .catch(errDict => this.setState({ toastMessage: errDict.error }));
    };

    uploadProfileImage = () => {
        const { appWebSocket, hcAccountId, hcCompanyId, token } = this.props;

        if (appWebSocket != null) {
            this.setState({ toastMessage: this.savingMessage, toastType: toast.TYPE.INFO });
        }

        appWebSocket
            .uploadProfileImage(hcAccountId, hcCompanyId, this.state.accountProfilePic, webApi.userIP, token)
            .then(res => {
                this.setState(
                    {
                        accountProfilePic: res.profileImage,
                        profileKey: res.profileKey,
                        initialAccountProfilePic: res.profileImage,
                        toastMessage: this.saveMessage,
                        toastType: toast.TYPE.SUCCESS
                    },
                    () => {
                        this.props.setProfileImage(res.profileImage);
                        this.props.getNewAccessTokens({ token, dataToken: res.dataToken });
                    }
                );
            })
            .catch(error => this.setState({ toastMessage: error.error }));
    };

    handleCancelButton = event => {
        event.preventDefault();
        const { hcAccountId, hcCompanyId, token } = this.props;
        webApi
            .fetchGetCompanySetting(hcAccountId, hcCompanyId, token)
            .then(this.onFetchCompanySetting)
            .catch(errDict => this.setState({ toastMessage: errDict.error }));
        this.setState({ toastMessage: this.changesCanceledMessage, toastType: toast.TYPE.WARNING });
    };

    onChangeCompanySetting = resDict => {
        this.setState({
            initialAccountEmail: this.state.accountEmail,
            initialAccountName: this.state.accountName,
            initialCompanyName: this.state.companyName
        });
    };

    onChangePassword = () => {
        const { onChangePassword } = this.props;
        onChangePassword(this.state.accountEmail);
    };

    setProfilePhoto = event => {
        fileApi.convertFileToDataURL(event.target.files[0]).then(stringBase64 => {
            this.setState({ accountProfilePic: stringBase64 });
        });
    };

    unsetProfilePhoto = event => {
        event.preventDefault();
        this.setState({ accountProfilePic: "" });
    };

    deleteProfileImage = () => {
        const { hcAccountId, hcCompanyId, token } = this.props;
        webApi
            .fetchDeleteProfileImage(hcAccountId, hcCompanyId, this.state.profileKey, token)
            .then(this.onDeleteProfileImage)
            .catch(errDict => this.setState({ toastMessage: errDict.error }));
    };

    onDeleteProfileImage = resDict => {
        const { setProfileImage } = this.props;
        this.setState(
            {
                toastMessage: this.saveMessage,
                toastType: toast.TYPE.SUCCESS,
                accountProfilePic: "",
                profileKey: "",
                initialAccountProfilePic: ""
            },
            () => setProfileImage(appConstants.defaultProfilePic)
        );
    };

    render() {
        return (
            <div className="ui2-container-div background-color-v2 slide-up-animation">
                <div className="account-setting-main-div">
                    <p className="account-main-heading">PROFILE SETTING</p>
                    <span className="settings-input-label">Profile Picture</span>
                    <div className="profile-container-div">
                        <img
                            src={this.state.accountProfilePic || appConstants.defaultProfilePic}
                            alt="User Profile"
                            name={this.state.accountName}
                            id="profilePicture"
                            ref={n => (this.imgNode = n)}
                            onError={() => (this.imgNode.src = appConstants.defaultProfilePic)}
                        />
                        <div id="profileOptions">
                            <label htmlFor="profilePicButton" className="profile-input-label"></label>
                            <input
                                type="image"
                                src="/images/settings/btn_delete.png"
                                alt="delete profile button"
                                id="deleteProfilePicButton"
                                onClick={this.unsetProfilePhoto}
                            />
                        </div>
                        <input
                            type="file"
                            alt="edit profile button"
                            id="profilePicButton"
                            accept="image/*"
                            onClick={event => (event.target.value = null)}
                            onChange={this.setProfilePhoto}
                        />
                    </div>
                    <p />
                    <span className="settings-input-label">Company Name</span>
                    <input
                        id="companyNameInput"
                        value={this.state.companyName}
                        className="account-setting-text-input"
                        onChange={this.handleCompanyNameChange}
                        readOnly={this.props.role !== appEnums.Role.Owner}
                    />
                    <p />
                    <span className="settings-input-label">Name</span>
                    <input
                        id="companyAccountNameInput"
                        value={this.state.accountName}
                        className="account-setting-text-input"
                        onChange={this.handleAccountNameChange}
                        autoComplete="off"
                    />
                    <p />
                    <span className="settings-input-label">Email Address&nbsp;</span>
                    <input
                        id="accountEmailAddressInput"
                        value={this.state.accountEmail}
                        className="account-setting-text-input"
                        placeholder="name@helpchatter"
                        onChange={this.handleAccountEmailChange}
                        type="email"
                        readOnly={true}
                    />
                    <input onClick={this.onChangePassword} className="change-password-button" value="Change Password" type="button" />
                    <form onSubmit={this.handleSubmit} className="account-setting-form">
                        <input
                            id="accountSettingSubmitButton"
                            type="submit"
                            className="account-setting-main-button"
                            value="SAVE"
                            alt="Save Changes"
                        />
                        <input
                            id="accountSettingCancelChangesButton"
                            type="button"
                            value="CANCEL"
                            alt="Cancel Changes"
                            className="account-setting-main-button"
                            onClick={this.handleCancelButton}
                        />
                    </form>
                </div>
            </div>
        );
    }
}
