import React, { useState } from "react"
import appEnums from "../appEnums";
import Select from "react-select";
import { uniqueId } from "lodash";

export default function GuestRoleForm({
    guestAppNamesOptions,
    guestCanAccessAllApps,
    setGuestCanAccessAllApps,
    guestAppNames,
    setGuestAppNames,
    guestPermissionLevel,
    setGuestPermissionLevel,
}) {
    const [formId] = useState(() => uniqueId())

    return (
        <>
            <div className="mt-3 form-row">
                <div className="col-12">
                    <span className="mr-2">Can access</span>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name={`guestAppAccess${formId}`}
                            value="limited"
                            id={`guestAppAccessLimited${formId}`}
                            checked={!guestCanAccessAllApps}
                            onChange={() => setGuestCanAccessAllApps(false) }
                        />
                        <label className="form-check-label" htmlFor={`guestAppAccessLimited${formId}`}>Limited Apps</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            type="radio"
                            name={`guestAppAccess${formId}`}
                            value="all"
                            id={`guestAppAccessAll${formId}`}
                            checked={guestCanAccessAllApps}
                            onChange={() => setGuestCanAccessAllApps(true)}
                        />
                        <label className="form-check-label" htmlFor={`guestAppAccessAll${formId}`}>All Apps</label>
                    </div>
                </div>
            </div>
            <div className="mt-3 form-row">
                <div className="col-6">
                    <label htmlFor="guestAppNames">App(s)</label>
                    <Select
                        inputId="guestAppNames"
                        isMulti
                        isDisabled={guestCanAccessAllApps}
                        value={guestAppNames}
                        onChange={(newValue) => setGuestAppNames(newValue)}
                        options={guestAppNamesOptions ?? null}
                        isLoading={guestAppNamesOptions == null}
                    />
                </div>
                <div className="col-6">
                    <label htmlFor="guestPermissionLevel">Permission Level</label>
                    <Select
                        inputId="guestPermissionLevel"
                        value={guestPermissionLevel}
                        onChange={(newValue) => setGuestPermissionLevel(newValue)}
                        options={guestPermissionLevelOptions}
                    />
                </div>
            </div>
        </>
    )
}

export const guestPermissionLevelOptions = [
    { label: 'Read only', value: appEnums.GuestPermissionLevel.Read },
    { label: 'Read and reply', value: appEnums.GuestPermissionLevel.ReadWrite },
];

const permissionLevelOptionLookup = Object.fromEntries(
    guestPermissionLevelOptions.map(option => [option.value, option])
);

export const getPermissionLevelOption = (value) => {
    return permissionLevelOptionLookup[value];
};
