import appEnums from "../appEnums";
import appConsts from "../appConstants";
import HTMLReactParser from "html-react-parser";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { jwtVerify } from "jose";
import { MD5 } from "crypto-js";
import { escapeRegExp, isEmpty } from "lodash";

const commonApi = {
    isBoolean: function (arg) {
        return typeof arg === "boolean";
    },

    isNumber: function (arg) {
        return typeof arg === "number";
    },

    isInteger: function (arg) {
        return /^-?\d+$/.test(arg);
    },

    isString: function (arg) {
        return typeof arg === "string";
    },

    isFunction: function (arg) {
        return typeof arg === "function";
    },

    isObject: function (arg) {
        return arg !== null && typeof arg === "object";
    },

    isArray: function (arg) {
        return arg instanceof Array;
    },

    generateHash: function (requestContents, key) {
        const requestContentKeys = Object.keys(requestContents).sort();
        let paramsString = "";
        requestContentKeys.forEach(param => {
            if (param !== "hash") {
                if (typeof requestContents[param] === "object") paramsString += JSON.stringify(requestContents[param]);
                else paramsString += requestContents[param];
            }
        });
        return MD5(MD5(key).toString() + paramsString).toString();
    },

    verifyDataToken: async function (payloadString, secretKey) {
        try {
            if (typeof secretKey === "string") {
                secretKey = new TextEncoder().encode(secretKey);
            }
            return (await jwtVerify(payloadString, secretKey)).payload;
        } catch (error) {
            return null;
        }
    },

    safeJsonParse: function (jsonString) {
        try {
            return JSON.parse(jsonString);
        } catch (error) {
            return {};
        }
    },

    checkUser: function (dict1, dict2) {
        return dict1.bundleId === dict2.bundleId && dict1.deviceId === dict2.deviceId;
    },

    displayToast: function (message, toastId, type, options) {
        if (toast.isActive(toastId)) toast.update(toastId, { render: message, ...options });
        else toast(message, { toastId, type, ...options });
    },

    dismissToast: function (toastId) {
        if (toast.isActive(toastId)) {
            toast.dismiss(toastId);
        }
    },

    getRandomString: function (length = 8) {
        const array = new Uint8Array(length);
        globalThis.crypto.getRandomValues(array);
        return [...array].map(b => b.toString(16).padStart(2, "0")).join("");
    },

    // https://www.theiphonewiki.com/wiki/Models
    // https://github.com/pluwen/Apple-Device-Model-list
    mapToDevice: function (deviceModel) {
        switch (deviceModel) {
            // iPhone
            case "iPhone1,1":
                return "iPhone";
            case "iPhone1,2":
                return "iPhone 3G";
            case "iPhone2,1":
                return "iPhone 3GS";
            case "iPhone3,1":
            case "iPhone3,2":
            case "iPhone3,3":
                return "iPhone 4";
            case "iPhone4,1":
            case "iPhone4,2":
            case "iPhone4,3":
                return "iPhone 4S";
            case "iPhone5,1":
            case "iPhone5,2":
                return "iPhone 5";
            case "iPhone5,3":
            case "iPhone5,4":
                return "iPhone 5C";
            case "iPhone6,1":
            case "iPhone6,2":
                return "iPhone 5S";
            case "iPhone7,2":
                return "iPhone 6";
            case "iPhone7,1":
                return "iPhone 6 Plus";
            case "iPhone8,1":
                return "iPhone 6S";
            case "iPhone8,2":
                return "iPhone 6S Plus";
            case "iPhone8,4":
                return "iPhone SE";
            case "iPhone9,1":
            case "iPhone9,3":
                return "iPhone 7";
            case "iPhone9,2":
            case "iPhone9,4":
                return "iPhone 7 Plus";
            case "iPhone10,1":
            case "iPhone10,4":
                return "iPhone 8";
            case "iPhone10,2":
            case "iPhone10,5":
                return "iPhone 8 Plus";
            case "iPhone10,3":
            case "iPhone10,6":
                return "iPhone X";
            case "iPhone11,2":
                return "iPhone XS";
            case "iPhone11,4":
            case "iPhone11,6":
                return "iPhone XS Max";
            case "iPhone11,8":
                return "iPhone XR";
            case "iPhone12,1":
                return "iPhone 11";
            case "iPhone12,3":
                return "iPhone 11 Pro";
            case "iPhone12,5":
                return "iPhone 11 Pro Max";

            // iPad
            case "iPad1,1":
                return "iPad";
            case "iPad2,1":
            case "iPad2,2":
            case "iPad2,3":
            case "iPad2,4":
                return "iPad 2";
            case "iPad3,1":
            case "iPad3,2":
            case "iPad3,3":
                return "iPad 3";
            case "iPad3,4":
            case "iPad3,5":
            case "iPad3,6":
                return "iPad 4";
            case "iPad6,11":
            case "iPad6,12":
                return "iPad 5";
            case "iPad7,5":
            case "iPad7,6":
                return "iPad 6";
            case "iPad7,11":
            case "iPad7,12":
                return "iPad 7";
            case "iPad4,1":
            case "iPad4,2":
            case "iPad4,3":
                return "iPad Air";
            case "iPad5,3":
            case "iPad5,4":
                return "iPad Air 2";
            case "iPad11,3":
            case "iPad11,4":
                return "iPad Air 3";
            case "iPad2,5":
            case "iPad2,6":
            case "iPad2,7":
                return "iPad Mini";
            case "iPad4,4":
            case "iPad4,5":
            case "iPad4,6":
                return "iPad Mini 2";
            case "iPad4,7":
            case "iPad4,8":
            case "iPad4,9":
                return "iPad Mini 3";
            case "iPad5,1":
            case "iPad5,2":
                return "iPad Mini 4";
            case "iPad11,1":
            case "iPad11,2":
                return "iPad Mini 5";
            case "iPad6,3":
            case "iPad6,4":
                return "iPad Pro 9.7-inch";
            case "iPad7,3":
            case "iPad7,4":
                return "iPad Pro 10.5-inch";
            case "iPad8,1":
            case "iPad8,2":
            case "iPad8,3":
            case "iPad8,4":
                return "iPad Pro 11-inch";
            case "iPad8,9":
            case "iPad8,10":
                return "iPad Pro 11-inch 2";
            case "iPad6,7":
            case "iPad6,8":
                return "iPad Pro 12.9-inch";
            case "iPad7,1":
            case "iPad7,2":
                return "iPad Pro 12.9-inch 2";
            case "iPad8,5":
            case "iPad8,6":
            case "iPad8,7":
            case "iPad8,8":
                return "iPad Pro 12.9-inch 3";
            case "iPad8,11":
            case "iPad8,12":
                return "iPad Pro 12.9-inch 4";

            // iPod Touch
            case "iPod1,1":
                return "iPod Touch";
            case "iPod2,1":
                return "iPod Touch 2";
            case "iPod3,1":
                return "iPod Touch 3";
            case "iPod4,1":
                return "iPod Touch 4";
            case "iPod5,1":
                return "iPod Touch 5";
            case "iPod7,1":
                return "iPod Touch 6";
            case "iPod9,1":
                return "iPod Touch 7";

            // AirPods
            case "AirPods1,1":
                return "AirPods";
            case "AirPods2,1":
                return "AirPods 2";
            case "iProd8,1":
                return "AirPods Pro";

            // Apple TV
            case "AppleTV2,1":
                return "Apple TV 2";
            case "AppleTV3,1":
            case "AppleTV3,2":
                return "Apple TV 3";
            case "AppleTV5,3":
                return "Apple TV 4";
            case "AppleTV6,2":
                return "Apple TV 4K";

            // Apple Watch
            case "Watch1,1":
            case "Watch1,2":
                return "Apple Watch";
            case "Watch2,6":
            case "Watch2,7":
                return "Apple Watch Series 1";
            case "Watch2,3":
            case "Watch2,4":
                return "Apple Watch Series 2";
            case "Watch3,1":
            case "Watch3,2":
            case "Watch3,3":
            case "Watch3,4":
                return "Apple Watch Series 3";
            case "Watch4,1":
            case "Watch4,2":
            case "Watch4,3":
            case "Watch4,4":
                return "Apple Watch Series 4";
            case "Watch5,1":
            case "Watch5,2":
            case "Watch5,3":
            case "Watch5,4":
                return "Apple Watch Series 5";

            // HomePod
            case "AudioAccessory1,1":
            case "AudioAccessory1,2":
                return "HomePod";

            // Simulator
            case "i386":
            case "x86_64":
                return "Simulator";

            default:
                return deviceModel;
        }
    },

    getChatSurveyStarValue: function (chatSurvey) {
        const star = chatSurvey & ~appEnums.ChatSurveyStates.Finish;
        if (star === appEnums.ChatSurveyStates.Star0) {
            return 0;
        }
        return star;
    },

    getPlatformIconSrc: function (platform) {
        let src = null;
        switch (platform) {
            case appEnums.Platform.iOS:
                src = appConsts.iosPlatformIcon;
                break;
            case appEnums.Platform.AndroidGoogle:
            case appEnums.Platform.AndroidAmazon:
                src = appConsts.androidPlatformIcon;
                break;
            case appEnums.Platform.Unity:
                src = appConsts.unityPlatformIcon;
                break;
            default:
                src = "";
                break;
        }
        return src;
    },

    parseHtmlString: function (string) {
        let result = string;
        try {
            let clean = DOMPurify.sanitize(string, { USE_PROFILES: { html: true } });
            result = HTMLReactParser(clean);
        } catch (error) {
            console.error(error);
        }
        return result;
    },

    parseHtmlStringHighlight: function (string, searchText = "") {
        // replace new lines with whitespace first...
        const strippedString = string.replace(/(\r\n|\n|\r)/gm, ' ');
        let result = this.encodeHTMLEntities(strippedString);
        try {
            let clean = result;
            if (searchText) {
                let searchTextSplit = searchText
                    .trim()
                    .split(" ")
                    .filter(word => !isEmpty(word));
                clean = this.Highlight(clean, searchTextSplit);
            }
            result = HTMLReactParser(clean);
        } catch (error) {
            console.error(error);
        }
        return result;
    },

    Highlight: function (text = "", tags = []) {
        if (tags.length === 0) return text;
        const matches = [...text.matchAll(new RegExp(tags.map(tag => escapeRegExp(tag)).join("|"), "ig"))];
        const startText = text.slice(0, matches[0]?.index);
        let result = startText;

        matches.forEach((match, i) => {
            const startIndex = match.index;
            const currentText = match[0];
            const endIndex = startIndex + currentText.length;
            const nextIndex = matches[i + 1]?.index;
            const untilNextText = text.slice(endIndex, nextIndex);
            const markText = !isEmpty(currentText) ? `<mark class="bg-mark">${currentText}</mark>` : "";

            result += `<span key=${i}>${markText}${untilNextText}</span>`;
        });

        return result;
    },

    removeElemHtmlTag: function (string) {
        return string.replace(/(<([^>]+)>)/gi, "");
    },

    isChatSurveyFinished: function (chatSurvey) {
        return (chatSurvey & appEnums.ChatSurveyStates.Finish) === appEnums.ChatSurveyStates.Finish;
    },

    encodeHTMLEntities: function (text) {
        var textArea = document.createElement("textarea");
        textArea.innerText = text;
        return textArea.innerHTML;
    },

    canParseURL: function (url, base) {
        const urlString = String(url);
        const baseArg = base === undefined ? undefined : String(base);

        try {
            return !!new URL(urlString, baseArg);
        } catch (error) {
            return false;
        }
    },
};

export default commonApi;
