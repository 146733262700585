import React from "react";
import "./menuUi.css";
import appEnums from "../appEnums";
import appConstants from "../appConstants";
import { Link } from "@reach/router";
import webApi from "../api/webApi";

const { Faq, HomeDashboardMetrics, DeveloperConsole, Help, GeneralSettings, CreateApp, AppSetting, Message } = appEnums.RootPage;

export default class MenuUi extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasNewMessage: false };
    }

    onFetchHasNewMessage = resDict => this.setState({ hasNewMessage: resDict.hasNewMessage });

    indicatorBarUi = (
        <img title="Indicator bar" alt="Indicator bar" src="/images/menu-bar/homepage_indicator.png" className="indicator-bar" />
    );

    fetchHasNewMessage = () => {
        const { bundleId, token } = this.props;
        webApi
            .fetchHasNewMessage(bundleId, token)
            .then(this.onFetchHasNewMessage)
            .catch(() => this.setState({ hasNewMessage: false }));
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.bundleId !== this.props.bundleId) {
            this.fetchHasNewMessage();
        }
    }

    componentDidMount() {
        this.fetchHasNewMessage();
    }

    onClickPriorityNewMessages = () => {
        const { setMessageStateFilter } = this.props;
        setMessageStateFilter(appEnums.MessageStates.PriorityNew);
    };

    onClickNewMessages = () => {
        const { setMessageStateFilter } = this.props;
        setMessageStateFilter(appEnums.MessageStates.New);
    };

    onClickPriorityOpenMessages = () => {
        const { setMessageStateFilter } = this.props;
        setMessageStateFilter(appEnums.MessageStates.PriorityOpen);
    };

    onClickOpenMessages = () => {
        const { setMessageStateFilter } = this.props;
        setMessageStateFilter(appEnums.MessageStates.Open);
    };

    onClickPendingMessages = () => {
        const { setMessageStateFilter } = this.props;
        setMessageStateFilter(appEnums.MessageStates.Pending);
    };

    onClickClosedMessages = () => {
        const { setMessageStateFilter } = this.props;
        setMessageStateFilter(appEnums.MessageStates.Closed);
    };

    onClickLogoutButton = () => {
        const { isLogin, onLogoutCallback } = this.props;
        if (isLogin && onLogoutCallback) {
            onLogoutCallback();
        }
    };

    onClickMessageButton = () => {
        const { rootPage, setMessageStateFilter } = this.props;
        if (rootPage === Message) setMessageStateFilter(appEnums.MessageStates.New);
        else this.props.onShowMenu(Message);
    };

    onClickFaqButton = () => {
        this.props.onShowMenu(Faq);
    };

    onClickAnalyticsButton = () => {
        this.props.onShowMenu(HomeDashboardMetrics);
    };

    onClickHelpButton = () => {
        this.props.onShowMenu(Help);
    };

    onClickSettingsButton = () => {
        this.props.onShowMenu(GeneralSettings);
    };

    onClickConsoleButton = () => {
        this.props.onShowMenu(DeveloperConsole);
    };

    indicatorBarGenerator = isClicked => {
        if (isClicked) return this.indicatorBarUi;
        return null;
    };

    render() {
        const { rootPage, isLogin, messageStateFilter, role } = this.props;
        const { priorityNewMessageCount, newMessageCount, priorityOpenMessageCount, openMessageCount, closedMessageCount, pendingMessageCount } = this.props;

        const { PriorityNew, New, PriorityOpen, Open, Closed, Pending } = appEnums.MessageStates;
        const isFaqClicked = rootPage === Faq;
        const isAnalyticsClicked = rootPage === HomeDashboardMetrics;
        const isConsoleClicked = rootPage === DeveloperConsole || rootPage === CreateApp || rootPage === AppSetting;
        const isHelpClicked = rootPage === Help;
        const isSettingsClicked = rootPage === GeneralSettings;

        const isMessageClicked = rootPage === Message;
        const isPriorityNewClicked = messageStateFilter === PriorityNew;
        const isNewClicked = messageStateFilter === New;
        const isPriorityOnGoingClicked = messageStateFilter === PriorityOpen;
        const isOnGoingClicked = messageStateFilter === Open;
        const isPendingClicked = messageStateFilter === Pending;
        const isClosedClicked = messageStateFilter === Closed;

        const approxPriorityNewMessageCount = approximateNumber(priorityNewMessageCount);
        const approxNewMessageCount = approximateNumber(newMessageCount);
        const approxPriorityOpenMessageCount = approximateNumber(priorityOpenMessageCount);
        const approxOpenMessageCount = approximateNumber(openMessageCount);
        const approxClosedMessageCount = approximateNumber(closedMessageCount);
        const approxPendingMessageCount = approximateNumber(pendingMessageCount);

        const messageNotificationIcon = this.state.hasNewMessage ? (
            <img src="/images/menu-bar/icon_notif.png" alt="Message Icon Notification" className="message-notification-icon" />
        ) : null;

        const messagesIcon = (
            <div className="menu-icon-container">
                {messageNotificationIcon}
                <img
                    src="/images/menu-bar/btn_messages.png"
                    alt="Messages"
                    title="Messages"
                    className="menu-icon"
                    id="messageMenuButton"
                    onClick={this.onClickMessageButton}
                />
            </div>
        );

        const faqIcon = (
            role !== appEnums.Role.Guest && <div className="menu-icon-container">
                {this.indicatorBarGenerator(isFaqClicked)}
                <Link to="/">
                    <img
                        src="/images/menu-bar/btn_faq.png"
                        alt="FAQ"
                        title="FAQ"
                        id="faqButton"
                        className="menu-icon"
                        onClick={this.onClickFaqButton}
                    />
                </Link>
            </div>
        );

        const analyticsIcon = (
            role !== appEnums.Role.Guest && <div className="menu-icon-container">
                {this.indicatorBarGenerator(isAnalyticsClicked)}
                <Link to="/">
                    <img
                        src="/images/menu-bar/btn_stats.png"
                        alt="Analytics"
                        title="Analytics"
                        id="analyticsButton"
                        className="menu-icon"
                        onClick={this.onClickAnalyticsButton}
                    />
                </Link>
            </div>
        );

        const consoleIcon =
            ![appEnums.Role.Agent, appEnums.Role.Guest].includes(role) ? (
                <div className="menu-icon-container">
                    {this.indicatorBarGenerator(isConsoleClicked)}
                    <Link to="/">
                        <img
                            src="/images/menu-bar/btn_developers_console.png"
                            alt="Developer's console"
                            title="Developer's console"
                            id="consoleButton"
                            className="menu-icon"
                            onClick={this.onClickConsoleButton}
                        />
                    </Link>
                </div>
            ) : null;

        const helpIcon = (
            role !== appEnums.Role.Guest && <div className="menu-icon-container">
                {this.indicatorBarGenerator(isHelpClicked)}
                <Link to="/">
                    <img
                        src="/images/menu-bar/btn_about.png"
                        alt="Help"
                        title="Help"
                        className="menu-icon"
                        id="helpMenuButton"
                        onClick={this.onClickHelpButton}
                    />
                </Link>
            </div>
        );

        const generalSettingsIcon = (
            <div className="menu-icon-container">
                {this.indicatorBarGenerator(isSettingsClicked)}
                <Link to="/">
                    <img
                        src="/images/menu-bar/btn_settings.png"
                        alt="General Settings"
                        title="General Settings"
                        id="generalSettingsButton"
                        className="menu-icon"
                        onClick={this.onClickSettingsButton}
                    />
                </Link>
            </div>
        );

        const logoutIcon = isLogin ? (
            <div className="menu-icon-container">
                <Link to="/">
                    <img
                        src="/images/menu-bar/btn_logout.png"
                        alt="Logout"
                        title="Logout"
                        className="menu-icon"
                        id="logoutMenuButton"
                        onClick={this.onClickLogoutButton}
                    />
                </Link>
            </div>
        ) : null;

        const profileImage = (
            <div className="position-relative">
                <img src="/images/menu-bar/status_online.png" alt="online status" className="online-user-bullet" />
                <img
                    src={this.props.profileImage || appConstants.defaultProfilePic}
                    alt="user profile"
                    title="your image"
                    className="menu-profile-pic"
                    ref={n => (this.imgNode = n)}
                    onError={() => (this.imgNode.src = appConstants.defaultProfilePic)}
                />
            </div>
        );

        return (
            <div className="menu-main-container">
                <div className="menu-top-section">
                    {profileImage}
                    {messagesIcon}
                    <div id="messageFilters">
                        {isMessageClicked && (
                            <>
                                <div className="message-filter-container" onClick={this.onClickPriorityNewMessages}>
                                    {this.indicatorBarGenerator(isPriorityNewClicked)}
                                    <img
                                        src="/images/menu-bar/message_prio-new.png"
                                        alt="New Message Indicator"
                                        className="mesage-indicator-bullet"
                                    />
                                    <button id="priorityNewIssuesBtn" title={`Priority New Issues: ${priorityNewMessageCount}`}>
                                        Pri New
                                    </button>
                                    <div className="message-count-div">
                                        <span className="message-count-text" title={priorityNewMessageCount}>{approxPriorityNewMessageCount}</span>
                                    </div>
                                </div>
                                <div className="message-filter-container" onClick={this.onClickNewMessages}>
                                    {this.indicatorBarGenerator(isNewClicked)}
                                    <img
                                        src="/images/menu-bar/message_new_issues.png"
                                        alt="New Message Indicator"
                                        className="mesage-indicator-bullet"
                                    />
                                    <button id="newIssuesBtn" title={`New Issues: ${newMessageCount}`}>
                                        New
                                    </button>
                                    <div className="message-count-div">
                                        <span className="message-count-text" title={newMessageCount}>{approxNewMessageCount}</span>
                                    </div>
                                </div>
                                <div className="message-filter-container" onClick={this.onClickPriorityOpenMessages}>
                                    {this.indicatorBarGenerator(isPriorityOnGoingClicked)}
                                    <img
                                        src="/images/menu-bar/message_prio-open.png"
                                        alt="On Going Message Indicator"
                                        className="mesage-indicator-bullet"
                                    />
                                    <button id="onGoingIssuesBtn" title={`Priority OnGoing Issues: ${priorityOpenMessageCount}`}>
                                        Pri Open
                                    </button>
                                    <div className="message-count-div">
                                        <span className="message-count-text" title={priorityOpenMessageCount}>{approxPriorityOpenMessageCount}</span>
                                    </div>
                                </div>
                                <div className="message-filter-container" onClick={this.onClickOpenMessages}>
                                    {this.indicatorBarGenerator(isOnGoingClicked)}
                                    <img
                                        src="/images/menu-bar/message_ongoing.png"
                                        alt="On Going Message Indicator"
                                        className="mesage-indicator-bullet"
                                    />
                                    <button id="onGoingIssuesBtn" title={`OnGoing Issues: ${openMessageCount}`}>
                                        Open
                                    </button>
                                    <div className="message-count-div">
                                        <span className="message-count-text" title={openMessageCount}>{approxOpenMessageCount}</span>
                                    </div>
                                </div>
                                <div className="message-filter-container" onClick={this.onClickPendingMessages}>
                                    {this.indicatorBarGenerator(isPendingClicked)}
                                    <img
                                        src="/images/menu-bar/message_pending.png"
                                        alt="On Going Message Indicator"
                                        className="mesage-indicator-bullet"
                                    />
                                    <button id="pendingIssuesBtn" title={`Pending Issues: ${pendingMessageCount}`}>
                                        Pending
                                    </button>
                                    <div className="message-count-div">
                                        <span className="message-count-text" title={pendingMessageCount}>{approxPendingMessageCount}</span>
                                    </div>
                                </div>
                                <div className="message-filter-container" onClick={this.onClickClosedMessages}>
                                    {this.indicatorBarGenerator(isClosedClicked)}
                                    <img
                                        src="/images/menu-bar/message_closed.png"
                                        alt="Closed Message Indicator"
                                        className="mesage-indicator-bullet"
                                    />
                                    <button id="closedIssuesBtn" title={`Closed Issues: ${closedMessageCount}`}>
                                        Closed
                                    </button>
                                    <div className="message-count-div">
                                        <span className="message-count-text" title={closedMessageCount}>{approxClosedMessageCount}</span>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {faqIcon}
                    {analyticsIcon}
                    {consoleIcon}
                </div>
                <div className="menu-bottom-section">
                    {helpIcon}
                    {generalSettingsIcon}
                    {logoutIcon}
                </div>
            </div>
        );
    }
}

function approximateNumber(num) {
    if (typeof num != 'number') {
        return 0;
    }

    const THOUSAND = 1000;
    const TEN_THOUSAND = 10000;
    const MILLION = 1000000;

    if (num < TEN_THOUSAND) {
        return `${num}`;
    } else if (num < MILLION) {
        const rounded = Math.round(num / THOUSAND);
        if (rounded >= THOUSAND) {
            return '1m';
        }
        return `${rounded}k`;
    } else {
        return `${Math.round(num / MILLION)}m`;
    }
}
