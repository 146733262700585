import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import commonApi from "../api/commonApi";
import webApi from "../api/webApi";
import appConstants from "../appConstants";

import LANG_CODES from "../language-codesISO639-1.json";

export default class AutomationTranslationUi extends React.Component {
    constructor(props) {
        super(props);
        this.state = { messages: [], message: "", languageCode: "", showForm: false, updateModal: null };
    }

    modalToggle = null;

    showForm = event => {
        this.setState({ showForm: true });
    };

    closeForm = event => {
        this.setState({ showForm: false, message: "", languageCode: "" });
    };

    toastId = "__Automation_Translation__";

    componentDidMount() {
        const { bundleId } = this.props;
        this.fetchAutoMessages(bundleId);
    }

    fetchAutoMessages = bundleId => {
        const { hcCompanyId, token, messageType } = this.props;
        webApi
            .fetchGetAutomationMessages(hcCompanyId, bundleId, messageType, token)
            .then(messages => {
                this.setState({ messages });
            })
            .catch(error => {
                commonApi.displayToast(error.error, this.toastId, toast.TYPE.ERROR);
            });
    };

    componentWillUnmount() {
        commonApi.dismissToast(this.toastId);
    }

    onChangeMessage = event => {
        this.setState({ message: event.target.value });
    };

    onChangeLangCode = event => {
        this.setState({ languageCode: event.target.value });
    };

    onSubmit = event => {
        event.preventDefault();
        const { hcCompanyId, bundleId, token, messageType } = this.props;
        const { languageCode, message } = this.state;
        webApi
            .fetchAddAutomationMessage(hcCompanyId, bundleId, messageType, languageCode, message, token)
            .then(() => {
                this.closeForm();
                this.fetchAutoMessages(bundleId);
            })
            .catch(error => {
                commonApi.displayToast(error.error, this.toastId, toast.TYPE.ERROR);
            });
    };

    onDeleteMessage = event => {
        const { languagecode, bundleid, type } = event.target.dataset;
        const { hcCompanyId, token } = this.props;
        webApi
            .fetchDeleteAutomationMessage(hcCompanyId, bundleid, type, languagecode, token)
            .then(() => {
                const { messages } = this.state;
                const updated = messages.filter(data => {
                    return data.languageCode !== languagecode;
                });
                this.setState({ messages: updated });
            })
            .catch(error => {
                commonApi.displayToast(error.error, this.toastId, toast.TYPE.ERROR);
            });
    };

    form = () => {
        return (
            <form onSubmit={this.onSubmit} className="row my-4 pl-n4 automation-translated-row">
                <div className="col-3">
                    <select
                        className="form-control d-inline-block bg-white"
                        onChange={this.onChangeLangCode}
                        value={this.state.languageCode}
                        required>
                        <option value="">Choose a language</option>
                        {LANG_CODES.map(lang => {
                            return (
                                <option key={lang.code} value={lang.code}>
                                    {lang.language}
                                </option>
                            );
                        })}
                    </select>
                </div>
                <div className="col-6">
                    <input
                        type="text"
                        className="form-control d-inline-block bg-white"
                        onChange={this.onChangeMessage}
                        value={this.state.message}
                        maxLength={appConstants.MAX_MESSAGE_ESCAPE_LENGTH}
                        required
                    />
                </div>
                <div className="col-3 automation-localized-option align-items-center">
                    <input type="submit" className="automation-btn" value="SAVE" />
                    <input type="reset" className="automation-btn" value="CANCEL" onClick={this.closeForm} />
                </div>
            </form>
        );
    };

    onModalSubmit = () => {
        this.fetchAutoMessages(this.props.bundleId);
    };

    onClickUpdateBtn = event => {
        const { languagecode, bundleid, type, automessage } = event.target.dataset;
        const { hcCompanyId, token } = this.props;

        this.setState(
            {
                updateModal: (
                    <UpdateModal
                        languageCode={languagecode}
                        hcCompanyId={hcCompanyId}
                        bundleId={bundleid}
                        messageType={type}
                        message={automessage}
                        onCancel={this.onCancelModal}
                        onSubmit={this.onModalSubmit}
                        token={token}
                        toastId={this.toastId}
                    />
                )
            },
            () => {
                this.modalToggle.click();
            }
        );
    };

    onCancelModal = event => {
        this.setState({ updateModal: null });
    };

    render() {
        return (
            <div id="automationTranslation">
                <div className="d-block my-4">
                    {this.state.messages.length > 0 && (
                        <span className="official-text-color d-block mt-2 mb-4 font-weight-bold">Condition:</span>
                    )}
                    {this.state.messages.map(data => {
                        return (
                            <div key={data.languageCode} className="row my-2 pl-n4 automation-translated-row">
                                <div className="col-2">
                                    <span className="official-text-color font-weight-bold">Language is:</span>&nbsp;
                                </div>
                                <div className="col-2">
                                    <select className="form-control d-inline-block" disabled={true} value={data.languageCode}>
                                        {LANG_CODES.map(lang => {
                                            return (
                                                <option key={lang.code} value={lang.code}>
                                                    {lang.language}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                                <div className="col-6">
                                    <input type="text" className="form-control d-inline-block bg-white" disabled value={data.message} />
                                </div>
                                <div className="col-2">
                                    <input
                                        data-languagecode={data.languageCode}
                                        data-bundleid={this.props.bundleId}
                                        data-type={this.props.messageType}
                                        data-automessage={data.message}
                                        onClick={this.onClickUpdateBtn}
                                        type="image"
                                        className="automation-message-btn"
                                        src="/images/settings/btn_edit.png"
                                        alt="edit auto message"
                                    />
                                    <input
                                        data-languagecode={data.languageCode}
                                        data-bundleid={this.props.bundleId}
                                        data-type={this.props.messageType}
                                        type="image"
                                        onClick={this.onDeleteMessage}
                                        className="automation-message-btn"
                                        src="/images/settings/btn_delete.png"
                                        alt="delete auto message"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="my-2 d-block">
                    <button id="addTranslatedAutoMessage" className="automation-btn" onClick={this.showForm}>
                        &nbsp;&nbsp;&nbsp;ADD
                    </button>
                </div>

                {this.state.showForm && this.form()}
                <input
                    type="button"
                    style={{ width: "0.1px", height: "0.1px", visibility: "hidden" }}
                    data-target="#updateTranslationModal"
                    data-toggle="modal"
                    ref={n => (this.modalToggle = n)}
                />
                {this.state.updateModal}
            </div>
        );
    }
}

const UpdateModal = props => {
    const [message, setMessage] = useState("");
    var closeBtn = null;

    useEffect(() => {
        setMessage(props.message);
    }, [props.languageCode, props.message]);

    const onSubmit = event => {
        event.preventDefault();
        const { hcCompanyId, bundleId, languageCode, messageType, token } = props;

        if (message !== props.message) {
            webApi
                .fetchUpdateAutomationMessage(hcCompanyId, bundleId, messageType, languageCode, message, token)
                .then(() => {
                    commonApi.displayToast("Message updated successfully", props.toastId, toast.TYPE.SUCCESS);
                    props.onSubmit();
                    closeBtn.click();
                })
                .catch(error => {
                    commonApi.displayToast(error.error, props.toastId, toast.TYPE.ERROR);
                });
        }
    };

    return (
        <div className="modal fade" tabIndex="-1" data-backdrop="static" id="updateTranslationModal" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Update Message</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.onCancel}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <textarea
                                id="automationTranslationTextArea"
                                className="form-control"
                                type="text"
                                value={message}
                                maxLength={appConstants.MAX_MESSAGE_ESCAPE_LENGTH}
                                onChange={event => setMessage(event.target.value)}
                            />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" disabled={message.trim().length < 3} onClick={onSubmit}>
                            Save
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            ref={n => (closeBtn = n)}
                            onClick={props.onCancel}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
