import events from "events";

class AppEvent {
    constructor() {
        this.eventEmitter = null;
        this.instance = null;
    }

    init(instance) {
        this.instance = instance;
        this.eventEmitter = new events.EventEmitter();
    }
    close() {
        this.instance = null;
        if (this.eventEmitter != null) {
            this.eventEmitter.removeAllListeners();
            this.eventEmitter = null;
        }
    }

    getInstance() {
        return this.instance;
    }

    fireEvent(eventKey, data) {
        if (this.eventEmitter != null) {
            this.eventEmitter.emit(eventKey, data);
        }
    }

    addCallback(eventKey, listener) {
        if (this.eventEmitter != null) {
            this.eventEmitter.on(eventKey, listener);
        }
    }

    removeCallback(eventKey, listener) {
        if (this.eventEmitter != null) {
            this.eventEmitter.removeListener(eventKey, listener);
        }
    }
}

AppEvent.EVENT_FETCH_ERROR = "fetchError";
AppEvent.EVENT_SEND_MESSAGE = "onSendMessage";
AppEvent.EVENT_INTERNAL_NOTES = "onSendInternalNotes";
AppEvent.EVENT_UNSET_UNSAVED_CHANGES = "dismissUnsavedChanges";
AppEvent.EVENT_SET_UNSAVED_CHANGES = "engageUnsavedChanges";
AppEvent.EVENT_CHANGE_MESSAGE_STATE = "changeMessageState";
AppEvent.EVENT_SORT_MESSAGE_LIST_TIMESTAMP = "sortMessageListByTimestamp";

AppEvent.EVENT_MESSAGE_TAG_FILTER = "messageTagFiltering";
AppEvent.EVENT_UPDATE_MESSAGES_COUNT = "changeMessagesCount";
AppEvent.EVENT_FETCH_APP_CONVERSATIONS = "fetchAppConversations";
AppEvent.EVENT_CONVERSATION_STATE_FILTER = "conversationStateFilter";

AppEvent.EVENT_FAQ_SAVE_DRAFT = "faqSaveAsDraft";
AppEvent.EVENT_FAQ_PUBLISH = "faqPublish";

AppEvent.EVENT_FAQ_CREATE = "faqCreate";
AppEvent.EVENT_FAQ_DELETE = "faqDelete";

AppEvent.EVENT_FAQ_SAVE_CHANGES = "faqSaveChanges";
AppEvent.EVENT_FAQ_SAVE_PUBLISH = "faqSavePublish";
AppEvent.EVENT_FAQ_SAVE_UNPUBLISH = "faqSaveUnpublish";
AppEvent.EVENT_FAQ_CLEAR_EDITOR = "faqClearEditor";

AppEvent.EVENT_TRANSLATE = "translate";

export default AppEvent;
