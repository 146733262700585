import React from "react";
import "./autocomplete.css";

const AutoComplete = props => {
    const { renderSuggestion, getSuggestions, inputValue, domId, isSuggestionOpen } = props;
    const computedSuggestions = getSuggestions(inputValue).slice(0, 10);
    const computedSuggestionUi = isSuggestionOpen ? computedSuggestions.map(renderSuggestion) : null;
    return (
        <div className="autocomplete">
            <div id={domId} className="autocomplete-items">
                {computedSuggestionUi}
            </div>
        </div>
    );
};

export default AutoComplete;
