import React, { Component } from 'react';
import './billingUi.css';

class BillingUi extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <div className="background-color-v2 ui2-container-div"></div>;
    }
}

export default BillingUi;
