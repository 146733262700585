import React, { Component } from "react";
import * as emoji from "node-emoji";
import "./createAppUi.css";
import commonApi from "../api/commonApi";
import webApi from "../api/webApi";
import AppEvent from "../appEvent";
import AppEnums from "../appEnums";
import { toast } from "react-toastify";
import appConstants from "../appConstants";
import fileApi from "../api/fileApi";
import { BlockerUI } from "../common";

export default class CreateAppUi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appName: "",
            platform: AppEnums.Platform.None,
            bundleId: "",
            appIcon: "",
            adminLink: "",
            passwordHash: "",
            appStoreLink: "",
            uploading: false,
            appIconKey: ""
        };
    }

    toastId = "__CREATE_APP__";
    fileNamePadding = commonApi.getRandomString(16);

    handleAppNameChange = event => this.setState({ appName: event.target.value });
    handlePlatformChange = event => this.setState({ platform: event.target.value });
    handleBundleIdChange = event => this.setState({ bundleId: event.target.value });
    handleAppIconChange = event => this.setState({ appIcon: event.target.value });
    handleAdminLinkChange = event => this.setState({ adminLink: event.target.value });
    handlePasswordHashChange = event => this.setState({ passwordHash: event.target.value });
    handleAppStoreLinkChange = event => this.setState({ appStoreLink: event.target.value });

    handleUploadIcon = event => {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            if (file.size > appConstants.imageMaxSizeMB)
                return commonApi.displayToast("Image file size is too big", this.toastId, toast.TYPE.ERROR, {});

            const { appWebSocket, hcCompanyId, hcAccountId } = this.props;

            fileApi
                .convertFileToArrayBuffer(file)
                .then(binaryImage => {
                    this.setState({ uploading: true }, () => {
                        const imgExt = file.type.split("/")[1];
                        const fileName = `appicon_${hcCompanyId}_${hcAccountId}_${this.fileNamePadding}.${imgExt}`;
                        appWebSocket
                            .uploadAppIcon(hcCompanyId, hcAccountId, binaryImage, file.type, fileName)
                            .then(resultJson => {
                                this.setState({ appIcon: resultJson.icon, appIconKey: resultJson.appIconKey, uploading: false });
                            })
                            .catch(error => {
                                this.setState({ uploading: false });
                                console.error(error);
                                commonApi.displayToast("Failed to upload App Icon", this.toastId, toast.TYPE.ERROR, {});
                            });
                    });
                })
                .catch(error => {
                    console.log(error);
                    commonApi.displayToast("Failed to upload App Icon", this.toastId, toast.TYPE.ERROR, {});
                });
        }
    };

    componentDidUpdate(prevState, prevProps) {
        const { appName, platform, bundleId, appIcon, adminLink, passwordHash, appStoreLink } = this.state;
        let isChange = appName || bundleId || appIcon || adminLink || passwordHash || appStoreLink;
        isChange = isChange || parseInt(platform) !== AppEnums.Platform.None;
        const { appEvent } = this.props;
        if (appEvent != null) {
            if (isChange) {
                appEvent.fireEvent(AppEvent.EVENT_SET_UNSAVED_CHANGES);
            } else {
                appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
            }
        }
    }

    componentWillUnmount() {
        const { appEvent } = this.props;
        if (appEvent) {
            appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
        }
        commonApi.dismissToast(this.toastId);
    }

    handleSubmit = event => {
        event.preventDefault();
        if (emoji.strip(this.state.appName).length === 0) {
            return commonApi.displayToast("App Name is required", this.toastId, toast.TYPE.ERROR, {});
        }
        if (emoji.strip(this.state.bundleId).length === 0) {
            return commonApi.displayToast("Bundle ID is required", this.toastId, toast.TYPE.ERROR, {});
        }
        const metaData = {
            platform: this.state.platform,
            appIcon: emoji.strip(this.state.appIcon),
            passwordHash: emoji.strip(this.state.passwordHash),
            adminLink: emoji.strip(this.state.adminLink),
            appStoreLink: emoji.strip(this.state.appStoreLink),
            appIconKey: this.state.appIconKey
        };

        webApi
            .fetchCreateApp(
                this.props.hcCompanyId,
                emoji.strip(this.state.bundleId),
                emoji.strip(this.state.appName),
                JSON.stringify(metaData),
                this.props.token
            )
            .then(this.onFetchCreateApp)
            .catch(errDict => {
                commonApi.displayToast(errDict.error, this.toastId, toast.TYPE.ERROR, {});
                this.props.appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict);
            });
    };

    onFetchCreateApp = res => {
        if (commonApi.isObject(res) && "hcCompanyId" in res) {
            const { onCreateAppCallback, hcCompanyId, appEvent } = this.props;
            if (onCreateAppCallback != null && appEvent != null) {
                appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
                onCreateAppCallback(hcCompanyId, this.state.bundleId);
            }
        }
    };

    render() {
        return (
            <div className="ui1-container-div-lg background-color-v2 slide-up-animation">
                <div id="createAppPage">
                    <p className="create-app-main-heading">CREATE APP</p>
                    <form onSubmit={this.handleSubmit}>
                        <div className="my-2">
                            <span className="settings-input-label">App Name</span>
                            <input
                                id="createAppInputAppName"
                                value={this.state.appName}
                                autoFocus
                                required
                                onChange={this.handleAppNameChange}
                                className="create-app-text-input"
                            />
                        </div>

                        <div className="my-2">
                            <span className="settings-input-label">Platform</span>
                            <select
                                id="createAppSelectPlatform"
                                value={this.state.platform}
                                required
                                onChange={this.handlePlatformChange}
                                className="create-app-text-input">
                                {Object.keys(AppEnums.Platform).map(key => {
                                    return (
                                        <option value={AppEnums.Platform[key]} key={key}>
                                            {key}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>

                        <div className="my-2">
                            <span className="settings-input-label">Bundle ID</span>
                            <input
                                id="createAppInputBundleId"
                                value={this.state.bundleId}
                                required
                                placeholder="com.company_name.xxx"
                                onChange={this.handleBundleIdChange}
                                className="create-app-text-input"
                            />
                        </div>

                        <div className="my-2">
                            <span className="settings-input-label">
                                App Icon <small>(Optional)</small>
                            </span>
                            <div className="d-flex flex-row align-items-start">
                                <input
                                    id="createAppInputAppIcon"
                                    value={this.state.appIcon}
                                    placeholder="Enter URL"
                                    onChange={this.handleAppIconChange}
                                    className="create-app-text-input"
                                />
                                &nbsp;
                                <input
                                    type="file"
                                    name="appIconUpload"
                                    onChange={this.handleUploadIcon}
                                    accept="image/*"
                                    ref={u => (this.uploadRef = u)}
                                    style={{ width: "0.1px", height: "0.1px" }}
                                    id="appIconUpload"
                                />
                                <button className="btn btn-light align-self-center" type="button" onClick={() => this.uploadRef.click()}>
                                    Upload
                                </button>
                            </div>
                        </div>

                        <div className="my-2">
                            <span className="settings-input-label">
                                Admin Link <small>(Optional)</small>
                            </span>
                            <input
                                id="createAppInputAdminLink"
                                value={this.state.adminLink}
                                placeholder="Enter URL"
                                onChange={this.handleAdminLinkChange}
                                className="create-app-text-input"
                            />
                        </div>

                        <div className="my-2">
                            <span className="settings-input-label">
                                Password Hash <small>(Optional)</small>
                            </span>
                            <input
                                id="createAppInputPasswordHash"
                                value={this.state.passwordHash}
                                type="text"
                                placeholder="Enter URL"
                                onChange={this.handlePasswordHashChange}
                                className="create-app-text-input"
                            />
                        </div>

                        <div className="my-2">
                            <span className="settings-input-label">
                                App Store Link <small>(Optional)</small>
                            </span>
                            <input
                                id="createAppInputAppStoreLink"
                                value={this.state.appStoreLink}
                                placeholder="Enter URL"
                                onChange={this.handleAppStoreLinkChange}
                                className="create-app-text-input"
                            />
                        </div>
                        <button id="createAppSubmitButton" alt="Create" type="submit">
                            CREATE
                        </button>
                    </form>
                </div>
                {this.state.uploading && <BlockerUI />}
            </div>
        );
    }
}
