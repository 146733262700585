import React from 'react';

const FaqArticleTag = props => {
    const { tag } = props;
    let view = null;
    if (!tag) {
        return view;
    }
    switch (tag.toLowerCase()) {
        case 'androidgoogle':
            view = <img src="/images/faqs/android_icon.png" className="faq-icon-tag" alt={tag} title={tag} />;
            break;
        case 'ios':
            view = <img src="/images/faqs/apple_icon.png" className="faq-icon-tag" alt={tag} title={tag} />;
            break;
        default:
            view = <span className="faq-text-tag">{tag}</span>;
    }
    return view;
};

export default FaqArticleTag;
