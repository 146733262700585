import React from 'react';
import './noMessageSelected.css';

const NoMessageSelected = props => {
    return (
        <div className="ui2-container-div slide-up-animation" id="noMessageContainer">
            <div className="no-message-div">
                <div className="no-message-content">
                    <div className="no-message-banner"></div>
                    <p className="no-message-text">You have not selected any messages.</p>
                </div>
            </div>
        </div>
    );
};

export default NoMessageSelected;
