import React, { Component } from "react";
import "./faqArticleView.css";
import FaqArticleTag from "./faqArticleTag";
import Moment from "react-moment";
import AppEvent from "../appEvent";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import { customBlockRendererFn, customStyleMap, editorDecorators } from "./faqUtils";
import { FaqShareModal } from "./faqEditorModal";
import DeleteConfirm from "../customAlerts/deleteConfirm";
import Loading from "../loading";
import appConstants from "../appConstants";

class FaqArticleView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            isLoading: true,
            rawDataObj: {},
            shareableLink: ""
        };
        this.onEdit = this.onEdit.bind(this);
        this.onShare = this.onShare.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.fetchFaqContent = this.fetchFaqContent.bind(this);
        this.onFetchFaqContent = this.onFetchFaqContent.bind(this);
    }

    onFetchFaqContent(res) {
        const body = res.body;
        const rawDataObj = JSON.parse(body);
        const fromRaw = convertFromRaw(rawDataObj);
        const contentState = EditorState.createWithContent(fromRaw);
        const contentWithDecorator = EditorState.set(contentState, { decorator: editorDecorators });
        this.setState({ editorState: contentWithDecorator, isLoading: false, rawDataObj: rawDataObj });
    }

    fetchFaqContent() {
        const { faqArticleObj, appWebSocket, appEvent } = this.props;
        if (!appWebSocket) {
            return;
        }
        const json = {
            hcAccountId: faqArticleObj.lastHcAccountId,
            contentKey: faqArticleObj.contentKey
        };
        appWebSocket
            .retrieveFaq(json)
            .then(this.onFetchFaqContent)
            .catch(errDict => appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
    }

    componentDidMount() {
        this.fetchFaqContent();
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedFaqId } = this.props;
        if (prevProps.selectedFaqId !== selectedFaqId) {
            this.setState({ isLoading: true }, this.fetchFaqContent);
        }
    }

    onShare() {
        const { bundleId, faqArticleObj } = this.props;
        const hostname = window.location.host;
        const protocol = window.location.protocol;
        const publicLink = `${protocol}//${hostname}/faq/public/${bundleId}/${faqArticleObj.appFaqId}`;
        this.setState({ shareableLink: publicLink });
    }

    onDelete() {
        const { faqArticleObj, appWebSocket, appEvent, bundleId } = this.props;
        if (!appWebSocket || !appEvent) {
            return;
        }
        const { rawDataObj } = this.state;
        const entityKeys = Object.keys(rawDataObj.entityMap);
        // array of keys to be used for deleting files associated to this article uploaded in s3
        const s3KeyList = entityKeys
            .map(key => rawDataObj.entityMap[key])
            .filter(obj => obj.type === "IMAGE" && obj.data.imageKey)
            .map(obj => obj.data.imageKey);
        s3KeyList.push(faqArticleObj.contentKey);
        const json = {
            contentKeys: s3KeyList,
            hcAccountId: faqArticleObj.lastHcAccountId,
            appFaqId: faqArticleObj.appFaqId,
            bundleId: bundleId
        };
        appEvent.fireEvent(AppEvent.EVENT_FAQ_DELETE, faqArticleObj);
        appWebSocket.deleteFaq(json).catch(errDict => {
            appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict);
        });
    }

    onEdit() {
        const { onFaqEditCallBack, faqArticleObj } = this.props;
        onFaqEditCallBack(faqArticleObj, this.state.editorState);
    }

    render() {
        const { faqArticleObj, appInfo } = this.props;
        const tagsList = faqArticleObj.tags.map((tag, index) => {
            return <FaqArticleTag tag={tag} key={index} />;
        });

        const calendarStrings = {
            sameDay: "[Today]",
            nextDay: "[Tomorrow]",
            nextWeek: "dddd",
            lastDay: "[Yesterday]",
            lastWeek: "MMMM D, YYYY",
            sameElse: "MMMM D, YYYY"
        };
        const dateUi = (
            <Moment unix calendar={calendarStrings} interval={3600000}>
                {faqArticleObj.dateCreated / 1000}
            </Moment>
        );

        const authorProfile = (
            <div className="faq-author-div">
                <img
                    src={faqArticleObj.profileImage || appConstants.defaultProfilePic}
                    className="faq-author-img"
                    alt="author profile"
                    ref={n => (this.profile = n)}
                    onError={() => (this.profile.src = appConstants.defaultProfilePic)}
                />
                <div className="faq-author-description">
                    Created by&nbsp;{faqArticleObj.authorName}
                    <br />
                    {dateUi}
                </div>
            </div>
        );

        const optionsUi = (
            <div className="faq-article-options">
                {/* <input
                    title="Share this article"
                    type="button"
                    alt="share"
                    id="faqShare"
                    className="faq-options-icon"
                    data-toggle="modal"
                    data-target="#faqShareModal"
                    onClick={this.onShare}
                /> */}
                <input
                    title="Edit this article"
                    type="button"
                    alt="edit"
                    id="faqEdit"
                    className="faq-options-icon"
                    onClick={this.onEdit}
                    disabled={this.state.isLoading}
                />
                <input
                    type="button"
                    alt="delete"
                    id="faqDelete"
                    className="faq-options-icon"
                    data-toggle="modal"
                    data-target="#confirmModal"
                    title="Delete this article"
                    disabled={this.state.isLoading}
                />
            </div>
        );
        return (
            <div className="background-color-v2 ui2-container-div">
                <div className="faq-view-space">
                    {optionsUi}
                    <div className="faq-article-header-div">
                        <span className="faq-app-category">FAQ&nbsp;/&nbsp;{appInfo ? appInfo.appName : ""}</span>
                        <span className="faq-article-title">{faqArticleObj.title}</span>
                        {tagsList}
                        {authorProfile}
                    </div>
                    <div className="faq-article-div">
                        {this.state.isLoading ? (
                            <Loading />
                        ) : (
                            <Editor
                                customStyleMap={customStyleMap}
                                blockRendererFn={customBlockRendererFn}
                                editorState={this.state.editorState}
                                spellCheck={true}
                                readOnly={true}
                            />
                        )}
                    </div>
                </div>
                <DeleteConfirm
                    onAbort={null}
                    onConfirm={this.onDelete}
                    message={
                        <span>
                            Are you sure you want to delete <span className="text-orange">{faqArticleObj.title}</span>&#63;
                        </span>
                    }
                    title={<strong>Confirm Delete</strong>}
                    abortLabel="Cancel"
                    confirmLabel="Confirm"
                    domId="confirmModal"
                />
                <FaqShareModal shareableLink={this.state.shareableLink} publish={faqArticleObj.publish} />
            </div>
        );
    }
}

export default FaqArticleView;
