import React, { Component } from "react";
import commonApi from "../api/commonApi";

class AppMenuItem extends Component {
    state = { platformIconSrc: "" };

    componentDidMount() {
        setTimeout(this.setPlatformIconSrc, 100);
        if (this.props.isSelected) {
            this.mainRef.scrollIntoView({ behavior: "smooth" });
        }
    }

    setPlatformIconSrc = () => {
        const { appInfoDict, bundleId } = this.props;
        const appInfo = appInfoDict[bundleId];
        let src = commonApi.getPlatformIconSrc(parseInt(appInfo.platform));
        this.setState({ platformIconSrc: src });
    };

    onChangeApp = () => {
        const { onChangeApp, bundleId } = this.props;
        onChangeApp(bundleId);
    };

    render() {
        const { bundleId, appInfoDict, isSelected, newMessageCount } = this.props;

        const appInfo = appInfoDict[bundleId];

        if (appInfo == null) return null;

        const platformIcon = this.state.platformIconSrc ? <img src={this.state.platformIconSrc} alt="os icon" /> : null;

        const menuIcon = (
            <img
                className="app-menu-item-icon"
                alt={appInfo.appName}
                src={appInfo.icon || "/images/app/defaultIcon.png"}
                ref={img => (this.appIconImg = img)}
                onError={() => (this.appIconImg.src = "/images/app/defaultIcon.png")}
            />
        );

        const menuText = <span className="app-menu-text">{appInfo.appName}</span>;
        const newMsgCountText =
            newMessageCount && newMessageCount > 0 ? <span className="app-new-msg-count">{newMessageCount}</span> : null;

        return (
            <div className={`app-menu-item${isSelected ? " active" : ""}`} ref={n => (this.mainRef = n)} onClick={this.onChangeApp}>
                <div className="d-flex align-items-center w-100">
                    <div className="align-self-start" style={{ position: "relative" }}>
                        {menuIcon}
                        {newMsgCountText}
                    </div>
                    <div className="ml-1 align-self-center app-menu-text-div">{menuText}</div>
                    <div className="align-self-center">{platformIcon}</div>
                </div>
            </div>
        );
    }
}

export default AppMenuItem;
