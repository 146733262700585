import React, { Component } from "react";
import { ToastContainer, toast, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Match } from "@reach/router";
import "./index.css";
import "./App.css";
import MenuUi from "./menu/menuUi";
import LoginUi from "./login/loginUi";
import ResetPasswordUi from "./login/resetPasswordUi";
import HomeUi from "./home/homeUi";
import HomeDashboardMetricsUi from "./home/homeDashboardMetricsUi";
import DeveloperConsoleUi from "./devconsole/developerConsoleUi";
import CreateAppUi from "./devconsole/createAppUi";
import MsgListUi from "./message/msgListUi";
import ChatUi from "./chat/chatUi";
import AppSettingUi from "./devconsole/appSettingUi";
import InstantPushVerificationUi from "./devconsole/instantPushVerificationUi";
import DeepLinking from "./devconsole/deepLinking";
import AccountSettingUi from "./setting/accountSettingUi";
import ChangePasswordUi from "./login/changePasswordUi";
import HelpUi from "./help/helpUi";
import GeneralSettingsUi from "./setting/generalSettingsUi";
import FaqUi from "./faqs/faqUi";
import AutomationSettingUi from "./automation/automationSettingUi";
import FaqArticleView from "./faqs/faqArticleView";
import FaqCreateUi from "./faqs/faqCreateUi";
import FaqEditUi from "./faqs/faqEditUi";
import FaqPublicView from "./faqs/faqPublicView";
import EditAutoResponseUi from "./automation/editAutoResponseUi";
import EditAutoMessageUi from "./automation/editAutoMessageUi";
import EditAutoResolveUi from "./automation/editAutoResolveUi";
import UsersAndPermissionsUi from "./permissions/usersAndPermissionsUi";
import Confirm from "./customAlerts/confirm";
import Alert from "./customAlerts/alert";
import BillingUi from "./billing/billingUi";
import Tbd from "./customAlerts/tbd";
import NoMessageSelected from "./chat/noMessageSelected";
import TemplateBuilderUi from "./templateBuilder/templateBuilderUi";
import { BlockerUI } from "./common";

import appEnums from "./appEnums";
import appConstants from "./appConstants";
import AppWebSocket from "./appWebSocket";
import webApi from "./api/webApi";
import AppEvent from "./appEvent";
import shortcut from "./shortcut";
import commonApi from "./api/commonApi";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rootPage: appEnums.RootPage.Loading,
            generalSettingsTab: appEnums.GeneralSettings.None,
            bundleId: "",
            token: "",
            appInfoDict: {},
            isLogin: false,
            hcAccountId: -1,
            profileImage: appConstants.defaultProfilePic,
            profileKey: "",
            hcCompanyId: -1,

            role: appEnums.Role.Agent,
            guestPermissionLevel: appEnums.GuestPermissionLevel.Read,
            active: appEnums.AccountState.Active,

            selectedMsgData: null,
            msgSortState: 0,
            ui2: null,
            selectedFaqId: -1,
            priorityNewMessageCount: 0,
            newMessageCount: 0,
            priorityOpenMessageCount: 0,
            openMessageCount: 0,
            closedMessageCount: 0,
            pendingMessageCount: 0,
            messageStateFilter: appEnums.MessageStates.None,
            unsavedChangesFlag: false,
            dialogBoxUi: null
        };

        this.appEvent = null;
        this.appWebSocket = new AppWebSocket();
        this.unsavedChangesToastId = 1;
        this.shortcut = shortcut;
    }

    onForgotPassword = () => this.setState({ rootPage: appEnums.RootPage.ResetPassword });
    onSelectMsg = selectedMsgData => this.setState({ selectedMsgData });
    onSortMsg = msgSortState => this.setState({ msgSortState });
    hideDialogBox = () => this.setState({ dialogBoxUi: null });
    setDialogBox = dialogBoxUi => this.setState({ dialogBoxUi });
    setRole = role => this.setState({ role });
    setProfileImage = src => this.setState({ profileImage: src || appConstants.defaultProfilePic });
    setNewMessageCount = newMessageCount => this.setState({ newMessageCount });

    emptyDiv = (<div className="background-color-v2 ui2-container-div"></div>);

    async componentDidMount() {
        if (this.props.isUnitTest) {
            return;
        }
        await webApi.updateUserIP();

        const token = window.localStorage.getItem("token");
        var dataToken = window.localStorage.getItem("dataToken");
        if (dataToken && token) {
            webApi
                .fetchVerifyAccessToken(token)
                .then(response => {
                    return this.onVerifyAccessToken(response, dataToken);
                })
                .catch(errorDict => {
                    this.setState({ rootPage: appConstants.defaultRootPage }, () => this.onEventFetchError(errorDict));
                });
        } else this.setState({ rootPage: appConstants.defaultRootPage });
    }

    onVerifyAccessToken = async (response, dataToken) => {
        const hash = commonApi.generateHash(
            {
                ip: webApi.userIP
            },
            webApi.userIP
        );

        const authData = await commonApi.verifyDataToken(dataToken, hash);

        if (response.success && authData !== null) {
            webApi
                .fetchCheckAccountActive(authData.hcAccountId)
                .then(() => this.onLogin(authData))
                .catch(this.onLogout);
        } else this.setState({ rootPage: appConstants.defaultRootPage });
    };

    initializeAppEvent = () => {
        if (this.appEvent == null) {
            this.appEvent = new AppEvent();
            this.appEvent.init(this.appEvent);
            this.appEvent.addCallback(AppEvent.EVENT_FETCH_ERROR, this.onEventFetchError);
            this.appEvent.addCallback(AppEvent.EVENT_CHANGE_MESSAGE_STATE, this.onEventChangeMessageState);
            this.appEvent.addCallback(AppEvent.EVENT_UNSET_UNSAVED_CHANGES, this.unsetUnsavedChangesFlag);
            this.appEvent.addCallback(AppEvent.EVENT_SET_UNSAVED_CHANGES, this.setUnsavedChangesFlag);
            this.appEvent.addCallback(AppEvent.EVENT_UPDATE_MESSAGES_COUNT, this.getConversationsCount);
        }
    };

    getSavedConversationFilter = () => {
        const key = appConstants.MESSAGE_BTN_FILTER_KEY.replace(appConstants.BUNDLE_ID_KEY, this.state.bundleId);
        let value = window.localStorage.getItem(key);
        let filter;
        let isExpired = true;

        if (value) {
            filter = value.split(":")[0];
            filter = parseFloat(filter);
            let timestamp = value.split(":")[1];
            isExpired = Date.now() - parseInt(timestamp) > appConstants.MESSAGE_BTN_FILTER_EXPR;
        }

        if (filter >= 0 && !isExpired) {
            this.setState({ messageStateFilter: filter });
        } else {
            window.localStorage.removeItem(key);
            this.setState({ messageStateFilter: appEnums.MessageStates.New });
        }
    };

    onDeleteApp = () => {
        delete this.state.appInfoDict[this.state.bundleId];
        const bundleList = Object.keys(this.state.appInfoDict);
        this.setState({
            selectedMsgData: null,
            bundleId: bundleList[0],
            rootPage: appEnums.RootPage.AppSetting,
            unsavedChangesFlag: false
        });
    };

    getNewAccessTokens = response => {
        this.setState({ token: response.token }, () => {
            window.localStorage.setItem("token", response.token);
            window.localStorage.setItem("dataToken", response.dataToken);
        });
    };

    confirmNavigation = (confirmCallBack, abortCallBack) => {
        return this.setState(
            {
                dialogBoxUi: (
                    <Confirm
                        title="Confirm Navigation"
                        message={
                            <span>
                                You have unsaved changes.
                                <br />
                                Are you sure you want to leave this page?
                            </span>
                        }
                        onAbort={abortCallBack}
                        onConfirm={confirmCallBack}
                        confirmLabel="Continue"
                        abortLabel="Cancel"
                    />
                )
            },
            () => toast.dismiss()
        );
    };

    confirmMenuNavigation = page => {
        const confirmCallBack = () => this.setState({ unsavedChangesFlag: false, dialogBoxUi: null }, () => this.onShowMenu(page));
        return this.confirmNavigation(confirmCallBack, this.hideDialogBox);
    };

    showUnsavedChangesToast = () => {
        commonApi.displayToast("You have unsaved changes", this.unsavedChangesToastId, toast.TYPE.INFO, {
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            closeButton: true
        });
    };

    hideUnsavedChangesToast = () => commonApi.dismissToast(this.unsavedChangesToastId);

    unsetUnsavedChangesFlag = () => {
        if (!this.state.unsavedChangesFlag) {
            return this.hideUnsavedChangesToast();
        }
        this.setState({ unsavedChangesFlag: false }, this.hideUnsavedChangesToast);
    };

    setUnsavedChangesFlag = () => {
        if (this.state.unsavedChangesFlag) {
            return this.showUnsavedChangesToast();
        }
        this.setState({ unsavedChangesFlag: true }, this.showUnsavedChangesToast);
    };

    setMessageStateFilter = buttonFilter => {
        this.setState({ messageStateFilter: buttonFilter }, () => {
            const doNotSave = !this.state.bundleId || buttonFilter === appEnums.MessageStates.None;
            if (doNotSave) {
                return;
            }
            const key = appConstants.MESSAGE_BTN_FILTER_KEY.replace(appConstants.BUNDLE_ID_KEY, this.state.bundleId);
            const value = appConstants.MESSAGE_BTN_FILTER_FRMT.replace(appConstants.CONVERSATION_STATE_KEY, buttonFilter).replace(
                appConstants.TIMESTAMP_KEY,
                Date.now()
            );
            window.localStorage.setItem(key, value);
        });
    };

    checkToUpdateLastLogin = () => {
        let perfEntries = window.performance.getEntriesByType("navigation");
        perfEntries = perfEntries.length > 0 ? perfEntries[0] : null;
        const update = perfEntries && perfEntries.type !== "reload" && perfEntries.type !== "back_forward";

        if (!update) {
            return;
        }
        const { hcAccountId, hcCompanyId, token } = this.state;
        webApi
            .fetchUpdateLastLogin(hcAccountId, hcCompanyId, token)
            .catch(err => console.log("Failed to update last login timestamp err:", err.error));
    };

    componentWillUnmount() {
        if (this.appEvent != null) {
            this.appEvent.close();
            this.appEvent = null;
        }

        if (this.appWebSocket.socket !== null) {
            this.appWebSocket.disconnect();
        }
    }

    onShowMenu = page => {
        if (this.state.unsavedChangesFlag && page !== this.state.rootPage) {
            return this.confirmMenuNavigation(page);
        }
        if (page === appEnums.RootPage.Login && this.state.isLogin) {
            return webApi
                .fetchAppInfoData(this.state.hcCompanyId, this.state.token)
                .then(this.onLoginGoToMessages)
                .catch(this.onEventFetchError);
        }
        if (page === appEnums.RootPage.HomeDashboardMetrics) {
            return webApi
                .fetchAppInfoData(this.state.hcCompanyId, this.state.token)
                .then(this.onFetchAppInfoData)
                .catch(this.onEventFetchError);
        }
        this.setState({ rootPage: page });
    };

    onChangeApp = bundleId => {
        if (this.state.unsavedChangesFlag && this.state.bundleId !== bundleId) {
            return this.confirmChangeApp(bundleId);
        }
        this.setState({ bundleId, selectedMsgData: null }, () => {
            this.appWebSocket.sendSocketInfo(bundleId);
            window.localStorage.setItem(appConstants.LAST_SELECTED_APP_KEY, bundleId);
        });
    };

    confirmChangeApp = bundleId => {
        const confirmCallBack = () =>
            this.setState({ unsavedChangesFlag: false, dialogBoxUi: null }, () => {
                this.onChangeApp(bundleId);
                this.hideUnsavedChangesToast();
            });
        this.confirmNavigation(confirmCallBack, this.hideDialogBox);
    };

    onLogin = authData => {
        this.initializeAppEvent();
        const metaData = commonApi.safeJsonParse(authData.metaData);
        this.setState(
            {
                hcAccountId: parseInt(authData.hcAccountId),
                hcCompanyId: parseInt(authData.hcCompanyId),
                token: authData.token,
                isLogin: true,
                profileImage: metaData.profileImage || appConstants.defaultProfilePic,
                profileKey: metaData.profileKey || "",
                role: metaData.role,
                guestPermissionLevel: metaData.guestPermissionLevel,
            },
            () => {
                this.onShowMenu(appEnums.RootPage.Login);
                this.checkToUpdateLastLogin();
            }
        );
    };

    onLoginGoToMessages = appInfoDict => {
        const last_app = window.localStorage.getItem(appConstants.LAST_SELECTED_APP_KEY);
        this.setState(
            {
                rootPage: appEnums.RootPage.Message,
                bundleId: appInfoDict[last_app] ? last_app : Object.keys(appInfoDict)[0],
                appInfoDict: appInfoDict
            },
            () => {
                if (this.appWebSocket.socket === null && !this.props.isUnitTest) {
                    this.appWebSocket.connectToServer(this.state.hcAccountId, this.state.hcCompanyId, this.state.token, this.onSocketConnect);
                }
                this.appWebSocket.sendSocketInfo(this.state.bundleId);
            }
        );
    };

    onFetchAppInfoData = appInfoDict => {
        this.setState(
            {
                rootPage: appEnums.RootPage.HomeDashboardMetrics,
                bundleId: appInfoDict[this.state.bundleId] ? this.state.bundleId : Object.keys(appInfoDict)[0],
                appInfoDict: appInfoDict
            },
            () => {
                if (this.appWebSocket.socket === null && !this.props.isUnitTest) {
                    this.appWebSocket.connectToServer(this.state.hcAccountId, this.state.hcCompanyId, this.state.token, this.onSocketConnect);
                }
                this.appWebSocket.sendSocketInfo(this.state.bundleId);
            }
        );
    };

    confirmLogout = () => {
        const confirmCallBack = () => this.setState({ unsavedChangesFlag: false, dialogBoxUi: null }, this.onLogout);
        return this.confirmNavigation(confirmCallBack, this.hideDialogBox);
    };

    onLogout = () => {
        if (this.state.unsavedChangesFlag) {
            return this.confirmLogout();
        }
        if (this.appEvent !== null) {
            this.appEvent.removeCallback(AppEvent.EVENT_FETCH_ERROR, this.onEventFetchError);
            this.appEvent.removeCallback(AppEvent.EVENT_CHANGE_MESSAGE_STATE, this.onEventChangeMessageState);
            this.appEvent.close();
            this.appEvent = null;
        }
        if (this.appWebSocket.socket !== null) {
            this.appWebSocket.disconnect();
        }
        this.setState(
            {
                hcAccountId: -1,
                hcCompanyId: -1,
                role: appEnums.Role.Agent,
                rootPage: appEnums.RootPage.Login,
                bundleId: "",
                isLogin: false,
                selectedMsgData: null,
                unsavedChangesFlag: false
            },
            this.hideUnsavedChangesToast
        );

        window.localStorage.removeItem("token");
        window.localStorage.removeItem("dataToken");
    };

    onSocketConnect = () => {
        if (this.appWebSocket.socket !== null) {
            this.appWebSocket.sendSocketInfo(this.state.bundleId);
            // Subscribe to selected conversation for realtime updates
            if (this.state.selectedMsgData) {
                const { bundleId, deviceId } = this.state.selectedMsgData;
                this.appWebSocket.sendSelectedConversation(bundleId, deviceId);
            }
        }
    };

    onEventFetchError = errorDict => {
        console.error(errorDict);
        // '070001: Verify token failed'
        if ("error" in errorDict && errorDict.error.includes("070001")) {
            this.onLogout();
        }
    };

    onEventChangeMessageState = dataDict => {
        webApi
            .fetchConversation(dataDict.bundleId, dataDict.deviceId, this.state.token)
            .then(this.onFetchConversation)
            .catch(this.onEventFetchError);
    };

    onFetchConversation = msgData => {
        const { bundleId } = this.state;
        this.setState(
            {
                selectedMsgData: msgData,
                bundleId: bundleId !== msgData.bundleId ? msgData.bundleId : bundleId
            },
            () => {
                window.localStorage.setItem(appConstants.LAST_SELECTED_APP_KEY, msgData.bundleId);
                const key = appConstants.MESSAGE_BTN_FILTER_KEY.replace(appConstants.BUNDLE_ID_KEY, msgData.bundleId);
                const value = appConstants.MESSAGE_BTN_FILTER_FRMT.replace(appConstants.CONVERSATION_STATE_KEY, msgData.state).replace(
                    appConstants.TIMESTAMP_KEY,
                    Date.now()
                );
                window.localStorage.setItem(key, value);
            }
        );
    };

    onCreateApp = (hcCompanyId, bundleId) => {
        webApi
            .fetchAppInfoData(hcCompanyId, this.state.token)
            .then(appInfoDict => this.onCreateAppFetchAppInfoData(hcCompanyId, bundleId, appInfoDict))
            .catch(this.onEventFetchError);
    };

    onCreateAppFetchAppInfoData = (hcCompanyId, bundleId, appInfoDict) => {
        this.setState(
            {
                rootPage: appEnums.RootPage.HomeDashboardMetrics,
                bundleId: bundleId,
                hcCompanyId: hcCompanyId,
                appInfoDict: appInfoDict
            },
            () => this.appWebSocket.sendSocketInfo(bundleId)
        );
    };

    resetUi2 = () => {
        this.setState({
            ui2: this.emptyDiv,
            selectedFaqId: -1,
            generalSettingsTab: appEnums.GeneralSettings.None
        });
    };

    confirmFaqArticleView = faqArticleObj => {
        const confirmCallBack = () =>
            this.setState({ dialogBoxUi: null, unsavedChangesFlag: false }, () => this.onFaqArticleView(faqArticleObj));
        this.confirmNavigation(confirmCallBack, this.hideDialogBox);
    };

    onFaqArticleView = faqArticleObj => {
        const { appInfoDict, bundleId, unsavedChangesFlag } = this.state;
        if (unsavedChangesFlag) {
            return this.confirmFaqArticleView(faqArticleObj);
        }
        const view = (
            <FaqArticleView
                faqArticleObj={faqArticleObj}
                selectedFaqId={faqArticleObj.appFaqId}
                onFaqEditCallBack={this.onFaqEdit}
                appInfo={appInfoDict[bundleId]}
                bundleId={bundleId}
                appWebSocket={this.appWebSocket}
                appEvent={this.appEvent}
                hideDialogBox={this.hideDialogBox}
            />
        );
        this.setState({ ui2: view, selectedFaqId: faqArticleObj.appFaqId });
    };

    onFaqEditSaveChanges = faqArticleObj => {
        this.onFaqArticleView(faqArticleObj);
    };

    onFaqEditCancel = faqArticleObj => {
        this.onFaqArticleView(faqArticleObj);
    };

    confirmNewFaq() {
        const confirmCallBack = () => this.setState({ unsavedChangesFlag: false, dialogBoxUi: null }, this.onClickNewFaq);
        this.confirmNavigation(confirmCallBack, this.hideDialogBox);
    }

    onClickNewFaq = () => {
        const { bundleId, hcAccountId, hcCompanyId, appInfoDict } = this.state;
        if (this.state.unsavedChangesFlag) {
            return this.confirmNewFaq();
        }
        const view = (
            <FaqCreateUi
                appEvent={this.appEvent}
                appWebSocket={this.appWebSocket}
                bundleId={bundleId}
                hcAccountId={hcAccountId}
                hcCompanyId={hcCompanyId}
                resetUi2={this.resetUi2}
                appInfo={appInfoDict[bundleId]}
            />
        );
        this.setState({ ui2: view, selectedFaqId: -1 });
    };

    confirmChangePasswordNavigation = email => {
        const confirmCallBack = () => this.setState({ unsavedChangesFlag: false, dialogBoxUi: null }, () => this.onChangePassword(email));
        return this.confirmNavigation(confirmCallBack, this.hideDialogBox);
    };

    onChangePassword = email => {
        if (this.state.unsavedChangesFlag) {
            return this.confirmChangePasswordNavigation(email);
        }
        const view = (
            <ChangePasswordUi
                onChangePasswordCancel={this.onChangePasswordCancel}
                email={email}
                token={this.state.token}
                appEvent={this.appEvent}
                getNewAccessTokens={this.getNewAccessTokens}
                onChangePasswordSuccess={this.onChangePasswordSuccess}
            />
        );
        this.setState({ ui2: view });
    };

    onChangePasswordSuccess = () => {
        this.setState({
            unsavedChangesFlag: false,
            ui2: (
                <AccountSettingUi
                    token={this.state.token}
                    hcAccountId={this.state.hcAccountId}
                    hcCompanyId={this.state.hcCompanyId}
                    appEvent={this.appEvent}
                    appWebSocket={this.appWebSocket}
                    onChangePassword={this.onChangePassword}
                    setProfileImage={this.setProfileImage}
                    getNewAccessTokens={this.getNewAccessTokens}
                    profileImage={this.state.profileImage}
                />
            ),
            dialogBoxUi: null
        });
    };

    onChangePasswordCancel = () => {
        if (this.state.unsavedChangesFlag) {
            return this.confirmNavigation(this.onChangePasswordSuccess, this.hideDialogBox);
        }
        this.onChangePasswordSuccess();
    };

    onFaqEdit = (faqArticleObj, editorState) => {
        const view = (
            <FaqEditUi
                faqArticleObj={faqArticleObj}
                editorState={editorState}
                onFaqEditSaveChanges={this.onFaqEditSaveChanges}
                onFaqEditCancel={this.onFaqEditCancel}
                appEvent={this.appEvent}
                appWebSocket={this.appWebSocket}
                bundleId={this.state.bundleId}
                hcCompanyId={this.state.hcCompanyId}
                hcAccountId={this.state.hcAccountId}
            />
        );
        this.setState({ ui2: view });
    };

    confirmEditAutoResponse = responseText => {
        const confirmCallBack = () =>
            this.setState({ unsavedChangesFlag: false, dialogBoxUi: null }, () => this.onEditAutoResponse(responseText));
        return this.confirmNavigation(confirmCallBack, this.hideDialogBox);
    };

    onEditAutoResponse = responseText => {
        if (this.state.unsavedChangesFlag) {
            return this.confirmEditAutoResponse(responseText);
        }
        const ui2 = (
            <EditAutoResponseUi
                token={this.state.token}
                hcCompanyId={this.state.hcCompanyId}
                bundleId={this.state.bundleId}
                responseText={responseText}
                onClickSettingsTab={this.onClickSettingsTab}
                role={this.state.role}
                appInfoDict={this.state.appInfoDict}
                appEvent={this.appEvent}
                onChangeApp={this.onChangeApp}
            />
        );
        this.setState({ ui2 });
    };

    confirmEditAutoMessage = (messageText, days) => {
        const confirmCallBack = () =>
            this.setState({ unsavedChangesFlag: false, dialogBoxUi: null }, () => this.onEditAutoMessage(messageText, days));
        return this.confirmNavigation(confirmCallBack, this.hideDialogBox);
    };

    onEditAutoMessage = (messageText, days) => {
        if (this.state.unsavedChangesFlag) {
            return this.confirmEditAutoMessage(messageText, days);
        }
        const ui2 = (
            <EditAutoMessageUi
                token={this.state.token}
                hcCompanyId={this.state.hcCompanyId}
                bundleId={this.state.bundleId}
                srcMessageText={messageText}
                srcDays={days}
                onClickSettingsTab={this.onClickSettingsTab}
                appEvent={this.appEvent}
                appInfoDict={this.state.appInfoDict}
                onChangeApp={this.onChangeApp}
            />
        );
        this.setState({ ui2 });
    };

    confirmEditAutoResolve(messageText, days) {
        const confirmCallBack = () =>
            this.setState({ unsavedChangesFlag: false, dialogBoxUi: null }, () => this.onEditAutoResolve(messageText, days));
        return this.confirmNavigation(confirmCallBack, this.hideDialogBox);
    }

    onEditAutoResolve = (messageText, days) => {
        if (this.state.unsavedChangesFlag) {
            return this.confirmEditAutoResolve(messageText, days);
        }
        const ui2 = (
            <EditAutoResolveUi
                token={this.state.token}
                hcCompanyId={this.state.hcCompanyId}
                bundleId={this.state.bundleId}
                srcMessageText={messageText}
                srcDays={days}
                onClickSettingsTab={this.onClickSettingsTab}
                appEvent={this.appEvent}
                appInfoDict={this.state.appInfoDict}
                onChangeApp={this.onChangeApp}
            />
        );
        this.setState({ ui2 });
    };

    confirmCreateAppButton = () => {
        const confirmCallBack = () => this.setState({ unsavedChangesFlag: false, dialogBoxUi: null }, this.onClickCreateAppButton);
        this.confirmNavigation(confirmCallBack, this.hideDialogBox);
    };

    onClickCreateAppButton = () => {
        if (this.state.unsavedChangesFlag) {
            return this.confirmCreateAppButton();
        }
        this.setState({ rootPage: appEnums.RootPage.CreateApp });
    };

    confirmSettingsTabNavigation = page => {
        const confirmCallBack = () => this.setState({ unsavedChangesFlag: false, dialogBoxUi: null }, () => this.onClickSettingsTab(page));
        return this.confirmNavigation(confirmCallBack, this.hideDialogBox);
    };

    onSaveAppSetting = () => {
        webApi
            .fetchAppInfoData(this.state.hcCompanyId, this.state.token)
            .then(appInfoDict => {
                this.setState(
                    {
                        bundleId: this.state.bundleId ? this.state.bundleId : Object.keys(appInfoDict)[0],
                        appInfoDict: appInfoDict,
                        rootPage: appEnums.RootPage.AppSetting,
                        unsavedChangesFlag: false
                    },
                    this.hideUnsavedChangesToast
                );
            })
            .catch(this.onEventFetchError);
    };

    onClickSettingsTab = tabPage => {
        const navigate = this.state.unsavedChangesFlag;
        if (navigate) {
            return this.confirmSettingsTabNavigation(tabPage);
        }
        let selectedPage = null;
        this.setState({ generalSettingsTab: tabPage });
        switch (tabPage) {
            case appEnums.GeneralSettings.Automation:
                selectedPage = (
                    <AutomationSettingUi
                        hcCompanyId={this.state.hcCompanyId}
                        bundleId={this.state.bundleId}
                        token={this.state.token}
                        appInfoDict={this.state.appInfoDict}
                        appEvent={this.appEvent}
                        onClickSettingsTab={this.onClickSettingsTab}
                        onChangeApp={this.onChangeApp}
                        onEditAutoResponseCallBack={this.onEditAutoResponse}
                        onEditAutoMessageCallback={this.onEditAutoMessage}
                        onEditAutoResolveCallback={this.onEditAutoResolve}
                    />
                );
                break;
            case appEnums.GeneralSettings.Profile:
                selectedPage = (
                    <AccountSettingUi
                        token={this.state.token}
                        hcAccountId={this.state.hcAccountId}
                        hcCompanyId={this.state.hcCompanyId}
                        appEvent={this.appEvent}
                        appWebSocket={this.appWebSocket}
                        onChangePassword={this.onChangePassword}
                        setProfileImage={this.setProfileImage}
                        getNewAccessTokens={this.getNewAccessTokens}
                        profileImage={this.state.profileImage}
                        role={this.state.role}
                    />
                );
                break;
            case appEnums.GeneralSettings.Billing:
                selectedPage = <BillingUi />;
                break;
            case appEnums.GeneralSettings.UsersAndPermissions:
                selectedPage = (
                    <UsersAndPermissionsUi
                        hcAccountId={this.state.hcAccountId}
                        hcCompanyId={this.state.hcCompanyId}
                        token={this.state.token}
                        setRole={this.setRole}
                        resetUi2={this.resetUi2}
                        appEvent={this.appEvent}
                    />
                );
                break;
            case appEnums.GeneralSettings.Template:
                selectedPage = (
                    <TemplateBuilderUi
                        hcCompanyId={this.state.hcCompanyId}
                        bundleId={this.state.bundleId}
                        appInfoDict={this.state.appInfoDict}
                        token={this.state.token}
                        appEvent={this.appEvent}
                        onClickSettingsTab={this.onClickSettingsTab}
                        onEditAutoResponseCallBack={this.onEditAutoResponse}
                        onEditAutoMessageCallback={this.onEditAutoMessage}
                        onEditAutoResolveCallback={this.onEditAutoResolve}
                    />
                );
                break;
            default:
                selectedPage = null;
                break;
        }
        this.setState({ ui2: selectedPage });
    };

    componentDidUpdate(prevProps, prevState) {
        const { rootPage, generalSettingsTab, bundleId, selectedMsgData } = this.state;
        const { Billing } = appEnums.GeneralSettings;
        const { DeveloperConsole } = appEnums.RootPage;
        if (prevState.generalSettingsTab !== Billing && generalSettingsTab === Billing) {
            return this.setState({
                dialogBoxUi: (
                    <Alert
                        onConfirm={this.hideDialogBox}
                        confirmLabel="OK"
                        title={<strong>Under Development</strong>}
                        message="This page is currently Under Development"
                    />
                ),
                ui2: (
                    <div className="background-color-v2 ui2-container-div">
                        <Tbd />
                    </div>
                )
            });
        }
        if (prevState.rootPage !== DeveloperConsole && rootPage === DeveloperConsole) {
            return this.setState({
                dialogBoxUi: (
                    <Alert
                        onConfirm={this.hideDialogBox}
                        confirmLabel="OK"
                        title={<strong>Under Development</strong>}
                        message="This page is currently Under Development"
                    />
                )
            });
        }
        if (prevState.bundleId !== bundleId && bundleId) {
            this.getSavedConversationFilter();
            this.getConversationsCount();
        }
        if (selectedMsgData && selectedMsgData !== prevState.selectedMsgData) {
            const { bundleId, deviceId } = this.state.selectedMsgData;
            this.appWebSocket.sendSelectedConversation(bundleId, deviceId);
        }
    }

    getConversationsCount = () => {
        webApi
            .fetchGetMessagesCount(this.state.bundleId, this.state.token)
            .then(response => {
                const { newMessageCount, openMessageCount, closedMessageCount, pendingMessageCount } = response;
                this.setState({ newMessageCount, openMessageCount, closedMessageCount, pendingMessageCount });
            })
            .catch(this.onEventFetchError);

        webApi
            .fetchGetPriorityMessagesCount(this.state.hcCompanyId, this.state.bundleId, this.state.token)
            .then(response => {
                const { priorityNewMessageCount, priorityOpenMessageCount } = response;
                this.setState({ priorityNewMessageCount, priorityOpenMessageCount });
            })
            .catch(this.onEventFetchError);
    };

    render() {
        let ui1 = null;
        let ui2 = null;
        let dialogBoxUi = this.state.dialogBoxUi;
        switch (this.state.rootPage) {
            case appEnums.RootPage.Loading:
                ui1 = <BlockerUI />;
                break;
            case appEnums.RootPage.Login:
                ui1 = <LoginUi onLoginCallback={this.onLogin} onForgotPasswordCallback={this.onForgotPassword} />;
                break;

            case appEnums.RootPage.ResetPassword:
                ui1 = <ResetPasswordUi />;
                break;

            case appEnums.RootPage.HomeDashboardMetrics:
                ui1 = (
                    <HomeDashboardMetricsUi
                        token={this.state.token}
                        bundleId={this.state.bundleId}
                        hcCompanyId={this.state.hcCompanyId}
                        appInfoDict={this.state.appInfoDict}
                        onChangeApp={this.onChangeApp}
                        appEvent={this.appEvent}
                        onClickCreateAppButton={this.onClickCreateAppButton}
                        role={this.state.role}
                    />
                );
                ui2 = this.emptyDiv;
                break;

            case appEnums.RootPage.DeveloperConsole:
                ui1 = (
                    <DeveloperConsoleUi
                        token={this.state.token}
                        bundleId={this.state.bundleId}
                        hcAccountId={this.state.hcAccountId}
                        hcCompanyId={this.state.hcCompanyId}
                        onShowMenu={this.onShowMenu}
                    />
                );
                break;

            case appEnums.RootPage.CreateApp:
                ui1 = (
                    <CreateAppUi
                        token={this.state.token}
                        hcAccountId={this.state.hcAccountId}
                        hcCompanyId={this.state.hcCompanyId}
                        onCreateAppCallback={this.onCreateApp}
                        appEvent={this.appEvent}
                        onShowMenu={this.onShowMenu}
                        appWebSocket={this.appWebSocket}
                    />
                );
                break;

            case appEnums.RootPage.AppSetting:
                ui1 = (
                    <AppSettingUi
                        token={this.state.token}
                        hcCompanyId={this.state.hcCompanyId}
                        bundleId={this.state.bundleId}
                        appInfoDict={this.state.appInfoDict}
                        appWebSocket={this.appWebSocket}
                        appEvent={this.appEvent}
                        onDeleteAppCallBack={this.onDeleteApp}
                        onChangeApp={this.onChangeApp}
                        onClickCreateAppButton={this.onClickCreateAppButton}
                        role={this.state.role}
                        onSaveAppSetting={this.onSaveAppSetting}
                    />
                );
                break;

            case appEnums.RootPage.InstantPushVerification:
                ui1 = (
                    <InstantPushVerificationUi
                        token={this.state.token}
                        hcCompanyId={this.state.hcCompanyId}
                        bundleId={this.state.bundleId}
                        appInfoDict={this.state.appInfoDict}
                        appWebSocket={this.appWebSocket}
                        appEvent={this.appEvent}
                        onChangeApp={this.onChangeApp}
                        onClickCreateAppButton={this.onClickCreateAppButton}
                        role={this.state.role}
                        shortcut={this.shortcut}
                    />
                );
                break;

            case appEnums.RootPage.GeneralSettings:
                ui1 = (
                    <GeneralSettingsUi
                        onClickSettingsTab={this.onClickSettingsTab}
                        generalSettingsTab={this.state.generalSettingsTab}
                        resetUi2={this.resetUi2}
                        bundleId={this.state.bundleId}
                        role={this.state.role}
                    />
                );
                ui2 = this.state.ui2 === null ? this.emptyDiv : this.state.ui2;
                break;

            case appEnums.RootPage.Message: {
                const deviceId = this.state.selectedMsgData ? this.state.selectedMsgData.deviceId : "";
                ui1 = (
                    <MsgListUi
                        token={this.state.token}
                        bundleId={this.state.bundleId}
                        selectedDeviceId={deviceId}
                        onSelectItemCallBack={this.onSelectMsg}
                        appWebSocket={this.appWebSocket}
                        appEvent={this.appEvent}
                        onChangeApp={this.onChangeApp}
                        appInfoDict={this.state.appInfoDict}
                        hcCompanyId={this.state.hcCompanyId}
                        onClickCreateAppButton={this.onClickCreateAppButton}
                        messageStateFilter={this.state.messageStateFilter}
                        setMessageStateFilter={this.setMessageStateFilter}
                        role={this.state.role}
                        shortcut={this.shortcut}
                        newMessageCount={this.state.newMessageCount}
                    />
                );
                ui2 = (
                    <Match path="/messages/:bundleId/:deviceId">
                        {urlParams =>
                            urlParams.match ? (
                                <ChatUi
                                    bundleId={urlParams.match.bundleId}
                                    deviceId={urlParams.match.deviceId}
                                    hcAccountId={this.state.hcAccountId}
                                    hcCompanyId={this.state.hcCompanyId}
                                    token={this.state.token}
                                    msgData={this.state.selectedMsgData}
                                    appWebSocket={this.appWebSocket}
                                    appEvent={this.appEvent}
                                    profileImage={this.state.profileImage}
                                    setMessageStateFilter={this.setMessageStateFilter}
                                    shortcut={this.shortcut}
                                    onFetchConversation={this.onFetchConversation}
                                    onEventFetchError={this.onEventFetchError}
                                    setDialogBox={this.setDialogBox}
                                    onSelectItemCallBack={this.onSelectMsg}
                                    role={this.state.role}
                                    guestPermissionLevel={this.state.guestPermissionLevel}
                                />
                            ) : (
                                <NoMessageSelected />
                            )
                        }
                    </Match>
                );
                break;
            }
            case appEnums.RootPage.Help:
                ui1 = <HelpUi />;
                break;

            case appEnums.RootPage.Faq:
                ui1 = (
                    <FaqUi
                        onClickNewFaq={this.onClickNewFaq}
                        onFaqArticleView={this.onFaqArticleView}
                        resetUi2={this.resetUi2}
                        selectedFaqId={this.state.selectedFaqId}
                        hcCompanyId={this.state.hcCompanyId}
                        token={this.state.token}
                        appInfoDict={this.state.appInfoDict}
                        onEventFetchError={this.onEventFetchError}
                        onChangeApp={this.onChangeApp}
                        bundleId={this.state.bundleId}
                        onClickCreateAppButton={this.onClickCreateAppButton}
                        appEvent={this.appEvent}
                        role={this.state.role}
                        appWebSocket={this.appWebSocket}
                    />
                );
                ui2 = this.state.ui2;
                break;

            case appEnums.RootPage.Home:
            default:
                ui1 = <HomeUi />;
                break;
        }

        const appMainDiv = (
            <div className="main-container">
                <ToastContainer
                    position={toast.POSITION.TOP_RIGHT}
                    autoClose={false}
                    newestOnTop={true}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    transition={Flip}
                />
                {this.state.isLogin && this.state.rootPage !== appEnums.RootPage.Login && (
                    <MenuUi
                        priorityNewMessageCount={this.state.priorityNewMessageCount}
                        newMessageCount={this.state.newMessageCount}
                        priorityOpenMessageCount={this.state.priorityOpenMessageCount}
                        openMessageCount={this.state.openMessageCount}
                        pendingMessageCount={this.state.pendingMessageCount}
                        closedMessageCount={this.state.closedMessageCount}
                        messageStateFilter={this.state.messageStateFilter}
                        setMessageStateFilter={this.setMessageStateFilter}
                        rootPage={this.state.rootPage}
                        onShowMenu={this.onShowMenu}
                        isLogin={this.state.isLogin}
                        onLogoutCallback={this.onLogout}
                        profileImage={this.state.profileImage}
                        role={this.state.role}
                        bundleId={this.state.bundleId}
                        token={this.state.token}
                    />
                )}
                {ui1} {ui2}
                {dialogBoxUi}
            </div>
        );

        return (
            <Match path="/">
                {urlParams => {
                    if (urlParams.match) {
                        return appMainDiv;
                    }

                    const pathname = urlParams.location.pathname;
                    const pathnameArray = pathname.split("/");
                    if (pathnameArray[1] === "faq" && pathnameArray[2] === "public") {
                        const bundleId = pathnameArray[3];
                        const appFaqId = pathnameArray[4];
                        if (bundleId && appFaqId) {
                            return <FaqPublicView bundleId={bundleId} appFaqId={appFaqId} />;
                        }
                    } else if (pathnameArray[1] === "instantPush" && pathnameArray[2] === "public") {
                        const bundleId = pathnameArray[3];
                        const keyData = pathnameArray[4];
                        return <DeepLinking bundleId={bundleId} keyData={keyData} />;
                    }
                    return appMainDiv;
                }}
            </Match>
        );
    }
}

export default App;
