import React, { Component } from 'react';
import FaqEditor from './faqEditor';
import AppEvent from '../appEvent';

class FaqCreateUi extends Component {
    constructor(props) {
        super(props);
        this.onSaveAsDraft = this.onSaveAsDraft.bind(this);
        this.onPublish = this.onPublish.bind(this);
    }

    componentDidUpdate() {
        // if user click the + NEW FAQ button again
        const { appEvent } = this.props;
        if (appEvent) {
            appEvent.fireEvent(AppEvent.EVENT_FAQ_CLEAR_EDITOR);
        }
    }

    onSaveAsDraft() {
        const { appEvent } = this.props;
        if (appEvent) {
            appEvent.fireEvent(AppEvent.EVENT_FAQ_SAVE_DRAFT);
        }
    }

    onPublish() {
        const { appEvent } = this.props;
        if (appEvent) {
            appEvent.fireEvent(AppEvent.EVENT_FAQ_PUBLISH);
        }
    }

    render() {
        const { appEvent, appWebSocket, hcAccountId, hcCompanyId, bundleId, appInfo } = this.props;
        return (
            <div className="background-color-v2 ui2-container-div">
                <div className="faq-editor-main-div ">
                    <span className="faq-editor-div-header">CREATE NEW ARTICLE</span>
                    <FaqEditor
                        appEvent={appEvent}
                        appWebSocket={appWebSocket}
                        hcAccountId={hcAccountId}
                        hcCompanyId={hcCompanyId}
                        bundleId={bundleId}
                        appInfo={appInfo}
                    />
                    <div className="faq-buttons-container">
                        <input
                            type="button"
                            value="SAVE AS DRAFT"
                            id="faqCreateDraft"
                            alt="Save As Draft"
                            onClick={this.onSaveAsDraft}
                            title="Save as Draft Button"
                        />
                        <input
                            type="button"
                            value="PUBLISH"
                            id="faqCreatePublish"
                            alt="Publish"
                            onClick={this.onPublish}
                            title="Publish Button"
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default FaqCreateUi;
