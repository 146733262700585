import events from "events";
import io from "socket.io-client";
import appConstants from "./appConstants";
import appEnums from "./appEnums";
import webApi from "./api/webApi";
import commonApi from "./api/commonApi";
import { toast } from "react-toastify";

class AppWebSocket {
    constructor() {
        this.eventEmitter = new events.EventEmitter();
        this.webSocketConnection = null;
        this.socket = null;

        this.hcCompanyId = -1;
        this.hcAccountId = -1;
        this.onConnectCallback = null;
    }

    connectToServer = (hcAccountId, hcCompanyId, token, onConnectCallback) => {
        this.hcAccountId = hcAccountId;
        this.hcCompanyId = hcCompanyId;
        this.onConnectCallback = onConnectCallback;

        this.socket = io(webApi.getChatSocket(), {
            auth: { token }
        });
        this.socket.on("connect", this.onConnect);
        this.socket.on("disconnect", this.onDisconnect);
        this.socket.on("error", this.onError);
        this.socket.on(appConstants.EVENT_MESSAGE, this.onMessage);
        this.socket.on(appConstants.EVENT_TYPING, this.onTyping);
    };

    disconnect = () => {
        if (this.socket !== null) {
            this.socket.off("connect", this.onConnect);
            this.socket.off("error", this.onError);
            this.socket.off(appConstants.EVENT_MESSAGE, this.onMessage);
            this.socket.off(appConstants.EVENT_TYPING, this.onTyping);
            this.socket.close();
            this.socket.off("disconnect", this.onDisconnect);
            this.socket = null;
        }

        if (this.eventEmitter !== null) {
            this.eventEmitter.removeAllListeners();
        }
    };

    onConnect = () => {
        console.log("AppWebSocket.onConnect");
        this.onConnectCallback?.();
    };
    onDisconnect = (reason) => {
        if (reason === "io server disconnect") {
            commonApi.displayToast("Failed to authenticate with WebSocket server – Please login again",
                "__WebSocket_Disconnected__",
                toast.TYPE.ERROR,
                { theme: "dark"})
        }
        console.log("AppWebSocket.onDisconnect");
    };
    onError = error => {
        console.log("AppWebSocket.onError: " + error);
    };

    sendSocketInfo = bundleId => {
        const json = {
            hcAccountId: this.hcAccountId,
            hcCompanyId: this.hcCompanyId,
            bundleId: bundleId,
            ipAddress: webApi.userIP
        };
        if (this.socket !== null) {
            this.socket.emit(appConstants.EVENT_SOCKET_INFO, json);
        }
    };

    uploadAppIcon = (hcCompanyId, hcAccountId, binaryImage, imageType, fileName) => {
        return new Promise((resolve, reject) => {
            const payload = { hcCompanyId, hcAccountId, binaryImage, imageType, fileName };
            if (this.socket !== null) {
                this.socket.emit(appConstants.EVENT_UPLOAD_APP_ICON, payload, resultJson => {
                    resolve(resultJson);
                });
            } else {
                reject({});
            }
        });
    };

    updateFaqArticlesOrder = (bundleId, orders) => {
        return new Promise((resolve, reject) => {
            if (this.socket !== null) {
                const json = {
                    hcCompanyId: this.hcCompanyId,
                    bundleId: bundleId,
                    orders: orders
                };
                this.socket.emit(appConstants.EVENT_UPDATE_FAQS_ORDER, json, resultJson => {
                    resolve(resultJson);
                });
            } else {
                reject({});
            }
        });
    };

    sendMessage = (bundleId, deviceId, message, html, onStartCallback) => {
        return new Promise((resolve, reject) => {
            const json = {
                sid: Date.now(),
                hcAccountId: this.hcAccountId,
                bundleId: bundleId,
                deviceId: deviceId,
                message: message,
                html: html
            };
            if (this.socket !== null) {
                if (onStartCallback !== null) {
                    onStartCallback(json.sid);
                }
                this.socket.emit(appConstants.EVENT_MESSAGE, json, resultJson => {
                    resolve(resultJson);
                });
            } else {
                reject();
            }
        });
    };

    sendInternalNotes = (bundleId, deviceId, message, sender, onStartCallback) => {
        return new Promise((resolve, reject) => {
            const json = {
                sid: Date.now(),
                hcAccountId: this.hcAccountId,
                bundleId,
                deviceId,
                message,
                isNote: true,
                sender
            };
            if (this.socket !== null) {
                if (onStartCallback !== null) {
                    onStartCallback(json.sid);
                }
                this.socket.emit(appConstants.EVENT_INTERNAL_NOTES, json, resultJson => {
                    resolve(resultJson);
                });
            } else {
                reject({});
            }
        });
    };

    sendImage = (bundleId, deviceId, binaryImage, imageType, onStartCallback) => {
        return new Promise((resolve, reject) => {
            const json = {
                sid: Date.now(),
                hcAccountId: this.hcAccountId,
                bundleId: bundleId,
                deviceId: deviceId,
                message: "(photo)",
                html: "<p>(photo)</p>",
                binaryImage: binaryImage,
                imageType: imageType
            };
            if (this.socket !== null) {
                if (onStartCallback !== null) {
                    onStartCallback(json.sid);
                }
                this.socket.emit(appConstants.EVENT_MESSAGE, json, resultJson => {
                    resolve(resultJson);
                });
            } else {
                reject();
            }
        });
    };

    sendVideoLink = (bundleId, deviceId, videoLink, onStartCallback) => {
        return new Promise((resolve, reject) => {
            const json = {
                sid: Date.now(),
                hcAccountId: this.hcAccountId,
                bundleId: bundleId,
                deviceId: deviceId,
                message: "(video)",
                html: "<p>(video)</p>",
                video: videoLink
            };
            if (this.socket) {
                if (onStartCallback) {
                    onStartCallback(json.sid);
                }
                this.socket.emit(appConstants.EVENT_MESSAGE, json, resultJson => {
                    resolve(resultJson);
                });
            } else {
                reject();
            }
        });
    };

    sendVideoFile = (bundleId, deviceId, binaryVideo, videoType, onStartCallback) => {
        return new Promise((resolve, reject) => {
            const json = {
                sid: Date.now(),
                hcAccountId: this.hcAccountId,
                bundleId: bundleId,
                deviceId: deviceId,
                message: "(video)",
                html: "<p>(video)</p>",
                binaryVideo: binaryVideo,
                videoType: videoType
            };
            if (this.socket) {
                if (onStartCallback) {
                    onStartCallback({ sid: json.sid, video: "" });
                }
                this.socket.emit(appConstants.EVENT_MESSAGE, json, resultJson => {
                    resolve(resultJson);
                });
            } else {
                reject();
            }
        });
    };

    uploadProfileImage = (hcAccountId, hcCompanyId, stringBase64, remoteAddress, token) => {
        return new Promise((resolve, reject) => {
            const json = { hcAccountId, hcCompanyId, stringBase64, remoteAddress, token };
            if (this.socket !== null) {
                this.socket.emit(appConstants.EVENT_UPLOAD_PROFILE_IMAGE, json, resultJson => resolve(resultJson));
            } else {
                reject();
            }
        });
    };

    uploadFaqImage = (hcAccountId, hcCompanyId, bundleId, image) => {
        return new Promise((resolve, reject) => {
            const json = {
                hcAccountId: hcAccountId,
                hcCompanyId: hcCompanyId,
                bundleId: bundleId,
                image: image
            };
            if (this.socket !== null) {
                this.socket.emit(appConstants.EVENT_UPLOAD_FAQ_IMAGE, json, resultJson => resolve(resultJson));
            } else {
                reject();
            }
        });
    };

    deleteFaqImage = (hcAccountId, imageKey) => {
        return new Promise((resolve, reject) => {
            const json = { hcAccountId: hcAccountId, imageKey: imageKey };
            if (this.socket !== null) {
                this.socket.emit(appConstants.EVENT_DELETE_FAQ_IMAGE, json, resultJson => resolve(resultJson));
            } else {
                reject();
            }
        });
    };

    addFaq = json => {
        return new Promise((resolve, reject) => {
            if (this.socket !== null) {
                this.socket.emit(appConstants.EVENT_ADD_FAQ, json, resultJson => resolve(resultJson));
            } else {
                reject();
            }
        });
    };

    deleteFaq = json => {
        return new Promise((resolve, reject) => {
            if (this.socket !== null) {
                this.socket.emit(appConstants.EVENT_DELETE_FAQ, json, resultJson => resolve(resultJson));
            } else {
                reject();
            }
        });
    };

    retrieveFaq = json => {
        return new Promise((resolve, reject) => {
            if (this.socket !== null) {
                this.socket.emit(appConstants.EVENT_RETRIEVE_FAQ, json, resultJson => resolve(resultJson));
            } else {
                reject();
            }
        });
    };

    saveChangesFaq = json => {
        return new Promise((resolve, reject) => {
            if (this.socket !== null) {
                this.socket.emit(appConstants.EVENT_SAVE_CHANGES_FAQ, json, resultJson => resolve(resultJson));
            } else {
                reject();
            }
        });
    };

    updateConversationMetadataTags = json => {
        return new Promise((resolve, reject) => {
            if (this.socket !== null) {
                json.hcAccountId = this.hcAccountId;
                this.socket.emit(appConstants.EVENT_UPDATE_CONVERSATION_METADATA_TAGS, json, resultJson => resolve(resultJson));
            } else {
                reject();
            }
        });
    };

    sendAskRating = (bundleId, deviceId, onStartCallback) => {
        return new Promise((resolve, reject) => {
            const json = {
                sid: Date.now(),
                hcAccountId: this.hcAccountId,
                bundleId: bundleId,
                deviceId: deviceId,
                message: "(askRating)",
                html: "<p>(askRating)</p>",
                askRating: appEnums.AskRatingStates.None
            };
            if (this.socket !== null) {
                if (onStartCallback !== null) {
                    onStartCallback(json.sid);
                }
                this.socket.emit(appConstants.EVENT_MESSAGE, json, resultJson => {
                    resolve(resultJson);
                });
            } else {
                reject();
            }
        });
    };

    sendChatSurvey = (bundleId, deviceId, onStartCallback) => {
        return new Promise((resolve, reject) => {
            const json = {
                sid: Date.now(),
                hcAccountId: this.hcAccountId,
                bundleId: bundleId,
                deviceId: deviceId,
                message: "(chatSurvey)",
                html: "<p>(chatSurvey)</p>",
                chatSurvey: appEnums.ChatSurveyStates.None
            };
            if (this.socket !== null) {
                if (onStartCallback !== null) {
                    onStartCallback(json.sid);
                }
                this.socket.emit(appConstants.EVENT_MESSAGE, json, resultJson => {
                    resolve(resultJson);
                });
            } else {
                reject();
            }
        });
    };

    onMessage = json => {
        if (this.eventEmitter !== null) {
            this.eventEmitter.emit("onMessage", json);
        }
    };

    addMessageCallback = listener => {
        if (this.eventEmitter !== null) {
            this.eventEmitter.on("onMessage", listener);
        }
    };

    removeMessageCallback = listener => {
        if (this.eventEmitter !== null) {
            this.eventEmitter.removeListener("onMessage", listener);
        }
    };

    sendSelectedConversation = (bundleId, deviceId) => {
        if (this.socket !== null && this.socket.connected) {
            const json = {
                hcAccountId: this.hcAccountId,
                bundleId: bundleId,
                deviceId: deviceId
            };
            this.socket.emit(appConstants.EVENT_SELECT_CONVERSATION, json);
        }
    };

    sendTyping = (bundleId, deviceId) => {
        const json = {
            hcAccountId: this.hcAccountId,
            bundleId: bundleId,
            deviceId: deviceId
        };
        if (this.socket !== null && this.socket.connected) {
            this.socket.emit(appConstants.EVENT_TYPING, json);
        }
    };

    onTyping = json => {
        if (this.eventEmitter !== null && json !== null) {
            this.eventEmitter.emit("onTyping", json);
        }
    };

    addTypingCallback = listener => {
        if (this.eventEmitter !== null) {
            this.eventEmitter.on("onTyping", listener);
        }
    };

    removeTypingCallback = listener => {
        if (this.eventEmitter !== null) {
            this.eventEmitter.removeListener("onTyping", listener);
        }
    };

    sendChangeState = (bundleId, deviceId, state) => {
        const json = {
            hcAccountId: this.hcAccountId,
            bundleId: bundleId,
            deviceId: deviceId,
            state: state
        };
        if (this.socket !== null) {
            this.socket.emit(appConstants.EVENT_CHANGE_STATE, json);
        }
    };

    updateAPNFiles = (hcCompanyId, bundleId, oldFileName, fileName, fileData, isSandbox) => {
        return new Promise((resolve, reject) => {
            const json = {
                hcCompanyId: hcCompanyId,
                hcAccountId: this.hcAccountId,
                bundleId: bundleId,
                oldFileName: oldFileName,
                fileName: fileName,
                fileData: fileData,
                isSandbox: isSandbox
            };
            if (this.socket !== null) {
                this.socket.emit(appConstants.EVENT_UPDATE_APN_FILES, json, resultJson => {
                    resolve(resultJson);
                });
            } else {
                reject();
            }
        });
    };

    waitingForLinkDevice = (bundleId, isStart) => {
        return new Promise((resolve, reject) => {
            const json = {
                hcAccountId: this.hcAccountId,
                bundleId: bundleId,
                isStart: isStart
            };
            if (this.socket !== null) {
                this.socket.emit(appConstants.EVENT_LINK_DEVICE, json, resultJson => {
                    resolve(resultJson);
                });
            } else {
                reject();
            }
        });
    };

    waitingPushToken = (bundleId, deviceId, isAndroid, isStart) => {
        return new Promise((resolve, reject) => {
            const json = {
                hcAccountId: this.hcAccountId,
                bundleId: bundleId,
                deviceId: deviceId,
                isAndroid: isAndroid,
                isStart: isStart
            };
            if (this.socket !== null) {
                this.socket.emit(appConstants.EVENT_INSTANT_PUSH_TOKEN, json, resultJson => {
                    resolve(resultJson);
                });
            } else {
                reject();
            }
        });
    };

    waitingForOpenInstantPush = (bundleId, deviceId, isStart) => {
        return new Promise((resolve, reject) => {
            const json = {
                hcAccountId: this.hcAccountId,
                bundleId: bundleId,
                deviceId: deviceId,
                isStart: isStart
            };
            if (this.socket !== null) {
                this.socket.emit(appConstants.EVENT_OPEN_INSTANT_PUSH, json, resultJson => {
                    resolve(resultJson);
                });
            } else {
                reject();
            }
        });
    };
}

export default AppWebSocket;
