import React from "react";
import AppMenuItem from "./appMenuItem";
import appEnums from "../appEnums";
import { Link } from "@reach/router";
import webApi from "../api/webApi";
import commonApi from "../api/commonApi";
import { toast } from "react-toastify";

export default class AppMenuListUi extends React.Component {
    constructor(props) {
        super(props);
        this.state = { newMessageCounts: {} };
        this.toastId = "__APP_MENU_LIST_UI__";
    }

    componentDidMount() {
        const { token, hcCompanyId } = this.props;
        webApi
            .fetchGetCompanyNewMessageCounts(hcCompanyId, token)
            .then(newMessageCounts => {
                this.setState({ newMessageCounts });
            })
            .catch(error => {
                commonApi.displayToast(error.error, this.toastId, toast.TYPE.ERROR);
            });
    }

    render() {
        const { bundleId, appInfoDict, onChangeApp, onClickCreateAppButton, role, token } = this.props;
        const itemListUi = Object.keys(appInfoDict).map(id => {
            return (
                <Link to="/" key={id}>
                    <AppMenuItem
                        bundleId={id}
                        appInfoDict={appInfoDict}
                        onChangeApp={onChangeApp}
                        isSelected={bundleId === id}
                        newMessageCount={this.state.newMessageCounts[id]}
                        token={token}
                    />
                </Link>
            );
        });

        return (
            <div className="app-menu-list-div slide-up-animation">
                <div className="app-menu-list">{itemListUi}</div>
                {role != null && role !== appEnums.Role.Guest && role !== appEnums.Role.Agent && (
                    <div id="createNewAppDiv">
                        <Link to="/">
                            <button id="createNewAppButton" onClick={onClickCreateAppButton}>
                                CREATE NEW APP
                            </button>
                        </Link>
                    </div>
                )}
            </div>
        );
    }
}
