import React, { Component } from 'react';
import './developerConsoleUi.css';
import appEnums from '../appEnums';

class DeveloperConsoleUi extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.addAppSrc = '/images/devconsole/icon_add_app.png';
        this.editAndConfiAppSrc = '/images/devconsole/icon_edit_config.png';
        this.sdkSrc = '/images/devconsole/icon_sdk.png';
        this.integrationSrc = '/images/devconsole/icon_integration.png';
        this.analyticsSrc = '/images/devconsole/icon_analytics.png';
        this.settingsSrc = '/images/devconsole/icon_settings.png';
        this.instantPushVerificationSrc = '/images/devconsole/icon_add_app.png';
        this.onClickAddApp = this.onClickAddApp.bind(this);
        this.onClickEditAndConfigureApp = this.onClickEditAndConfigureApp.bind(this);
        this.onClickSdk = this.onClickSdk.bind(this);
        this.onClickIntegration = this.onClickIntegration.bind(this);
        this.onClickAnalytics = this.onClickAnalytics.bind(this);
        this.onClickSettings = this.onClickSettings.bind(this);
        this.onClickInstantPushVerification = this.onClickInstantPushVerification.bind(this);
        this.setUpButtonGenerator = this.setUpButtonGenerator.bind(this);
        this.readDocsGenerator = this.readDocsGenerator.bind(this);
    }

    onClickAddApp() {
        this.props.onShowMenu(appEnums.RootPage.CreateApp);
    }

    onClickEditAndConfigureApp() {
        this.props.onShowMenu(appEnums.RootPage.AppSetting);
    }

    onClickSdk() { }

    onClickIntegration() { }

    onClickAnalytics() { }

    onClickSettings() { }

    onClickInstantPushVerification() {
        this.props.onShowMenu(appEnums.RootPage.InstantPushVerification);
    }

    setUpButtonGenerator(domId, onClickBehavior) {
        return (
            <button className="console-set-up-button" onClick={onClickBehavior} id={domId}>
                SET UP
            </button>
        );
    }

    readDocsGenerator(domId, onClickBehavior = null) {
        return (
            <button className="read-docs-button" id={domId} onClick={onClickBehavior}>
                Read Docs
            </button>
        );
    }

    render() {
        return (
            <div className="ui1-container-div-lg background-color-v2 slide-up-animation">
                <div className="console-header-div">DEVELOPER&apos;S CONSOLE</div>
                <div className="console-card-container">
                    <div className="console-card">
                        <img alt="Add App" title="Add App" src={this.addAppSrc} className="console-card-icon" />
                        <p className="console-card-label">CREATE APPLICATION</p>
                        <span className="console-card-description">Create New Application</span>
                        <hr className="console-card-hr" />
                        <div className="console-card-action">
                            {this.readDocsGenerator('createAppDocs')}
                            {this.setUpButtonGenerator('createAppButton', this.onClickAddApp)}
                        </div>
                    </div>

                    <div className="console-card">
                        <img
                            alt="Edit and Configure App"
                            title="Edit and Configure App"
                            src={this.editAndConfiAppSrc}
                            className="console-card-icon"
                        />
                        <p className="console-card-label">EDIT AND CONFIGURE APPLICATION</p>
                        <span className="console-card-description">Configure and manage your apps</span>
                        <hr className="console-card-hr" />
                        <div className="console-card-action">
                            {this.readDocsGenerator('appSettingDocs')}
                            {this.setUpButtonGenerator('appSettingButton', this.onClickEditAndConfigureApp)}
                        </div>
                    </div>

                    <div className="console-card">
                        <img alt="SDK" title="SDK" src={this.sdkSrc} className="console-card-icon" />
                        <p className="console-card-label">SDK</p>
                        <span className="console-card-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                        <hr className="console-card-hr" />
                        <div className="console-card-action">
                            {this.readDocsGenerator('sdkDocs')}
                            {this.setUpButtonGenerator('sdkSetupButton', this.onClickSdk)}
                        </div>
                    </div>

                    <div className="console-card">
                        <img alt="Integration" title="Integration" src={this.integrationSrc} className="console-card-icon" />
                        <p className="console-card-label">INTEGRATION</p>
                        <span className="console-card-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                        <hr className="console-card-hr" />
                        <div className="console-card-action">
                            {this.readDocsGenerator('integrationDocs')}
                            {this.setUpButtonGenerator('integrationSetupButton', this.onClickIntegration)}
                        </div>
                    </div>

                    <div className="console-card">
                        <img alt="Analytics" title="Analytics" src={this.analyticsSrc} className="console-card-icon" />
                        <p className="console-card-label">ANALYTICS</p>
                        <span className="console-card-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                        <hr className="console-card-hr" />
                        <div className="console-card-action">
                            {this.readDocsGenerator('analyticsDocs')}
                            {this.setUpButtonGenerator('analyticsSetupButton', this.onClickAnalytics)}
                        </div>
                    </div>

                    <div className="console-card">
                        <img alt="Settings" title="Settings" src={this.settingsSrc} className="console-card-icon" />
                        <p className="console-card-label">SETTINGS</p>
                        <span className="console-card-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                        <hr className="console-card-hr" />
                        <div className="console-card-action">
                            {this.readDocsGenerator('settingsDocs')}
                            {this.setUpButtonGenerator('settingSetupButton', this.onClickSettings)}
                        </div>
                    </div>

                    <div className="console-card">
                        <img alt="InstantPushVerification" title="InstantPushVerification" src={this.instantPushVerificationSrc} className="console-card-icon" />
                        <p className="console-card-label">Instant Push Verification</p>
                        <span className="console-card-description">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</span>
                        <hr className="console-card-hr" />
                        <div className="console-card-action">
                            {this.readDocsGenerator('instantPushVerificationDocs')}
                            {this.setUpButtonGenerator('instantPushVerificationSetupButton', this.onClickInstantPushVerification)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeveloperConsoleUi;
