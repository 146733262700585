import React, { Component } from 'react';
import * as emoji from 'node-emoji';
import './templateBuilderUi.css';
import appConstants from "../appConstants";
import TemplateBuilderListUi from './templateBuilderListUi';
import webApi from '../api/webApi';
import AppEvent from '../appEvent';
import appEnums from "../appEnums";
import { toast } from 'react-toastify';
import AppMenuUi from "../appMenu/appMenuUi";

const { MAX_MESSAGE_ESCAPE_LENGTH } = appConstants;

class TemplateBuilderUi extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bundleId: "",
            initialBundleId: "",

            maxTemplateCount: 10,
            maxTitleLength: 46,
            maxMessageLength: MAX_MESSAGE_ESCAPE_LENGTH,

            templateList: [],
            initialTemplateList: [],
            error: '',
            errorType: toast.TYPE.ERROR // error, warning, success, default, info
        };

        this.mainNode = null;
        this.toastId = '__Template_Builder__';
        this.dismissError = () => this.setState({ error: '', errorType: toast.TYPE.ERROR });
    }

    componentDidMount() {
        const { bundleId } = this.props;

        this.onChangeApp(bundleId);
        this.onChangeInitialApp(bundleId);
    }

    componentDidUpdate(prevProps, prevState) {
        const { appEvent } = this.props;
        const bundleIdChange = this.state.bundleId !== this.state.initialBundleId;
        const templateChange = this.state.templateList !== this.state.initialTemplateList;

        const somethingChange = bundleIdChange || templateChange;
        if (appEvent != null) {
            if (somethingChange) appEvent.fireEvent(AppEvent.EVENT_SET_UNSAVED_CHANGES);
            else appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
        }

        if (prevState && this.state.error !== prevState.error && this.state.error) {
            if (toast.isActive(this.toastId)) {
                toast.update(this.toastId, { type: this.state.errorType, render: this.state.error });
            } else {
                toast(this.state.error, {
                    position: toast.POSITION.TOP_RIGHT,
                    type: this.state.errorType,
                    toastId: this.toastId,
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    pauseOnFocusLoss: false,
                    onClose: this.dismissError,
                    onClick: this.dismissError
                });
            }
        }
    }

    componentWillUnmount() {
        const { appEvent } = this.props;
        toast.dismiss(this.toastId);
        if (appEvent != null) appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
    }

    fetchGetTemplateBuilder = () => {
        const { hcCompanyId, token } = this.props;
        const { bundleId } = this.state;

        webApi
            .fetchGetTemplateBuilder(hcCompanyId, bundleId, token)
            .then(this.onFetchTemplateBuilder)
            .catch(errDict => this.setState({ error: errDict.error }));
    }

    onFetchTemplateBuilder = resDict => {
        this.setState({
            templateList: resDict.sort((a, b) => a.sequence - b.sequence),
            initialTemplateList: resDict.sort((a, b) => a.sequence - b.sequence)
        });
    };

    onChangeTemplateBuilder = () => {
        this.setState(
            {
                error: 'Saved',
                errorType: toast.TYPE.SUCCESS,
                initialTemplateList: this.state.templateList,
            },
            () => {
                const { appEvent } = this.props;
                if (appEvent != null) appEvent.fireEvent(AppEvent.EVENT_UNSET_UNSAVED_CHANGES);
            }
        );
    };

    handleSubmit = event => {
        event.preventDefault();
        this.dismissError();

        const verify = this.verifyTemplateList();

        if (verify) {
            const { hcCompanyId, token } = this.props;
            const { bundleId, templateList } = this.state;
            const metaData = {
                templateList
            };

            webApi
                .fetchChangeTemplateBuilder(hcCompanyId, bundleId, JSON.stringify(metaData), token)
                .then(this.onChangeTemplateBuilder)
                .catch(errDict => this.setState({ error: errDict.error }));
        }
        else this.setState({ error: 'Please complete the form.' });
    };

    verifyTemplateList = () => {
        const { templateList } = this.state;

        let complete = true;

        templateList.forEach(data => {
            if (emoji.strip(data.title).length === 0 || emoji.strip(data.message).length === 0) {
                complete = false;
            }
        });

        return complete;
    }

    handleCancelButton = event => {
        event.preventDefault();
        this.fetchGetTemplateBuilder();
        this.setState({ error: 'Changes Canceled', errorType: toast.TYPE.WARNING });
    };

    onChangeApp = bundleId => {
        this.setState({ bundleId },
            () => {
                this.fetchGetTemplateBuilder()
            }
        );
    };

    onChangeInitialApp = initialBundleId => {
        this.setState({ initialBundleId });
    };

    addTemplate = () => {
        const { templateList, maxTemplateCount } = this.state;

        if (templateList.length === maxTemplateCount) {
            this.setState({ error: 'Only ' + maxTemplateCount + ' templates allowed.' });
            return false;
        }

        templateList.push(
            {
                languageCode: "en",
                title: "",
                message: "",
                shortcut: "",
                publish: true
            },
        )

        this.onChangeTemplateList(templateList);
    }

    onChangeTemplateList = (templateList) => {
        this.setState({ templateList });
    }

    render() {
        return (
            <div id="templateTranslation"
                className="ui2-container-div template-container-div slide-up-animation"
                ref={n => (this.mainNode = n)}>
                <span className="template-main-heading">TEMPLATE BUILDER</span>

                <div className="template-content-block">
                    <p className="template-div-header">Name of App/Game</p>
                    <p className="template-content-label">
                        Assign templates for each app/games.
                    </p>

                    <div className="d-inline-block w-50">
                        <AppMenuUi
                            hcCompanyId={this.props.hcCompanyId}
                            appInfoDict={this.props.appInfoDict}
                            bundleId={this.state.bundleId}
                            onChangeApp={this.onChangeApp}
                            role={appEnums.Role.Agent}
                            token={this.props.token}
                        />
                    </div>
                </div>

                <div className="template-content-block">
                    <p className="template-div-header">Templates</p>
                    <p className="template-content-label">
                        List down frequently used templates. (Max 10)
                    </p>
                    <div className="template-option-container">
                        <TemplateBuilderListUi
                            hcCompanyId={this.props.hcCompanyId}
                            bundleId={this.state.bundleId}
                            templateList={this.state.templateList}
                            maxTemplateCount={this.state.maxTemplateCount}
                            maxTitleLength={this.state.maxTitleLength}
                            maxMessageLength={this.state.maxMessageLength}
                            onChangeTemplateList={this.onChangeTemplateList}
                            appEnums={appEnums}
                        />
                        <br />
                        <button
                            id="templatePageAddButton"
                            onClick={this.addTemplate}
                        >
                            &nbsp;&nbsp;Add Template
                        </button>
                    </div>
                </div>

                <form onSubmit={this.handleSubmit} className="mt-5">
                    <input id="templatePageSubmitButton" type="submit" value="SAVE" alt="Save Changes" />
                    <input
                        id="templatePageCancelButton"
                        type="button"
                        value="CANCEL"
                        alt="Cancel Changes"
                        onClick={this.handleCancelButton}
                    />
                </form>
            </div>
        );
    }
}

export default TemplateBuilderUi;
