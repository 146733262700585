import React, { Component } from "react";
import "./message.css";
import appEnums from "../appEnums";
import MsgListItem from "./msgListItem";
import webApi from "../api/webApi";
import AppEvent from "../appEvent";
import AppMenuUi from "../appMenu/appMenuUi";
import { Link } from "@reach/router";
import Loading from "../loading";
import { ConversationFilterForm, ConversationFilterFormTrigger } from "../conversationFilterForm";

export default class MsgListUi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            conversations: [],
            isLoading: true,
            filters: {
                timestampStart: null,
                timestampEnd: null,
                dateAscending: false,
                deviceType: "All",
                sdk: "",
                appVersion: "",
                country: "",
            },
        };
    }

    scrollTimeout = null;
    searchTimeout = null;
    controller = new AbortController();
    selectedDataIndex = -1;
    conversationDictionary = {};
    listNode = null;

    getCurrentConversations = () => {
        return this.state.conversations[this.props.messageStateFilter];
    };

    sortFunction = (a, b) => {
        return !this.state.filters.dateAscending ? b.timestamp - a.timestamp : a.timestamp - b.timestamp;
    };

    onApplyFilters = newFilters => {
        this.setState({
            filters: newFilters,
            conversations: [],
            isLoading: true,
        }, () => this.fetchConversations(this.props.bundleId));
    };

    filterDeviceFunction = conversation => {
        if (this.state.filters.deviceType === "Android") {
            return !conversation.deviceId.includes("-");
        } //IOS
        else {
            return conversation.deviceId.includes("-");
        }
    };

    componentDidMount() {
        const { appWebSocket, appEvent, bundleId } = this.props;

        if (this.listNode) this.listNode.addEventListener("scroll", this.handleScroll);
        if (appWebSocket) appWebSocket.addMessageCallback(this.onReceiveMessage);

        this.fetchConversations(bundleId);

        if (appEvent) {
            appEvent.addCallback(AppEvent.EVENT_SEND_MESSAGE, this.onSendMessage);
            appEvent.addCallback(AppEvent.EVENT_CHANGE_MESSAGE_STATE, this.onChangeConversationState);
            appEvent.addCallback(AppEvent.EVENT_SORT_MESSAGE_LIST_TIMESTAMP, this.sortConversationsByTimestamp);
            appEvent.addCallback(AppEvent.EVENT_FETCH_APP_CONVERSATIONS, this.fetchConversations);
            appEvent.addCallback(AppEvent.EVENT_ADD_CONVERSATION_TAG, this.onAddConversationTag);
        }
        this.registerKeyboardShortcuts();
    }

    componentDidUpdate(prevProps, prevState) {
        const { appEvent, bundleId, messageStateFilter } = this.props;
        if (appEvent && prevProps.bundleId !== bundleId) {
            appEvent.fireEvent(AppEvent.EVENT_FETCH_APP_CONVERSATIONS, bundleId);
        }

        if (prevProps.messageStateFilter !== messageStateFilter) {
            setTimeout(this.scrollToTop, 100);
            if (!this.state.conversations[messageStateFilter]) {
                this.setState({ isLoading: true }, () => {
                    this.fetchConversations(bundleId);
                });
            } else if (this.state.isLoading) {
                if (!this.controller.signal.aborted) {
                    this.controller.abort();
                    this.controller = new AbortController();
                }
                this.setState({ isLoading: false });
            }
        }
    }

    componentWillUnmount() {
        if (this.listNode) {
            this.listNode.removeEventListener("scroll", this.handleScroll);
        }
        const { appWebSocket, appEvent } = this.props;
        if (appWebSocket) {
            appWebSocket.removeMessageCallback(this.onReceiveMessage);
        }
        if (appEvent) {
            appEvent.removeCallback(AppEvent.EVENT_SEND_MESSAGE, this.onSendMessage);
            appEvent.removeCallback(AppEvent.EVENT_CHANGE_MESSAGE_STATE, this.onChangeConversationState);
            appEvent.removeCallback(AppEvent.EVENT_SORT_MESSAGE_LIST_TIMESTAMP, this.sortConversationsByTimestamp);
            appEvent.removeCallback(AppEvent.EVENT_FETCH_APP_CONVERSATIONS, this.fetchConversations);
            appEvent.removeCallback(AppEvent.EVENT_ADD_CONVERSATION_TAG, this.onAddConversationTag);
        }
        this.removeKeyboardShortcuts();
        this.controller.abort();
    }

    fetchConversations = bundleId => {
        if (!this.controller.signal.aborted) {
            this.controller.abort();
            this.controller = new AbortController();
        }

        const { token, appEvent, hcCompanyId, messageStateFilter } = this.props;
        const { PriorityNew, PriorityOpen } = appEnums.MessageStates;

        const order = this.state.filters.dateAscending ? 1 : 0;
        let { searchText } = this.state;
        const { timestampStart, timestampEnd, sdk, appVersion, country } = this.state.filters;
        searchText = searchText.trim().toLowerCase();
        const timestampPagination = order ? 0 : Number.MAX_SAFE_INTEGER;

        if (
            messageStateFilter === -1 ||
            this.getCurrentConversations() ||
            ((messageStateFilter === PriorityNew || messageStateFilter === PriorityOpen) && searchText !== "")
        ) {
            return new Promise(resolve => {
                this.setState({ isLoading: false }, () => resolve());
            });
        }

        const fetchPromise = webApi
            .fetchConversations(
                this.controller.signal,
                hcCompanyId,
                bundleId,
                timestampPagination,
                timestampStart,
                timestampEnd,
                messageStateFilter,
                searchText,
                order,
                sdk,
                appVersion,
                country,
                token
            )
            .then(fetchedConversations => {
                const conversations = [...this.state.conversations];
                conversations[messageStateFilter] = fetchedConversations;
                this.setState({ conversations, isLoading: false }, () => this.updateConversationsDict());
            })
            .catch(errorDict => {
                if (appEvent) appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errorDict);
            });

        return fetchPromise;
    };

    updateConversationsDict = () => {
        const conversations = this.getCurrentConversations() || [];
        this.conversationDictionary = {};
        for (var i = 0; i < conversations.length; i++) {
            const conversation = conversations[i];
            this.conversationDictionary[conversation.deviceId] = conversation;
        }
    };

    onChangeSearch = event =>
        this.setState({ searchText: event.target.value }, () => {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(() => {
                this.setState({ isLoading: true, conversations: [] }, () => {
                    this.fetchConversations(this.props.bundleId).finally(() => {
                        this.searchTimeout = null;
                        this.setState({ isLoading: false });
                    });
                });
            }, 1000);
        });

    onAddConversationTag = jsonData => {
        const { appWebSocket, bundleId } = this.props;
        const msgData = this.conversationDictionary[jsonData.deviceId];
        msgData.tags = jsonData.tags;
        this.props.onSelectItemCallBack(msgData);
        const socketJson = { bundleId: bundleId, deviceId: jsonData.deviceId, tags: jsonData.tags };
        appWebSocket.updateConversationMetadataTags(socketJson);
    };

    previousMessageShortcut = () => {
        const currSize = this.getCurrentConversations().length;
        this.selectedDataIndex = (this.selectedDataIndex - 1) % currSize;
        this.selectedDataIndex = this.selectedDataIndex < 0 ? 0 : this.selectedDataIndex;
        this.onSelectItem(this.getCurrentConversations()[this.selectedDataIndex]);
    };

    nextMessageShortcut = () => {
        const currSize = this.getCurrentConversations().length;
        this.selectedDataIndex++;
        this.selectedDataIndex = this.selectedDataIndex > currSize - 1 ? currSize - 1 : this.selectedDataIndex;
        this.onSelectItem(this.getCurrentConversations()[this.selectedDataIndex]);
    };

    sortConversationsByTimestamp = () => {
        const selectedState = this.props.messageStateFilter;
        const conversations = [...this.state.conversations];
        const sortedConversationTab = conversations[selectedState];
        if (sortedConversationTab) {
            sortedConversationTab.sort(this.sortFunction);

            this.setState({ conversations }, () => {
                if (sortedConversationTab.length > 0) this.selectedDataIndex = 0;
            });
        }
    };

    messageTabShortcut = filter => {
        const { setMessageStateFilter, messageStateFilter } = this.props;
        if (messageStateFilter !== filter) {
            setMessageStateFilter(filter);
        }
        const conversations = this.state.conversations[filter];
        if (conversations.length > 0) {
            const data = conversations[0];
            this.onSelectItem(data);
        }
    };

    scrollToTop = () => {
        if (!this.listNode) return true;
        this.listNode.scrollTop = 0;
    };

    handleScroll = event => {
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        const isBottom = Math.floor(scrollHeight - scrollTop - 10) <= clientHeight;

        if (!isBottom) return true;
        if (this.scrollTimeout) return true;

        const { messageStateFilter, hcCompanyId, token, bundleId } = this.props;
        if (messageStateFilter === -1) return;

        const conversations = this.getCurrentConversations();
        const size = conversations.length;
        const lastConversation = conversations[size - 1];
        if (!lastConversation) return true;

        const order = this.state.filters.dateAscending ? 1 : 0;
        const { searchText } = this.state;
        const { timestampStart, timestampEnd, sdk, appVersion, country } = this.state.filters;
        const { timestamp } = lastConversation;

        this.scrollTimeout = setTimeout(() => {
            webApi
                .fetchConversations(
                    this.controller.signal,
                    hcCompanyId,
                    bundleId,
                    timestamp,
                    timestampStart,
                    timestampEnd,
                    messageStateFilter,
                    searchText,
                    order,
                    sdk,
                    appVersion,
                    country,
                    token,
                )
                .then(response => {
                    const { conversations } = this.state;

                    let filtered = conversations[messageStateFilter] || [];
                    if (response.length === 0) return;

                    response.forEach(ticket => {
                        const found = filtered.some(el => el.deviceId === ticket.deviceId);
                        if (!found) filtered.push(ticket);
                    });

                    conversations[messageStateFilter] = filtered;

                    this.setState({ conversations }, () => this.updateConversationsDict());
                })
                .catch(errorDict => {
                    const { appEvent } = this.props;
                    if (appEvent) appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errorDict);
                })
                .finally(() => {
                    this.scrollTimeout = null;
                });
        }, 500);

        return true;
    };

    onChangeApp = bundleId => {
        const { onChangeApp } = this.props;
        if (bundleId === this.props.bundleId) {
            return;
        }
        this.setState({ isLoading: true, conversations: [] }, () => {
            if (onChangeApp) onChangeApp(bundleId);
        });
    };

    registerKeyboardShortcuts = () => {
        const { shortcut } = this.props;
        const { New, Open, Closed } = appEnums.MessageStates;

        if (!shortcut) return;

        if (window.navigator.userAgent.indexOf("Mac") >= 0) {
            shortcut.add(appEnums.KeyboardShortcut.MacNewMessageTab, () => this.messageTabShortcut(New));
            shortcut.add(appEnums.KeyboardShortcut.MacOpenMessagesTab, () => this.messageTabShortcut(Open));
            shortcut.add(appEnums.KeyboardShortcut.MacClosedMessagesTab, () => this.messageTabShortcut(Closed));
            shortcut.add(appEnums.KeyboardShortcut.MacNextMessage0, this.nextMessageShortcut);
            shortcut.add(appEnums.KeyboardShortcut.MacNextMessage1, this.nextMessageShortcut);
            shortcut.add(appEnums.KeyboardShortcut.MacPrevMessage0, this.previousMessageShortcut);
            shortcut.add(appEnums.KeyboardShortcut.MacPrevMessage1, this.previousMessageShortcut);
        } else {
            shortcut.add(appEnums.KeyboardShortcut.WinNewMessagesTab, () => this.messageTabShortcut(New));
            shortcut.add(appEnums.KeyboardShortcut.WinOpenMessagesTab, () => this.messageTabShortcut(Open));
            shortcut.add(appEnums.KeyboardShortcut.WinClosedMessagesTab, () => this.messageTabShortcut(Closed));
            shortcut.add(appEnums.KeyboardShortcut.WinNextMessage0, this.nextMessageShortcut);
            shortcut.add(appEnums.KeyboardShortcut.WinNextMessage1, this.nextMessageShortcut);
            shortcut.add(appEnums.KeyboardShortcut.WinPrevMessage0, this.previousMessageShortcut);
            shortcut.add(appEnums.KeyboardShortcut.WinPrevMessage1, this.previousMessageShortcut);
        }
    };

    removeKeyboardShortcuts = () => {
        const { shortcut } = this.props;

        if (!shortcut) return;

        if (window.navigator.userAgent.indexOf("Mac") >= 0) {
            shortcut.remove(appEnums.KeyboardShortcut.MacNewMessageTab);
            shortcut.remove(appEnums.KeyboardShortcut.MacOpenMessagesTab);
            shortcut.remove(appEnums.KeyboardShortcut.MacClosedMessagesTab);
            shortcut.remove(appEnums.KeyboardShortcut.MacNextMessage0);
            shortcut.remove(appEnums.KeyboardShortcut.MacNextMessage1);
            shortcut.remove(appEnums.KeyboardShortcut.MacPrevMessage0);
            shortcut.remove(appEnums.KeyboardShortcut.MacPrevMessage1);
        } else {
            shortcut.remove(appEnums.KeyboardShortcut.WinNewMessagesTab);
            shortcut.remove(appEnums.KeyboardShortcut.WinOpenMessagesTab);
            shortcut.remove(appEnums.KeyboardShortcut.WinClosedMessagesTab);
            shortcut.remove(appEnums.KeyboardShortcut.WinNextMessage0);
            shortcut.remove(appEnums.KeyboardShortcut.WinNextMessage1);
            shortcut.remove(appEnums.KeyboardShortcut.WinPrevMessage0);
            shortcut.remove(appEnums.KeyboardShortcut.WinPrevMessage1);
        }
    };

    onSelectItem = msgData => {
        const { bundleId, selectedDeviceId, onSelectItemCallBack } = this.props;
        if (msgData.bundleId !== bundleId || msgData.deviceId !== selectedDeviceId) {
            this.selectedDataIndex = this.getCurrentConversations().indexOf(msgData);
            if (onSelectItemCallBack != null) {
                onSelectItemCallBack(msgData);
            }
        }
    };

    onSendMessage = info => {
        if ("chatJson" in info) {
            const chatJson = info.chatJson;
            const isInCurrentDataDict = chatJson.deviceId in this.conversationDictionary;
            if (isInCurrentDataDict) {
                let msgData = this.conversationDictionary[chatJson.deviceId];

                delete msgData.timestamp;
                msgData.sid = chatJson.sid;
                msgData.lastMessage = chatJson.message;
                msgData.html = chatJson.html;
                msgData.lastMessageSource = appEnums.MessageSource.Server;
                this.setState({});
            }
        } else if ("result" in info) {
            const result = info.result;
            const isInCurrentDataDict = result.deviceId in this.conversationDictionary;
            const conversations = this.getCurrentConversations();
            if (isInCurrentDataDict) {
                for (let i = conversations.length - 1; i >= 0; i--) {
                    let msgData = conversations[i];
                    if ("sid" in msgData && msgData.sid === result.sid) {
                        msgData.timestamp = result.timestamp;
                        this.setState({});
                        break;
                    }
                }
            }
        }
    };

    onReceiveMessage = chatJson => {
        const isInCurrentDataDict = chatJson.deviceId in this.conversationDictionary;
        const { bundleId, selectedDeviceId, token, appEvent } = this.props;
        if (isInCurrentDataDict) {
            const msgData = this.conversationDictionary[chatJson.deviceId];
            msgData.timestamp = chatJson.timestamp;
            msgData.lastMessage = chatJson.message;
            msgData.lastMessageSource = appEnums.MessageSource.Client;
            const { New, Open, Closed } = appEnums.MessageStates;

            const isNew =
                chatJson.bundleId === bundleId &&
                chatJson.deviceId === selectedDeviceId &&
                (msgData.state === Closed || msgData.state === New);
            if (isNew) {
                webApi
                    .fetchChangeState(chatJson.bundleId, chatJson.deviceId, New, token)
                    .catch(errDict => appEvent.fireEvent(AppEvent.EVENT_FETCH_ERROR, errDict));
                msgData.state = New;
            } else {
                msgData.state = Open;
            }
            this.setState({});
        } else {
            appEvent && appEvent.fireEvent(AppEvent.EVENT_FETCH_APP_CONVERSATIONS, bundleId);
        }
    };

    onChangeConversationState = dataDict => {
        const isInCurrentDataDict = dataDict.deviceId in this.conversationDictionary;
        if (isInCurrentDataDict) {
            const msgData = this.conversationDictionary[dataDict.deviceId];
            msgData.state = dataDict.state;
        }
    };

    msgListRef = node => {
        if (node) {
            this.listNode = node;
            this.listNode.addEventListener("scroll", this.handleScroll);
        }
    };

    render() {
        const { appEvent, bundleId, token, role } = this.props;
        const { newMessageCount, selectedDeviceId, onClickCreateAppButton } = this.props;

        if (this.props.appInfoDict[bundleId] == null) {
            return <div id="messageList" className="ui1-container-div slide-up-animation"></div>;
        }

        let conversationsToShow = this.getCurrentConversations() || [];
        if (this.state.filters.deviceType !== "All") {
            conversationsToShow = conversationsToShow.filter(this.filterDeviceFunction);
        }

        return (
            <div id="messageList" className="d-flex flex-column ui1-container-div slide-up-animation">
                <AppMenuUi
                    appInfoDict={this.props.appInfoDict}
                    bundleId={bundleId}
                    onClickCreateAppButton={onClickCreateAppButton}
                    onChangeApp={this.onChangeApp}
                    token={token}
                    role={role}
                    hcCompanyId={this.props.hcCompanyId}
                    newMessageCount={newMessageCount}
                    appEvent={this.props.appEvent}
                >
                    <ConversationFilterFormTrigger
                        filters={this.state.filters}
                        id="conversationFilterFormTrigger"
                        data-toggle="collapse"
                        data-target="#collapseFilters"
                        aria-expanded="false"
                        aria-controls="collapseFilters"
                    />
                </AppMenuUi>
                <div id="messageFilterDiv">
                    <input
                        id="messageSearchInput"
                        type="text"
                        onChange={this.onChangeSearch}
                        value={this.state.searchText}
                        title="Search here (message, udid, tags)"
                        placeholder="Search here (message, udid, tags)"
                    />
                    <ConversationFilterForm
                        filters={this.state.filters}
                        onApplyFilters={this.onApplyFilters}
                    />
                </div>
                {this.state.isLoading ? (
                    <Loading />
                ) : (
                    <div className="message-list-div slide-up-animation" ref={this.msgListRef}>
                        {conversationsToShow.map(data => (
                            <Link to={encodeURI(`/messages/${bundleId}/${data.deviceId}`)} key={data.deviceId}>
                                <MsgListItem
                                    searchKeyword={document.getElementById("messageSearchInput").value}
                                    msgData={data}
                                    bundleId={bundleId}
                                    appEvent={appEvent}
                                    selectedDeviceId={selectedDeviceId}
                                    onSelectCallback={() => this.onSelectItem(data)}
                                />
                            </Link>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}
