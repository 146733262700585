import React from "react";
import "./common.css";

export const BlockerUI = () => {
    return (
        <div id="blockerUiOverlay" tabIndex="-1" style={{ zIndex: 1000 }} role="dialog" aria-labelledby="blocker" aria-hidden="true">
            <div id="blockerMainDiv">
                <div className="spinner-border official-text-color" role="status" style={{ width: "4rem", height: "4rem", color: "#df6900" }}>
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    );
};
