import React from 'react';

const DeleteConfirm = props => {
    const onConfirm = () => {
        const { onConfirm } = props;
        if (onConfirm != null) {
            onConfirm();
        }
    };

    const onAbort = () => {
        const { onAbort } = props;
        if (onAbort != null) {
            onAbort();
        }
    };

    const { title, message, confirmLabel, abortLabel, domId } = props;
    return (
        <div
            className="modal fade"
            id={domId}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="confirmDeleteModal"
            aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="hc-modal-content">
                    <div className="hc-modal-header">
                        <div className="hc-header-content">
                            <img className="hc-header-img" src="/images/settings/icon_remove_popup.png" alt="warrning" />
                            <span className="hc-modal-title" id="confirmDeleteModal">
                                {title}
                            </span>
                        </div>
                        <button type="button" className="hc-modal-close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="hc-modal-body">
                        <p>{message}</p>
                    </div>
                    <div className="hc-modal-footer">
                        <button type="button" className="hc-modal-btn" onClick={onConfirm} data-dismiss="modal" id="confirmButton">
                            {confirmLabel}
                        </button>
                        <button type="button" className="hc-modal-btn" data-dismiss="modal" onClick={onAbort} id="abortButton">
                            {abortLabel}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteConfirm;
