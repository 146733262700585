import React from "react";
import "./chatLoadingUi.css";

export default function ChatLoadingUi() {
    return (
        <div className="d-flex justify-content-center chat-loading-container">
            <table>
                <tbody>
                    <tr>
                        <td align="center">
                            <img src="/images/login/logo_helpchatter.png" className="chat-loading-logo" alt="chat loading logo" />
                        </td>
                    </tr>
                    <tr>
                        <td align="center">
                            <img src="/images/chat/loading_image.png" className="chat-loading-circle" alt="chat loading circle" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
