import React, { Component } from "react";
import "./resetPasswordUi.css";
import webApi from "../api/webApi";
import appConstants from "../appConstants";

const pageEnum = { InputEmail: 0, SetNewPassword: 1, ResetPasswordSuccess: 2 };

class ResetPasswordUi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: pageEnum.InputEmail,
            email: "",
            newPassword: "",
            newPassword2: "",
            resetCode: "",
            error: ""
        };

        this.timerId = null;

        this.handleEmailChange = event => this.setState({ email: event.target.value });
        this.dismissError = () => this.setState({ error: "" });

        this.handleNewPasswordChange = event => this.setState({ newPassword: event.target.value });
        this.handleNewPasswordChange2 = event => this.setState({ newPassword2: event.target.value });
        this.handleResetCodeChange = event => this.setState({ resetCode: event.target.value });
    }

    componentWillUnmount() {
        if (this.timerId != null) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    }

    handleEmailKeyUp = event => {
        if (event.keyCode === 13) this.handleSendPasswordResetCodeEmailSubmit(event);
        else return false;
    };

    handleSendPasswordResetCodeEmailSubmit = event => {
        event.preventDefault();
        if (!this.state.email) {
            return this.setState({ error: "Email is required" });
        }
        this.setState({ error: "Sending..." }, () => {
            webApi
                .fetchSendPasswordResetCode(this.state.email)
                .then(this.onFetchSendPasswordResetCode)
                .catch(errDict => this.setState({ error: errDict.error }));
        });
    };

    onFetchSendPasswordResetCode = resDict => {
        if ("skip" in resDict) this.setState({ email: "", page: pageEnum.SetNewPassword, error: "" });
        else this.setState({ page: pageEnum.SetNewPassword, error: "" });
    };

    handleResetCodeKeyUp = event => {
        if (event.keyCode === 13) this.handleSetNewPasswordSubmit(event);
        else return false;
    };

    handleSetNewPasswordSubmit = event => {
        event.preventDefault();

        if (!this.state.newPassword) {
            return this.setState({ error: "New password is required" });
        }
        if (!this.state.newPassword2) {
            return this.setState({ error: "Confirm new password is required" });
        }
        if (this.state.newPassword !== this.state.newPassword2) {
            return this.setState({ error: "The two passwords are not the same" });
        }
        if (!this.state.resetCode) {
            return this.setState({ error: "Reset code is required" });
        }

        this.dismissError();

        webApi
            .fetchSetNewPassword(this.state.resetCode, this.state.newPassword)
            .then(this.onFetchSetNewPassword)
            .catch(errDict => this.setState({ error: errDict?.message }));
    };

    onFetchSetNewPassword = () => {
        this.setState(
            {
                page: pageEnum.ResetPasswordSuccess,
                newPassword: "",
                newPassword2: "",
                resetCode: ""
            },
            () => {
                this.timerId = setTimeout(() => {
                    window.location.reload();
                }, appConstants.SET_NEW_PASSWORD_SUCCESS_RELOAD_TIME);
            }
        );
    };

    render() {
        const errorUi = this.state.error ? (
            <div className="login-error alert-info" onClick={this.dismissError}>
                <span>
                    <button type="button" className="close" aria-label="Close" id="resetPasswordErrorState" onClick={this.dismissError}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    {this.state.error}
                </span>
            </div>
        ) : null;

        const imageBanner = <img src="/images/login/logo_helpchatter.png" id="helpChatterBannerLogo" alt="help chatter Logo" />;

        let ui = null;

        switch (this.state.page) {
            case pageEnum.InputEmail:
            default:
                ui = (
                    <div className="reset-password-container-div">
                        {imageBanner}
                        <div
                            className="mb-3 reset-password-card bg-transparent"
                            title="Enter your email and we will send you a reset code.">
                            <div className="reset-password-header-card">
                                <p className="reset-password-header-text">RESET PASSWORD</p>
                                <div className="triangle-right"></div>
                            </div>
                            <div className="reset-password-body-card">
                                <div className="reset-password-body-content">
                                    <form onSubmit={this.handleSendPasswordResetCodeEmailSubmit}>
                                        <div className="form-group container-fluid mb-5">
                                            <span className="reset-password-input-label">Email</span>
                                            <input
                                                id="resetPasswordEmailInput"
                                                autoFocus
                                                value={this.state.email}
                                                onChange={this.handleEmailChange}
                                                onKeyUp={this.handleEmailKeyUp}
                                                required={true}
                                                type="email"
                                            />
                                        </div>
                                        <input
                                            id="resetPasswordSendResetCodeEmailButton"
                                            type="submit"
                                            value="SUBMIT"
                                            alt="Enter your email and we will send you a reset code."
                                            title="Enter your email and we will send you a reset code."
                                        />
                                    </form>
                                    <label className="reset-code-link" onClick={() => this.onFetchSendPasswordResetCode({ skip: true })}>
                                        <u>Already have a reset code?</u>
                                    </label>
                                </div>
                            </div>
                            {errorUi}
                        </div>
                    </div>
                );
                break;

            case pageEnum.SetNewPassword: {
                const text =
                    this.state.email !== "" ? (
                        <div className="reset-message-div alert alert-info" role="alert">
                            <p>
                                We have sent a reset code to the email address:&nbsp;
                                {this.state.email}
                            </p>
                            <p>If the email does not arrive soon, check your spam folder (it was sent from support@kooapps.com).</p>
                        </div>
                    ) : null;
                ui = (
                    <div className="reset-password-container-div">
                        {imageBanner}
                        {text}
                        <div
                            className="mb-3 reset-password-card bg-transparent"
                            title="Enter your new password together with your reset code.">
                            <div className="reset-password-header-card">
                                <p className="reset-password-header-text">RESET PASSWORD</p>
                                <div className="triangle-right"></div>
                            </div>
                            <div className="reset-password-body-card">
                                <div className="reset-password-body-content">
                                    <div className="form-group container-fluid">
                                        <span className="login-label">New Password</span>
                                        <input
                                            type="password"
                                            id="resetPasswordNewPasswordInput"
                                            value={this.state.newPassword}
                                            onChange={this.handleNewPasswordChange}
                                            className="login-input"
                                        />
                                    </div>
                                    <div className="form-group container-fluid">
                                        <span className="login-label">Confirm New Password</span>
                                        <input
                                            type="password"
                                            id="resetPasswordNewPasswordInput2"
                                            value={this.state.newPassword2}
                                            onChange={this.handleNewPasswordChange2}
                                            className="login-input"
                                        />
                                    </div>

                                    <div className="form-group container-fluid">
                                        <span className="login-label">Reset Code</span>
                                        <input
                                            id="resetPasswordResetCodeInput"
                                            value={this.state.resetCode}
                                            onChange={this.handleResetCodeChange}
                                            onKeyUp={this.handleResetCodeKeyUp}
                                            className="login-input"
                                        />
                                    </div>

                                    <form onSubmit={this.handleSetNewPasswordSubmit}>
                                        <input
                                            id="resetPasswordSetNewPasswordButton"
                                            type="submit"
                                            value="SUBMIT"
                                            className="login-button"
                                        />
                                    </form>
                                </div>
                            </div>
                            {errorUi}
                        </div>
                    </div>
                );
                break;
            }
            case pageEnum.ResetPasswordSuccess:
                ui = (
                    <div className="reset-password-container-div">
                        <div className="reset-message-success alert alert-success" role="alert">
                            <p className="h3">Reset Your Password</p>
                            Your new password has been successfully set.
                            <p />
                            You may now login using your new password.
                        </div>
                    </div>
                );
                break;
        }

        return ui;
    }
}

export default ResetPasswordUi;
