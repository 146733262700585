import React from 'react';

const Tbd = props => {
    return (
        <div className="tbd-div slide-up-animation">
            <div className="tbd-content">
                <img src="/images/under_development.png" alt="Under Development" className="tbd-logo" />
                <p className="tbd-text">
                    This&nbsp;<strong>Page</strong>&nbsp;is&nbsp;<strong>Under Development</strong>.
                </p>
            </div>
        </div>
    );
};

export default Tbd;
